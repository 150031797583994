import { Avatar, Box, Card, Grid, Icon, Modal, Switch, Typography } from "@mui/material";
import axios from "axios";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { txtColorChange } from "layouts/Rewards/User/components/Common/ColorChange";
import DataGridTables from "layouts/Rewards/User/components/Common/DataGridTables";
import Loader from "layouts/Rewards/User/components/Common/Loader";
import URLSettings from "layouts/Rewards/User/components/Common/URLSettings";
import React, { useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
import addGiftCard from "../../../../../assets/images/add-giftcard.png";
import MDButton from "components/MDButton";
import { bgColorChange } from "layouts/Rewards/User/components/Common/ColorChange";
import CloseIcon from "@mui/icons-material/Close";
import MDInput from "components/MDInput";
import VisibilityIcon from "@mui/icons-material/Visibility";
import AddIcon from "@mui/icons-material/Add";
import MDSnackbar from "components/MDSnackbar";
import EditIcon from "@mui/icons-material/Edit";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import TextArea from "layouts/Rewards/User/components/Common/TextArea";
import TextareaAutosize from 'react-textarea-autosize';

const CustomGiftCards = () => {
  let APIURL = URLSettings();

  const [id, setId] = useState();
  const [cardName, setCardName] = useState();
  const [cardCode, setCardCode] = useState();
  const [cardValue, setCardValue] = useState();
  const [rInstructions, setRInstructions] = useState();
  const [terms, setTerms] = useState();
  const [description, setDescription] = useState();
  const [disclaimer, setDisclaimer] = useState();
  const [status, setStatus] = useState(true);
  const [codeVal, setCodeVal] = useState(false);
  const [codeList, setCodeList] = useState([]); 
  const [url, setURL] = useState();

  const [selectedFile, setSelectedFile] = useState(null);
  const handleFileSelect = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const [selectImportFile, setSelectImportFile] = useState(null);
  const handleImportFile = (event) => {
    setSelectImportFile(event.target.files[0]);
  };

  const [opText, setOpText] = useState(false);

  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpText(false);
    if (opText) {
      setId();
      setCardName();
      setCardCode();
      setCardValue();
      setSelectedFile();
      setURL();
      setRInstructions();
      setTerms();
      setDescription();
      setDisclaimer();
      setStatus(true);
      setCodeVal(false);
    }
    setOpen(false);
  };

  const [showImport, setShowImport] = useState(false);
  const handleOpenCodes = () => setShowImport(!showImport);
  const handleCloseCodes = () => setShowImport(false);

  const handleCodes = async (cell) => {
    setId(cell.id);
    handleOpenCodes();
  };

  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const handleShowClose = () => {
    setShow(false);
    setCodeList([]);
  };

  const handleCodeVal = () => {
    setCodeVal(!codeVal);
  };

  const handleStatus = () => {
    setStatus(!status);
  };

  const [eMsg, setEMsg] = useState("");
  const [createError, setCreateError] = useState(false);
  const [createSuccess, setCreateSuccess] = useState(false);
  const [updateError, setUpdateError] = useState(false);
  const [updateSuccess, setUpdateSuccess] = useState(false);
  const [importError, setImportError] = useState(false);
  const [importSuccess, setImportSuccess] = useState(false);

  const toggleSnackbar = () => {
    if (createError) {
      setCreateError(!createError);
    }
    if (createSuccess) {
      setCreateSuccess(!createSuccess);
    }
    if (updateError) {
      setUpdateError(!updateError);
    }
    if (updateSuccess) {
      setUpdateSuccess(!updateSuccess);
    }
    if (importError) {
      setImportError(!importError);
    }
    if (importSuccess) {
      setImportSuccess(!importSuccess);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      setCreateError(false);
      setCreateSuccess(false);
      setUpdateError(false);
      setUpdateSuccess(false);
      setImportError(false);
      setImportSuccess(false);
    }, 2000);
  }, [createError, createSuccess, updateError, updateSuccess, importError, importSuccess]);

  useEffect(() => {
    if (!localStorage.getItem("token")) {
      window.location.reload();
      return navigate("/login");
    }
  }, []);

  const customCards = useQuery(["customCard"], async () =>
    axios.get(`${APIURL.url}/admin/customgiftcard/catalog`, {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    })
  );

  const customCardList = useMutation(
    async () =>
      axios.get(`${APIURL.url}/admin/customgiftcards?gc_id=` + id, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }),
    {
      onError: (err) => {
        if (err.response.status === 401) {
          logout();
        }
      },
      onSuccess: (data) => {
        // console.log("data::", data);
        setCodeList(data?.data);
        // handleShow();
      },
    }
  );

  const handleListCards = async (cell) => {
    setId(cell.id);
    handleShow();
    setTimeout(() => {
      customCardList.mutate();
    }, 2000);
  };

  const handleCreate = async (event) => {
    event.preventDefault();

    if (selectedFile != null) {
      const formData = new FormData();
      formData.append("gc_image", selectedFile);
      formData.append("file_location", "Giftcards");
      formData.append("code", APIURL.code);
      formData.append("card_name", cardName);
      formData.append("card_code", cardCode);
      formData.append("card_value", cardValue);
      formData.append("redemption_instructions", rInstructions);
      formData.append("terms", terms);
      formData.append("description", description);
      formData.append("disclaimer", disclaimer);
      formData.append("status", status);
      try {
        const response = await axios({
          method: "post",
          url: `${APIURL.url}/admin/customgiftcard/catalog`,
          data: formData,
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: localStorage.getItem("token"),
          },
        });
        if (response) {
          setCreateSuccess(true);
          setCardName();
          setCardCode();
          setCardValue();
          setSelectedFile();
          setURL();
          setRInstructions();
          setTerms();
          setDescription();
          setDisclaimer();
          setStatus(true);
          setCodeVal(false);
          handleClose();
          customCards.refetch();
        }
      } catch (error) {
        setEMsg(err.response.data);
        setCreateError(true);
        if (err.response.status === 401) {
          window.location.reload();
          return navigate("/login");
        }
      }
    } else {
      setEMsg("Please select the card image");
      setCreateError(true);
    }
  };

  const handleCardEdit = async (cell) => {
    setId(cell.id);
    setCardName(cell.card_name);
    setCardCode(cell.card_code);
    setCardValue(cell.card_value);
    setRInstructions(cell.redemption_instructions);
    setTerms(cell.terms);
    setDescription(cell.description);
    setDisclaimer(cell.disclaimer);
    setStatus(cell.status);
    setURL(cell.image_url);
    setOpText(true);
    handleOpen();
  };

  const handleUpdate = async (event) => {
    event.preventDefault();

    // if (selectedFile != null) {
      const formData = new FormData();
      if (selectedFile != null && (url === '' || url === undefined)) {
        formData.append("gc_image", selectedFile);
        formData.append("file_location", "Giftcards");
        formData.append("code", APIURL.code);
      } else {
        formData.append("url", url);
      }
      formData.append("card_name", cardName);
      formData.append("card_code", cardCode);
      formData.append("card_value", cardValue);
      formData.append("redemption_instructions", rInstructions);
      formData.append("terms", terms);
      formData.append("description", description);
      formData.append("disclaimer", disclaimer);
      formData.append("status", status);
      
      try {
        const response = await axios({
          method: "put",
          url: `${APIURL.url}/admin/customgiftcard/catalog?pk_id=` + id,
          data: formData,
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: localStorage.getItem("token"),
          },
        });
        if (response) {
          setUpdateSuccess(true);
          setId();
          setCardName();
          setCardCode();
          setCardValue();
          setSelectedFile();
          setURL('');
          setRInstructions();
          setTerms();
          setDescription();
          setDisclaimer();
          setStatus(true);
          setCodeVal(false);
          handleClose();
          customCards.refetch();
        }
      } catch (err) {
        setEMsg(err.response.data);
        setUpdateError(true);
        if (err.response.status === 401) {
          window.location.reload();
          return navigate("/login");
        }
      }
    // } else {
    //   setEMsg("Please select the card image");
    //   setCreateError(true);
    // }
  };

  const handleAddCardsList = async (event) => {
    event.preventDefault();

    if (selectImportFile != null) {
      const formData = new FormData();
      formData.append("csv_codes_data", selectImportFile);
      formData.append("file_location", "Giftcards");
      formData.append("code", APIURL.code);

      try {
        const response = await axios({
          method: "post",
          url: `${APIURL.url}/admin/customgiftcards?gc_id=` + id,
          data: formData,
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: localStorage.getItem("token"),
          },
        });
        if (response) {
          setImportSuccess(true);
          customCards.refetch();
          handleCloseCodes();
        }
      } catch (err) {
        setEMsg(err.response.data);
        setImportError(true);
        if (err.response.status === 401) {
          window.location.reload();
          return navigate("/login");
        }
      }
    } else {
      setEMsg("Please select the File");
      setImportError(true);
    }
  };

  if (customCards.isError) {
    if (customCards.error.response.status === 401) {
      window.location.reload();
      return navigate("/login");
    }
  }

  if (!customCards.isSuccess) {
    return <Loader />;
  }

  const cardColumns = [
    {
      field: "code_val",
      headerName: "Code Value",
      minWidth: 200,
      renderCell: (params) => {
        if (params.row.code_generated) {
          return <span className="txtGreen">{params.value}</span>;
        } else {
          return <span className="txtRed">{params.value}</span>;
        }
      },
    },
  ];

  const columns = [
    {
      field: "id",
      headerName: "ID",
      width: 90,
    },
    {
      field: "card_name",
      headerName: "Card Name",
      minWidth: 200,
    },
    {
      field: "card_code",
      headerName: "Card Code",
      minWidth: 200,
    },
    {
      field: "card_value",
      headerName: "Card Value",
      minWidth: 200,
    },
    {
      field: "image_url",
      headerName: "Card Image",
      minWidth: 200,
      renderCell: (params) => {
        return <img src={params.value} width="50" />;
      },
    },
    {
      field: "no_of_cards_left",
      headerName: "No of Cards Left",
      minWidth: 200,
    },
    {
      field: "actions",
      headerName: "Actions",
      sortable: false,
      renderCell: (params) => {
        return (
          <>
            <MDButton
              variant="gradient"
              color="error"
              size="small"
              iconOnly
              onClick={() => handleCodes(params.row)}
              sx={bgColorChange}
              className="viewIcon"
            >
              <CloudUploadIcon />
            </MDButton>
            <MDButton
              variant="gradient"
              color="error"
              size="small"
              iconOnly
              onClick={() => handleListCards(params.row)}
              sx={bgColorChange}
              className="viewIcon"
            >
              <VisibilityIcon />
            </MDButton>
            <MDButton
              variant="gradient"
              color="error"
              size="small"
              iconOnly
              onClick={() => handleCardEdit(params.row)}
              sx={bgColorChange}
            >
              <EditIcon />
            </MDButton>
          </>
        );
      },
    },
  ];

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Card>
        <MDBox p={2} m={2}>
          <Grid container>
            <Grid item xs={6}>
              <MDTypography variant="h5" fontWeight="bold" sx={txtColorChange}>
                Custom Gift Cards
              </MDTypography>
            </Grid>
            <Grid item xs={6}>
              <MDButton
                variant="gradient"
                color="info"
                sx={bgColorChange}
                className="adminBtn iconBtns"
                onClick={handleOpen}
              >
                <img src={addGiftCard} width="25" height="25" />
              </MDButton>
            </Grid>
          </Grid>
        </MDBox>
        <MDSnackbar
          color="error"
          icon="error"
          title="Error: Something went wrong"
          content={eMsg}
          open={createError}
          close={toggleSnackbar}
        />
        <MDSnackbar
          color="success"
          icon="check_circle"
          title="Success"
          content="Card Created"
          open={createSuccess}
          close={toggleSnackbar}
        />
        <MDSnackbar
          color="error"
          icon="error"
          title="Error: Something went wrong"
          content={eMsg}
          open={updateError}
          close={toggleSnackbar}
        />
        <MDSnackbar
          color="success"
          icon="check_circle"
          title="Success"
          content="Card Updated"
          open={updateSuccess}
          close={toggleSnackbar}
        />
        <MDSnackbar
          color="error"
          icon="error"
          title="Error: Something went wrong"
          content={eMsg}
          open={importError}
          close={toggleSnackbar}
        />
        <MDSnackbar
          color="success"
          icon="check_circle"
          title="Success"
          content="Codes Imported"
          open={importSuccess}
          close={toggleSnackbar}
        />
        <DataGridTables tableRow={customCards?.data?.data} columns={columns} />
      </Card>

      {/* Cards List View */}
      <Modal
        open={show}
        onClose={handleShowClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="modalPopupBox">
          <Typography id="keep-mounted-modal-title" variant="h5" component="h2" sx={txtColorChange}>
            Custom Gift Card List
          </Typography>
          <MDBox sx={{ minHeight: "150px" }}>
            {!customCardList.isSuccess ? (
              <Loader />
            ) : (
              <DataGridTables tableRow={codeList} columns={cardColumns} txtColor="dark" />
            )}
          </MDBox>
        </Box>
      </Modal>

      {/* Upload Codes */}
      <Modal
        open={showImport}
        onClose={handleCloseCodes}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="modalPopupBox">
          <Typography id="keep-mounted-modal-title" variant="h5" component="h2" sx={txtColorChange}>
            Import Custom Codes
          </Typography>
          <MDBox sx={{ minHeight: "150px" }}>
            <Grid container>
              <Grid item xs={6} className="cardPos">
                <MDBox mt={2} mb={2}>
                  <span fontSize="small" className="cardImage">
                    Upload Code Value
                  </span>
                  <MDInput type="file" fullWidth onChange={handleImportFile} />
                </MDBox>
              </Grid>
            </Grid>
            <MDBox mt={2} mb={1}>
              <MDButton
                variant="gradient"
                color="info"
                onClick={handleAddCardsList}
                sx={bgColorChange}
              >
                Submit
              </MDButton>
            </MDBox>
          </MDBox>
        </Box>
      </Modal>

      {/* Add Custom Card */}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="modalPopupBox" sx={{ overflowY: "scroll", height: "600px" }}>
          <Typography id="keep-mounted-modal-title" variant="h5" component="h2" sx={txtColorChange}>
            {opText ? "Update Custom Gift Card" : "Add Custom Gift Card"}
          </Typography>
          <MDBox component="form" role="form">
            <MDBox mt={2} mb={2}>
              <Grid container>
                <Grid item xs={6}>
                  <MDBox mt={2} mb={2} mr={1}>
                    <MDInput
                      type="text"
                      label="Card Name"
                      fullWidth
                      placeholder="Card Name"
                      value={cardName}
                      onChange={(e) => {
                        setCardName(e.target.value);
                      }}
                    />
                  </MDBox>
                </Grid>
                <Grid item xs={6}>
                  <MDBox mt={2} mb={2}>
                    <MDInput
                      type="text"
                      label="Card Code"
                      fullWidth
                      placeholder="Card Code"
                      value={cardCode}
                      onChange={(e) => {
                        setCardCode(e.target.value);
                      }}
                    />
                  </MDBox>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={6}>
                  <MDBox mt={2} mb={2} mr={1}>
                    <MDInput
                      type="text"
                      label="Card Value"
                      fullWidth
                      placeholder="Card Value"
                      value={cardValue}
                      onChange={(e) => {
                        setCardValue(e.target.value);
                      }}
                    />
                  </MDBox>
                </Grid>
                <Grid item xs={6} className="cardPos">
                  <MDBox mt={2} mb={2}>
                    <span fontSize="small" className="cardImage">
                      Card Image
                    </span>
                    <MDInput type="file" fullWidth onChange={handleFileSelect} />
                    <span fontSize="small" className="noteCardText">
                      (200w-326ppi)
                    </span>
                  </MDBox>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={12}>
                  <MDBox>
                    <MDTypography
                      variant="p"
                      fontWeight="regular"
                      color="text"
                      sx={{ fontSize: "12px", color: "#ffffff" }}>Description</MDTypography>
                    <TextareaAutosize
                      value={description}
                      placeholder="Description"
                      minRows={3}
                      rows={5}
                      maxRows={3}
                      onChange={(e) => setDescription(e.target.value)}
                      className="textArea"
                    />
                    {/* <TextArea label="Description" value={description} handleChange={(val) => setDescription(val)} /> */}
                  </MDBox>
                </Grid>
                <Grid item xs={12}>
                  <MDBox>
                    <MDTypography
                      variant="p"
                      fontWeight="regular"
                      color="text"
                      sx={{ fontSize: "12px", color: "#ffffff" }}>Redemption Instructions</MDTypography>
                    <TextareaAutosize
                      placeholder="Redemption Instructions"
                      minRows={3}
                      rows={5}
                      maxRows={3}
                      value={rInstructions}
                      onChange={(e) => setRInstructions(e.target.value)}
                      className="textArea"
                    />
                    {/* <TextArea label="Redemption Instructions" value={rInstructions} handleChange={(val) => setRInstructions(val)} /> */}
                  </MDBox>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={12}>
                  <MDBox>
                  <MDTypography
                      variant="p"
                      fontWeight="regular"
                      color="text"
                      sx={{ fontSize: "12px", color: "#ffffff" }}
                    >Terms</MDTypography>
                    <TextareaAutosize
                      placeholder="Terms"
                      minRows={3}
                      rows={5}
                      maxRows={3}
                      value={terms}
                      onChange={(e) => setTerms(e.target.value)}
                      className="textArea"
                    />
                    {/* <TextArea label="Terms" value={terms} handleChange={(val) => setTerms(val)} /> */}
                  </MDBox>
                </Grid>
                <Grid item xs={12}>
                  <MDBox>
                  <MDTypography
                      variant="p"
                      fontWeight="regular"
                      color="text"
                      sx={{ fontSize: "12px", color: "#ffffff" }}
                    >
                  Disclaimer
                  </MDTypography>
                    <TextareaAutosize
                      placeholder="Disclaimer"
                      minRows={3}
                      rows={5}
                      maxRows={3}
                      value={disclaimer}
                      onChange={(e) => setDisclaimer(e.target.value)}
                      className="textArea"
                    />
                    {/* <TextArea label="Disclaimer" value={disclaimer} handleChange={(val) => setDisclaimer(val)} /> */}
                  </MDBox>
                </Grid>
              </Grid>
              <Grid container spacing={1}>
                <Grid item xs={6}>
                  <MDBox mt={2} mb={2}>
                    <MDTypography
                      variant="button"
                      fontWeight="regular"
                      color="text"
                      sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
                    >
                      &nbsp;&nbsp;Status :&nbsp;
                    </MDTypography>
                    <Switch
                      checked={status}
                      onChange={handleStatus}
                      // sx={{
                      //   "&.MuiSwitch-root .MuiSwitch-thumb":{
                      //     backgroundColor: "#F44335",
                      //   },
                      //   "&.MuiSwitch-root .Mui-checked .MuiSwitch-thumb": {
                      //     backgroundColor: "#4CAF50",
                      //   },
                      //   "&.MuiSwitch-root .MuiSwitch-switchBase": {
                      //     color: "#F44335",
                      //   },
                      // }}
                    />
                  </MDBox>
                </Grid>
              </Grid>
            </MDBox>
            <MDBox mt={2} mb={1}>
              {opText ? (
                <>
                  <MDButton
                    variant="gradient"
                    color="info"
                    onClick={handleUpdate}
                    sx={bgColorChange}
                  >
                    Submit
                  </MDButton>
                </>
              ) : (
                <>
                  <MDButton
                    variant="gradient"
                    color="info"
                    onClick={handleCreate}
                    sx={bgColorChange}
                  >
                    Submit
                  </MDButton>
                </>
              )}
            </MDBox>
          </MDBox>
        </Box>
      </Modal>
    </DashboardLayout>
  );
};

export default CustomGiftCards;
