/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import { Skeleton } from "@mui/material";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";

// Timeline context
import { useTimeline } from "examples/Timeline/context";

// Custom styles for the TimelineItem
import timelineItem from "examples/Timeline/TimelineItem/styles";

function TimelineItemSkeleton({ lastItem }) {
  const isDark = useTimeline();

  return (
    <MDBox position="relative" mb={3} sx={(theme) => timelineItem(theme, { lastItem, isDark })}>
      <MDBox
        display="flex"
        justifyContent="center"
        alignItems="center"
        bgColor="secondary"
        color="white"
        width="2rem"
        height="2rem"
        borderRadius="50%"
        position="absolute"
        top="8%"
        left="2px"
        zIndex={2}
        sx={{ fontSize: ({ typography: { size } }) => size.sm }}
      >
        <Skeleton variant="circle" width={20} height={20} animation="wave" />
      </MDBox>
      <MDBox ml={5.75} pt={0.7} lineHeight={0} maxWidth="30rem">
        <Skeleton variant="text" width="100%" height={20} animation="wave" />
        <MDBox mt={0.5}>
          <Skeleton variant="text" width="50%" height={10} animation="wave" />
        </MDBox>
        <MDBox mt={2} mb={1.5}>
          <Skeleton variant="text" width="90%" height={20} animation="wave" />
        </MDBox>
      </MDBox>
    </MDBox>
  );
}

// Setting default values for the props of TimelineItem
TimelineItemSkeleton.defaultProps = {
  lastItem: false,
};

// Typechecking props for the TimelineItem
TimelineItemSkeleton.propTypes = {
  lastItem: PropTypes.bool,
};

export default TimelineItemSkeleton;
