import { txtColorChange } from "./ColorChange";

const HighlightText = (text) => {

  const words = text.split(' ');

  return (
    <div style={{ whiteSpace: 'pre-line' }}>
      {words.map((word, index) => (
        <span key={index} style={(word.startsWith('#') || word.startsWith('@')) ? { color: {txtColorChange}, fontWeight: 'bold' } : null}>
          {word}
          {index !== words.length - 1 && ' '}
        </span>
      ))}
    </div>
  );

  // console.log("tx::", text1);
  // const text = 'This is a #hashtag and this is another word.';
  //   return 
  //   <div style={{ whiteSpace: 'pre-line' }}>
  //     {text.split(' ').map((word, index) => (
  //     if (word.startsWith('#')) {
  //       return (
  //         <span key={`hash-${index}`} style={{ color: 'green', fontWeight: 'bold' }}>
  //           {word}{' '}
  //         </span>
  //       );
  //     } else if (word.startsWith('@')) {
  //       return (
  //         <span key={`at-${index}`} style={{ color: 'blue', fontWeight: 'bold' }}>
  //           {word}{' '}
  //         </span>
  //       );
  //     } else {
  //       return <span key={`normal-${index}`}>{word }{' '} </span>;
  //     }
  //   ))};
  // </div>
};

export default HighlightText;

