// import Card from "@mui/material/Card";
// import Divider from "@mui/material/Divider";

// Material Dashboard 2 PRO React components
import axios from "axios";
import { Box, Card, Grid, Icon, Modal, Switch, Tooltip, Typography } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
// import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import SiteFooter from "layouts/Rewards/User/components/Common/SiteFooter";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useMutation, useQuery } from "react-query";
import MDInput from "components/MDInput";
import URLSettings from "layouts/Rewards/User/components/Common/URLSettings";
import MDSnackbar from "components/MDSnackbar";
import Loader from "layouts/Rewards/User/components/Common/Loader";
import Logout from "layouts/Rewards/User/components/Common/Logout";
import { txtColorChange, bgColorChange } from "layouts/Rewards/User/components/Common/ColorChange";
import CloseIcon from '@mui/icons-material/Close';
import MDAvatar from "components/MDAvatar";
import DashboardMenu from "examples/Navbars/DashboardMenu";
// OrderDetails page components

const jose = require("jose");

function AdminSettings() {
  let APIURL = URLSettings();
  const navigate = useNavigate();

  useEffect(() => {
    if (!localStorage.getItem("token")) {
      window.location.reload();
    return navigate("/login");
    }
  }, []);

  const [eMsg, setEMsg] = useState("");
  const [sendError, setSendError] = useState("");
  const [sendSuccess, setSendSuccess] = useState("");
  const toggleSnackbar = () => {
    if (sendError) {
      setSendError(!sendError);
    }
    if (sendSuccess) {
      setSendSuccess(!sendSuccess);
    }
  };
  useEffect(() => {
    setTimeout(() => {
      setSendSuccess(false);
      setSendError(false);
    }, 2000);
  }, [sendError, sendSuccess]);

  const claims = jose.decodeJwt(localStorage.getItem("token"));

  const settings = useQuery(["settings"], async () =>
    axios.get(`${APIURL.url}/admin/settings`, {
      params: {
        company_id: claims.company_id,
      },
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    })
  );

  // if (claims.sp_admin !== "yes") {
  //   return navigate("/home");
  // }


  if (settings.isError) {
    setEMsg(settings.error.response.data);
    if (settings.error.response.status === 401) {
      window.location.reload();
      return navigate("/login");
    }
  }

  const [Name, setName] = useState("");
  const [sVal, setSValue] = useState("");
  const [sId, setSId] = useState("");
  const [sStatus, setSStatus] = useState("");
  const handleStatus = () => {
    setSStatus(!sStatus);
  };

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [imageType, setImageType] = useState("");
  const [fLocation, setFLocation] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const handleFileSelect = (event) => {
    setSelectedFile(event.target.files[0]);
  };
  const handleEdit = (row) => {
    setImageType(row.type)
    setFLocation("common");
    // image_link/
    setSId(row.id);
    setName(row.Name);
    setSValue(row.Value);
    setSStatus(row.Status);
    handleOpen();
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (selectedFile != null) { 

      const formData = new FormData();
      formData.append("file", selectedFile);
      formData.append("file_location", fLocation);
      formData.append("code", APIURL.code);
      formData.append("s_id", sId);
      formData.append("name", Name);
      formData.append("value", sVal);
      formData.append("status", sStatus);
      try {
        const response = await axios({
          method: "post",
          url: `${APIURL.url}/admin/updateSettings`,
          data: formData,
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: localStorage.getItem("token"),
          },
        });
        // console.log("res::", response);
        if (response) {
          // console.log(response);
          setSendSuccess(true);
          settings.refetch();
          setSId("");
          setName("");
          setSValue("");
          setSStatus("");
          handleClose();
        }
      } catch (error) {
        // console.log(error);
        setEMsg(error.response.data);
        setSendError(true);
        handleClose();
        if (error.response.status === 401) {
          window.location.reload();
          return navigate("/login");
        }
      }

    } else {

      let jsonData = {
          "data": [
              {
                  "s_id": sId,
                  "name": Name,
                  "value": sVal,
                  "status": sStatus
              }
          ]
      }

      try {
        const response = await axios({
          method: "post",
          url: `${APIURL.url}/admin/settings`,
          data: jsonData,
          // data: {
          //   s_id: sId,
          //   name: Name,
          //   value: sVal,
          //   status: sStatus
          // },
          headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.getItem("token"),
          },
        });
        if (response) {
          setSendSuccess(true);
          settings.refetch();
          setSId("");
          setName("");
          setSValue("");
          setSStatus("");
          handleClose();
        }
      } catch (error) {
        setEMsg(response.data);
        setSendError(true);
        handleClose();
        if (error.response.status === 401) {
          window.location.reload();
          return navigate("/login");
        }
      }

    }
  };
  
  let designFlag = false;
  let adminFlag = false;
  let sadminFlag = false;
  if (claims.user_roles.indexOf("design") > -1) {
    designFlag = true;
  }
  if (claims.user_roles.indexOf("admin") > -1) {
    adminFlag = true;
  }
  if (claims.user_roles.indexOf("super admin") > -1) {
    sadminFlag = true;
  }

  if (!settings.isSuccess) {
    return <Loader />;
  }

  return (
    <DashboardLayout>
      {/* <DashboardNavbar /> */}
      <DashboardMenu />
      <MDBox my={3}>
        <MDSnackbar
          color="error"
          icon="error"
          title="Error: Please try again"
          content={eMsg}
          open={sendError}
          close={toggleSnackbar}
        />
        <MDSnackbar
          color="success"
          icon="check_circle"
          title="Success"
          content="Settings Updated"
          open={sendSuccess}
          close={toggleSnackbar}
        />
        <Grid container mt={2} mb={2}>
          {/* {(adminFlag || sadminFlag) && (
          <Grid xs={12} md={6} lg={6}>
            <Card sx={{marginBottom: "5px", height: "380px"}}>
              <MDBox p={2} m={2}>
                <MDTypography variant="h5" fontWeight="bold" sx={txtColorChange}>
                  Side Menu Text
                </MDTypography>
                <MDBox pt={1} pb={1} px={3}>
                  {settings.data.data.rows.map((info) => ( <>
                    { (info.Name === "side_bar_1" || info.Name === "side_bar_2" || info.Name === "side_bar_3" || info.Name === "side_bar_4" || info.Name === "side_bar_5" || info.Name === "side_bar_6") && (
                    <Grid container spacing={3} mt={2} mb={1}>
                      <Grid xs={12} md={6} lg={6}>
                        <MDTypography
                          display="inline-block"
                          component="p"
                          variant="button"
                          fontWeight="regular"
                          color="text"
                        >
                          {info.Name}:
                        </MDTypography>
                      </Grid>
                      <Grid xs={12} md={6} lg={6}>
                        <MDBox
                          alt="spotify logo"
                          sx={{ marginTop: "-8px", float: "left", marginRight: "8px" }}
                        >
                          <Tooltip title="Edit" placement="top">
                            <MDButton
                              variant="gradient"
                              color="info"
                              size="small"
                              iconOnly
                              onClick={() => handleEdit(info)}
                              sx={bgColorChange}
                            >
                              <Icon>edit</Icon>
                            </MDButton>
                          </Tooltip>
                        </MDBox>
                        <MDTypography
                          display="inline-block"
                          component="p"
                          variant="button"
                          fontWeight="regular"
                          color="text"
                          sx={{ float: "left" }}
                        >
                          <b>{info.Value}</b>
                          {info.type === 'image_link' && (
                            <img src={info.Value} width="25" height="25" className="imgLink" />
                          )}
                          {info.Value.includes("#") === true && (
                            <Box className="imgLink" sx={{ width: "25px", float: "right", height: "25px", backgroundColor: info.Value }}></Box>
                          )} 
                        </MDTypography>
                      </Grid>
                    </Grid>
                    )}
                    </>
                  ))}
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
          )} */}
          {(adminFlag || sadminFlag) && (
          <Grid xs={12} md={6} lg={6}>
            <Card sx={{marginBottom: "5px", marginLeft: "5px", paddingBottom: "10px", height: "380px"}}>
              <MDBox p={2} m={2}>
                <MDTypography variant="h5" fontWeight="bold" sx={txtColorChange}>
                  Org. Hierarchy
                </MDTypography>
                <MDBox pt={2} pb={4} px={3}>
                  {settings.data.data.rows.map((info) => ( <>
                    { (info.Name === "org_level_1" || info.Name === "org_level_2" || info.Name === "org_level_3" || info.Name === "org_level_4") && (
                    <Grid container spacing={3} mt={2} mb={2}>
                      <Grid xs={12} md={6} lg={6}>
                        <MDTypography
                          display="inline-block"
                          component="p"
                          variant="button"
                          fontWeight="regular"
                          color="text"
                        >
                          {info.Name}:
                        </MDTypography>
                      </Grid>
                      <Grid xs={12} md={6} lg={6}>
                        <MDBox
                          alt="spotify logo"
                          sx={{ marginTop: "-8px", float: "left", marginRight: "8px" }}
                        >
                          <Tooltip title="Edit" placement="top">
                            <MDButton
                              variant="gradient"
                              color="info"
                              size="small"
                              iconOnly
                              onClick={() => handleEdit(info)}
                              sx={bgColorChange}
                            >
                              <Icon>edit</Icon>
                            </MDButton>
                          </Tooltip>
                        </MDBox>
                        <MDTypography
                          display="inline-block"
                          component="p"
                          variant="button"
                          fontWeight="regular"
                          color="text"
                          sx={{ float: "left" }}
                        >
                          <b>{info.Value}</b>
                          {info.type === 'image_link' && (
                            <img src={info.Value} width="25" height="25" className="imgLink" />
                          )}
                          {info.Value.includes("#") === true && (
                            <Box className="imgLink" sx={{ width: "25px", float: "right", height: "25px", backgroundColor: info.Value }}></Box>
                          )} 
                        </MDTypography>
                      </Grid>
                    </Grid>
                    )}
                    </>
                  ))}
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
          )}
          {/* {(adminFlag || sadminFlag || designFlag) && (
          <Grid xs={12} md={8} lg={8}>
            <Card sx={{marginBottom: "5px", marginLeft: "5px", height: "410px"}}>
              <MDBox p={2} m={2}>
                <MDTypography variant="h5" fontWeight="bold" sx={txtColorChange}>
                  Social Media
                </MDTypography>
                <MDBox pt={2} pb={3} px={3}>
                  {settings.data.data.rows.map((info) => ( <>
                    { (info.Name === "facebook" || info.Name === "instagram" || info.Name === "twitter" || info.Name === "tiktok" || info.Name === "linkedin") && (
                    <Grid container spacing={3} mt={2} mb={2}>
                      <Grid xs={12} md={3} lg={3}>
                        <MDTypography
                          display="inline-block"
                          component="p"
                          variant="button"
                          fontWeight="regular"
                          color="text"
                        >
                          {info.Name}:
                        </MDTypography>
                      </Grid>
                      <Grid xs={12} md={9} lg={9}>
                        <MDBox
                          alt="spotify logo"
                          sx={{ marginTop: "-8px", float: "left", marginRight: "8px" }}
                        >
                          <Tooltip title="Edit" placement="top">
                            <MDButton
                              variant="gradient"
                              color="info"
                              size="small"
                              iconOnly
                              onClick={() => handleEdit(info)}
                              sx={bgColorChange}
                            >
                              <Icon>edit</Icon>
                            </MDButton>
                          </Tooltip>
                        </MDBox>
                        <MDTypography
                          display="inline-block"
                          component="p"
                          variant="button"
                          fontWeight="regular"
                          color="text"
                          sx={{ float: "left", marginRight: "15px",  width: "90%" }}
                        >
                          <b>{info.Value}</b>
                          {info.type === 'image_link' && (
                            <img src={info.Value} width="25" height="25" className="imgLink" />
                          )}
                          {info.Value.includes("#") === true && (
                            <Box className="imgLink" sx={{ width: "25px", float: "right", height: "25px", backgroundColor: info.Value }}></Box>
                          )} 
                        </MDTypography>
                      </Grid>
                    </Grid>
                    )}
                    </>
                  ))}
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
          )} */}
          {(sadminFlag) && (
          <Grid xs={12} md={6} lg={6}>
            <Card sx={{marginBottom: "5px", marginLeft: "5px", height: "380px"}}>
              <MDBox p={2} m={2}>
                <MDTypography variant="h5" fontWeight="bold" sx={txtColorChange}>
                  Layout Config
                </MDTypography>
                <MDBox pt={2} pb={3} px={3}>
                  {settings.data.data.rows.map((info) => ( <>
                    { (info.Name === "layoutOrder") && (
                    <Grid container spacing={3} mt={2} mb={2}>
                      <Grid xs={12} md={4} lg={4}>
                        <MDTypography
                          display="inline-block"
                          component="p"
                          variant="button"
                          fontWeight="regular"
                          color="text"
                        >
                          {info.Name}:
                        </MDTypography>
                      </Grid>
                      <Grid xs={12} md={8} lg={8}>
                        <MDBox
                          alt="spotify logo"
                          sx={{ marginTop: "-8px", float: "left", marginRight: "8px" }}
                        >
                          <Tooltip title="Edit" placement="top">
                            <MDButton
                              variant="gradient"
                              color="info"
                              size="small"
                              iconOnly
                              onClick={() => handleEdit(info)}
                              sx={bgColorChange}
                            >
                              <Icon>edit</Icon>
                            </MDButton>
                          </Tooltip>
                        </MDBox>
                        <MDTypography
                          display="inline-block"
                          component="p"
                          variant="button"
                          fontWeight="regular"
                          color="text"
                          sx={{ float: "left" }}
                        >
                          <b>{info.Value}</b>
                          {info.type === 'image_link' && (
                            <img src={info.Value} width="25" height="25" className="imgLink" />
                          )}
                          {info.Value.includes("#") === true && (
                            <Box className="imgLink" sx={{ width: "25px", float: "right", height: "25px", backgroundColor: info.Value }}></Box>
                          )} 
                        </MDTypography>
                      </Grid>
                    </Grid>
                    )}
                    </>
                  ))}
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
          )}
          
          {/* {(adminFlag || sadminFlag || designFlag) && (
          <Grid xs={12} md={6} lg={6}>
            <Card sx={{marginBottom: "5px", marginLeft: "5px", height: "540px"}}>
              <MDBox p={2} m={2}>
                <MDTypography variant="h5" fontWeight="bold" sx={txtColorChange}>
                  Activity
                </MDTypography>
                <MDBox pt={2} pb={3} px={3}>
                  <Grid container spacing={3} mt={2} mb={2}>
                    {settings.data.data.rows.map((info) => ( <>
                      { (info.Name === "activity_name" || info.Name === "activity_string" || info.Name === "activity_video" || info.Name === "like_no" || info.Name === "like_yes") && (<>
                      {info.type === "image_link" ? (
                        <Grid xs={12} md={6} lg={6}>
                          <MDBox m={2} sx={{ marginBottom: "10px" }}>
                            <MDBox position="relative" height="max-content" mx="auto">
                              <MDTypography
                                display="inline-block"
                                component="p"
                                variant="button"
                                fontWeight="regular"
                                color="text"
                              >
                                {info.Name}:
                              </MDTypography>
                              <MDAvatar
                                src={info.Value}
                                alt="profile picture"
                                size="xl"
                                variant="rounded"
                              />
                              <MDBox
                                alt="spotify logo"
                                position="absolute"
                                left={0}
                                bottom={0}
                                mr={-3}
                                mb={-3}
                              >
                                <Tooltip title="Edit" placement="top">
                                  <MDButton
                                    variant="gradient"
                                    color="info"
                                    size="small"
                                    iconOnly
                                    onClick={() => handleEdit(info)}
                                    sx={bgColorChange}
                                  >
                                    <Icon>edit</Icon>
                                  </MDButton>
                                </Tooltip>
                                <MDTypography
                                display="inline-block"
                                component="p"
                                variant="button"
                                fontWeight="regular"
                                color="text"
                              >
                                (24*24)
                              </MDTypography>
                              </MDBox>
                            </MDBox>
                          </MDBox>
                        </Grid>
                      ) : (
                      <Grid container spacing={3} mt={2} mb={2}>
                        <Grid xs={12} md={6} lg={6}>
                          <MDTypography
                            display="inline-block"
                            component="p"
                            variant="button"
                            fontWeight="regular"
                            color="text"
                          >
                            {info.Name}:
                          </MDTypography>
                        </Grid>
                        <Grid xs={12} md={6} lg={6}>
                          <MDBox
                            alt="spotify logo"
                            sx={{ marginTop: "-8px", float: "left", marginRight: "8px" }}
                          >
                            <Tooltip title="Edit" placement="top">
                              <MDButton
                                variant="gradient"
                                color="info"
                                size="small"
                                iconOnly
                                onClick={() => handleEdit(info)}
                                sx={bgColorChange}
                              >
                                <Icon>edit</Icon>
                              </MDButton>
                            </Tooltip>
                          </MDBox>
                          {info.Name === "activity_video" ? (<>
                            <MDTypography
                              display="inline-block"
                              component="p"
                              variant="button"
                              fontWeight="regular"
                              color="text"
                              sx={{ float: "left", width: "120px" }}
                            >
                              <b>{info.Value}</b>
                              {info.type === 'image_link' && (
                                <img src={info.Value} width="25" height="25" className="imgLink" />
                              )}
                              {info.Value.includes("#") === true && (
                                <Box className="imgLink" sx={{ width: "25px", float: "right", height: "25px", backgroundColor: info.Value }}></Box>
                              )} 
                            </MDTypography>
                          </>) :(<>
                            <MDTypography
                              display="inline-block"
                              component="p"
                              variant="button"
                              fontWeight="regular"
                              color="text"
                              sx={{ float: "left" }}
                            >
                              <b>{info.Value}</b>
                              {info.type === 'image_link' && (
                                <img src={info.Value} width="25" height="25" className="imgLink" />
                              )}
                              {info.Value.includes("#") === true && (
                                <Box className="imgLink" sx={{ width: "25px", float: "right", height: "25px", backgroundColor: info.Value }}></Box>
                              )} 
                            </MDTypography>
                          </>)}
                        </Grid>
                      </Grid>
                      )}
                      </>)}
                      </>
                    ))}
                  </Grid>
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
          )} */}
          {/* {(adminFlag || sadminFlag || designFlag) && (
          <Grid xs={12} md={4} lg={4}>
            <Card sx={{marginBottom: "5px", marginLeft: "5px", height: "400px"}}>
              <MDBox p={2} m={2}>
                <MDTypography variant="h5" fontWeight="bold" sx={txtColorChange}>
                  Home Page
                </MDTypography>
                <MDBox pt={1} pb={1}>
                  <Grid container mb={2}>
                  {settings.data.data.rows.map((info) => ( <>
                    { (info.Name === "activity_gif_image" || info.Name === "activity_image1" || info.Name === "userText1" || info.Name === "userText2") && (<>
                    {info.type === "image_link" ? (
                      <Grid xs={12} md={6} lg={6}>
                        <MDBox sx={{ marginBottom: "50px" }}>
                          <MDBox position="relative" height="max-content" mx="auto">
                            <MDTypography
                              display="inline-block"
                              component="p"
                              variant="button"
                              fontWeight="regular"
                              color="text"
                            >
                              {info.Name}:
                            </MDTypography>
                            <MDAvatar
                              src={info.Value}
                              alt="profile picture"
                              size="xl"
                              variant="rounded"
                            />
                            <MDBox
                              alt="spotify logo"
                              position="absolute"
                              left={0}
                              bottom={0}
                              mr={-3}
                              mb={-3}
                            >
                              <Tooltip title="Edit" placement="top">
                                <MDButton
                                  variant="gradient"
                                  color="info"
                                  size="small"
                                  iconOnly
                                  onClick={() => handleEdit(info)}
                                  sx={bgColorChange}
                                >
                                  <Icon>edit</Icon>
                                </MDButton>
                              </Tooltip>
                              <MDTypography
                                display="inline-block"
                                component="p"
                                variant="button"
                                fontWeight="regular"
                                color="text"
                              >
                                {info.Name === "activity_gif_image" && (<>
                                (600*360)
                                </>)}
                                {info.Name === "activity_image1" && (<>
                                  (600*360)
                                </>)}
                              </MDTypography>
                            </MDBox>
                          </MDBox>
                        </MDBox>
                      </Grid>
                    ) : (
                    <Grid container spacing={3} mt={4}>
                      <Grid xs={12} md={6} lg={6}>
                        <MDTypography
                          display="inline-block"
                          component="p"
                          variant="button"
                          fontWeight="regular"
                          color="text"
                        >
                          {info.Name}:
                        </MDTypography>
                      </Grid>
                      <Grid xs={12} md={6} lg={6}>
                        <MDBox
                          alt="spotify logo"
                          sx={{ marginTop: "-8px", float: "left", marginRight: "8px" }}
                        >
                          <Tooltip title="Edit" placement="top">
                            <MDButton
                              variant="gradient"
                              color="info"
                              size="small"
                              iconOnly
                              onClick={() => handleEdit(info)}
                              sx={bgColorChange}
                            >
                              <Icon>edit</Icon>
                            </MDButton>
                          </Tooltip>
                        </MDBox>
                        <MDTypography
                          display="inline-block"
                          component="p"
                          variant="button"
                          fontWeight="regular"
                          color="text"
                          sx={{ float: "left" }}
                        >
                          <b>{info.Value}</b>
                          {info.type === 'image_link' && (
                            <img src={info.Value} width="25" height="25" className="imgLink" />
                          )}
                          {info.Value.includes("#") === true && (
                            <Box className="imgLink" sx={{ width: "25px", float: "right", height: "25px", backgroundColor: info.Value }}></Box>
                          )} 
                        </MDTypography>
                      </Grid>
                    </Grid>
                    )}
                    </>)}
                    </>
                  ))}
                  </Grid>
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
          )} */}
          {/* {(adminFlag || sadminFlag || designFlag) && (
          <Grid xs={12} md={6} lg={6}>
            <Card sx={{marginBottom: "5px", marginLeft: "5px", height: "400px"}}>
              <MDBox p={2} m={2}>
                <MDTypography variant="h5" fontWeight="bold" sx={txtColorChange}>
                  Login
                </MDTypography>
                <MDBox pt={1} pb={1}>
                  <Grid container mb={2}>
                  {settings.data.data.rows.map((info) => ( <>
                    { (info.Name === "background_image" || info.Name === "login_screen_logo" || info.Name === "login_screen_fav_icon" || info.Name === "side_bar_logo") && (
                    <Grid item xs={12} md={6} lg={6}>
                      <MDBox sx={{ marginBottom: "50px" }}>
                        <MDBox position="relative" height="max-content" mx="auto">
                          <MDTypography
                            display="inline-block"
                            component="p"
                            variant="button"
                            fontWeight="regular"
                            color="text"
                          >
                            {info.Name}:
                          </MDTypography>
                          <MDAvatar
                            src={info.Value}
                            alt="profile picture"
                            size="xl"
                            variant="rounded"
                          />
                          <MDBox
                            alt="spotify logo"
                            position="absolute"
                            left={0}
                            bottom={0}
                            mr={-3}
                            mb={-3}
                          >
                            <Tooltip title="Edit" placement="top">
                              <MDButton
                                variant="gradient"
                                color="info"
                                size="small"
                                iconOnly
                                onClick={() => handleEdit(info)}
                                sx={bgColorChange}
                              >
                                <Icon>edit</Icon>
                              </MDButton>
                            </Tooltip>
                            <MDTypography
                              display="inline-block"
                              component="p"
                              variant="button"
                              fontWeight="regular"
                              color="text"
                            >
                              {info.Name === "background_image" && (<>
                              (1920*1080)
                              </>)}
                              {info.Name === "login_screen_logo" && (<>
                              (100*50)
                              </>)}
                              {info.Name === "login_screen_fav_icon" && (<>
                              (25*25)
                              </>)}
                              {info.Name === "side_bar_logo" && (<>
                              (180*100)
                              </>)}
                            </MDTypography>
                          </MDBox>
                        </MDBox>
                      </MDBox>
                    </Grid>
                    )}
                    </>
                  ))}
                  </Grid>
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
          )} */}
          {(adminFlag || sadminFlag || designFlag) && (
          <Grid xs={12} md={6} lg={6}>
            <Card sx={{marginBottom: "5px", marginLeft: "5px", height: "400px"}}>
              <MDBox p={2} m={2}>
                <MDTypography variant="h5" fontWeight="bold" sx={txtColorChange}>
                  Colors Management
                </MDTypography>
                <MDBox pt={2} pb={3} px={3}>
                  {settings.data.data.rows.map((info) => ( <>
                    {/* info.Name === "btnBgColor" || info.Name === "profileBgColor" || info.Name === "sidebarBgColor" || info.Name === "sidebarTxtColor" ||   */}
                    { (info.Name === "transColor1" || info.Name === "transColor2" || info.Name === "txtColor" || info.Name === "txtColor1") && (
                    <Grid container spacing={3} mt={2} mb={2}>
                      <Grid xs={12} md={7} lg={7}>
                        <MDTypography
                          display="inline-block"
                          component="p"
                          variant="button"
                          fontWeight="regular"
                          color="text"
                        >
                          {info.Name === "btnBgColor" && (<>
                            {info.Name} [All button colors]: 
                          </>)}
                          {info.Name === "profileBgColor" && (<>
                            {info.Name} [Profile Background Color]: 
                          </>)}
                          {info.Name === "sidebarBgColor" && (<>
                            {info.Name} [Menu Background Color]: 
                          </>)}
                          {info.Name === "sidebarTxtColor" && (<>
                            {info.Name} [Menu Text Color]: 
                          </>)}
                          {info.Name === "transColor1" && (<>
                            {info.Name} [Transaction Text Color 1]: 
                          </>)}
                          {info.Name === "transColor2" && (<>
                            {info.Name} [Transaction Text Color 2]: 
                          </>)}
                          {info.Name === "txtColor" && (<>
                            {info.Name} [Primary Text Color]: 
                          </>)}
                          {info.Name === "txtColor1" && (<>
                            {info.Name} [Secondary Text Color]: 
                          </>)}
                        </MDTypography>
                      </Grid>
                      <Grid xs={12} md={5} lg={5}>
                        <MDBox
                          alt="spotify logo"
                          sx={{ marginTop: "-8px", float: "left", marginRight: "8px" }}
                        >
                          <Tooltip title="Edit" placement="top">
                            <MDButton
                              variant="gradient"
                              color="info"
                              size="small"
                              iconOnly
                              onClick={() => handleEdit(info)}
                              sx={bgColorChange}
                            >
                              <Icon>edit</Icon>
                            </MDButton>
                          </Tooltip>
                        </MDBox>
                        <MDTypography
                          display="inline-block"
                          component="p"
                          variant="button"
                          fontWeight="regular"
                          color="text"
                          sx={{ float: "left" }}
                        >
                          <b>{info.Value}</b>
                          {info.type === 'image_link' && (
                            <img src={info.Value} width="25" height="25" className="imgLink" />
                          )}
                          {info.Value.includes("#") === true && (
                            <Box className="imgLink" sx={{ width: "25px", float: "right", height: "25px", backgroundColor: info.Value }}></Box>
                          )} 
                        </MDTypography>
                      </Grid>
                    </Grid>
                    )}
                    </>
                  ))}
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
          )}
          {(adminFlag || sadminFlag || designFlag) && (
          <Grid xs={12} md={6} lg={6}>
            <Card sx={{marginBottom: "5px", marginLeft: "5px", height: "400px"}}>
              <MDBox p={2} m={2}>
                <MDTypography variant="h5" fontWeight="bold" sx={txtColorChange}>
                  Birthday Anniversary
                </MDTypography>
                <MDBox pt={2} pb={3} px={3}>
                  {settings.data.data.rows.map((info) => ( <> 
                    { (info.Name === "enable_anniversary" || info.Name === "enable_bday" || info.Name === "celebration_image") && (<>
                      {info.type === "image_link" ? (
                        <Grid container spacing={3} mt={2} mb={2}>
                          <MDBox sx={{ marginBottom: "50px" }}>
                            <MDBox position="relative" height="max-content" mx="auto">
                              <MDTypography
                                display="inline-block"
                                component="p"
                                variant="button"
                                fontWeight="regular"
                                color="text"
                              >
                                {info.Name}:
                              </MDTypography>
                              <MDAvatar
                                src={info.Value}
                                alt="profile picture"
                                size="xl"
                                variant="rounded"
                              />
                              <MDBox
                                alt="spotify logo"
                                position="absolute"
                                left={0}
                                bottom={0}
                                mr={-3}
                                mb={-3}
                              >
                                <Tooltip title="Edit" placement="top">
                                  <MDButton
                                    variant="gradient"
                                    color="info"
                                    size="small"
                                    iconOnly
                                    onClick={() => handleEdit(info)}
                                    sx={bgColorChange}
                                  >
                                    <Icon>edit</Icon>
                                  </MDButton>
                                </Tooltip>
                                <MDTypography
                                  display="inline-block"
                                  component="p"
                                  variant="button"
                                  fontWeight="regular"
                                  color="text"
                                >
                                  {info.Name === "celebration_image" && (<>
                                  (600*400)
                                  </>)}
                                </MDTypography>
                              </MDBox>
                            </MDBox>
                          </MDBox>
                        </Grid>
                      ) : (
                        <Grid container spacing={3} mt={3} mb={2}>
                          <Grid xs={12} md={6} lg={6}>
                            <MDTypography
                              display="inline-block"
                              component="p"
                              variant="button"
                              fontWeight="regular"
                              color="text"
                            >
                              {info.Name}:
                            </MDTypography>
                          </Grid>
                          <Grid xs={12} md={6} lg={6}>
                            <MDBox
                              alt="spotify logo"
                              sx={{ marginTop: "-8px", float: "left", marginRight: "8px" }}
                            >
                              <Tooltip title="Edit" placement="top">
                                <MDButton
                                  variant="gradient"
                                  color="info"
                                  size="small"
                                  iconOnly
                                  onClick={() => handleEdit(info)}
                                  sx={bgColorChange}
                                >
                                  <Icon>edit</Icon>
                                </MDButton>
                              </Tooltip>
                            </MDBox>
                            <MDTypography
                              display="inline-block"
                              component="p"
                              variant="button"
                              fontWeight="regular"
                              color="text"
                              sx={{ float: "left" }}
                            >
                              <b>{info.Value}</b>
                              {info.type === 'image_link' && (
                                <img src={info.Value} width="25" height="25" className="imgLink" />
                              )}
                              {info.Value.includes("#") === true && (
                                <Box className="imgLink" sx={{ width: "25px", float: "right", height: "25px", backgroundColor: info.Value }}></Box>
                              )} 
                            </MDTypography>
                          </Grid>
                        </Grid>
                      )}
                    </>)}
                    </>
                  ))}
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
          )}
          {(adminFlag || sadminFlag) && (
          <Grid xs={12} md={8} lg={8}>
            <Card sx={{marginBottom: "5px", marginLeft: "5px", height: "280px"}}>
              <MDBox p={2} m={2}>
                <MDTypography variant="h5" fontWeight="bold" sx={txtColorChange}>
                  Import Files Management
                </MDTypography>
                <MDBox pt={2} pb={3} px={3}>
                  {settings.data.data.rows.map((info) => ( <>
                    { (info.Name === "sample_file_points_import" || info.Name === "sample_file_users_import") && (
                    <Grid container spacing={3} mt={2} mb={2}>
                      <Grid xs={12} md={4} lg={4}>
                        <MDTypography
                          display="inline-block"
                          component="p"
                          variant="button"
                          fontWeight="regular"
                          color="text"
                        >
                          {info.Name}:
                        </MDTypography>
                      </Grid>
                      <Grid xs={12} md={8} lg={8}>
                        <MDBox
                          alt="spotify logo"
                          sx={{ marginTop: "-8px", float: "left" }}
                        >
                          <Tooltip title="Edit" placement="top">
                            <MDButton
                              variant="gradient"
                              color="info"
                              size="small"
                              iconOnly
                              onClick={() => handleEdit(info)}
                              sx={bgColorChange}
                            >
                              <Icon>edit</Icon>
                            </MDButton>
                          </Tooltip>
                        </MDBox>
                        <MDTypography
                          display="inline-block"
                          component="p"
                          variant="button"
                          fontWeight="regular"
                          color="text"
                          sx={{ float: "left", width: "90%", marginLeft: "5px" }}
                        >
                          <b>{info.Value}</b>
                          {info.type === 'image_link' && (
                            <img src={info.Value} width="25" height="25" className="imgLink" />
                          )}
                          {info.Value.includes("#") === true && (
                            <Box className="imgLink" sx={{ width: "25px", float: "right", height: "25px", backgroundColor: info.Value }}></Box>
                          )} 
                        </MDTypography>
                      </Grid>
                    </Grid>
                    )}
                    </>
                  ))}
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
          )}
          {(adminFlag || sadminFlag || designFlag) && (
          <Grid xs={12} md={4} lg={4}>
            <Card sx={{marginBottom: "5px", marginLeft: "5px", height: "280px"}}>
              <MDBox p={2} m={2}>
                <MDTypography variant="h5" fontWeight="bold" sx={txtColorChange}>
                  Celebration Page
                </MDTypography>
                <MDBox pt={3} pb={1}>
                  <Grid container mb={2}>
                  {settings.data.data.rows.map((info) => ( <>
                    { (info.Name === "celebration_image1" || info.Name === "celebration_image2") && (
                    <Grid item xs={12} md={6} lg={6}>
                      <MDBox sx={{ marginBottom: "50px" }}>
                        <MDBox position="relative" height="max-content" mx="auto">
                        <MDTypography
                          display="inline-block"
                          component="p"
                          variant="button"
                          fontWeight="regular"
                          color="text"
                        >
                          {info.Name}:
                        </MDTypography>
                          <MDAvatar
                            src={info.Value}
                            alt="profile picture"
                            size="xl"
                            variant="rounded"
                          />
                          <MDBox
                            alt="spotify logo"
                            position="absolute"
                            left={0}
                            bottom={0}
                            mr={-3}
                            mb={-3}
                          >
                            <Tooltip title="Edit" placement="top">
                              <MDButton
                                variant="gradient"
                                color="info"
                                size="small"
                                iconOnly
                                onClick={() => handleEdit(info)}
                                sx={bgColorChange}
                              >
                                <Icon>edit</Icon>
                              </MDButton>
                            </Tooltip>
                            <MDTypography
                              display="inline-block"
                              component="p"
                              variant="button"
                              fontWeight="regular"
                              color="text"
                            >
                              {(info.Name === "celebration_image1" || info.Name === "celebration_image2") && (<>
                              (600*360)
                              </>)}
                            </MDTypography>
                          </MDBox>
                        </MDBox>
                      </MDBox>
                    </Grid>
                    )}
                    </>
                  ))}
                  </Grid>
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
          )}
          {(sadminFlag) && (
          <Grid xs={12} md={6} lg={6}>
            <Card sx={{marginBottom: "5px", marginLeft: "5px", height:"730px"}}>
              <MDBox p={2} m={2}>
                <MDTypography variant="h5" fontWeight="bold" sx={txtColorChange}>
                  Gift Card Config
                </MDTypography>
                <MDBox pt={2} pb={4} px={3}>
                  {settings.data.data.rows.map((info) => ( <>
                    { (info.Name === "gc_account_id" || info.Name === "gc_api_secret" || info.Name === "gc_api_user" || info.Name === "gc_customer_id" || info.Name === "gc_url" || info.Name === "giftcard_name" || info.Name === "giftcard_flag" || info.Name === "donation_name" || info.Name === "donation_flag" || info.Name === "swag_name" || info.Name === "swag_flag" || info.Name === "tabs_defaltVal") && (
                    <Grid container spacing={3} mt={2} mb={2}>
                      <Grid xs={12} md={6} lg={6}>
                        <MDTypography
                          display="inline-block"
                          component="p"
                          variant="button"
                          fontWeight="regular"
                          color="text"
                        >
                          {info.Name}:
                        </MDTypography>
                      </Grid>
                      <Grid xs={12} md={6} lg={6}>
                        <MDBox
                          alt="spotify logo"
                          sx={{ marginTop: "-8px", float: "left", marginRight: "8px" }}
                        >
                          <Tooltip title="Edit" placement="top">
                            <MDButton
                              variant="gradient"
                              color="info"
                              size="small"
                              iconOnly
                              onClick={() => handleEdit(info)}
                              sx={bgColorChange}
                            >
                              <Icon>edit</Icon>
                            </MDButton>
                          </Tooltip>
                        </MDBox>
                        {info.Name === "gc_api_secret" || info.Name === "gc_url" ? (<>
                        <MDTypography
                          display="inline-block"
                          component="p"
                          variant="button"
                          fontWeight="regular"
                          color="text"
                          sx={{ float: "left", width: "85%" }}
                        >
                          <b>{info.Value}</b>
                          {info.type === 'image_link' && (
                            <img src={info.Value} width="25" height="25" className="imgLink" />
                          )}
                          {info.Value.includes("#") === true && (
                            <Box className="imgLink" sx={{ width: "25px", float: "right", height: "25px", backgroundColor: info.Value }}></Box>
                          )} 
                        </MDTypography>
                        </>) :(<>
                        <MDTypography
                          display="inline-block"
                          component="p"
                          variant="button"
                          fontWeight="regular"
                          color="text"
                          sx={{ float: "left" }}
                        >
                          <b>{info.Value}</b>
                          {info.type === 'image_link' && (
                            <img src={info.Value} width="25" height="25" className="imgLink" />
                          )}
                          {info.Value.includes("#") === true && (
                            <Box className="imgLink" sx={{ width: "25px", float: "right", height: "25px", backgroundColor: info.Value }}></Box>
                          )} 
                        </MDTypography>
                        </>)}
                      </Grid>
                    </Grid>
                    )}
                    </>
                  ))}
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
          )}
          {sadminFlag && (
          <Grid xs={12} md={6} lg={6}>
            <Card sx={{marginBottom: "5px", marginLeft: "5px", height:"730px"}}>
              <MDBox p={2} m={2}>
                <MDTypography variant="h5" fontWeight="bold" sx={txtColorChange}>
                  Others
                </MDTypography>
                <MDBox pt={2} pb={3} px={3}>
                  {settings.data.data.rows.map((info) => ( <>
                    { (info.Name === "copy_right" || info.Name === "disclaimer" || info.Name === "EMAIL_SERVICE" || info.Name === "ME" || info.Name === "RAM1" || info.Name === "point_conversion" || info.Name === "sign_in_type" || info.Name === "support") && (
                    <Grid container spacing={3} mt={2} mb={2}>
                      <Grid xs={12} md={6} lg={6}>
                        <MDTypography
                          display="inline-block"
                          component="p"
                          variant="button"
                          fontWeight="regular"
                          color="text"
                        >
                          {info.Name}:
                        </MDTypography>
                      </Grid>
                      <Grid xs={12} md={6} lg={6}>
                        <MDBox
                          alt="spotify logo"
                          sx={{ marginTop: "-8px", float: "left", marginRight: "8px" }}
                        >
                          <Tooltip title="Edit" placement="top">
                            <MDButton
                              variant="gradient"
                              color="info"
                              size="small"
                              iconOnly
                              onClick={() => handleEdit(info)}
                              sx={bgColorChange}
                            >
                              <Icon>edit</Icon>
                            </MDButton>
                          </Tooltip>
                        </MDBox>
                        {info.Name === "disclaimer" || info.Name === "support" ? (<>
                        <MDTypography
                          display="inline-block"
                          component="p"
                          variant="button"
                          fontWeight="regular"
                          color="text"
                          sx={{ float: "left", width: "85%" }}
                        >
                          <b>{info.Value}</b>
                          {info.type === 'image_link' && (
                            <img src={info.Value} width="25" height="25" className="imgLink" />
                          )}
                          {info.Value.includes("#") === true && (
                            <Box className="imgLink" sx={{ width: "25px", float: "right", height: "25px", backgroundColor: info.Value }}></Box>
                          )} 
                        </MDTypography>
                        </>) : (<>
                        <MDTypography
                          display="inline-block"
                          component="p"
                          variant="button"
                          fontWeight="regular"
                          color="text"
                          sx={{ float: "left" }}
                        >
                          <b>{info.Value}</b>
                          {info.type === 'image_link' && (
                            <img src={info.Value} width="25" height="25" className="imgLink" />
                          )}
                          {info.Value.includes("#") === true && (
                            <Box className="imgLink" sx={{ width: "25px", float: "right", height: "25px", backgroundColor: info.Value }}></Box>
                          )} 
                        </MDTypography>
                        </>)}
                      </Grid>
                    </Grid>
                    )}
                    </>
                  ))}
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
          )}
        </Grid>

        {/* <Card sx={{marginBottom: "5px"}}>
          <MDBox m={2}>
            <MDTypography variant="h5" fontWeight="bold" sx={txtColorChange}>
              Images
            </MDTypography>
            <Grid container>
              {settings.data.data.rows.map((info) => (
                <>
                {info.type === "image_link" && (
                  <Grid item xs={12} md={3} lg={3}>
                    <MDBox m={2}>
                      <MDBox position="relative" height="max-content" mx="auto">
                      <Typography>{info.Name}</Typography>
                        <MDAvatar
                          src={info.Value}
                          alt="profile picture"
                          size="xl"
                          variant="rounded"
                        />
                        <MDBox
                          alt="spotify logo"
                          position="absolute"
                          left={0}
                          bottom={0}
                          mr={-3}
                          mb={-3}
                        >
                          <Tooltip title="Edit" placement="top">
                            <MDButton
                              variant="gradient"
                              color="info"
                              size="small"
                              iconOnly
                              onClick={() => handleEdit(info)}
                              sx={bgColorChange}
                            >
                              <Icon>edit</Icon>
                            </MDButton>
                          </Tooltip>
                        </MDBox>
                      </MDBox>
                    </MDBox>
                  </Grid>
                )}
                </>
              ))}
            </Grid>
          </MDBox>
        </Card> */}
        {/* <Card>
          <MDBox p={2} m={2}>
            <MDTypography variant="h5" fontWeight="bold" sx={txtColorChange}>
              Admin Settings
            </MDTypography>
            <MDBox pt={4} pb={3} px={3}>
              {settings.data.data.rows.map((info) => (
                <Grid container spacing={3} mt={2} mb={2}>
                  <Grid xs={12} md={4} lg={4}>
                    <MDTypography
                      display="inline-block"
                      component="p"
                      variant="button"
                      fontWeight="regular"
                      color="text"
                    >
                      {info.Name}:
                    </MDTypography>
                  </Grid>
                  <Grid xs={12} md={6} lg={6}>
                    <MDBox
                      alt="spotify logo"
                      sx={{ marginTop: "-8px", float: "left", marginRight: "8px" }}
                    >
                      <Tooltip title="Edit" placement="top">
                        <MDButton
                          variant="gradient"
                          color="info"
                          size="small"
                          iconOnly
                          onClick={() => handleEdit(info)}
                          sx={bgColorChange}
                        >
                          <Icon>edit</Icon>
                        </MDButton>
                      </Tooltip>
                    </MDBox>
                    <MDTypography
                      display="inline-block"
                      component="p"
                      variant="button"
                      fontWeight="regular"
                      color="text"
                      sx={{ float: "left" }}
                    >
                      <b>{info.Value}</b>
                      {info.type === 'image_link' && (
                        <img src={info.Value} width="25" height="25" className="imgLink" />
                      )}
                      {info.Value.includes("#") === true && (
                        <Box className="imgLink" sx={{ width: "25px", float: "right", height: "25px", backgroundColor: info.Value }}></Box>
                      )} 
                    </MDTypography>
                  </Grid>
                </Grid>
              ))}
            </MDBox>
          </MDBox>
        </Card> */}
      </MDBox>
      <SiteFooter />

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="modalPopupBox">
          <Grid container>
            <Grid item xs={12}>
              <Typography id="keep-mounted-modal-title" variant="h5" component="h2" sx={txtColorChange}>
                Edit Settings
              </Typography>
              <MDBox variant="outlined" className="close-button" onClick={handleClose}><CloseIcon /></MDBox>
            </Grid>
          </Grid>
          <MDBox component="form" role="form" onSubmit={handleSubmit}>
            <MDBox mt={2} mb={2}>
              {Name}
              {/* <MDInput
                type="text"
                fullWidth
                placeholder="Name"
                value={Name}
                onChange={(e) => {
                  setName(e.target.value);
                }}
              /> */}
            </MDBox>
            {(imageType === "image_link" || imageType === "file_link") && (
              <MDInput type="file" fullWidth onChange={handleFileSelect} />
            // <MDBox mt={2} mb={2}>
            //   <MDInput
            //     type="text"
            //     fullWidth
            //     placeholder="Value"
            //     value={sVal}
            //     onChange={(e) => {
            //       setSValue(e.target.value);
            //     }}
            //   />
            // </MDBox>
            )}
            {(imageType === "text") && (
            <MDBox mt={2} mb={2}>
              <MDInput
                type="text"
                fullWidth
                placeholder="Value"
                value={sVal}
                onChange={(e) => {
                  setSValue(e.target.value);
                }}
              />
            </MDBox>
            )}
            <MDBox mt={2} mb={2}>
              <MDTypography
                variant="button"
                fontWeight="regular"
                color="text"
                sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
              >
                &nbsp;&nbsp;Status :&nbsp;
              </MDTypography>
              <Switch checked={sStatus} onChange={handleStatus} /> 
            </MDBox>
            <MDBox mt={2} mb={1}>
            {/* onClick={handleUpdate} */}
              <MDButton type="submit" variant="gradient" color="info" sx={bgColorChange}>
                Submit
              </MDButton>
            </MDBox>
          </MDBox>
        </Box>
      </Modal>
    </DashboardLayout>
  );
}

export default AdminSettings;
