import React, { useEffect, useState } from 'react';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardMenu from 'examples/Navbars/DashboardMenu';
import SiteFooter from '../SiteFooter';
import { CommentSection } from 'react-comments-section'
import 'react-comments-section/dist/index.css'

// import "./styles.css";
import cdata from "./data.json";
import CustomInput from "./CustomInput";
import Editable from "./Editable";
import MDBox from 'components/MDBox';
import { Card } from '@mui/material';


const NewCelebration = () => {

  const [comment, setComment] = useState(cdata);
  const userId = "01a";
  const avatarUrl = "https://ui-avatars.com/api/name=Riya&background=random";
  const name = "xyz";
  const signinUrl = "/signin";
  const signupUrl = "/signup";
  let count = 0;
  comment.map((i) => {
    count += 1;
    i.replies && i.replies.map((i) => (count += 1));
  });

  const data = [
    {
      userId: '01a',
      comId: '012',
      fullName: 'Riya Negi',
      avatarUrl: 'https://ui-avatars.com/api/name=Riya&background=random',
      userProfile: 'https://www.linkedin.com/in/riya-negi-8879631a9/',
      text: 'Hey, Loved your blog! ',
      replies: [
        {
        userId: '02a',
        comId: '013',
    userProfile: 'https://www.linkedin.com/in/riya-negi-8879631a9/',
    fullName: 'Adam Scott',
    avatarUrl: 'https://ui-avatars.com/api/name=Adam&background=random',
    text: 'Thanks! It took me 1 month to finish this project but I am glad it helped out someone!🥰'
        }
        ]
      }
    ]

  const [textEditable, setTextEditable] = useState("");

  useEffect(() => {
    console.log(comment);
  }, [comment]);

  const customInputFunc = (props) => {
    return (
      <CustomInput
        parentId={props.parentId}
        cancellor={props.cancellor}
        value={props.value}
        edit={props.edit}
        submit={props.submit}
        handleCancel={props.handleCancel}
      />
    );
  };

  const editable = (props) => {
    return (
      <Editable
        value={textEditable}
        onChange={(evt) => {
          setTextEditable(evt.target.value);
        }}
        onCancel={(evt) => {
          setTextEditable("");
        }}
        onPost={(evt) => {
          console.log("onPost >", textEditable);
        }}
      />
    );
  };

  return (
    <DashboardLayout>
        <DashboardMenu />
        <MDBox>
          <Card>
          <div className="cols">
          <div className="commentSection">
            <div className="header">
              {" "}
              Custom Input Field component (user logged in)
            </div>
            <CommentSection
              currentUser={userId ? {
                currentUserId: '01a',
                currentUserImg:
                  'https://ui-avatars.com/api/name=Riya&background=random',
                currentUserProfile:
                  'https://www.linkedin.com/in/riya-negi-8879631a9/',
                currentUserFullName: 'Riya Negi'
              }:null}
              // currentUser={{
              //   currentUserId: '01a',
              //   currentUserImg:
              //     'https://ui-avatars.com/api/name=Riya&background=random',
              //   currentUserProfile:
              //     'https://www.linkedin.com/in/riya-negi-8879631a9/',
              //   currentUserFullName: 'Riya Negi'
              // }}
              // advancedInput={true}
              logIn={{
                loginLink: 'http://localhost:3001/',
                signupLink: 'http://localhost:3001/'
              }}
              commentData={data}
              // commentData={data}
              setComment={setComment}
              signinUrl={signinUrl}
              signupUrl={signupUrl}
              advancedInput={true}
              // customInput={customInputFunc}
              currentData={(data) => {
                console.log('curent data', data)
              }}
              hrStyle={{ border: '0.5px solid #ff0072' }}
              titleStyle={{ color: '#f2f2f2' }}
              imgStyle={{ borderRadius: '50%', float: "left" }}
              // inputStyle={{ border: '1px solid rgb(208 208 208)' }}
              formStyle={{ backgroundColor: 'white' }}
              submitBtnStyle={{ width: "100px", border: '1px solid #007573', backgroundColor: '#007573', padding: '7px 15px' }}
              cancelBtnStyle={{
                width: "100px", 
                border: '1px solid #b0b0b0',
                backgroundColor: '#b0b0b0',
                color: 'white',
                padding: '7px 15px',
                display: 'block',
              }}
              overlayStyle={{ backgroundColor: '#0f0d29', color: 'white' }}
              replyInputStyle={{ borderBottom: '1px solid black', color: 'black' }}
              // onSubmitAction={(data: {
              //   userId: string
              //   comId: string
              //   avatarUrl: string
              //   userProfile?: string
              //   fullName: string
              //   text: string
              //   replies: any
              //   commentId: string
              // }) => console.log('check submit, ', data)}
              // currentData={(data: any) => {
              //   console.log('curent data', data)
              // }}
            />
          </div>
        </div>
        </Card>
        </MDBox>
      <SiteFooter />
    </DashboardLayout>
  );
}

export default NewCelebration;