import * as React from 'react';
// import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { DataGridPro, GridToolbar } from '@mui/x-data-grid-pro';
import { LicenseInfo } from '@mui/x-license-pro';
import { useMaterialUIController } from 'context';
import { darken, lighten, styled, Theme } from '@mui/material/styles';
import "./DataGridStyle.css"

LicenseInfo.setLicenseKey('799776bde75c538c495e2569492090a5Tz05ODI0NCxFPTE3NTgxMTczNjcwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLFBWPWluaXRpYWwsS1Y9Mg==');

// .css-154sxbz-MuiPaper-root-MuiDataGrid-paper

function DataGridTables({tableRow, columns, txtColor=null, checkSelection=false}) {

  const [controller] = useMaterialUIController(); 
  const { darkMode } = controller;

  let modeVal = "text";
  if (txtColor != null) {
    modeVal = "secondary";
  } else {
    if (darkMode) {
      modeVal = "secondary";
    } else {
      modeVal = "text";
    }
  }
  let colorVal = "inherit"
  let hoverColorVal = '#344767 !important';
  let darkTxtColor = "inherit";
  if (darkMode) {
    colorVal = '#EEF1F6 !important';
    hoverColorVal = "#344767 !important";
    darkTxtColor = "#ffffff !important";
  }

  return ( 
    // style={{ height: "700px", width: '100%'}} {darkMode ? "secondary" : "text"}
    <div color={modeVal}>
      <DataGridPro
        rows={tableRow}
        columns={columns}
        pagination={true}
        checkboxSelection={checkSelection}
        slots={{
          toolbar: GridToolbar,
        }}    
        sx={{
          color: colorVal,
          "&.MuiDataGrid-row:hover": {
            backgroundColor: "#e0e0e0",
            color: hoverColorVal,
          },
          "&.MuiDataGrid-row.Mui-selected": {
            backgroundColor: "#e0e0e0",
            color: hoverColorVal,
          },
          "&.MuiList-root": {
            backgroundColor: "#EEF1F6 !important",
          },
          "&.MuiPaper-root": {
            color: "#000000 !important"
          },
          "&.MuiDataGrid-paper":{
            background: "#EEF1F6 !important"
          },
          "&.MuiDataGrid-root .MuiTablePagination-root, .MuiTablePagination-selectIcon, .MuiTablePagination-select":{
            color: darkTxtColor
          },
          "&.MuiDataGrid-root .MuiDataGrid-filterIcon, .MuiSvgIcon-root":{
            color: darkTxtColor
          },
          "&.MuiDataGrid-panel .MuiPaper-root, .MuiDataGrid-panelWrapper, .MuiInput-root, .MuiInput-input": {
            color: "#000000 !important"
          },
          "&.MuiDataGrid-panel .MuiDataGrid-columnsPanelRow, .MuiTypography-root, .MuiFormControlLabel-root, .MuiFormControlLabel-label":{
            color: "#000000 !important"
          },
          "&.MuiDataGrid-menu .MuiListItemText-root, .MuiTypography-root":{
            color: "#000000 !important"
          },
          // , .css-sxlrxn-MuiButtonBase-root-MuiMenuItem-root , .MuiButtonBase-root
          "&.MuiDataGrid-root .MuiList-root, .MuiMenuItem-root": {
            color: "#000000 !important"
          },
          "&.MuiDataGrid-root .MuiInput-root, .MuiInput-input": {
            color: "#000000 !important" // Change the text color
          },
          "&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus":
          {
            background: "#e0e0e0",
            outline: "none",
          },
          "&.css-1x7sjdv-MuiButtonBase-root-MuiIconButton-root.Mui-disabled, &.MuiTablePagination-root, &MuiButtonBase-root .Mui-disabled": {
            color: "#EEF1F6 !important",
            background: "#e0e0e0",
          }
        }}
        initialState={{
          ...tableRow.initialState,
          pagination: {
            ...tableRow.initialState?.pagination,
            paginationModel: {
              pageSize: 10,
              /* page: 0 // default value will be used if not passed */
            },
          },
        }}
        // disableColumnResize={true}
        disableRowSelectionOnClick
        unstable_cellSelection
        pageSizeOptions={[10, 25, 50, 100]}
        autoHeight
      />
    </div>
  );
}

export default DataGridTables;
