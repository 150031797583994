import { Card, Grid } from '@mui/material'
import axios from 'axios'
import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout'
import DashboardMenu from 'examples/Navbars/DashboardMenu'
import DashboardNavbar from 'examples/Navbars/DashboardNavbar'
import { bgColorChange } from 'layouts/Rewards/User/components/Common/ColorChange'
import { txtColorChange } from 'layouts/Rewards/User/components/Common/ColorChange'
import DataGridTables from 'layouts/Rewards/User/components/Common/DataGridTables'
import Loader from 'layouts/Rewards/User/components/Common/Loader'
import SiteFooter from 'layouts/Rewards/User/components/Common/SiteFooter'
import URLSettings from 'layouts/Rewards/User/components/Common/URLSettings'
import React, { useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { useNavigate } from 'react-router-dom'

const EmailTemplates = () => {

  let APIURL = URLSettings();
  const navigate = useNavigate(); 

  useEffect(() => {
    if (!localStorage.getItem("token")) {
      window.location.reload();
      return navigate("/login");
    }
  }, []);

  const emailColumns = [
    { 
        field: "id", 
        headerName: "ID", 
        width: 90 
    },
    {
        field: "company_name",
        headerName: "Company Name",
        minWidth: 200
    },
  ];

  const emailTemps = useQuery(["emailTemps"], async () =>
    axios.get(`${APIURL.url}/admin/emailtemplates`, {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    })
  );
  if (emailTemps.isError) {
    if (emailTemps.error.response.status === 401) {
      window.location.reload();
      return navigate("/login");
    }
  }

  if (!emailTemps.isSuccess) {
    return <Loader />;
  }

  console.log("em::", emailTemps);


  return (
    <DashboardLayout>
      {/* <DashboardNavbar /> */}
      <DashboardMenu />
      <MDBox mt={1} my={3}>
        <Card p={2}>
          <MDBox p={3} lineHeight={1}>
            <Grid container>
              <Grid item xs={6}>
                <MDTypography variant="h5" fontWeight="medium" sx={txtColorChange}>
                  Email Templates
                </MDTypography>
              </Grid>
            </Grid>
          </MDBox>
          <MDBox pt={4} pb={3} px={3}>
            <Grid container spacing={3} mt={2} mb={2}>
                <Grid xs={12} md={4} lg={4}>
                    <MDTypography
                    display="inline-block"
                    component="p"
                    variant="button"
                    fontWeight="regular"
                    color="text"
                    pl={3}
                    >
                    Company Name:
                    </MDTypography>
                </Grid>
                <Grid xs={12} md={6} lg={6}>
                    <MDTypography
                    display="inline-block"
                    component="p"
                    variant="button"
                    fontWeight="regular"
                    color="text"
                    sx={{ float: "left" }}
                    >
                    <b>{emailTemps.data.data.company_name}</b>
                    </MDTypography>
                </Grid>
            </Grid>
            <Grid container spacing={3} mt={2} mb={2}>
                <Grid xs={12} md={4} lg={4}>
                    <MDTypography
                    display="inline-block"
                    component="p"
                    variant="button"
                    fontWeight="regular"
                    color="text"
                    pl={3}
                    >
                    Email Body:
                    </MDTypography>
                </Grid>
                <Grid xs={12} md={6} lg={6}>
                    <MDTypography
                    display="inline-block"
                    component="p"
                    variant="button"
                    fontWeight="regular"
                    color="text"
                    sx={{ float: "left" }}
                    >
                    <b>{emailTemps.data.data.email_body}</b>
                    </MDTypography>
                </Grid>
            </Grid>
            <Grid container spacing={3} mt={2} mb={2}>
                <Grid xs={12} md={4} lg={4}>
                    <MDTypography
                    display="inline-block"
                    component="p"
                    variant="button"
                    fontWeight="regular"
                    color="text"
                    pl={3}
                    >
                    Email Employee Message Body:
                    </MDTypography>
                </Grid>
                <Grid xs={12} md={6} lg={6}>
                    <MDTypography
                    display="inline-block"
                    component="p"
                    variant="button"
                    fontWeight="regular"
                    color="text"
                    sx={{ float: "left" }}
                    >
                    <b>{emailTemps.data.data.email_employee_message_body}</b>
                    </MDTypography>
                </Grid>
            </Grid>
            <Grid container spacing={3} mt={2} mb={2}>
                <Grid xs={12} md={4} lg={4}>
                    <MDTypography
                    display="inline-block"
                    component="p"
                    variant="button"
                    fontWeight="regular"
                    color="text"
                    pl={3}
                    >
                    Email Footer:
                    </MDTypography>
                </Grid>
                <Grid xs={12} md={6} lg={6}>
                    <MDTypography
                    display="inline-block"
                    component="p"
                    variant="button"
                    fontWeight="regular"
                    color="text"
                    sx={{ float: "left" }}
                    >
                    <b>{emailTemps.data.data.email_footer}</b>
                    </MDTypography>
                </Grid>
            </Grid>
            <Grid container spacing={3} mt={2} mb={2}>
                <Grid xs={12} md={4} lg={4}>
                    <MDTypography
                    display="inline-block"
                    component="p"
                    variant="button"
                    fontWeight="regular"
                    color="text"
                    pl={3}
                    >
                    Email Header:
                    </MDTypography>
                </Grid>
                <Grid xs={12} md={6} lg={6}>
                    <MDTypography
                    display="inline-block"
                    component="p"
                    variant="button"
                    fontWeight="regular"
                    color="text"
                    sx={{ float: "left" }}
                    >
                    <b>{emailTemps.data.data.email_header}</b>
                    </MDTypography>
                </Grid>
            </Grid>
            <Grid container spacing={3} mt={2} mb={2}>
                <Grid xs={12} md={4} lg={4}>
                    <MDTypography
                    display="inline-block"
                    component="p"
                    variant="button"
                    fontWeight="regular"
                    color="text"
                    pl={3}
                    >
                    Email Style:
                    </MDTypography>
                </Grid>
                <Grid xs={12} md={6} lg={6}>
                    <MDTypography
                    display="inline-block"
                    component="p"
                    variant="button"
                    fontWeight="regular"
                    color="text"
                    sx={{ float: "left" }}
                    >
                    <b>{emailTemps.data.data.email_style}</b>
                    </MDTypography>
                </Grid>
            </Grid>
            <Grid container spacing={3} mt={2} mb={2}>
                <Grid xs={12} md={4} lg={4}>
                    <MDTypography
                    display="inline-block"
                    component="p"
                    variant="button"
                    fontWeight="regular"
                    color="text"
                    pl={3}
                    >
                    Email Subject:
                    </MDTypography>
                </Grid>
                <Grid xs={12} md={6} lg={6}>
                    <MDTypography
                    display="inline-block"
                    component="p"
                    variant="button"
                    fontWeight="regular"
                    color="text"
                    sx={{ float: "left" }}
                    >
                    <b>{emailTemps.data.data.email_subject}</b>
                    </MDTypography>
                </Grid>
            </Grid>
          </MDBox>
        </Card>
      </MDBox> 
      <SiteFooter />
    </DashboardLayout>
  )
}

export default EmailTemplates