import { Avatar, Box, Card, Divider, Grid, Icon, Stack } from "@mui/material";
import axios from "axios";
import MDBox from "components/MDBox";
import ProfilesList from "examples/Lists/ProfilesList";
import profilesListData from "layouts/pages/profile/profile-overview/data/profilesListData";
// import { Tree, TreeNode } from "react-organizational-chart";
import { useQuery } from "react-query";
import URLSettings from "../Common/URLSettings";
import profileImage from "assets/images/team-3.jpg";
import MDAvatar from "components/MDAvatar";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import { txtColorChange, bgColorChange, profileColorChange } from "../Common/ColorChange";
import Feed from "../Feed";
import { Link, useNavigate } from "react-router-dom";
import { useMaterialUIController } from "context";
import Logout from "../Common/Logout";
import ListView from "examples/Lists/ListView";
import ReactPlayer from "react-player";
import Image from "mui-image";
import { useEffect } from "react";
import ProfileBlock from "./ProfileBlock";
import VideoBlock from "./VideoBlock";
import Feeds from "./Feeds";
import Upcoming from "./Upcoming";
import LeaderBoard from "./LeaderBoard";
import { Masonry } from '@mui/lab';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import ImageBlock from "./ImageBlock";
import { isMobile, isTablet } from "react-device-detect";
import ActivityImage1 from "./ActivityImage1";

const jose = require("jose");

const heights = [150, 30, 90, 70, 110, 150, 130, 80, 50, 90, 100, 150, 30, 50, 80];

// const Item = styled(Paper)(({ theme }) => ({
//   backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
//   ...theme.typography.body2,
//   padding: theme.spacing(0.5),
//   textAlign: 'center',
//   color: theme.palette.text.secondary,
// }));


function Celebration({pageStatus}) {

  // console.log("page Status::", pageStatus);

  const navigate = useNavigate();
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  let APIURL = URLSettings();
  let profilPic = localStorage.getItem("profilepic");
  const settings = localStorage.getItem("settings");
  var obj = JSON.parse(settings);
  let videoObj = obj.find((o) => o.Name === "activity_video");
  let bdayObj = obj.find((o) => o.Name === "enable_bday");
  let anniversaryObj = obj.find((o) => o.Name === "enable_anniversary");
  let celebrationImage = obj.find((o) => o.Name === "celebration_image");
  let userText1 = obj.find((o) => o.Name === "userText1");
  let userText2 = obj.find((o) => o.Name === "userText2");
  const layoutInfo = obj.find((o) => o.Name === "layoutOrder");
  let orderInfo = JSON.parse(layoutInfo.Value);

  let upcomingFlag1 = false;
  let upcomingFlag2 = false;
  if (bdayObj.Value === "yes") {
    upcomingFlag1 = true;
  } 
  if (anniversaryObj.Value === "yes") {
    upcomingFlag2 = true;
  }

  const celebration = useQuery(["celebration"], async () =>
    axios.get(`${APIURL.url}/user/celebration`, {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    })
  );

  const token = useQuery("token", async () => {
    // should be validating with public key
    const text = localStorage.getItem("token");
    if (text === null || text === "") {
      throw new Error("No token found");
    }
    const claims = jose.decodeJwt(text);
    claims.token = text;
    return claims;
  });

  const points = useQuery(["senderPoints"], async () =>
    axios.get(`${APIURL.url}/getPointCount`, {
      params: {
        isSender: true,
      },
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    })
  );

  const profileDetails = useQuery(["profileDetails"], async () =>
    axios.get(`${APIURL.url}/getProfileDetails`, {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    })
  );

  const leaderBoard = useQuery(["leaderBoard"], async () =>
    axios.get(`${APIURL.url}/getLeaderBoard`, {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    })
  );

  if (token.isError) {
    window.location.reload();
    return navigate("/login");
  }

  useEffect(() => {
    if (!localStorage.getItem("token")) {
      window.location.reload();
      return navigate("/login");
    }
  }, []);

  if (points.isError || celebration.isError || profileDetails.isError || leaderBoard.isError) {
    if (celebration.error.response.status === 401) {
      window.location.reload();
      return navigate("/login");
    } 
  }

  if (!celebration.isSuccess || !profileDetails.isSuccess || !leaderBoard.isSuccess || points.isLoading || token.isLoading) {
    return (
      <MDBox my={2}>
        <MDBox p={1} m={1} mt={2}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6} lg={6}>
              <Card />
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
    );
  }

  let birthdayList =
    celebration.data.data["upcoming_birthdays"][celebration.data.data["order"][0]];

  let anniversaryList =
    celebration.data.data["upcoming_anniversary"][celebration.data.data["order"][0]];

  let cardBoard = "cardBody";
  if (darkMode) {
    cardBoard = "cardBodyDark";
  }

  // const sortInfo = [
  //   { id: "box-1", name: "Profile Block", height: "430px" },
  //   { id: "box-4", name: "Upcoming Block", height: "430px" },
  //   { id: "box-3", name: "Leader Board Block", height: "430px" },
  //   { id: "box-2", name: "Video Block", height: "430px" },
  //   { id: "box-6", name: "GIF Image Block", height: "430px" },
  //   { id: "box-5", name: "Feeds", height: "960px" },
  // ];
  
  let masonryBlock = 2;
  let autoHeight = "auto";
  if (isMobile === false) {
    masonryBlock = 2;
    autoHeight = "";
  } else {
    masonryBlock = 1;
    autoHeight = "auto";
  }

  return (<>
    <MDBox my={0}>
      <MDBox p={0} m={0} mt={0}>
        <Grid container spacing={0}>
          <Masonry
            horizontalOrder={true}
            items={orderInfo}
            columns={masonryBlock}
          >
            {orderInfo.map((block) => (
              <MDBox item key={block.id} sx={{ height: (autoHeight === "" ? block.height : "auto") }}>
                {block.name === "Profile Block" && (
                  <ProfileBlock pageStatus={pageStatus} />
                )}
                {block.name === "Video Block" && (
                  <VideoBlock pageStatus={pageStatus} />
                )}
                {block.name === "Feeds" && (
                  <Feeds pageStatus={pageStatus} />
                )}
                {block.name === "Upcoming Block" && (
                  <div sx={{ borderRadius: "0.75rem"}}>
                    <Upcoming pageStatus={pageStatus} />
                  </div>
                )}
                {block.name === "Leader Board Block" && (
                  <LeaderBoard pageStatus={pageStatus} />
                )}
                {block.name === "GIF Image Block" && (
                  <ImageBlock pageStatus={pageStatus} />
                )}
                {block.name === "Activity Image 1" && (
                  <ActivityImage1 pageStatus={pageStatus} />
                )}
              </MDBox>
            ))}
          </Masonry>
        </Grid>
      </MDBox>
    </MDBox>
    </>);
}

Celebration.propTypes = {};

export default Celebration;
