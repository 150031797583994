import { Card, Grid } from "@mui/material";
import MDBox from "components/MDBox";
import React from "react";
import Image from "mui-image";

const ImageBlock1 = () => {
    
  const settings = localStorage.getItem("settings");
  var obj = JSON.parse(settings);
  let activityImage = obj.find((o) => o.Name === "celebration_image1");

  return (<>
    {activityImage.Status && (
    <Card sx={{
      marginBottom: "8px",
    }}>
      <MDBox p={2}>
        <Image
            src={activityImage.Value}
            maxWidth={false}
            sx={{ borderRadius: "0.75rem", width: "100%", height: "100%" }}
        />
      </MDBox>
    </Card>
    )}
  </>);
};

export default ImageBlock1;
