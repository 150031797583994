import React, { useEffect, useState } from "react";
import URLSettings from "../Common/URLSettings";
import { useNavigate } from "react-router-dom";
import MDBox from "components/MDBox";
import { Box, Card, Grid, Icon, Modal, Tooltip, Typography } from "@mui/material";
import Image from "mui-image";
import ProfilesList from "examples/Lists/ProfilesList";
import { useMutation, useQuery } from "react-query";
import axios from "axios";
import { isMobile } from "react-device-detect";
import MDSnackbar from "components/MDSnackbar";
import MDButton from "components/MDButton";
import { bgColorChange, txtColorChange } from "../Common/ColorChange";
import CloseIcon from '@mui/icons-material/Close';
import MDInput from "components/MDInput";

const Upcoming = ({pageStatus}) => {

  let APIURL = URLSettings();
  const navigate = useNavigate();

  const settings = localStorage.getItem("settings");
  var obj = JSON.parse(settings); 
  let bdayObj = obj.find((o) => o.Name === "enable_bday");
  let anniversaryObj = obj.find((o) => o.Name === "enable_anniversary");
  let celebrationImage = obj.find((o) => o.Name === "celebration_image");
  
  let blockHeight = "400px !important";
  if (isMobile) {
    blockHeight = "auto";
  }

  let upcomingFlag1 = false;
  let upcomingFlag2 = false;
  if (bdayObj.Value === "yes") {
    upcomingFlag1 = true;
  } 
  if (anniversaryObj.Value === "yes") {
    upcomingFlag2 = true;
  }

  const celebration = useQuery(["celebration"], async () =>
    axios.get(`${APIURL.url}/user/celebration`, {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    })
  );

  //Page Edit Options
  const [blockStatus, setBlockStatus] = useState(pageStatus);
  useEffect(() => {
    setBlockStatus(pageStatus);
  },[pageStatus])  

  const [eMsg, setEMsg] = useState("");
  const [sendError, setSendError] = useState(""); 
  const [sendSuccess, setSendSuccess] = useState("");
  const toggleSnackbar = () => {
    if (sendError) {
      setSendError(!sendError);
    }
    if (sendSuccess) {
      setSendSuccess(!sendSuccess);
    }
  };
  const [selectedFile, setSelectedFile] = useState(null);
  const handleFileSelect = (event) => {
    setSelectedFile(event.target.files[0]);
  };
  
  const [sId, setSId] = useState("");
  const [sVal, setSValue] = useState("");
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false); 
  const handleEdit = (val) => {
    setSId(val.id);
    setSValue(val.Value);
    handleOpen();
  }
  useEffect(() => {
    setTimeout(() => {
      setSendSuccess(false);
      setSendError(false);
    }, 2000);
  }, [sendError, sendSuccess]); 

  const reloadSettings = useMutation(
    async () =>
      axios.get(`${APIURL.url}/admin/settings`, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }),
    {
      onError: (error) => {
      },
      onSuccess: (data) => {
        localStorage.setItem("settings", JSON.stringify(data.data.rows));
        window.location.reload();
      },
    }
  );

  const handleSubmit = async (event) => {
    event.preventDefault(); 
    if (selectedFile != null) {
      const formData = new FormData();
      formData.append("file", selectedFile);
      formData.append("file_location", "common");
      formData.append("code", APIURL.code);
      formData.append("s_id", sId);
      formData.append("value", sVal);
      formData.append("status", true);
      try {
        const response = await axios({ 
          method: "post",
          url: `${APIURL.url}/admin/updateSettings`,
          data: formData,
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: localStorage.getItem("token"),
          },
        });
        if (response) {
          reloadSettings.mutate();
          setEMsg(response.data);
          setSendSuccess(true);
          setSId("");
          setSValue("");
          handleClose();
        }
      } catch (error) {
        setEMsg(error.response.data);
        setSendError(true);
        handleClose();
        if (error.response.status === 401) {
          window.location.reload();
          return navigate("/login");
        }
      }
    } 
  }


  if (celebration.isError) {
    if (celebration.error.response.status === 401) {
      window.location.reload();
      return navigate("/login");
    } 
  }

  if (!celebration.isSuccess) {
    return (
      <MDBox my={2}>
        <MDBox p={1} m={1} mt={2}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6} lg={6}>
              <Card />
            </Grid>
          </Grid>
        </MDBox>
      </MDBox> 
    );
  }

  let birthdayList =
    celebration.data.data["upcoming_birthdays"][celebration.data.data["order"][0]];

  let anniversaryList =
    celebration.data.data["upcoming_anniversary"][celebration.data.data["order"][0]];

  return (<>
      {!upcomingFlag1 && !upcomingFlag2 ? (
        <Card sx={{
            marginBottom: "5px",
          }}>
            <MDSnackbar
              color="error"
              icon="error"
              title="Error: Please try again"
              content={eMsg}
              open={sendError}
              close={toggleSnackbar}
            />
            <MDSnackbar
              color="success"
              icon="check_circle"
              title="Success"
              content="Settings Updated"
              open={sendSuccess}
              close={toggleSnackbar}
            />
            {blockStatus && (
              <MDBox sx={{ position: "absolute", right: 0, top: 0, zIndex: 99}}>
                <Tooltip title="Edit" placement="top"> 
                  <MDButton
                    variant="gradient"
                    color="info"
                    size="small"
                    iconOnly
                    onClick={() => handleEdit(celebrationImage)}
                    sx={bgColorChange}
                  >
                    <Icon>edit</Icon> 
                  </MDButton>  
                </Tooltip> 
              </MDBox>
            )}
            <MDBox p={2}>
              <Image
                src={celebrationImage.Value}
                maxWidth={false}
                sx={{ borderRadius: "0.75rem", height: blockHeight }}
              />
            </MDBox>
          {/* Modal Popup Starts Here */}
          <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          >  
            <Box className="modalPopupBox"> 
              <Grid container>
                <Grid item xs={12}> 
                  <Typography id="keep-mounted-modal-title" variant="h5" component="h2" sx={txtColorChange}> 
                    Edit Image (png / gif - 560*390)
                  </Typography> 
                  <MDBox variant="outlined" className="close-button" onClick={handleClose}><CloseIcon /></MDBox> 
                </Grid>
              </Grid>
              <MDBox component="form" role="form" onSubmit={handleSubmit}>
                <Grid container>
                  <Grid item xs={12}>
                    <MDBox mt={2} mb={2} mr={1}> 
                      <MDInput type="file" fullWidth onChange={handleFileSelect} />
                    </MDBox>
                  </Grid>
                </Grid>
                <MDBox mt={2} mb={1}>  
                  <MDButton type="submit" variant="gradient" color="info" sx={bgColorChange}>
                    Save
                  </MDButton>
                </MDBox>
              </MDBox>
            </Box> 
          </Modal>
          {/* Modal Popup Ends Here */}
        </Card>
      ) : (
        <MDBox sx={{ backgroundColor: "#ffffff", borderRadius: "10px", padding: "5px", marginBottom: "20px" }}>
          <Card
            class="lbBoard"
          >
            <Grid container>
              {upcomingFlag1 && (
                <Grid item xs={12} md={6} lg={6}>
                  <ProfilesList
                    title="Upcoming Birthdays"
                    profiles={birthdayList}
                    shadow={false}
                    event_type={"birthday"}
                  />
                </Grid>
              )}
              {upcomingFlag2 && (
                <Grid item xs={12} md={6} lg={6}>
                  <ProfilesList
                    title="Upcoming Anniversary"
                    profiles={anniversaryList}
                    shadow={false}
                    event_type={"anniversary"}
                  />
                </Grid>
              )}
            </Grid>
          </Card>
        </MDBox>
      )}
    </>
  );
};

export default Upcoming;
