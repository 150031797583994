// Material Dashboard 2 PRO React components
// import PropTypes from "prop-types";
import { useEffect, useState } from "react";

// Material Dashboard 2 PRO React context
// import { useMaterialUIController } from "context";
import { Autocomplete, Box, MenuItem, Card, Grid, FormControl, InputLabel, Icon,  Typography, Modal, Tooltip, Tabs, Tab } from "@mui/material";
import MDBox from "components/MDBox";

import { isMobile } from "react-device-detect";

import { useMutation, useQuery } from "react-query";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import SiteFooter from "../Common/SiteFooter";
import { useMaterialUIController } from "context";
import URLSettings from "../Common/URLSettings";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import { bgColorChange, txtColorChange } from "../Common/ColorChange";
import DataGridTables from "../Common/DataGridTables";
import MDSnackbar from "components/MDSnackbar";
import InputEmoji from 'react-input-emoji'
import Select from '@mui/material/Select';
import Loader from "../Common/Loader";
import CloseIcon from '@mui/icons-material/Close';
import MDTypography from "components/MDTypography";
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import TabPanel from "../Common/TabPanel";

const jose = require("jose");

function GroupGift() {
  let APIURL = URLSettings();
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const navigate = useNavigate();

  const settings = localStorage.getItem("settings");

  useEffect(() => {
    if (!localStorage.getItem("token")) {
      window.location.reload();
      return navigate("/login");
    }
  }, []);

  const token = useQuery("token", async () => {
    // should be validating with public key
    const text = localStorage.getItem("token");
    if (text === null || text === "") {
      throw new Error("No token found");
    }
    const claims = jose.decodeJwt(text);
    claims.token = text;
    return claims;
  });

  if (token.isError) {
    window.location.reload();
    return navigate("/login");
  }

  //Group Related Code
  const [groupSuggestions, setGroupSuggestions] = useState([]); 
  const [groupRecipient, setgroupRecipient] = useState([]);  
  const [groupSuggestions1, setGroupSuggestions1] = useState([]);
  const [groupRecipient1, setgroupRecipient1] = useState([]);  

  const [userData, setUserData] = useState([]);
  const [userCount, setUserCount] = useState(0);
  const [actualPoints, setActualPoints] = useState();
  const [totalPoints, setTotalPoints] = useState(0);
  const [Recipient, setRecipient] = useState([]); 
  const [eMsg, setEMsg] = useState("");
  const [sendError, setSendError] = useState(false);
  const [sendSuccess, setSendSuccess] = useState(false);
  const [pointsError, setPointsError] = useState(false);
  const [disablePoints, setDisablePoints] = useState(false);
  const [pointsMessage, setPointsMessage] = useState("");
  const [defaultTemplate, setDefaultTemplate] = useState({ id: "", name: "" });

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const toggleSnackbar = () => {
    if (sendError) {
      setSendError(!sendError);
    }
    if (sendSuccess) {
      setSendSuccess(!sendSuccess);
    }
    if (pointsError) {
      setPointsError(!pointsError);
    }
  };
  useEffect(() => {
    setTimeout(() => {
      setSendError(false);
      setPointsError(false);
      setSendSuccess(false);
    }, 5000);
  }, [sendError, pointsError, sendSuccess]);

  const getGroupSuggestions = useMutation(
    async (search) =>
      axios.get(`${APIURL.url}/getGroupSuggestions`, {
        params: {
          search,
        },
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }),
    {
      onError: (err) => {
        setEMsg(err.response.data);
        setSearchError(true);
        if (err.response.status === 401) {
            window.location.reload();
            return navigate("/login");
        }
      },
      onSuccess: (data) => {
        setGroupSuggestions(data.data.groups);
      },
    }
  );
  
  const updateGroupSuggestions = (e) => {
    getGroupSuggestions.mutate(e.target.value);
  };

  const updateGroupRecipient = (e, option) => {
    if (option) {
      setgroupRecipient(option?.map(function(a){
        let groupObj = [a.key, a.name];
        return groupObj;
      }));
    } 
  };

  const getGroupSuggestions1 = useMutation(
    async (search) =>
      axios.get(`${APIURL.url}/getGroupSuggestions`, {
        params: {
          search,
        },
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }),
    {
      onError: (err) => {
        setEMsg(err.response.data);
        setSearchError(true);
        if (err.response.status === 401) {
            window.location.reload();
            return navigate("/login");
        }
      },
      onSuccess: (data) => {
        setGroupSuggestions1(data.data.groups);
      },
    }
  );
  
  const updateGroupSuggestions1 = (e) => {
    getGroupSuggestions1.mutate(e.target.value);
  };

  const updateGroupRecipient1 = (e, option) => {
    if (option) {
      setgroupRecipient1(option?.map(function(a){
        let groupObj = [a.key, a.name];
        return groupObj;
      }));
    } 
  };

  const templates = useQuery(
    "templates",
    async () =>
    axios.get(`${APIURL.url}/getMessageTemplate`, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }),
    {
      onSuccess: (data) => {
        const tempData = data.data.filter((list) => list.is_default === 1);
        if (tempData.length > 0) {
          setDefaultTemplate(tempData[0]);
        }
      },
    }
  );

  const updatePointsTemplate = (e, option) => {
    if (option) {
      if (option.props.value.is_default === 1) {
        setPointsMessage("");
      } else {
        setPointsMessage(option.props.value.message);
      }
    } else {
      setPointsMessage("");
    }
  };

  const handleRetrieve = async (event) => {
    event.preventDefault(); 
    let jsonData = {
        "filter_1": groupRecipient,
        "filter_2": groupRecipient1
    }
    try { 
      const response = await axios({
        method: "post",
        url: `${APIURL.url}/user/list`,
        data: jsonData,
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("token"),
        },
      });
      if (response) {
        setUserCount(response.data.length);
        setUserData(response.data);
        setDisablePoints(true);
      }
    } catch (error) {
      if (error.response.status === 401) {
        window.location.reload();
        return navigate("/login");
      }
    }
  }

  const handleChangePoints = (val) => {
    setActualPoints(val);
    setTotalPoints(userCount*val);
  }
  const sendPoints = useMutation(
    async () =>
      axios.post(
        `${APIURL.url}/sendPoints`,
        {
          points: actualPoints,
          message: pointsMessage,
          destinationAccountID: Recipient,
        },
        {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        }
      ),
    {
      onError: (err) => {
        handleClose();
        setDisablePoints(false);
        setEMsg(err.response.data);
        if (err.response.data === "Insufficient Points") {
          setPointsError(true);
        } else {
          setSendError(true);
        }
        if (err.response.status === 401) {
          window.location.reload();
          return navigate("/login");
        }
      },
      onSuccess: () => {
        handleClose();
        setDisablePoints(false);
        setSendSuccess(true);
        setTotalPoints();
        setRecipient([]);
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      },
    }
  );

  const handleSendPoints = () => {
    setRecipient(userData?.map(function(a){
      return a.id;
    }));
    setTimeout(() => {
      sendPoints.mutate();
    }, 2000);
  }

  const [gOpen, setGOpen] = useState(false);
  const handleGOpen = () => setGOpen(true);
  const handleGClose = () => setGOpen(false);

  const handleGroup = () => {

  }

  const [glOpen, setGLOpen] = useState(false);
  const handleGLOpen = () => setGLOpen(true);
  const handleGLClose = () => setGLOpen(false);
  const groupData = [];
  const groupColumns = [{ 
    field: "id", 
    headerName: "ID", 
    width: 60 
  },
  {
    field: "group_name",
    headerName: "Group Name",
    minWidth: 150
  },
  {
    field: "userslist",
    headerName: "Users List",
    minWidth: 250
  },
  {
    field: "actions",
    headerName: "Actions",
    minWidth: 150
  }
  ];

  const giftColumns = [
    { 
      field: "id", 
      headerName: "ID", 
      width: 60 
    },
    {
      field: "first_name",
      headerName: "First Name",
      minWidth: 150
    },
    {
      field: "last_name",
      headerName: "Last Name",
      minWidth: 150
    },
    {
      field: "employee_id",
      headerName: "Employee Id",
      minWidth: 150
    },
    {
      field: "job_title",
      headerName: "Job Title",
    },
    {
      field: "org_level_1",
      headerName: "Org Level 1",
    },
    {
      field: "org_level_2",
      headerName: "Org Level 2",
    },
    {
      field: "org_level_3",
      headerName: "Org Level 3",
    },
    {
      field: "org_level_4",
      headerName: "Org Level 4",
    },
  ];

  const [value, setValue] = useState(1);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  if (templates.isError) {
    if (templates.isError) {
      if (templates.error.response.status === 401) {
        window.location.reload();
        return navigate("/login");
      }
    }
  }

  if (!templates.isSuccess) {
    return <Loader />;
  } 

  return (
    <DashboardLayout>
      <MDBox my={3}>
        <Card p={2}> 
          <MDSnackbar
            color="error"
            icon="error"
            title="Error: Unable to Send"
            content={eMsg}
            open={sendError}
            close={toggleSnackbar}
          />
          <MDSnackbar
            color="success"
            icon="check_circle"
            title="Success"
            content="Points Sent"
            open={sendSuccess}
            close={toggleSnackbar}
          />
          <MDSnackbar
            color="error"
            icon="error"
            title="Error: Insufficient Points"
            content={eMsg}
            open={pointsError}
            close={toggleSnackbar}
          />
            <MDBox p={3} lineHeight={1}>

            <Tabs value={value} onChange={handleChange} aria-label="Tabs">
              <Tab label="Group Gifting" value={1} className="fontFamily" />
              <Tab label="Custom Groups" value={2} className="fontFamily" />
              <Tab label="Custom Groups List" value={3} className="fontFamily" />
            </Tabs>
            <TabPanel value={value} index={1}>
              <MDBox p={2}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={3} lg={3}>
                    <Autocomplete
                        multiple
                        options={groupSuggestions}
                        renderOption={(props, option) => (
                        <li {...props} key={option.name}>
                            {`${option.name}`}
                        </li>
                        )}
                        getOptionLabel={(option) =>
                        `${option.name}`
                        }
                        isOptionEqualToValue={(option, value) => option.name === value.name}
                        onChange={updateGroupRecipient}
                        renderInput={(params) => (
                        <MDInput
                            label="Select Primary Group"
                            {...params}
                            variant="standard"
                            onChange={updateGroupSuggestions}
                            // sx={{ mb: 3 }}
                        />
                        )}
                    />  
                    <span className="groupNote">(You can select upto 10 groups)</span>
                  </Grid>
                  <Grid item xs={12} md={1} lg={1}>
                    <Typography sx={{ marginTop: "10px", textAlign: "center", fontSize: "24px"}}>&</Typography>
                  </Grid>
                  <Grid item xs={12} md={3} lg={3}>
                    <Autocomplete
                        multiple
                        options={groupSuggestions1}
                        renderOption={(props, option) => (
                        <li {...props} key={option.name}>
                            {`${option.name}`}
                        </li>
                        )}
                        getOptionLabel={(option) =>
                        `${option.name}`
                        }
                        isOptionEqualToValue={(option, value) => option.name === value.name}
                        onChange={updateGroupRecipient1}
                        renderInput={(params) => (
                        <MDInput
                            label="Select Secondary Group"
                            {...params}
                            variant="standard"
                            onChange={updateGroupSuggestions1}
                            // sx={{ mb: 3 }}
                        />
                        )}
                    /> 
                    <span className="groupNote">(You can select upto 10 groups)</span>
                  </Grid>
                  <Grid item xs={12} md={2} lg={2}>
                    <MDButton variant="gradient" color="info" sx={bgColorChange} fullWidth onClick={handleRetrieve}>
                        Retrieve
                    </MDButton>
                  </Grid>
                  <Grid item xs={12} md={3} lg={3}>
                    <MDInput
                      type="text"
                      label={"Enter Points (Total Points : " + totalPoints +")"}
                      fullWidth
                      placeholder="Enter Points"
                      sx={{ marginLeft: "5px" }}
                      onChange={(e) => handleChangePoints(e.target.value)}
                    />  
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={3} lg={3}>
                    <FormControl variant="standard" sx={{ m: 1, width: "100%" }}>
                      <InputLabel id="demo-simple-select-standard-label">Select Template Type</InputLabel>
                      <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" label="Select Template Type" defaultValue={defaultTemplate} onChange={updatePointsTemplate} sx={{minHeight: "40px"}}>
                        {templates.data.data.map((template) => (
                          <MenuItem value={template}>{template.name}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={4} lg={4} sx={{ marginTop: "18px" }}>
                    <InputEmoji
                        value={pointsMessage}
                        onChange={setPointsMessage}
                        cleanOnEnter
                        // onEnter={handleOnEnter}
                        placeholder="Message"
                        borderRadius={8}
                        className="emojiPicker"
                      />
                  </Grid>
                  <Grid item xs={12} md={2} lg={2} sx={{ marginTop: "18px" }}>
                    <MDButton variant="gradient" color="info" sx={bgColorChange} disabled = {disablePoints ? false : true } onClick={handleOpen}>
                      <Icon sx={{ ml: 0.5 }} className="rotateIcon">send</Icon>
                    </MDButton>
                  </Grid>
                  <Grid item xs={12} md={3} lg={3}></Grid>
                </Grid><br />
                <Grid container spacing={2}>
                  <Grid item xs={12} md={12} lg={12}>
                      <DataGridTables tableRow={userData} columns={giftColumns} /> 
                  </Grid>
                </Grid>
              </MDBox> 
            </TabPanel>
            <TabPanel value={value} index={2}>
              {/* <Grid container>
                <Grid item xs={6}> 
                  <MDTypography variant="h5" fontWeight="medium" sx={txtColorChange}>
                    Group Gifting
                  </MDTypography>
                </Grid>
                <Grid item xs={6}>
                  <Tooltip title="Custom Group Listing" placement="top">
                    <MDButton
                      variant="gradient"
                      color="info"
                      sx={bgColorChange}
                      className="adminBtn iconBtns listIcons"
                      onClick={handleGLOpen}
                    >
                      <Icon fontSize="medium">list</Icon>
                    </MDButton>
                  </Tooltip> 
                  <Tooltip title="Create Custom Group" placement="top">
                  <MDButton
                    variant="gradient"
                    color="info"
                    sx={bgColorChange}
                    className="adminBtn iconBtns"
                    onClick={handleGOpen}
                  >
                    <PlaylistAddIcon sx={{ fontSize: "26px !important" }} />
                  </MDButton>
                  </Tooltip>
                </Grid>
              </Grid> */}
              <Grid container spacing={2}>
                <Grid item xs={12} md={3} lg={3}>
                  <MDInput
                    type="text"
                    label={"Enter Group Name"}
                    fullWidth
                    placeholder="Enter Points"
                    sx={{ marginLeft: "5px" }}
                  />  
                </Grid>
                <Grid item xs={12} md={3} lg={3}>
                  <Autocomplete
                      multiple
                      options={groupSuggestions}
                      renderOption={(props, option) => (
                      <li {...props} key={option.name}>
                          {`${option.name}`}
                      </li>
                      )}
                      getOptionLabel={(option) =>
                      `${option.name}`
                      }
                      isOptionEqualToValue={(option, value) => option.name === value.name}
                      onChange={updateGroupRecipient}
                      renderInput={(params) => (
                      <MDInput
                          label="Select Group"
                          {...params}
                          variant="standard"
                          onChange={updateGroupSuggestions}
                          sx={{ mb: 3 }}
                      />
                      )}
                  />  
                </Grid>
                <Grid item xs={12} md={2} lg={2}>
                  <MDButton variant="gradient" color="info" sx={bgColorChange} fullWidth onClick={handleRetrieve}>
                      Get User Data
                  </MDButton>                  
                </Grid>
                <Grid item xs={12} md={3} lg={3}>
                  <Autocomplete
                      multiple
                      options={groupSuggestions}
                      renderOption={(props, option) => (
                      <li {...props} key={option.name}>
                          {`${option.name}`}
                      </li>
                      )}
                      getOptionLabel={(option) =>
                      `${option.name}`
                      }
                      isOptionEqualToValue={(option, value) => option.name === value.name}
                      onChange={updateGroupRecipient}
                      renderInput={(params) => (
                      <MDInput
                          label="Select Users"
                          {...params}
                          variant="standard"
                          onChange={updateGroupSuggestions}
                          sx={{ mb: 3 }}
                      />
                      )}
                  /> 
                </Grid>
                <Grid item xs={12} md={1} lg={1}>
                  <MDButton variant="gradient" color="info" sx={bgColorChange} fullWidth onClick={handleRetrieve}>
                      Create
                  </MDButton>  
                  {/* <Tooltip title="Custom Group Listing" placement="top">
                    <MDButton
                      variant="gradient"
                      color="info"
                      sx={bgColorChange}
                      className="adminBtn iconBtns listIcons"
                      onClick={handleGLOpen}
                    >
                      <Icon fontSize="medium">list</Icon>
                    </MDButton>
                  </Tooltip>  */}
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12} md={12} lg={12}>
                    <DataGridTables tableRow={userData} columns={giftColumns} checkSelection={true} /> 
                </Grid>
              </Grid>
            </TabPanel>
            <TabPanel value={value} index={3}>
              <DataGridTables tableRow={groupData} columns={groupColumns} />       
            </TabPanel>

              
            </MDBox>
            

            {/* Confirm popup starts */}
            <Modal
              open={open}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box className="modalPopupBox">
                <Grid container> 
                  <Typography sx={{ fontSize: "14px" }} color="secondary">
                    Are you sure you want to confirm?
                  </Typography>
                  <MDBox variant="outlined" className="close-button" onClick={handleClose}><CloseIcon /></MDBox>
                </Grid>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={12} lg={12}>
                    <MDBox mt={2} mb={1}>
                      <MDButton variant="gradient" color="secondary" onClick={handleClose} className="spacing">
                        Cancel
                      </MDButton>
                      <MDButton variant="gradient" color="success" onClick={handleSendPoints} sx={bgColorChange} disabled = {disablePoints ? false : true }>
                        Confirm
                      </MDButton>
                    </MDBox>
                  </Grid>
                </Grid>
              </Box>
            </Modal>
            {/* Confirm popup ends */}
            {/* Adding Groups starts */}
            <Modal
              open={gOpen}
              onClose={handleGClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box className="modalPopupBox">
                <Grid container> 
                  <Typography id="keep-mounted-modal-title" variant="h5" component="h2" sx={txtColorChange}>
                  Custom Groups
                  </Typography>
                  <MDBox variant="outlined" className="close-button" onClick={handleGClose}><CloseIcon /></MDBox>
                </Grid>
                <MDBox component="form" role="form" className="formInput">
                  <Grid container>
                    <Grid item xs={6}>
                      <MDBox mt={2} mr={1}>
                        <MDInput
                          type="text"
                          label="Group Name"
                          fullWidth
                          placeholder="Group Name"
                        />
                      </MDBox>
                    </Grid>
                    <Grid item xs={6}>
                      <MDBox mt={2} mr={1}>
                        <Autocomplete
                          multiple
                          options={groupSuggestions}
                          renderOption={(props, option) => (
                          <li {...props} key={option.name}>
                              {`${option.name}`}
                          </li>
                          )}
                          getOptionLabel={(option) =>
                          `${option.name}`
                          }
                          isOptionEqualToValue={(option, value) => option.name === value.name}
                          onChange={updateGroupRecipient}
                          renderInput={(params) => (
                          <MDInput
                              label="Select Group"
                              {...params}
                              variant="standard"
                              onChange={updateGroupSuggestions}
                              sx={{ mb: 3 }}
                          />
                          )}
                        /> 
                      </MDBox>
                    </Grid>
                  </Grid>
                  <MDBox mt={0} mb={1}>
                    <MDButton variant="gradient" color="info" onClick={handleGroup} sx={bgColorChange}>
                      Create Group
                    </MDButton>
                  </MDBox>
                </MDBox>
              </Box>
            </Modal>
            {/* Adding Groups starts */}
            {/* Custom Group Listing Starts */}
            <Modal
              open={glOpen}
              onClose={handleGLClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box className="modalPopupBox">
                <Grid container> 
                  <Typography id="keep-mounted-modal-title" variant="h5" component="h2" sx={txtColorChange}>
                  Custom Group Lists
                  </Typography>
                  <MDBox variant="outlined" className="close-button" onClick={handleGLClose}><CloseIcon /></MDBox>
                </Grid>
                <MDBox component="form" role="form" className="formInput">
                  <Grid container>
                    <Grid item xs={12}> 
                      <DataGridTables tableRow={groupData} columns={groupColumns} />       
                    </Grid>
                  </Grid>
                </MDBox>
              </Box>
            </Modal>            
            {/* Custom Group Listing Ends */}
        </Card>
      </MDBox>
      <SiteFooter />
    </DashboardLayout>
  );
}

export default GroupGift;
