/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React components
import { useMaterialUIController } from "context";

// Timeline context
import { TimelineProvider } from "examples/Timeline/context";

import { txtColorChange } from "layouts/Rewards/User/components/Common/ColorChange";

function TimelineList({ title, dark, children, noCard }) {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  if (!noCard) {
    return (
      <TimelineProvider value={dark}>
        <Card>
          <MDBox
            // bgColor={dark ? "dark" : "white"}
            variant="gradient"
            borderRadius="xl"
            // sx={{ background: ({ palette: { background } }) => darkMode && background.card }}
            sx={{background: "transparent"}}
          >
            <MDBox pt={3} px={3}>
              <MDTypography variant="h6" fontWeight="medium" color={dark ? "white" : "dark"}>
                {title}
              </MDTypography>
            </MDBox>
            <MDBox p={2}>{children}</MDBox>
          </MDBox>
        </Card>
      </TimelineProvider>
    );
  }

  return (
    <TimelineProvider value={dark}>
      <MDBox
        // bgColor={dark ? "dark" : "white"}
        variant="gradient"
        borderRadius="xl"
        // sx={{ background: ({ palette: { background } }) => darkMode && background.card }}
        sx={{background: "transparent"}}
      >
        <MDBox pt={3} px={3}>
          <MDTypography variant="h6" fontWeight="medium" color={dark ? "white" : "dark"} sx={txtColorChange} className="fontFamily">
            {title}
          </MDTypography>
        </MDBox>
        <MDBox p={2}>{children}</MDBox>
      </MDBox>
    </TimelineProvider>
  );
}

// Setting default values for the props of TimelineList
TimelineList.defaultProps = {
  dark: false,
  noCard: false,
};

// Typechecking props for the TimelineList
TimelineList.propTypes = {
  title: PropTypes.string.isRequired,
  dark: PropTypes.bool,
  children: PropTypes.node.isRequired,
  noCard: PropTypes.bool,
};

export default TimelineList;
