// import Card from "@mui/material/Card";
// import Divider from "@mui/material/Divider";

// Material Dashboard 2 PRO React components
import { Card, Grid } from "@mui/material";
import axios from "axios";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { txtColorChange } from "layouts/Rewards/User/components/Common/ColorChange";
import SiteFooter from "layouts/Rewards/User/components/Common/SiteFooter";
import URLSettings from "layouts/Rewards/User/components/Common/URLSettings";
import { useEffect } from "react";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
// OrderDetails page components

function Dashboard() {
  let APIURL = URLSettings();
  const navigate = useNavigate();
  

  useEffect(() => {
    if (!localStorage.getItem("token")) {
      window.location.reload();
      return navigate("/login");
    }
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mt={1} my={3}>
        <Card>
          <MDBox p={3} lineHeight={1}>
            <Grid container>
              <Grid item xs={6}>
                <MDTypography variant="h5" fontWeight="medium" sx={txtColorChange}>
                  Dashboard
                </MDTypography>
              </Grid>
            </Grid>
          </MDBox>
        </Card>
      </MDBox>
      <SiteFooter />
    </DashboardLayout>
  );
}

export default Dashboard;
