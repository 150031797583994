import axios from 'axios';
import MDBox from 'components/MDBox';
import MDButton from 'components/MDButton';
import MDSnackbar from 'components/MDSnackbar';
import { bgColorChange } from 'layouts/Rewards/User/components/Common/ColorChange';
import URLSettings from 'layouts/Rewards/User/components/Common/URLSettings';
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';

const MyWidget = ({ name }) => <div>{name}</div>;

const Layout = ({data, layoutId}) => {

  let APIURL = URLSettings();
  const navigate = useNavigate();

  const [items, setItems] = useState(data);
  const [from, setFrom] = useState(null);
  const [to, setTo] = useState(null);

  const [eMsg, setEMsg] = useState("");
  const [sendError, setSendError] = useState(false);
  const [sendSuccess, setSendSuccess] = useState(false);

  const toggleSnackbar = () => {
    if (sendError) {
      setSendError(!sendError);
    }
    if (sendSuccess) {
      setSendSuccess(!sendSuccess);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      setSendError(false);
      setSendSuccess(false);
    }, 5000);
  }, [sendError, sendSuccess]);

  const handleJsLayout = async (event) => {
    try {
      const response = await axios({
        method: "post",
        url: `${APIURL.url}/admin/updateSettingsText`,
        data: {
          s_id: layoutId,
          value: JSON.stringify(items),
          status: true
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("token"),
        },
      });
      if (response) {
        setEMsg('Layout Updated Successfully');
        setSendSuccess(true);
      }
    } catch (error) {
      setEMsg(error.response.data);
      setSendError(true);
      if (error.response.status === 401) {
        window.location.reload();
        return navigate("/login");
      }
    }
  }  

  if (handleJsLayout.isError) {
    if (handleJsLayout.error.response.status === 401) {
      window.location.reload();
      return navigate("/login");
    }
  }

  return (<>
    <MDBox>
      <MDSnackbar
          color="error"
          icon="error"
          title="Error: Unable to Send"
          content={eMsg}
          open={sendError}
          close={toggleSnackbar}
        /> 
      <MDSnackbar
        color="success"
        icon="check_circle"
        title="Success"
        content={eMsg}
        open={sendSuccess}
        close={toggleSnackbar} />
      <div className="grid">
        {items && items.map((item, i) => (
          <div
            className={`box ${item.type}-${i}${i === to && to !== from ? " to" : ""}`}
            data-index={i}
            key={item.name}
            draggable="true"
            onDragStart={e => setFrom(Number(e.currentTarget.dataset.index))}
            onDragOver={e => {
                e.preventDefault();
                setTo(Number(e.currentTarget.dataset.index));
            }}
            onDragEnd={() => {
                items.splice(to, 0, items.splice(from, 1)[0]);
                setItems(items);
                setFrom(null);
                setTo(null);
            }}
            >
            <MyWidget name={item.name} />
          </div>
        ))}
      </div>
      <MDButton variant="gradient" color="success" className="layoutSubmit" onClick={handleJsLayout} sx={bgColorChange}>
        Confirm
      </MDButton>
    </MDBox>
    </>
  );
};

export default Layout;