// import Card from "@mui/material/Card";
// import Divider from "@mui/material/Divider";

// Material Dashboard 2 PRO React components
import axios from "axios";
import { Box, Card, Grid, Icon, Modal, Switch, Tooltip, Typography } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
// import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import SiteFooter from "layouts/Rewards/User/components/Common/SiteFooter";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useMutation, useQuery } from "react-query";
import MDInput from "components/MDInput";
import URLSettings from "layouts/Rewards/User/components/Common/URLSettings";
import MDSnackbar from "components/MDSnackbar";
import Loader from "layouts/Rewards/User/components/Common/Loader";
import Logout from "layouts/Rewards/User/components/Common/Logout";
import { txtColorChange, bgColorChange } from "layouts/Rewards/User/components/Common/ColorChange";
import MDAvatar from "components/MDAvatar";
import CloseIcon from '@mui/icons-material/Close';
// OrderDetails page components

const jose = require("jose");

// const style = {
//   position: "absolute",
//   top: "50%",
//   left: "50%",
//   transform: "translate(-50%, -50%)",
//   width: 500,
//   bgcolor: "#a8a9af",
//   border: "2px solid #000",
//   boxShadow: 24,
//   p: 4,
// };

function Settings() {
  let APIURL = URLSettings();
  const navigate = useNavigate();
  const logout = () => {
    localStorage.removeItem("token");
    navigate("/login");
  };

  useEffect(() => {
    if (!localStorage.getItem("token")) {
      window.location.reload();
      return navigate("/login");
    }
  }, []);

  const [eMsg, setEMsg] = useState("");
  const [sendError, setSendError] = useState("");
  const [sendSuccess, setSendSuccess] = useState("");
  const toggleSnackbar = () => {
    if (sendError) {
      setSendError(!sendError);
    }
    if (sendSuccess) {
      setSendSuccess(!sendSuccess);
    }
  };
  useEffect(() => {
    setTimeout(() => {
      setSendSuccess(false);
      setSendError(false);
    }, 2000);
  }, [sendError, sendSuccess]);

  const claims = jose.decodeJwt(localStorage.getItem("token"));

  const settings = useQuery(["settings"], async () =>
    axios.get(`${APIURL.url}/admin/client_settings`, {
      params: {
        company_id: claims.company_id,
      },
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    })
  );

  if (settings.isError) {
    setEMsg(settings.error.response.data);
    if (settings.error.response.status === 401) {
      window.location.reload();
      return navigate("/login");
    }
  }

  const [Name, setName] = useState("");
  const [sVal, setSValue] = useState("");
  const [sId, setSId] = useState("");
  const [sStatus, setSStatus] = useState(false);

  const handleStatus = () => {
    setSStatus(!sStatus);
  };

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [imageType, setImageType] = useState("");
  const [fLocation, setFLocation] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const handleFileSelect = (event) => {
    setSelectedFile(event.target.files[0]);
  };
  const handleEdit = (row) => {
    setImageType(row.type)
    setFLocation("common");
    // image_link/
    setSId(row.id);
    setName(row.Name);
    setSValue(row.Value);
    setSStatus(row.Status);
    handleOpen();
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (selectedFile != null) { 

      const formData = new FormData();
      formData.append("file", selectedFile);
      formData.append("file_location", fLocation);
      formData.append("code", APIURL.code);
      formData.append("s_id", sId);
      formData.append("name", Name);
      formData.append("value", sVal);
      formData.append("status", sStatus);
      try {
        const response = await axios({
          method: "post",
          url: `${APIURL.url}/admin/updateSettings`,
          data: formData,
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: localStorage.getItem("token"),
          },
        });
        // console.log("res::", response);
        if (response) {
          // console.log(response);
          setSendSuccess(true);
          settings.refetch();
          setSId("");
          setName("");
          setSValue("");
          setSStatus("");
          handleClose();
        }
      } catch (error) {
        // console.log(error);
        setEMsg(error.response.data);
        setSendError(true);
        handleClose();
        if (error.response.status === 401) {
          window.location.reload();
          return navigate("/login");
        }
      }

    } else {

      try {
        const response = await axios({
          method: "post",
          url: `${APIURL.url}/admin/updateSettingsText`,
          data: {
            s_id: sId,
            name: Name,
            value: sVal,
            status: sStatus
          },
          headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.getItem("token"),
          },
        });
        if (response) {
          setSendSuccess(true);
          settings.refetch();
          setSId("");
          setName("");
          setSValue("");
          setSStatus("");
          handleClose();
        }
      } catch (error) {
        setEMsg(response.data);
        setSendError(true);
        handleClose();
        if (error.response.status === 401) {
          window.location.reload();
          return navigate("/login");
        }
      }

    }
  };


  if (!settings.isSuccess) {
    return <Loader />;
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox my={3}>
        <MDSnackbar
          color="error"
          icon="error"
          title="Error: Please try again"
          content={eMsg}
          open={sendError}
          close={toggleSnackbar}
        />
        <MDSnackbar
          color="success"
          icon="check_circle"
          title="Success"
          content="Settings Updated"
          open={sendSuccess}
          close={toggleSnackbar}
        />
        <Card>
          <MDBox p={2} m={2}>
            <MDTypography variant="h5" fontWeight="bold" sx={txtColorChange}>
              Settings
            </MDTypography>
            <MDTypography variant="h5" mt={3} fontWeight="bold" sx={txtColorChange}>
              Text Edit Fields
            </MDTypography>
            <MDBox pt={4} pb={3} px={3}>
              {settings.data.data.rows.map((info) => (<>
                  {info.type === "text" && (
                  <Grid container spacing={3} mt={2} mb={2}>
                    <Grid xs={12} md={4} lg={4}>
                      <MDTypography
                        display="inline-block"
                        component="p"
                        variant="button"
                        fontWeight="regular"
                        color="text"
                      >
                        {info.Name}:
                      </MDTypography>
                    </Grid>
                    <Grid xs={12} md={6} lg={6}>
                      <MDBox
                        alt="spotify logo"
                        sx={{ marginTop: "-8px", float: "left", marginRight: "8px" }}
                      >
                        <Tooltip title="Edit" placement="top">
                          <MDButton
                            variant="gradient"
                            color="info"
                            size="small"
                            iconOnly
                            onClick={() => handleEdit(info)}
                            sx={bgColorChange}
                          >
                            <Icon>edit</Icon>
                          </MDButton>
                        </Tooltip>
                      </MDBox>
                      <MDTypography
                        display="inline-block"
                        component="p"
                        variant="button"
                        fontWeight="regular"
                        color="text"
                        sx={{ float: "left" }}
                      >
                        <b>{info.Value}</b>
                      </MDTypography>
                    </Grid>
                  </Grid>
                  )}
                </>
              ))}
            </MDBox>
            <MDTypography variant="h5" fontWeight="bold" sx={txtColorChange}>
              Image Upload
            </MDTypography>
            <Grid container>
              {settings.data.data.rows.map((info) => (<>
                {info.type === "image_link" && (
                  <Grid item xs={12} md={3} lg={3}>
                  <MDBox m={2}>
                    <MDBox position="relative" height="max-content" mx="auto">
                    <Typography>{info.Name}</Typography>
                      <MDAvatar
                        src={info.Value}
                        alt="profile picture"
                        size="xl"
                        variant="rounded"
                      />
                      <MDBox
                        alt="spotify logo"
                        position="absolute"
                        left={0}
                        bottom={0}
                        mr={-3}
                        mb={-3}
                      >
                        <Tooltip title="Edit" placement="top">
                          <MDButton
                            variant="gradient"
                            color="info"
                            size="small"
                            iconOnly
                            onClick={() => handleEdit(info)}
                            sx={bgColorChange}
                          >
                            <Icon>edit</Icon>
                          </MDButton>
                        </Tooltip>
                      </MDBox>
                    </MDBox>
                  </MDBox>
                  </Grid>
                )}
              </>))}
            </Grid>
          </MDBox>
        </Card>
      </MDBox>
      <SiteFooter />

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="modalPopupBox">
          <Grid container>
            <Grid item xs={12}>
              <Typography id="keep-mounted-modal-title" variant="h5" component="h2" sx={txtColorChange}>
                Edit Settings
              </Typography>
              <MDBox variant="outlined" className="close-button" onClick={handleClose}><CloseIcon /></MDBox>
            </Grid>
          </Grid>
          <MDBox component="form" role="form" onSubmit={handleSubmit}>
            <MDBox mt={2} mb={2}>
              {Name}
              {/* <MDInput
                type="text"
                fullWidth
                placeholder="Name"
                value={Name}
                onChange={(e) => {
                  setName(e.target.value);
                }}
              /> */}
            </MDBox>
            {(imageType === "image_link" || imageType === "file_link") && (
              <MDInput type="file" fullWidth onChange={handleFileSelect} />
            // <MDBox mt={2} mb={2}>
            //   <MDInput
            //     type="text"
            //     fullWidth
            //     placeholder="Value"
            //     value={sVal}
            //     onChange={(e) => {
            //       setSValue(e.target.value);
            //     }}
            //   />
            // </MDBox>
            )}
            {(imageType === "text") && (
            <MDBox mt={2} mb={2}>
              <MDInput
                type="text"
                fullWidth
                placeholder="Value"
                value={sVal}
                onChange={(e) => {
                  setSValue(e.target.value);
                }}
              />
            </MDBox>
            )}
            <MDBox mt={2} mb={2}>
              <MDTypography
                variant="button"
                fontWeight="regular"
                color="text"
                sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
              >
                &nbsp;&nbsp;Status :&nbsp;
              </MDTypography>
              <Switch checked={sStatus} onChange={handleStatus} />
            </MDBox>
            <MDBox mt={2} mb={1}>
            {/* onClick={handleUpdate} */}
              <MDButton type="submit" variant="gradient" color="info" sx={bgColorChange}>
                Submit
              </MDButton>
            </MDBox>
          </MDBox>
        </Box>
      </Modal>
    </DashboardLayout>
  );
}

export default Settings;
