/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
// import Icon from "@mui/material/Icon";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Timeline context
import { useTimeline } from "examples/Timeline/context";

// Custom styles for the TimelineItem
import timelineItem from "examples/Timeline/TimelineItem/styles";
import BasicModal from "layouts/Rewards/User/components/Common/ModalPopup";
import MDButton from "components/MDButton";
import { useState } from "react";
import Tooltip from "@mui/material/Tooltip";

function FeedTimelineItem({ color, icon, title, dateTime, description, lastItem, sender_details, sender_pb, sender_gb, atxt, reciever_details, reciever_pb, reciever_gb }) {
  const isDark = useTimeline();

  const [show, setShow] = useState(false);
  const handlePopup = () => {
    setShow(true);
  };
  const handleClose = () => setShow(false);

  return (
    <MDBox position="relative" mb={3} sx={(theme) => timelineItem(theme, { lastItem, isDark })}>
      <MDBox
        display="flex"
        justifyContent="center"
        alignItems="center"
        bgColor={color}
        border="1px solid #f28f34"
        color="white"
        width="2rem"
        height="2rem"
        borderRadius="50%"
        position="absolute"
        top="8%"
        left="2px"
        zIndex={2}
        sx={{ fontSize: ({ typography: { size } }) => size.sm }}
      >
        <MDBox component="img" src={icon} width="1.5rem" height="auto" />
        {/* <Icon fontSize="inherit">{icon}</Icon> */}
      </MDBox>
      <MDBox ml={5.75} pt={description ? 0.7 : 0.5} lineHeight={0} maxWidth="30rem">
      {/* color={isDark ? "white" : "dark"} */}
        <MDTypography variant="button" fontWeight="medium" sx={{ color:"#344767 !important" }}>
          {/* <Tooltip title={`Personal Balance: $${sender_pb} Giving Balance: $${sender_gb}`} placement="top">
            {`${sender_details}`}
          </Tooltip> 
          {` ${atxt} `}
          <Tooltip title={`Personal Balance: $${reciever_pb} Giving Balance: $${reciever_gb}`} placement="top">
            {reciever_details}
          </Tooltip>  */}
          {title}
        </MDTypography>
        <MDBox mt={0.5}>
          <MDTypography variant="caption" color={isDark ? "secondary" : "text"}>
            {dateTime}
          </MDTypography>
        </MDBox>
        <MDBox mb={1.5}>
          {description ? (
            <MDButton className="btnMessage btnBackground" onClick={handlePopup}>
              {description}
            </MDButton>
          ) : null}
          <BasicModal title={title} description={description} show={show} close={handleClose} />
        </MDBox>
      </MDBox>
    </MDBox>
  );
}

// Setting default values for the props of TimelineItem
FeedTimelineItem.defaultProps = {
  color: "info",
  lastItem: false,
  description: "",
};

// Typechecking props for the TimelineItem
FeedTimelineItem.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "dark",
    "light",
  ]),
  icon: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  // dateTime: PropTypes.string.isRequired,
  description: PropTypes.string,
  lastItem: PropTypes.bool,
  sender_details: PropTypes.string,
  atxt: PropTypes.string,
  reciever_details: PropTypes.string,
};

export default FeedTimelineItem;
