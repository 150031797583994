/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";
import { useMutation } from "react-query";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
// import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";

// Images
// import bgImage from "assets/images/bg-sign-in-basic.jpeg";
import MDSnackbar from "components/MDSnackbar";
// import APIURL from "../User/Constants";
import MDTypography from "components/MDTypography";
import Loader from "../User/components/Common/Loader";
import URLSettings from "../User/components/Common/URLSettings";
import NotFound from "../User/components/Common/NotFound";

function MLogin() {
  let APIURL = URLSettings();

  if (APIURL.url === null) {
    return <NotFound />;
  }

  const [eMsg, setEMsg] = useState("");
  const [employeeID, setEmployeeID] = useState(null);
  const [password, setPassword] = useState("");
  const [loginError, setLoginError] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    localStorage.clear();
  }, []);

  const login = useMutation(
    async () =>
      axios.post(`${APIURL.url}/login`, {
        employee_id: employeeID,
        password: password,
      }),
    {
      onError: (err) => {
        setLoginError(true);
        setEMsg(err.response.data);
      },
      onSuccess: (data) => {
        // save token
        localStorage.setItem("token", data.data.token);
        localStorage.setItem("admin", data.data.admin);
        localStorage.setItem("personal_balance", data.data.personal_balance);
        localStorage.setItem("profilepic", data.data.profile_pic);
        var token = data.data.token;
        if (token) {
          settings.mutate({ token });
        }
      },
    }
  );

  const settings = useMutation(
    async () =>
      axios.get(`${APIURL.url}/admin/settings`, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }),
    {
      onError: () => {
        // console.log(err);
      },
      onSuccess: (data) => {
        // save token
        localStorage.setItem("settings", JSON.stringify(data.data.rows));
        // redirect to dashboard
        navigate("/home");
        window.location.reload();
      },
    }
  );

  const [logo, setLogo] = useState(0);
  async function fetchLogoData() {
    try {
      const result = await axios.get(`${APIURL.url}/settings`, {
        params: { setting_name: "login_screen_logo" },
      });
      setLogo("");
      if (result.data.logo) {
        setLogo(result.data.logo);
      }
    } catch (error) {
      // console.error(error);
      setLogo("");
      <NotFound />;
    }
  }
  useEffect(() => {
    fetchLogoData();
  }, []);

  const toggleSnackbar = () => {
    if (loginError) {
      setLoginError(!loginError);
    }
  };
  useEffect(() => {
    setTimeout(() => {
      setLoginError(false);
    }, 2000);
  }, [loginError]);

  const handleLogin = (e) => {
    e.preventDefault();
    if (employeeID === "" && password === "") {
      e.preventDefault();
      setLoginError(true);
    }
    login.mutate();
  };

  if (login.isLoading === true || settings.isLoading === true) {
    return <Loader />;
  }

  return (
    <>
      {(logo === 0 && <Loader />) ||
        (logo === "" && <NotFound />) ||
        (logo && (
          <BasicLayout>
            <MDSnackbar
              color="error"
              icon="error"
              title="Error: Unable to Login"
              content="Employee ID or Password may be incorrect"
              open={loginError}
              close={toggleSnackbar}
            />
            <Card>
              <MDBox
                variant="gradient"
                className="loginLogo"
                borderRadius="lg"
                coloredShadow="info"
                mx={2}
                mt={-3}
                p={2}
                mb={1}
                textAlign="center"
              >
                <img src={logo} alt="Wireless Vision" width={100} />
              </MDBox>
              <MDBox pt={4} pb={3} px={3}>
                <MDBox component="form" role="form" onSubmit={handleLogin}>
                  {APIURL.code === "wirelessvision" ? (
                    <MDBox mb={2}>
                      <MDInput
                        type="input"
                        label="Phone Number"
                        value={employeeID}
                        onChange={(e) => setEmployeeID(e.target.value)}
                        fullWidth
                      />
                    </MDBox>
                  ) : (
                    // APIURL.code === "wildbills" && (
                    <MDBox mb={2}>
                      <MDInput
                        type="input"
                        label="Phone Number"
                        value={employeeID}
                        onChange={(e) => setEmployeeID(e.target.value)}
                        fullWidth
                      />
                    </MDBox>
                    // )
                  )}
                  <MDBox mb={2}>
                    <MDInput
                      type="password"
                      label="Password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      fullWidth
                    />
                  </MDBox>
                  <MDBox mt={4} mb={1}>
                    <MDButton type="submit" variant="gradient" color="info">
                      sign in
                    </MDButton>
                    {APIURL.code === "wirelessvision" ? (
                      <a href="https://mds.wirelessvision.com/reset" target="_blank">
                        <MDTypography
                          variant="button"
                          fontWeight="regular"
                          color="text"
                          sx={{ cursor: "pointer", userSelect: "none", mt: 1, float: "right" }}
                        >
                          Reset Password?
                        </MDTypography>
                      </a>
                    ) : (
                      // {APIURL.code === "wildbills" && (
                      <Link to="/new-resetpassword">
                        <MDTypography
                          variant="button"
                          fontWeight="regular"
                          color="text"
                          sx={{ cursor: "pointer", userSelect: "none", mt: 1, float: "right" }}
                        >
                          Reset Password?
                        </MDTypography>
                      </Link>
                      // )
                    )}
                  </MDBox>
                </MDBox>
              </MDBox>
            </Card>
          </BasicLayout>
        ))}
    </>
  );
}

export default MLogin;
