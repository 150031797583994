/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";

// react-router components
import { useLocation } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @material-ui core components
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";

// Material Dashboard 2 PRO React examples
import Breadcrumbs from "examples/Breadcrumbs";

// Custom styles for DashboardNavbar
import {
  navbar,
  navbarContainer,
  navbarRow,
  navbarIconButton,
  navbarDesktopMenu,
  navbarMobileMenu,
} from "examples/Navbars/DashboardNavbar/styles";

// Material Dashboard 2 PRO React context
import { useMaterialUIController, setTransparentNavbar, setMiniSidenav } from "context";
import { Grid, Menu } from "@mui/material";
import { useQuery } from "react-query";
import MDBadge from "components/MDBadge";
import NotificationItem from "examples/Items/NotificationItem";
import MDButton from "components/MDButton";
import MDAvatar from "components/MDAvatar";
import Cart from "layouts/Rewards/User/components/Common/Cart";
// import bImage from "../../../assets/b752192-200w-326ppi.png";
import { isMobile } from "react-device-detect";

const jose = require("jose");
function DashboardMenu({ absolute, light, isMini, cartCount, pageStatus }) {
  const [navbarType, setNavbarType] = useState();
  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav, transparentNavbar, fixedNavbar, darkMode } = controller;
  const route = useLocation().pathname.split("/").slice(1);

  const [blockStatus, setBlockStatus] = useState(pageStatus);
  useEffect(() => {
    setBlockStatus(pageStatus);
  },[pageStatus])


  

  useEffect(() => {
    // Setting the navbar type
    if (fixedNavbar) {
      setNavbarType("sticky");
    } else {
      setNavbarType("static");
    }

    // A function that sets the transparent state of the navbar.
    function handleTransparentNavbar() {
      setTransparentNavbar(dispatch, (fixedNavbar && window.scrollY === 0) || !fixedNavbar);
    }

    /** 
     The event listener that's calling the handleTransparentNavbar function when 
     scrolling the window.
    */
    window.addEventListener("scroll", handleTransparentNavbar);

    // Call the handleTransparentNavbar function to set the state with the initial value.
    handleTransparentNavbar();

    // Remove event listener on cleanup
    return () => window.removeEventListener("scroll", handleTransparentNavbar);
  }, [dispatch, fixedNavbar]);

  // var finalCart = JSON.parse(localStorage.getItem("cartItems"));
  // console.log("cart length", finalCart.length);

  // console.log("cart length", cartCount);

  const [cCount, setCartCount] = useState(0);

  const updateCountCart = (ccCount) => {
    setCartCount(ccCount);
  };

  useEffect(() => {
    if (cartCount < 0) {
      setCartCount(cartCount);
    } else {
      var finalCart = JSON.parse(localStorage.getItem("cartItems"));
      if (finalCart !== null) {
        setCartCount(finalCart.length);
      } else {
        setCartCount(0);
      }
    }
  }, [localStorage.getItem("cartItems"), cCount]);

  const [openMenu, setOpenMenu] = useState(false);
  const handleMiniSidenav = () => setMiniSidenav(dispatch, !miniSidenav);
  const handleOpenMenu = (event) => setOpenMenu(event.currentTarget);
  const handleCloseMenu = () => setOpenMenu(false);
  // Render the notifications menu
  const renderMenu = () => (
    <Menu
      anchorEl={openMenu}
      anchorReference={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      open={Boolean(openMenu)}
      onClose={handleCloseMenu}
      sx={{ mt: 2 }}
    >
      <Cart updateCountFromCart={updateCountCart} />
    </Menu>
  );

  // Styles for the navbar icons
  const iconsStyle = ({ palette: { dark, white, text }, functions: { rgba } }) => ({
    color: () => {
      let colorValue = light || darkMode ? white.main : dark.main;

      if (transparentNavbar && !light) {
        colorValue = darkMode ? rgba(text.main, 0.6) : text.main;
      }

      return colorValue;
    },
  });

  const token = useQuery("token", async () => {
    // should be validating with public key
    const text = localStorage.getItem("token");
    if (text === null || text === "") {
      throw new Error("No token found");
    }
    const claims = jose.decodeJwt(text);
    claims.token = text;
    return claims;
  });

  let pBal;
  if (token.isSuccess) {
    if (token !== "") {
      pBal = localStorage.getItem("personal_balance");
    }
  }

  if (isMobile === false) {
    return "";
  }else {
    return (
      <AppBar
        position={absolute ? "absolute" : navbarType}
        color="inherit"
        sx={(theme) => navbar(theme, { transparentNavbar, absolute, light, darkMode })}
      >
        <Toolbar sx={(theme) => navbarContainer(theme)}>
            {isMini ? null : (
              <MDBox sx={(theme) => navbarRow(theme, { isMini })}>
                <MDBox  color={darkMode ? "secondary" : "text"}>
                  <Grid container>
                    <Grid item xs={3}>
                      <IconButton
                        size="small"
                        disableRipple
                        color="inherit"
                        sx={navbarMobileMenu}
                        onClick={handleMiniSidenav}
                      >
                        <Icon fontSize="medium" color={darkMode ? "secondary" : "text"}>
                          {miniSidenav ? "menu" : "menu"}
                        </Icon>
                      </IconButton>
                    </Grid>
                  </Grid>
                </MDBox>
              </MDBox>
            )}
          {/* <MDBox color="inherit" mb={{ xs: 1, md: 0 }} sx={(theme) => navbarRow(theme, { isMini })}>
          
            
          </MDBox> */}
          {/* <MDBox sx={{ float: "right", fontSize: "18px" }}>
          <Icon mr={2} sx={{ float: "left", marginTop: "5px", fontSize: "22px" }}>
                  diamond
                </Icon>
                {pBal}pts
          </MDBox> */}
          {/* {isMini ? null : (
            <MDBox sx={(theme) => navbarRow(theme, { isMini })}>
              <MDBox color={light ? "inherit" : "inherit"}>
                <Icon mr={2} sx={{ float: "left", marginTop: "5px", fontSize: "22px" }}>
                  diamond
                </Icon>
                {pBal}pts
                <IconButton
                  size="small"
                  disableRipple
                  color={darkMode ? "secondary" : "text"}
                  sx={navbarIconButton}
                  aria-controls="notification-menu"
                  aria-haspopup="true"
                  variant="contained"
                  onClick={handleOpenMenu}
                >
                  <MDBadge badgeContent={cCount ? cCount : "0"} color="error" size="xs" circular>
                    <Icon color={darkMode ? "secondary" : "text"}>shopping_cart</Icon>
                  </MDBadge>
                </IconButton>
                {renderMenu()}
              </MDBox>
            </MDBox>
          )} */}
        </Toolbar>
      </AppBar>
    );
  }
}

// Setting default values for the props of DashboardMenu
DashboardMenu.defaultProps = {
  absolute: false,
  light: false,
  isMini: false,
};

// Typechecking props for the DashboardMenu
DashboardMenu.propTypes = {
  absolute: PropTypes.bool,
  light: PropTypes.bool,
  isMini: PropTypes.bool,
};

export default DashboardMenu;
