/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { forwardRef } from "react";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import MenuItem from "@mui/material/MenuItem";
import Link from "@mui/material/Link";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// custom styles for the NotificationItem
import menuItem from "examples/Items/NotificationItem/styles";
import { Icon } from "@mui/material";

function NotificationItem({ icon, title, quantity, keyNum, handleCartDelete, deleteEntry }) {
  const handleDelete = (keyNum) => {
    handleCartDelete(keyNum);
  };

  return (
    <MDBox py={0.5} display="flex" alignItems="center" lineHeight={1}>
      <MDTypography variant="body1" color="secondary" lineHeight={0.75}>
        {icon}
      </MDTypography>
      <MDTypography variant="button" fontWeight="regular" sx={{ ml: 2, width: "100px" }}>
        {title}
      </MDTypography>
      <MDTypography variant="button" fontWeight="regular" sx={{ mr: 2 }}>
        {quantity}
      </MDTypography>
      {deleteEntry && (
        <Icon
          color="error"
          // handleCartDelete
          onClick={() => {
            handleDelete(keyNum);
          }}
          sx={{ cursor: "pointer" }}
        >
          delete
        </Icon>
      )}
    </MDBox>
  );
}

// const NotificationItem = forwardRef(({ icon, title, quantity, keyNum, ...rest }, ref) => (
//   <MenuItem {...rest} ref={ref} sx={(theme) => menuItem(theme)}>
//     <MDBox component={Link} py={0.5} display="flex" alignItems="center" lineHeight={1}>
//       <MDTypography variant="body1" color="secondary" lineHeight={0.75}>
//         {icon}
//       </MDTypography>
//       <MDTypography variant="button" fontWeight="regular" sx={{ ml: 2, width: "100px" }}>
//         {title}
//       </MDTypography>
//       <MDTypography variant="button" fontWeight="regular" sx={{ mr: 2 }}>
//         {quantity}
//       </MDTypography>
//       <Icon
//         color="error"
//         // handleCartDelete
//         // onClick={() => {
//         //   handleCartDelete(keyNum);
//         // }}
//       >
//         delete
//       </Icon>
//     </MDBox>
//   </MenuItem>
// ));

// Typechecking props for the NotificationItem
NotificationItem.propTypes = {
  icon: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  quantity: PropTypes.string.isRequired,
  keyNum: PropTypes.string.isRequired,
};

export default NotificationItem;
