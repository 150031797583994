import React, { useState, useEffect } from "react";
import URLSettings from "../URLSettings";
import { Autocomplete, Avatar } from "@mui/material";
import MDAvatar from "components/MDAvatar";
import MDInput from "components/MDInput";
import { useMutation } from "react-query";
import axios from "axios";

const CustomMentionsTextarea = () => {
  let APIURL = URLSettings();
  const [text, setText] = useState("");
  const [userSuggestions, setUserSuggestions] = useState([]);
  const [mentions, setMentions] = useState([]);
  const [activeMentionIndex, setActiveMentionIndex] = useState(-1);

  const [msgRecipient, setMsgRecipient] = useState([]);
  const [userMsgSuggestions, setUserMsgSuggestions] = useState([]);
  const updateMsgRecipient = (e, option) => {
    if (option) {
      setMsgRecipient(option?.map(function(a){return a.id;}));
    } 
  }; 

  const getMsgSuggestions = useMutation(
    async (search) =>
      axios.get(`${APIURL.url}/getUserSuggestions`, {
        params: {
          search,
        },
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }),
    {
      onError: (err) => {
        console.log("errR::", err);
        if (err.response.status === 401) {
          logout();
        }
      },
      onSuccess: (data) => {
        console.log("dat:", data);
        setUserMsgSuggestions(data.data);
      },
    }
  );

  const updateMsgSuggestions = (e) => {
    getMsgSuggestions.mutate(e.target.value);
  };


  //   const getUsersList = useQuery(["getUsersList"], async () =>
  //   axios.get(`${APIURL.url}/getUsers`, {
  //     params: {
  //       employee_id: '',
  //       email: '',
  //       u_id: ''
  //     },
  //     headers: {
  //       Authorization: localStorage.getItem("token"),
  //     },
  //   })
  // );

  useEffect(() => {
    // Simulated asynchronous fetch user suggestions API call
    const fetchUserSuggestions = async () => {
      try {
        // Replace the following line with your actual API endpoint
        const response = await fetch(`${APIURL.url}/getUsers`, {
          headers: { Authorization: localStorage.getItem("token") },
        });
        const data = await response.json();
        console.log("data::", data);
        setUserSuggestions(data); // Assuming the response has a 'users' property
      } catch (error) {
        console.error("Error fetching user suggestions:", error);
      }
    };

    // Simulate API call delay
    const delay = setTimeout(() => {
      fetchUserSuggestions();
    }, 500); // Adjust the delay as needed

    // Cleanup on component unmount or when the text changes
    return () => clearTimeout(delay);
  }, [text]);

  const handleOnChange = async (e) => {
    const inputValue = e.target.value;
    setText(inputValue);

    // Check for mentions starting with "@" for user suggestions
    const mentionMatches = inputValue.match(/@(\w+)/g);

    if (mentionMatches) {
      const matchedUsers = mentionMatches.map((match) => match.substring(1));
      // const filteredSuggestions = getUserSuggestions(matchedUsers, inputValue);
      // setUserSuggestions(filteredSuggestions);
      const filteredSuggestions = await getUserSuggestions(matchedUsers, inputValue);
      setUserSuggestions(filteredSuggestions);
      // No need to simulate fetching user suggestions here since it's handled in useEffect
    } else {
      setUserSuggestions([]);
    }
  };

  const getUserSuggestions = async (matchedUsers, inputValue) => {
    try {
      // Replace the following line with your actual API endpoint
      const response = await fetch(`${APIURL.url}/getUsers`, {
        headers: { Authorization: localStorage.getItem("token") },
      });
      const data = await response.json();

      console.log("matched Users::", matchedUsers);
      // Simulate filtering based on matchedUsers and inputValue
      // const filteredUsers = data.filter(
      //   (user) =>
      //     matchedUsers.every((match) => user.first_name.includes(match)) &&
      //     user.first_name.toLowerCase().includes(inputValue.toLowerCase())
      // );

      // let finalUsers;
      // if (filteredUsers.length > 0) {
      //   finalUsers = filteredUsers
      // } else {
      //   finalUsers = data;
      // }

      // return finalUsers;
      const filteredUsers = data.filter(
        (user) =>
          matchedUsers.every((match) =>
            user.first_name.toLowerCase().includes(match.toLowerCase())
          ) && user.first_name.toLowerCase().includes(inputValue.toLowerCase())
      );

      return filteredUsers.length > 0 ? filteredUsers : [];
    } catch (error) {
      console.error("Error fetching user suggestions:", error);
      return [];
    }
  };

  // const getUserSuggestions = (matchedUsers) => {
  //   // Simulate fetching user suggestions based on matchedUsers
  //   const allUsers = ['john.doe', 'jane.smith', 'alice', 'bob']; // Replace with your user data
  //   return userSuggestions.filter((user) => matchedUsers.every((match) => user.first_name.includes(match)));
  // };

  const handleUserSelect = (username) => {
    console.log("Selected user::", username);
    console.log("index::", activeMentionIndex);
    // Handle the selected user mention
    const mentionToReplace = text.match(/@\w+/g)?.[activeMentionIndex];
    console.log("mentionto Replace::", mentionToReplace);
    // const updatedText = text.replace(mentionToReplace, `@${username.first_name} `);
    const updatedText = mentionToReplace
      ? text.replace(mentionToReplace, `${username.first_name}`)
      : `${text}${username.first_name} `;

    setMentions([...mentions, `@${username.first_name}`]);
    setText(updatedText);
    setUserSuggestions([]);
    setActiveMentionIndex(-1); // Close the dropdown
  };

  const handleKeyDown = (e) => {
    if (e.key === "ArrowUp" || e.key === "ArrowDown") {
      // Handle arrow key navigation in suggestions
      e.preventDefault();

      const delta = e.key === "ArrowUp" ? -1 : 1;
      const newIndex = Math.max(
        -1,
        Math.min(activeMentionIndex + delta, userSuggestions.length - 1)
      );

      setActiveMentionIndex(newIndex);
    } else if (e.key === "Enter" && activeMentionIndex !== -1) {
      // Handle Enter key to select a suggestion
      handleUserSelect(userSuggestions[activeMentionIndex]);
    }
  };

  const renderHighlightedText = () => {
    return text.split(" ").map((word, index) => {
      if (word.startsWith("#")) {
        return (
          <span key={index} style={{ color: "green", fontWeight: "bold" }}>
            {word}{" "}
          </span>
        );
      } else if (word.startsWith("@")) {
        return (
          <span key={index} style={{ color: "blue", fontWeight: "bold" }}>
            {word}{" "}
          </span>
        );
      } else {
        return <span key={index}>{word} </span>;
      }
    });
  };

  return (
    <div style={{ position: "relative" }}>
      <textarea
        value={text}
        onChange={handleOnChange}
        onKeyDown={handleKeyDown}
        style={{ width: "100%", minHeight: 100, padding: 10 }}
      />
      {userSuggestions.length > 0 && (
        <div
          style={{
            position: "absolute",
            top: "100%",
            left: 0,
            width: "100%",
            height: "200px",
            overflow: "auto",
            border: "1px solid #ccc",
            borderRadius: "5px",
            background: "white",
            zIndex: 1,
          }}
        >
          <Autocomplete
            multiple
            options={userMsgSuggestions}
            renderOption={(props, option) => (
              <li {...props} key={option.userid}>
                {option.profile_pic ? (
                  <MDAvatar
                    src={option.profile_pic}
                    alt={`${option.first_name.slice(0, 1)}${option.last_name.slice(0, 1)}`}
                    size="sm"
                    bgColor="light"
                  />
                ) : (
                  <Avatar
                    size="sm"
                    sx={{
                      bgcolor: "#ccc",
                      marginRight: "5px",
                      textTransform: "uppercase",
                    }}
                  >{`${option.first_name.slice(0, 1)}${option.last_name.slice(0, 1)}`}</Avatar>
                )}
                {`${option.first_name} ${option.last_name}`} <br />
                {`${option.employee_id}`}
              </li>
            )}
            getOptionLabel={(option) => `${option.first_name} ${option.last_name}`}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            // defaultValue={defaultRec}
            onChange={updateMsgRecipient}
            renderInput={(params) => (
              <MDInput
                label="Select Recipient"
                {...params}
                variant="standard"
                onChange={updateMsgSuggestions}
                sx={{ mb: 3 }}
              />
            )}
          />

          {/* {userSuggestions.map((user, index) => (
            <div
              key={index}
              style={{
                padding: "5px",
                cursor: "pointer",
                backgroundColor: index === activeMentionIndex ? "#f0f0f0" : "white",
              }}
              onClick={() => handleUserSelect(user)}
            >
              {user.first_name}
            </div>
          ))} */}
        </div>
      )}
      <div>
        <p>Highlighted Text: {renderHighlightedText()}</p>
        <p>
          Mentions:{" "}
          {mentions.map((mention, index) => (
            <span key={index} style={{ color: "blue" }}>
              {mention}{" "}
            </span>
          ))}
        </p>
      </div>
    </div>
  );
};

export default CustomMentionsTextarea;
