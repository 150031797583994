import { Autocomplete, Box, Card, Grid, Icon, Modal, Typography } from "@mui/material";
import axios from "axios";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { bgColorChange } from "layouts/Rewards/User/components/Common/ColorChange";
import { txtColorChange } from "layouts/Rewards/User/components/Common/ColorChange";
import DataGridTables from "layouts/Rewards/User/components/Common/DataGridTables";
import Loader from "layouts/Rewards/User/components/Common/Loader";
import URLSettings from "layouts/Rewards/User/components/Common/URLSettings";
import React, { useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import addGiftCard from "../../../../../assets/images/add-giftcard.png";
import MDInput from "components/MDInput";
const jose = require("jose");
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import MDSnackbar from "components/MDSnackbar";
import DashboardMenu from "examples/Navbars/DashboardMenu";

const GiftCards = () => {
  let APIURL = URLSettings();
  const navigate = useNavigate();

  const [id, setId] = useState();
  const [editVal, setEditVal] = useState([]);
  const [gCardVal, setGCardVal] = useState([]);
  const [commVal, setCommVal] = useState();
  const [finalVal, setFinalVal] = useState();

  const [eMsg, setEMsg] = useState("");
  const [createError, setCreateError] = useState(false);
  const [createSuccess, setCreateSuccess] = useState(false);
  const [updateError, setUpdateError] = useState(false);
  const [updateSuccess, setUpdateSuccess] = useState(false);

  const toggleSnackbar = () => {
    if (createError) {
      setCreateError(!createError);
    }
    if (createSuccess) {
      setCreateSuccess(!createSuccess);
    }
    if (updateError) {
      setUpdateError(!updateError);
    }
    if (updateSuccess) {
      setUpdateSuccess(!updateSuccess);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      setCreateError(false);
      setCreateSuccess(false);
      setUpdateError(false);
      setUpdateSuccess(false);
    }, 2000);
  }, [createError, createSuccess, updateError, updateSuccess]);

  const claims = jose.decodeJwt(localStorage.getItem("token"));


  const [show, setShow] = useState(false);
  const handleShow = () => {
    setShow(true);
  }
  const handleClose = () => {
    setShow(false);
  }

  const [eShow, setEShow] = useState(false);
  const handleEShow = () => {
    setEShow(true);
  }
  const handleEClose = () => {
    setEShow(false);
  }

  useEffect(() => {
    if (!localStorage.getItem("token")) {
      window.location.reload();
      return navigate("/login");
    }
  }, []);

  const gCards = useQuery(["gCards"], async () =>
    axios.get(`${APIURL.url}/admin/gift_cards`, {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    })
  );


  //Create
  const [addData, setAddData] = useState([{gift_card_id: "", comm_value: "", company_id: claims.company_id }]);
  const handleAddCard = () => {
    setAddData([...addData, {gift_card_id:"", comm_value: "", company_id: claims.company_id}])
  }

  const handleCardChange = (e, i) => {
    const {name, value} = e.target
    const onchangeVal = [...addData]
    if (name === "comm_value") {
      onchangeVal[i][name] = value
    } else {
      onchangeVal[i]['gift_card_id'] = value
    }
    setAddData(onchangeVal);
  }

  const handleDeleteCard = (i) => {
    const deleteVal = [...addData];
    if (addData.length > 1) {
      deleteVal.splice(i,1);
      setAddData(deleteVal);
    }
  }
  
  const createCard = useMutation(
    async () =>
      axios.post(
        `${APIURL.url}/admin/gift_cards`,
        {
          gift_conf_val: addData,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.getItem("token"),
          },
        }
      ),
    {
      onError: (err) => {
        setEMsg(err.response.data);
        setCreateError(true);
        if (err.response.status === 401) {
          window.location.reload();
          return navigate("/login");
        }
      },
      onSuccess: () => {
        setEMsg('Created successfully');
        setCreateSuccess(true);
        handleClose();
        gCards.refetch();
      },
    }
  );

  const handleCreateCard = () => {
    createCard.mutate();
  };

  //Update
  const [cardSuggestions, setCardSuggestions] = useState([]);
  const gCardSuggestion = useMutation(
    async (search) =>
      axios.get(`${APIURL.url}/admin/giftCardSuggestion`, {
        params: {
          search,
        },
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }),
    {
      onError: (err) => {
        setEMsg(err.response.data);
        setSearchError(true);
        if (err.response.status === 401) {
          logout();
        }
      },
      onSuccess: (data) => {
        setCardSuggestions(data.data);
      },
    }
  );

  const updateSuggestions = (e) => {
    gCardSuggestion.mutate(e.target.value);
  };

  const updateCards = (e, option) => {
    if (option) {
      setGCardVal(option);   

    }
  };

  const handleUpdate = async (row) => {
    setId(row.id);
    setEditVal({id: row.gift_card_id, name: row.gift_card_name, company_id: claims.company_id});
    setCommVal(row.comm_value);
    handleEShow();
  };

  const cUpdate = useMutation(
    async () =>
      axios.put(
        `${APIURL.url}/admin/gift_cards?gc_id=`+id,
        {
          company_id: claims.company_id,
          gift_card_id: editVal.id,
          comm_value: commVal,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.getItem("token"),
          },
        }
      ),
    {
      onError: (err) => {
        setEMsg(err.response.data);
        setUpdateError(true);
        if (err.response.status === 401) {
          window.location.reload();
          return navigate("/login");
        }
      },
      onSuccess: () => {
        setUpdateSuccess(true);
        handleEClose();
        gCards.refetch();
      },
    }
  );


  const handleUpdateCard = () => {
    cUpdate.mutate();
  }

  // const finalVal = {gift_conf_val: [{gift_card_id: editVal.id, comm_value: commVal, company_id: claims.company_id}]}
  //   console.log("fin::", finalVal);

  if (gCards.isError) {
    if (gCards.error.response.status === 401) {``
      window.location.reload();
      return navigate("/login");
    }
  }

  if (!gCards.isSuccess) {
    return <Loader />;
  }

  const columns = [
    {
      field: "id",
      headerName: "ID",
      width: 90,
    },
    {
      field: "gift_card_name",
      headerName: "Gift Card Name",
      minWidth: 300,
    },
    {
      field: "comm_value",
      headerName: "Commission Value",
      minWidth: 200,
    },
    {
      field: "actions",
      headerName: "Actions",
      sortable: false,
      renderCell: (params) => {
        return (
          <MDButton
            variant="gradient"
            color="error"
            size="small"
            iconOnly
            onClick={() => handleUpdate(params.row)}
            sx={bgColorChange}
          >
            <Icon>edit</Icon>
          </MDButton>
        );
      },
    },
  ];

  return (
    <DashboardLayout>
      {/* <DashboardNavbar /> */}
      <DashboardMenu />
      <MDBox mt={1} my={3}>
        <Card> 
          <MDSnackbar
            color="error"
            icon="error"
            title="Error: Something went wrong"
            content={eMsg}
            open={createError}
            close={toggleSnackbar}
          />
          <MDSnackbar
            color="success"
            icon="check_circle"
            title="Success"
            content="Card Created"
            open={createSuccess}
            close={toggleSnackbar}
          />
          <MDSnackbar
            color="error"
            icon="error"
            title="Error: Something went wrong"
            content={eMsg}
            open={updateError}
            close={toggleSnackbar}
          />
          <MDSnackbar
            color="success"
            icon="check_circle"
            title="Success"
            content="Card Updated"
            open={updateSuccess}
            close={toggleSnackbar}
          />
          <MDBox p={3} lineHeight={1}>
            <Grid container>
              <Grid item xs={6}>
                <MDTypography variant="h5" fontWeight="medium" sx={txtColorChange}>
                  Gift Cards
                </MDTypography>
              </Grid>
              <Grid item xs={6}>
                <MDButton
                  variant="gradient"
                  color="info"
                  sx={bgColorChange}
                  className="adminBtn iconBtns"
                  onClick={handleShow}
                >
                  <img src={addGiftCard} width="25" height="25" />
                </MDButton>
              </Grid>
            </Grid>
          </MDBox>
          <DataGridTables tableRow={gCards.data.data} columns={columns} />
        </Card>
      </MDBox>

      {/* Add Gift Cards */}
      <Modal
        open={show}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="modalPopupBox">
          <Typography id="keep-mounted-modal-title" variant="h5" component="h2" sx={txtColorChange}>
            Add Gift Cards
          </Typography>
          <MDBox sx={{ minHeight: "150px" }}>
            {
              addData.map((val, i) => 
              <Grid container>
                <Grid item xs={5}>
                  <MDBox mt={2} mb={2} mr={1}>
                    <Autocomplete
                        name="gift_card_id" 
                        options={cardSuggestions}
                        renderOption={(props, option) => (
                            <li {...props} key={option.userid} name="gift_card_id" value={option.id}>
                            {`${option.name}`} 
                            </li>
                        )}
                        getOptionLabel={(option) =>
                            `${option.name}`
                        }
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                        // onChange={updateCards}
                        onChange={(e) => {
                          handleCardChange(e, i);
                        }}
                        renderInput={(params) => (
                            <MDInput
                            label="Select Gift Card"
                            {...params}
                            variant="standard"
                            onChange={updateSuggestions}
                            sx={{ mb: 3 }}
                            />
                        )}
                    />
                  </MDBox> 
                </Grid>
                <Grid item xs={5}>
                  <MDBox mt={2} mb={2}>
                    <MDInput
                      type="text"
                      name="comm_value"
                      label="Commission Value"
                      fullWidth
                      placeholder="Commission Value"
                      value={val.comm_value}
                      onChange={(e) => {
                        handleCardChange(e, i);
                      }}
                    />
                  </MDBox>
                </Grid>
                <Grid item xs={2}>
                  <MDBox mt={2} mb={2}>
                    <AddIcon className="giftCardAdd" onClick={handleAddCard} />
                    <RemoveIcon className="giftCardAdd" onClick={() => handleDeleteCard(i)} />
                  </MDBox>
                </Grid>
              </Grid>
              )
            }
            <MDBox mt={2} mb={1}>
              <MDButton variant="gradient" color="info" sx={bgColorChange} onClick={handleCreateCard}>
                Submit
              </MDButton>
            </MDBox>
          </MDBox>
        </Box>  
      </Modal>
                      
      {/* Edit Gift Cards */}
      <Modal
        open={eShow}
        onClose={handleEClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="modalPopupBox">
          <Typography id="keep-mounted-modal-title" variant="h5" component="h2" sx={txtColorChange}>
            Edit Gift Cards
          </Typography>
          <MDBox sx={{ minHeight: "150px" }}>
            <Grid container>
              <Grid item xs={6}>
                <MDBox mt={2} mb={2} mr={1}>
                    <Autocomplete
                        options={cardSuggestions}
                        renderOption={(props, option) => (
                            <li {...props} key={option.userid}>
                            {`${option.name}`} 
                            </li>
                        )}
                        getOptionLabel={(option) =>
                            `${option.name}`
                        }
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                        defaultValue={editVal}
                        onChange={updateCards}
                        renderInput={(params) => (
                            <MDInput
                            label="Select Gift Card"
                            {...params}
                            variant="standard"
                            onChange={updateSuggestions}
                            sx={{ mb: 3 }}
                            />
                        )}
                    />
                </MDBox> 
              </Grid>
              <Grid item xs={6}>
                <MDBox mt={2} mb={2}>
                  <MDInput
                    type="text"
                    label="Commission Value"
                    fullWidth
                    placeholder="Commission Value"
                    value={commVal}
                    onChange={(e) => {
                      setCommVal(e.target.value);
                    }}
                  />
                </MDBox>
              </Grid>
            </Grid>
            <MDBox mt={2} mb={1}>
              <MDButton variant="gradient" color="info" sx={bgColorChange} onClick={handleUpdateCard}>
                Submit
              </MDButton>
            </MDBox>
          </MDBox>
        </Box>  
      </Modal>
      
    </DashboardLayout>
  );
};

export default GiftCards;
