// import Card from "@mui/material/Card";
// import Divider from "@mui/material/Divider";

// Material Dashboard 2 PRO React components
import {
  Autocomplete,
  Avatar,
  Box,
  Card,
  FormControl,
  Grid,
  Icon,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Typography,
} from "@mui/material";
import axios from "axios";
import MDAvatar from "components/MDAvatar";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDSnackbar from "components/MDSnackbar";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";
import { txtColorChange, bgColorChange } from "layouts/Rewards/User/components/Common/ColorChange";
import Loader from "layouts/Rewards/User/components/Common/Loader";
import Logout from "layouts/Rewards/User/components/Common/Logout";
import SiteFooter from "layouts/Rewards/User/components/Common/SiteFooter";
import URLSettings from "layouts/Rewards/User/components/Common/URLSettings";
import { useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import CloseIcon from '@mui/icons-material/Close';
import DataGridTables from "layouts/Rewards/User/components/Common/DataGridTables";
import DashboardMenu from "examples/Navbars/DashboardMenu";
// OrderDetails page components

const jose = require("jose");

function UserRoles() {
  let APIURL = URLSettings();
  const navigate = useNavigate();
  const logout = () => {
    localStorage.removeItem("token");
    navigate("/login");
  };

  useEffect(() => {
    if (!localStorage.getItem("token")) {
      // logout();
      // <Logout />
      window.location.reload();
      return navigate("/login");
    }
  }, []);

  const [searchError, setSearchError] = useState(false);
  const [sendSuccess, setSendSuccess] = useState(false);
  const [sendError, setSendError] = useState(false);
  const [eMsg, setEMsg] = useState("");
  const [deleteError, setDeleteError] = useState("");
  const [userId, setUserId] = useState();
  const [usRoleId, setUsRoleId] = useState();
  const toggleSnackbar = () => {
    if (deleteError) {
      setDeleteError(!deleteError);
    }
    if (searchError) {
      setSearchError(!searchError);
    }
    if (sendSuccess) {
      setSendSuccess(!sendSuccess);
    }
    if (sendError) {
      setSendError(!sendError);
    }
  };
  useEffect(() => {
    setTimeout(() => {
      setDeleteError(false);
      setSearchError(false);
      setSendSuccess(false);
      setSendError(false);
    }, 2000);
  }, [searchError, sendSuccess, sendError, deleteError]);

  const userRoles = useQuery(["userRole"], async () =>
    axios.get(`${APIURL.url}/admin/userSecurityRoles`, {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    })
  );

  const [Recipient, setRecipient] = useState("");
  const [uRole, setURole] = useState();
  const getSuggestions = useMutation(
    async (search) =>
      axios.get(`${APIURL.url}/getUserSuggestions`, {
        params: {
          search,
        },
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }),
    {
      onError: (err) => {
        setEMsg(err.response.data);
        setSearchError(true);
        if (err.response.status === 401) {
          // logout();
          // <Logout />
          window.location.reload();
          return navigate("/login");
        }
      },
      onSuccess: (data) => {
        setUserSuggestions(data.data);
      },
    }
  );
  const updateSuggestions = (e) => {
    getSuggestions.mutate(e.target.value);
  };
  const updateRecipient = (e, option) => {
    if (option) {
      setRecipient(option.id);
    }
  };

  if (userRoles.isError) {
    if (userRoles.error.response.status === 401) {
      // logout();
      // <Logout />
      window.location.reload();
      return navigate("/login");
    }
  }

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [openCreate, setOpenCreate] = useState(false);
  const handleCreateOpen = () => setOpenCreate(true);
  const handleCreateClose = () => setOpenCreate(false);

  const handleUserRoleDelete = async (cell) => {
    let obj = cell;
    Object.keys(obj).forEach((key) => {
      var replacedKey = key.trim().toLowerCase().replace(" ", "_");
      if (key !== replacedKey) {
        obj[replacedKey] = obj[key];
        delete obj[key];
      }
    });
    setUserId(cell.u_id);
    setUsRoleId(obj.security_name);
    handleOpen();
  };

  const handleUserSecurityRole = useMutation(
    async () =>
      axios.post(
        `${APIURL.url}/admin/userSecurityRoleUpdate`,
        {
          user_id: userId,
          role_name: usRoleId,
          process_type: "delete",
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.getItem("token"),
          },
        }
      ),
    {
      onError: (err) => {
        setEMsg(err.response.data);
        setDeleteError(true);
        if (err.response.status === 401) {
          window.location.reload();
          return navigate("/login");
        }
      },
      onSuccess: () => {
        handleClose();        
        userRoles.refetch();
      },
    }
  );

  const handleURoleDelete = (e) => {
    e.preventDefault();
    handleUserSecurityRole.mutate();
  };

  const handleChange = (event) => {
    // console.log("val::", event.target.value);
    setURole(event.target.value);
  };

  const [userSuggestions, setUserSuggestions] = useState([]);

  const handUserRoleCreate = useMutation(
    async () =>
      axios.post(
        `${APIURL.url}/admin/userSecurityRoleUpdate`,
        {
          user_id: Recipient,
          role_name: uRole,
          process_type: "insert",
        },
        {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        }
      ),
    {
      onError: (err) => {
        setEMsg(err.response.data);
        setSendError(true);
        if (err.response.status === 401) {
          // logout();
          // <Logout />
          window.location.reload();
          return navigate("/login");
        }
      },
      onSuccess: () => {
        setSendSuccess(true);
        setRecipient("");
        setURole("");
        handleCreateClose();
        userRoles.refetch();
      },
    }
  );
  const handleUserCreate = () => {
    handUserRoleCreate.mutate();
  };

  if (!userRoles.isSuccess) {
    return <Loader />;
  }

  const transColumns = [
    { 
      field: "id", 
      headerName: "ID", 
      width: 90 
    },
    {
      field: "First Name",
      headerName: "First Name",
      minWidth: 300
    },
    {
      field: "Last Name",
      headerName: "Last Name",
      minWidth: 200
    },
    {
      field: "Email",
      headerName: "Email",
      minWidth: 300
    },
    {
      field: "Security Name",
      headerName: "Security Name",
      minWidth: 150
    },
    {
      field: 'action',
      headerName: 'Action',
      sortable: false,
      renderCell: (params) => {
        return <MDButton
                variant="gradient"
                color="error"
                size="small"
                iconOnly
                onClick={() => handleUserRoleDelete(params.row)}
                sx={bgColorChange}
              >
                <Icon>delete</Icon>
              </MDButton>;
      },
    },
  ];

  return (
    <DashboardLayout>
      {/* <DashboardNavbar /> */}
      <DashboardMenu />
      <MDSnackbar
        color="error"
        icon="error"
        title="Error: Something went wrong"
        content={eMsg}
        open={deleteError}
        close={toggleSnackbar}
      />
      <MDSnackbar
        color="error"
        icon="error"
        title="Error: Unable to Search Users"
        content={eMsg}
        open={searchError}
        close={toggleSnackbar}
      />
      <MDSnackbar
        color="error"
        icon="error"
        title="Error: Unable to Assigned role"
        content={eMsg}
        open={sendError}
        close={toggleSnackbar}
      />
      <MDSnackbar
        color="success"
        icon="check_circle"
        title="Success"
        content="Role assigned to User"
        open={sendSuccess}
        close={toggleSnackbar}
      />
      <MDBox mt={1} my={3}>
        <Card>
          <MDBox p={3} lineHeight={1}>
            <Grid container>
              <Grid item xs={6}>
                <MDTypography variant="h5" fontWeight="medium" sx={txtColorChange}>
                  User Roles
                </MDTypography>
              </Grid>
              <Grid item xs={6}>
                <MDButton
                  variant="gradient"
                  color="info"
                  className="adminBtn"
                  sx={bgColorChange}
                  // sx={{ float: "right", marginLeft: "5px" }}
                  onClick={handleCreateOpen}
                >
                  Create New
                </MDButton>
              </Grid>
            </Grid>
          </MDBox>
          <DataTable
            table={userRoles.data.data}
            canSearch
            deleteAction
            handleUserRoleDelete={handleUserRoleDelete}
          />
          {/* <DataGridTables tableRow={userRoles.data.data.rows} columns={transColumns} /> */}

          {/* <MDTypography
            component="p"
            variant="button"
            fontWeight="bold"
            sx={{ textAlign: "center" }}
            mt={3}
            mb={5}
          >
            No Data Available
          </MDTypography> */}
        </Card>
      </MDBox>
      <SiteFooter />

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="modalPopupBox">
          <Grid container>
            <Grid item xs={6}>
              <Typography id="keep-mounted-modal-title" variant="h5" component="h2" sx={txtColorChange}>
                Delete User Role
              </Typography>
              <MDBox variant="outlined" className="close-button" onClick={handleClose}><CloseIcon /></MDBox>
            </Grid>
          </Grid>
          <MDBox component="form" role="form">
            <Grid container>
              <Grid item xs={12} mt={3}>
                Are you sure you want to delete?
              </Grid>
            </Grid>
            <MDBox mt={2} mb={1}>
              <MDButton type="submit" variant="gradient" color="info" sx={bgColorChange} onClick={handleURoleDelete}>
                Confirm
              </MDButton>
            </MDBox>
          </MDBox>
        </Box>
      </Modal>

      <Modal
        open={openCreate}
        onClose={handleCreateClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="modalPopupBox">
          <Grid container>
            <Grid item xs={12}>
              <Typography id="keep-mounted-modal-title" variant="h5" component="h2" sx={txtColorChange}>
                Create User Roles
              </Typography>
              <MDBox variant="outlined" className="close-button" onClick={handleCreateClose}><CloseIcon /></MDBox>
            </Grid>
          </Grid>
          <MDBox component="form" role="form">
            <Grid container>
              <Grid item xs={12}>
                <MDBox mt={2} mb={2} mr={1}>
                  <Autocomplete
                    options={userSuggestions}
                    renderOption={(props, option) => (
                      <li {...props} key={option.userid}>
                        {option.profile_pic ? (
                          <MDAvatar
                            src={option.profile_pic}
                            alt={`${option.first_name.slice(0, 1)}${option.last_name.slice(0, 1)}`}
                            size="sm"
                            bgColor="light"
                          />
                        ) : (
                          <Avatar
                            size="sm"
                            sx={{
                              bgcolor: "#ccc",
                              marginRight: "5px",
                              textTransform: "uppercase",
                            }}
                          >{`${option.first_name.slice(0, 1)}${option.last_name.slice(
                            0,
                            1
                          )}`}</Avatar>
                        )}
                        {`${option.first_name} ${option.last_name} - ${option.employee_id}`}
                      </li>
                    )}
                    getOptionLabel={(option) =>
                      `${option.first_name} ${option.last_name} - ${option.employee_id}`
                    }
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    onChange={updateRecipient}
                    renderInput={(params) => (
                      <MDInput
                        label="Select User"
                        {...params}
                        variant="standard"
                        onChange={updateSuggestions}
                        sx={{ mb: 3 }}
                      />
                    )}
                  />
                </MDBox>
              </Grid>
              <Grid item xs={12}>
                <MDBox mt={2} mb={2}>
                  <FormControl fullWidth size="small">
                    <InputLabel id="demo-simple-select-label">Select Role</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={uRole}
                      label="Select Role"
                      onChange={handleChange}
                      sx={{
                        minHeight: 44,
                      }}
                    >
                      <MenuItem value="admin">Admin</MenuItem>
                      <MenuItem value="super admin">Super Admin</MenuItem>
                      <MenuItem value="design">Design</MenuItem>
                      <MenuItem value="reporting">Reporting</MenuItem>
                    </Select>
                  </FormControl>
                </MDBox>
              </Grid>
            </Grid>
            <MDBox mt={2} mb={1}>
              <MDButton variant="gradient" color="info" onClick={handleUserCreate} sx={bgColorChange}>
                Submit
              </MDButton>
            </MDBox>
          </MDBox>
        </Box>
      </Modal>
    </DashboardLayout>
  );
}

export default UserRoles;
