/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { FormControl, InputLabel, MenuItem, Select, Snackbar, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import MDSnackbar from "components/MDSnackbar";
import Loader from "layouts/Rewards/User/components/Common/Loader";
import { useMaterialUIController } from "context";

const jose = require("jose");

function BookingCard1({
  image,
  title,
  description,
  price,
  location,
  action,
  cardDetails,
  cardType,
  handleCartCount,
  redemptionInstructions,
  terms,
  shortDescription,
  disclaimer
}) {
  // console.log("details::", cardType);

  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  //Getting dollar conversion value from settings
  const settings = localStorage.getItem("settings");
  var obj = JSON.parse(settings);
  let pString = obj.find((o) => o.Name === "point_conversion");
  let profileBg = obj.find((o) => o.Name === "profileBgColor");

  const [cartMsg, setCartMsg] = useState(false);
  const [cartErr, setCartErr] = useState(false);
  const toggleSnackbar = () => {
    if (cartMsg) {
      setCartMsg(!cartMsg);
    }
    if (cartErr) {
      setCartErr(!cartErr);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      setCartMsg(false);
      setCartErr(false);
    }, 2000);
  }, [cartMsg, cartErr]);

  const claims = jose.decodeJwt(localStorage.getItem("token"));

  const [isHovered, setIsHovered] = useState(false);
  const [itemNum, setItemNum] = useState();
  const handleMouseEnter = (val) => {
    setItemNum(val);
    setIsHovered(true);
  };
  const handleMouseLeave = () => {
    setItemNum();
    setIsHovered(false);
  };


  // const [loading, setLoading] = useState(false);
  // setLoading(true);

  let gift_card_type = "redemption";
  if (cardType === "swagstore") {
    gift_card_type =  cardType;
  }

  const handleClick = (cardInfo, staticVal, image) => {
    var pageView = JSON.parse(localStorage.getItem("cartItems"));

    //Check for Account amount with cart amount
    const remainingVal = claims.dollars - staticVal;

    let totalPoints;
    if (pageView === null) {
      totalPoints = 0;
    } else {
      totalPoints = pageView.reduce((prev, next) => prev + next.points, 0);
    }

    if (remainingVal >= totalPoints) {
      if (pageView === null) {
        pageView = [
          {
            points: Number(staticVal),
            code: cardInfo.id,
            gift_card_image: image,
            redemptionInstructions: redemptionInstructions,
            terms: terms,
            description: description,
            disclaimer: disclaimer,
            shortDescription: shortDescription,
            brandName: title,
            gift_card_type: gift_card_type,
          },
        ];
      } else {
        pageView = pageView.concat({
          points: Number(staticVal),
          code: cardInfo.id,
          gift_card_image: image,
          redemptionInstructions: redemptionInstructions,
          terms: terms,
          description: description,
          disclaimer: disclaimer,
          shortDescription: shortDescription,
          brandName: title,
          gift_card_type: gift_card_type
        });
      }

      localStorage.setItem("cartItems", JSON.stringify(pageView));
      setCartMsg(true);
      handleCartCount(JSON.parse(localStorage.getItem("cartItems")).length);
    } else {
      setCartErr(true);
    }
  };


  let priceNode;
  if (cardType === "swagstore") {
    let vVal = cardDetails.split(" - ")
    let swagCardInfo = {id : vVal[1], code: vVal[1]}
    priceNode = (
      <>
        <MDBox
          color={darkMode ? "secondary" : "text"}
          className="dollarView"
          component="button"
          onClick={() => handleClick(swagCardInfo, vVal[2], image)}
          disabled={claims.dollars >= vVal[2] ? false : true}
          onMouseEnter={() => handleMouseEnter('s1')}
          onMouseLeave={handleMouseLeave}
          sx={{
            backgroundColor: (isHovered && itemNum==='s1') ? profileBg.Value : 'white', color: (isHovered && itemNum==='s1') && "#ffffff", borderColor: (isHovered && itemNum==='s1') ? profileBg.Value : 'white'
          }}
        >
          ${Number(vVal[2])} 
        </MDBox>
      </>
    );
  } else {
    if (cardType === "FIXED_VALUE") {
      priceNode = (
        <>
          {cardDetails.map((cardInfo, index) => (
            <>
              <MDBox
                color={darkMode ? "secondary" : "text"}
                className="dollarView"
                component="button"
                onClick={() => handleClick(cardInfo, parseFloat(cardInfo.val), image)}
                disabled={claims.dollars >= cardInfo.val ? false : true}
                onMouseEnter={() => handleMouseEnter(index)}
                onMouseLeave={handleMouseLeave}
                sx={{
                  backgroundColor: (isHovered && itemNum===index) ? profileBg.Value : 'white', color: (isHovered && itemNum===index) && "#ffffff", borderColor: (isHovered && itemNum===index) ? profileBg.Value : 'white'
                }}
              >
                ${Number(cardInfo.val)}
              </MDBox>
            </>
          ))}
        </>
      );
    } else if (cardType === "VARIABLE_VALUE") {
      let variableVal = cardDetails[0];
      let vVal = variableVal.val.split(" - ")
      
      priceNode = (
        <>
        {((Number(5) >= Number(vVal[0])) && (Number(5) <= Number(vVal[1]))) && (
          <MDBox
            color={darkMode ? "secondary" : "text"}
            className="dollarView"
            component="button"
            onClick={() => handleClick(variableVal, 5, image)}
            disabled={claims.dollars >= Number(vVal[0]) ? false : true}
            onMouseEnter={() => handleMouseEnter('v1')}
            onMouseLeave={handleMouseLeave}
            sx={{
              backgroundColor: (isHovered && itemNum==='v1') ? profileBg.Value : 'white', color: (isHovered && itemNum==='v1') && "#ffffff", borderColor: (isHovered && itemNum==='v1') ? profileBg.Value : 'white'
            }}
          >
            $5
          </MDBox>
        )} 
        {((Number(10) >= Number(vVal[0])) && (Number(10) <= Number(vVal[1]))) && (
          <MDBox
            color={darkMode ? "secondary" : "text"}
            className="dollarView"
            component="button"
            onClick={() => handleClick(variableVal, 10, image)}
            disabled={claims.dollars >= Number(vVal[0]) ? false : true}
            onMouseEnter={() => handleMouseEnter('v2')}
            onMouseLeave={handleMouseLeave}
            sx={{
              backgroundColor: (isHovered && itemNum==='v2') ? profileBg.Value : 'white', color: (isHovered && itemNum==='v2') && "#ffffff", borderColor: (isHovered && itemNum==='v2') ? profileBg.Value : 'white'
            }}
          >
            $10
          </MDBox>
        )} 
        {((Number(15) >= Number(vVal[0])) && (Number(15) <= Number(vVal[1]))) && (
          <MDBox
            color={darkMode ? "secondary" : "text"}
            className="dollarView"
            component="button"
            onClick={() => handleClick(variableVal, 15, image)}
            disabled={claims.dollars >= Number(vVal[0]) ? false : true}
            onMouseEnter={() => handleMouseEnter('v3')}
            onMouseLeave={handleMouseLeave}
            sx={{
              backgroundColor: (isHovered && itemNum==='v3') ? profileBg.Value : 'white', color: (isHovered && itemNum==='v3') && "#ffffff", borderColor: (isHovered && itemNum==='v3') ? profileBg.Value : 'white'
            }}
          >
            $15
          </MDBox>
          )} 
          {((Number(20) >= Number(vVal[0])) && (Number(20) <= Number(vVal[1]))) && (
          <MDBox
            color={darkMode ? "secondary" : "text"}
            className="dollarView"
            component="button"
            onClick={() => handleClick(variableVal, 20, image)}
            disabled={claims.dollars >= Number(vVal[0]) ? false : true}
            onMouseEnter={() => handleMouseEnter('v4')}
            onMouseLeave={handleMouseLeave}
            sx={{
              backgroundColor: (isHovered && itemNum==='v4') ? profileBg.Value : 'white', color: (isHovered && itemNum==='v4') && "#ffffff", borderColor: (isHovered && itemNum==='v4') ? profileBg.Value : 'white'
            }}
          >
            $20
          </MDBox>
          )} 
          {((Number(25) >= Number(vVal[0])) && (Number(25) <= Number(vVal[1]))) && (
          <MDBox
            color={darkMode ? "secondary" : "text"}
            className="dollarView"
            component="button"
            onClick={() => handleClick(variableVal, 25, image)}
            disabled={claims.dollars >= Number(vVal[0]) ? false : true}
            onMouseEnter={() => handleMouseEnter('v5')}
            onMouseLeave={handleMouseLeave}
            sx={{
              backgroundColor: (isHovered && itemNum==='v5') ? profileBg.Value : 'white', color: (isHovered && itemNum==='v5') && "#ffffff", borderColor: (isHovered && itemNum==='v5') ? profileBg.Value : 'white'
            }}
          >
            $25
          </MDBox>
          )} 
          {((Number(50) >= Number(vVal[0])) && (Number(50) <= Number(vVal[1]))) && (
          <MDBox
            color={darkMode ? "secondary" : "text"}
            className="dollarView"
            component="button"
            onClick={() => handleClick(variableVal, 50, image)}
            disabled={claims.dollars >= Number(vVal[0]) ? false : true}
            onMouseEnter={() => handleMouseEnter('v6')}
            onMouseLeave={handleMouseLeave}
            sx={{
              backgroundColor: (isHovered && itemNum==='v6') ? profileBg.Value : 'white', color: (isHovered && itemNum==='v6') && "#ffffff", borderColor: (isHovered && itemNum==='v4') ? profileBg.Value : 'white'
            }}
          >
            $50
          </MDBox>
          )} 
          {((Number(100) >= Number(vVal[0])) && (Number(100) <= Number(vVal[1]))) && (
          <MDBox
            color={darkMode ? "secondary" : "text"}
            className="dollarView"
            component="button"
            onClick={() => handleClick(variableVal, 100, image)}
            disabled={claims.dollars >= Number(vVal[0]) ? false : true}
            onMouseEnter={() => handleMouseEnter('v7')}
            onMouseLeave={handleMouseLeave}
            sx={{
              backgroundColor: (isHovered && itemNum==='v7') ? profileBg.Value : 'white', color: (isHovered && itemNum==='v7') && "#ffffff", borderColor: (isHovered && itemNum==='v7') ? profileBg.Value : 'white'
            }}
          >
            $100
          </MDBox>
          )} 
          {title !== "Amazon.com" && (<>
          {((Number(250) >= Number(vVal[0])) && (Number(250) <= Number(vVal[1]))) && (
            <MDBox
              color={darkMode ? "secondary" : "text"}
              className="dollarView"
              component="button"
              onClick={() => handleClick(variableVal, 250, image)}
              disabled={claims.dollars >= Number(vVal[0]) ? false : true}
              onMouseEnter={() => handleMouseEnter('v8')}
              onMouseLeave={handleMouseLeave}
              sx={{
                backgroundColor: (isHovered && itemNum==='v8') ? profileBg.Value : 'white', color: (isHovered && itemNum==='v8') && "#ffffff", borderColor: (isHovered && itemNum==='v8') ? profileBg.Value : 'white'
              }}
            >
              $250
            </MDBox>
          )}
          {((Number(500) >= Number(vVal[0])) && (Number(500) <= Number(vVal[1]))) && (
            <MDBox
              color={darkMode ? "secondary" : "text"}
              className="dollarView"
              component="button"
              onClick={() => handleClick(variableVal, 500, image)}
              disabled={claims.dollars >= Number(vVal[0]) ? false : true}
              onMouseEnter={() => handleMouseEnter('v9')}
              onMouseLeave={handleMouseLeave}
              sx={{
                backgroundColor: (isHovered && itemNum==='v9') ? profileBg.Value : 'white', color: (isHovered && itemNum==='v9') && "#ffffff", borderColor: (isHovered && itemNum==='v9') ? profileBg.Value : 'white'
              }}
            >
              $500
            </MDBox>
          )}
          {((Number(1000) >= Number(vVal[0])) && (Number(1000) <= Number(vVal[1]))) && (
            <MDBox
              color={darkMode ? "secondary" : "text"}
              className="dollarView"
              component="button"
              onClick={() => handleClick(variableVal, 1000, image)}
              disabled={claims.dollars >= Number(vVal[0]) ? false : true}
              onMouseEnter={() => handleMouseEnter('v10')}
              onMouseLeave={handleMouseLeave}
              sx={{
                backgroundColor: (isHovered && itemNum==='v10') ? profileBg.Value : 'white', color: (isHovered && itemNum==='v10') && "#ffffff", borderColor: (isHovered && itemNum==='v10') ? profileBg.Value : 'white'
              }}
            >
              $1000
            </MDBox>
          )} 
          </>)}
        </>
      );
    } 
  }

  return (
    // {(logo === 0 && <Loader />)
    // {loading && (
    // <Loader />)}
    <Card
      sx={{
        "&:hover .card-header": {
          transform: action && "translate3d(0, -50px, 0)",
        },
        marginTop: "35px",
      }}
    >
      <MDSnackbar
        color="success"
        icon="check_circle"
        title="Success"
        content="Gift Card added to cart"
        open={cartMsg}
        close={toggleSnackbar}
      />
      <MDSnackbar
        color="error"
        icon="error"
        title="Error: Amount Exceeded"
        content="You don't have enough points to add a gift card."
        open={cartErr}
        close={toggleSnackbar}
      />
      <MDBox
        position="relative"
        borderRadius="lg"
        mt={-3}
        mx={2}
        className="card-header"
        sx={{ transition: "transform 300ms cubic-bezier(0.34, 1.61, 0.7, 1)" }}
      >
        <MDBox
          component="img"
          src={image}
          alt={title}
          borderRadius="lg"
          shadow="md"
          width="100%"
          height="100%"
          position="relative"
          zIndex={1}
        />
        <MDBox
          borderRadius="lg"
          shadow="md"
          width="100%"
          height="100%"
          position="absolute"
          left={0}
          top="0"
          sx={{
            backgroundImage: `url(${image})`,
            transform: "scale(0.94)",
            filter: "blur(12px)",
            backgroundSize: "cover",
          }}
        />
      </MDBox>

      <Divider />
      <MDTypography
        component="span"
        sx={{ fontSize: "12px", paddingRight: "12px", marginBottom: "10px", textAlign: "right"}}
      >
        $1 = {pString.Value}pts
      </MDTypography>
      <MDBox sx={{ ml: "10px", mr: "10px" }}>{priceNode}</MDBox>
      {/* <MDBox
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        pt={0.5}
        pb={3}
        px={3}
        lineHeight={1}
      >
        <MDTypography variant="body2" fontWeight="regular" color="text">
          {price}
        </MDTypography>
        <MDBox color="text" display="flex" alignItems="center">
          <Icon color="inherit" sx={{ m: 0.5 }}>
            place
          </Icon>
          <MDTypography variant="button" fontWeight="light" color="text">
            {location}
          </MDTypography>
        </MDBox>
      </MDBox> */}
    </Card>
  );
}

// Setting default values for the props of BookingCard
BookingCard1.defaultProps = {
  action: false,
};

// Typechecking props for the BookingCard1
BookingCard1.propTypes = {
  image: PropTypes.string.isRequired,
  // title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  price: PropTypes.string.isRequired,
  // location: PropTypes.node.isRequired,
  action: PropTypes.oneOfType([PropTypes.bool, PropTypes.node]),
  // cardDetails: PropTypes.string.isRequired,
  cardType: PropTypes.string.isRequired,
};

export default BookingCard1;
