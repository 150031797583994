import PropTypes from "prop-types";

// import { useState } from "react";

// Material Dashboard 2 PRO React context
// import { useMaterialUIController } from "context";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

function CodeDialog(props) {
  const { Type, Code, Open, handleOpen } = props;

  const copyCode = () => {
    navigator.clipboard.writeText(Code);
  };

  return (
    <Dialog
      open={Open}
      onClose={handleOpen}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Gift Card Code</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Congratulations! Your {Type} gift card code is: {Code}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={copyCode}>Copy</Button>
        <Button onClick={handleOpen} autoFocus>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}

CodeDialog.propTypes = {
  Type: PropTypes.string.isRequired,
  Code: PropTypes.string.isRequired,
  Open: PropTypes.bool.isRequired,
  handleOpen: PropTypes.func.isRequired,
};

export default CodeDialog;
