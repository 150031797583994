import React from 'react'
import Feed from '../Feed'
import { Card, Grid } from '@mui/material'

const Feeds = ({pageStatus}) => {
  return (
    <Card id="socialFeed" sx={{ overflow: "visible" }}>
      <Feed pageStatus={pageStatus} />
    </Card>
  )
}

export default Feeds