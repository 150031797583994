
import { Avatar, Badge, Box, Card, Grid, Icon, Modal, Tooltip, Typography } from "@mui/material";
import MDAvatar from "components/MDAvatar";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import React, { useEffect, useState } from "react";
import "./newfeeds.css";
import feedBanners from "assets/images/image_not_available.png";
import URLSettings from "../URLSettings";
import EmojiPicker, {
  EmojiStyle,
  SkinTones,
  Theme,
  Categories,
  EmojiClickData,
  Emoji,
  SuggestionMode,
  SkinTonePickerLocation,
} from "emoji-picker-react";
import axios from "axios";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import { bgColorChange, txtColorChange } from "../ColorChange";
const jose = require("jose");
import InsertEmoticonIcon from "@mui/icons-material/InsertEmoticon";
import AttachmentIcon from "@mui/icons-material/Attachment";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router-dom";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { useMaterialUIController } from "context";
import CommentIcon from "@mui/icons-material/Comment";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { useMutation, useQuery } from "react-query";
import moment from "moment";
import Loader from "../Loader";
import DateConvertion from "../DateConvertion";
import Image from "mui-image";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import CreateIcon from '@mui/icons-material/Create';
import DeleteIcon from '@mui/icons-material/Delete';
import MDSnackbar from "components/MDSnackbar";
import HighlightText from "../HighlightText";
import Permissions from "../Permissions";
import Mention from "./Mentions/Mention";

// const ImageUploadComponent = ({key}) => {
//   const [selectedImage, setSelectedImage] = useState(null);

//   const handleFileUpload = (event) => {
//     setSelectedImage(event.target.files[0]);
//   };

//   console.log("new;:", selectedImage);

//   return (<>
//       {/* <label for={"file-input" + key}>
//         {selectedImage && (
//           <span className="attachmentText">{selectedImage.name}</span>
//         )}
//         <AttachmentIcon className="rotateIcon" />
//       </label>
//       <input id={"file-input" + key} type="file" onChange={handleFileUpload} /> */}
//     <div>
//       <input type="file" onChange={handleFileUpload} />
//       {selectedImage && <img src={selectedImage.preview} />}
//     </div>
//   </>);
// };

const CreateForm = ({ parent_id, updateFlag, postData=null, key }) => {
  let APIURL = URLSettings();
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  let textColor = "darkColor";
  if (darkMode) {
    textColor = "lightColor";
  }

  const claims = jose.decodeJwt(localStorage.getItem("token"));
  let iconInfo;
  let profilPic = localStorage.getItem("profilepic");
  let firstName = claims.first_name;
  let lastName = claims.last_name;
  if (postData !== null) {
    profilPic = postData.profile_pic;
    firstName = postData.first_name;
    lastName = postData.last_name;
  }
  //Permissions
  let module_name = "Celebrations";
  let permission = Permissions(module_name); 

  if (profilPic === "null") {
    iconInfo = (
      <Avatar
        size="sm"
        sx={{
          bgcolor: "#ccc",
          marginRight: "5px",
          textTransform: "uppercase",
        }}
      >{`${firstName.slice(0, 1)}${lastName.slice(0, 1)}`}</Avatar>
    );
  } else {
    iconInfo = (
      <MDAvatar
        src={profilPic}
        alt={`${firstName.slice(0, 1)}${lastName.slice(0, 1)}`}
        size="sm"
        bgColor="light"
      />
    );
  }

  
  const [postId, setPostId] = useState(postData === null ? "": postData.id);
  const [postURL, setPostURL] = useState(postData === null ? "": postData.post_image_url);
  const [postText, setPostText] = useState(postData === null ? "": postData.message);
  const [fLocation, setFLocation] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [parentId, setParentId] = useState(parent_id);
  const [showPicker, setShowPicker] = useState(false);
  const [chosenEmoji, setChosenEmoji] = useState(null);

  //Mention and HashTag elements starts here
  const [emailUsers, setEmailUsers] = useState([]);
  const [mentions, setMentions] = useState([]);
  const [mentionInput, setMentionInput] = useState('');
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [suggestedUsers, setSuggestedUsers] = useState([]);

  const userEmails = useMutation(
    async () =>
    axios.post(
      `${APIURL.url}/sendPostsUserEmail`,
      {
        userids: emailUsers,
      },
      {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }
    ),
    {
      onError: (err) => {
        if (err.response.status === 401) {
          window.location.reload();
          return navigate("/login");
        }
      },
      onSuccess: (response) => {   
        setEmailUsers([]);    
      }
    },
  );
  //Mention and HashTag elements ends here

  const handleFileSelect = (event) => {
    setSelectedFile(event.target.files[0]);
    setFLocation("celebration_posts");
  };

  const onEmojiClick = (emojiData: EmojiClickData, event: MouseEvent) => {
    setChosenEmoji(emojiData.unified);
    setPostText(postText + emojiData.emoji);
  };

  const showEmojiPicker = () => {
    setShowPicker(!showPicker);
  };

  const getUsersList = useQuery(["getUsersList"], async () =>
    axios.get(`${APIURL.url}/getUsers`, {
      params: {
        employee_id: '',
        email: '',
        u_id: ''
      },
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    })
  );

  const msgCheck = useMutation(
    async () =>
      axios.post(
        `${APIURL.url}/messageCheck`,
        {
          message: postText,
        },
        {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        }
      ),
    {
      onError: (err) => {
        setEMsg(err.response.data);
        setSendError(true);
        if (err.response.status === 401) {
          window.location.reload();
          return navigate("/login");
        }
      },
      onSuccess: (response) => {
        if (response.data.clean === true) {
          // setPostText(response.data.message);
          if (postData === null) {
            handleSubmitPost.mutate();
          } else {
            handlePostEditSubmit.mutate();
          }
        } else {
          setPostText(response.data.message);
          setEMsg(
            "Your message contains  inappropriate language, which is replaced with *. Please rectify."
          );
          setSendError(true);
        }
      },
    }
  );

  const handleSubmit = (e) => {
    e.preventDefault();
    msgCheck.mutate();
  };

  const handleSubmitPost = useMutation(async () => {
    const formData = new FormData();
    formData.append("post_or_comment", postText);
    formData.append("post_file", selectedFile);
    formData.append("file_location", fLocation);
    formData.append("code", APIURL.code);
    if (parentId != null) {
      formData.append("parent_id", parentId);
    }

    try {
      const response = await axios({
        method: "post",
        url: `${APIURL.url}/company/celebration`,
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: localStorage.getItem("token"),
        },
      });

      if (response) {
        userEmails.mutate();
        updateFlag(true);
        setSelectedFile(null);
        setPostText("");
        setFLocation("");
      }
    } catch (error) {
      if (error.response.status === 401) {
        window.location.reload();
        return navigate("/login");
      }
    }
  });

  const handlePostEditSubmit = useMutation(async () => {
    const formData = new FormData();
    formData.append("post_id", postId);
    formData.append("post_url", postURL);
    formData.append("post_or_comment", postText);
    formData.append("post_file", selectedFile);
    formData.append("file_location", fLocation);
    formData.append("code", APIURL.code);
    formData.append("notification", postData?.notification);
    formData.append("status", true);
    if (parentId != null) {
      formData.append("parent_id", parentId);
    }

    try {
      const response = await axios({
        method: "put",
        url: `${APIURL.url}/company/celebration`,
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: localStorage.getItem("token"),
        },
      });

      if (response) {
        updateFlag(true);
        setSelectedFile(null);
        setPostText("");
        setFLocation("");
        setPostId("");
        setPostURL("");
      }
    } catch (error) {
      if (error.response.status === 401) {
        window.location.reload();
        return navigate("/login");
      }
    }
  });

  if (getUsersList.isError) {
    if (getUsersList.error.response.status === 401) {
      window.location.reload();
      return navigate("/login");
    }
  }

  //Mention and HashTag in textarea
  const handleContentChange = (e) => {
    const inputValue = e.target.value;
    setPostText(inputValue);

    const atIndex = inputValue.lastIndexOf('@');
    if (atIndex !== -1) {
      const query = inputValue.slice(atIndex + 1);
      setMentionInput(query);
      setSuggestedUsers(getUsersList.data.data.filter(user => user.first_name.toLowerCase().includes(query.toLowerCase())));
      setShowSuggestions(true);
    } else {
      setShowSuggestions(false);
    }
  };

  const handleAddMention = (user) => {
    setEmailUsers([...emailUsers, user]);
    const atIndex = postText.lastIndexOf('@');
    const newContent = postText.substring(0, atIndex) + `@${user.first_name} ` + postText.substring(atIndex + mentionInput.length + 1);
    setPostText(newContent);
    setMentions([...mentions, user.first_name]);
    setMentionInput('');
    setShowSuggestions(false);
  };

  const handleRemoveMention = (mentionToRemove) => {
    const updatedMentions = mentions.filter((mention) => mention !== mentionToRemove);
    setMentions(updatedMentions);
  };

  const renderMentions = () => {
    return mentions.map((mention, index) => (
      <Mention
        key={index}
        mention={mention}
        onClick={() => handleRemoveMention(mention)}
      />
    ));
  };
  const renderSuggestions = () => {
    if (!showSuggestions) {
      return null;
    }
      return (<>
        {suggestedUsers.length > 0 && (
        <ul className="suggestions">
          {suggestedUsers.map((user, index) => (
            <li key={index} onClick={() => handleAddMention(user)}>
              {user.profile_pic ? (
                <MDAvatar
                  src={user.profile_pic}
                  alt={`${user.first_name.slice(0, 1)}${user.last_name.slice(
                    0,
                    1
                  )}`}
                  size="sm"
                  bgColor="light"
                />
              ) : (
                <Avatar
                  size="sm"
                  sx={{
                    bgcolor: "#ccc",
                    marginRight: "5px",
                    textTransform: "uppercase",
                  }}
                >{`${user.first_name.slice(0, 1)}${user.last_name.slice(
                  0,
                  1
                )}`}</Avatar>
              )}
              {/* <img src={user.profile_pic} alt={user.name} className="profile-pic" /> */}
              {user.first_name} {user.last_name}
            </li>
          ))}
        </ul>
        )}
      </>);
  };
  if (!getUsersList.isSuccess) {
    // return <Loader />;
  }

  return (
    <MDBox p={2} component="form" role="form">
      {/* onSubmit={handleSubmit} */}

      <div className="post">
        {renderSuggestions()}
      </div>

      <MDInput
        label={iconInfo}
        placeholder="What's on your mind?"
        multiline
        rows={4}
        sx={{
          autoFocus: true,
          width: "100%",
          marginBottom: "15px",
          marginTop: "5px",
          "& .MuiInputLabel-root": {
            marginTop: "-8px",
          },
        }}
        InputLabelProps={{
          shrink: true,
        }}
        value={postText}
        onChange={handleContentChange}
        id="userInput" 
        // onChange={(e) => {
        //   setPostText(e.target.value);
        // }}
      />
      <MDBox sx={{ textAlign: "right" }}>
        <MDBox className="sendIcon">
          <MDButton
            variant="gradient"
            color="primary"
            className="ml-5"
            sx={bgColorChange}
            type="button"
            onClick={handleSubmit}
            disabled = {permission.write_permission ? false : true}
          >
            <Icon sx={{ ml: 0.5 }} className="rotateIcon">
              send
            </Icon>
          </MDButton>
        </MDBox>
        <MDBox className={"emojiBlock " + textColor}>
          <InsertEmoticonIcon onClick={showEmojiPicker} />
          {showPicker && (
            <>
              <MDBox variant="outlined" className="emoji-close-button" onClick={showEmojiPicker}>
                <CloseIcon />
              </MDBox>
              <EmojiPicker onEmojiClick={onEmojiClick} autoFocusSearch={false} />
            </>
          )}
        </MDBox>
        <MDBox className={"image-upload " + textColor}>
          {/* <ImageUploadComponent key={key} /> */}
          <MDBox sx={{ width: "170px !important" }}>
            <label for="file-input">
              {/* {selectedFile && (
                <span className="attachmentText">{selectedFile.name}</span>
              )} */}
              <input type="file" onChange={handleFileSelect} className="browseImg" />
              {/* {selectedFile && <img src={selectedFile.preview} />} */}
            </label>
          </MDBox>
          {/* <label for={"file-input"}>
            {selectedFile && (
              <span className="attachmentText">{selectedFile.name}</span>
            )}
            <AttachmentIcon className="rotateIcon" />
          </label>
          <input id={"file-input"} type="file" onChange={handleFileSelect} /> */}
        </MDBox>
        {postURL && (
        <MDBox className="imageView">
          <img src={postURL} />
        </MDBox>
        )}
      </MDBox>
    </MDBox>
  );
};

// , commentsCount, postCount
const Comment = ({ comment, updateFlagComment, parentId }) => {
  const APIURL = URLSettings();
  const navigate = useNavigate();
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  
  const token = jose.decodeJwt(localStorage.getItem("token"));
  let superFlag = 'no';
  if (token.sp_admin === "yes" || token.admin === "yes") {
    superFlag = "yes";
  }
  const settings = localStorage.getItem("settings");
  var obj = JSON.parse(settings);
  let likeYes = obj.find((o) => o.Name === "like_yes");
  let likeNo = obj.find((o) => o.Name === "like_no");

  const permission = Permissions(token.employee_id);

  let textColor = "darkColor";
  let backColor = "lightBackground";
  if (darkMode) {
    textColor = "lightColor";
    backColor = "darkBackground";
  }

  const [eMsg, setEMsg] = useState("");
  const [sendError, setSendError] = useState("");
  const [sendSuccess, setSendSuccess] = useState("");
  const toggleSnackbar = () => {
    if (sendError) {
      setSendError(!sendError);
    }
    if (sendSuccess) {
      setSendSuccess(!sendSuccess);
    }
  };
  useEffect(() => {
    setTimeout(() => {
      setSendSuccess(false);
      setSendError(false);
    }, 2000);
  }, [sendError, sendSuccess]);

  const [postId, setPostId] = useState("");
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setPostId();
    setOpen(false);
  };

  const handleReply = (val) => {
    setPostId(val);
    handleOpen();
  };

  const updateFlag = (flagVal) => {
    if (flagVal) {
      updateFlagComment(flagVal);
      handleClose();
    }
  };

  const [postURL, setPostURL] = useState("");
  const [postComment, setPostComment] = useState("");
  const [likePost, setLikePost] = useState("");
  const [notifyVal, setNotifyVal] = useState("");
  const [usersList, setUsersList] = useState([]);
  const [likeOpen, setLikeOpen] = useState(false);
  const handleLikeOpen = () => setLikeOpen(true);
  const handleLikeClose = () => {
    setUsersList([]);
    setLikeOpen(false);
  };

  const postLikes = useMutation(
    async () =>
      axios.post(
        `${APIURL.url}/celebrations/likes`,
        {
          post_id: postId,
          like: likePost,
        },
        {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        }
      ),
    {
      onError: (err) => {
        console.log(err);
        setLikePost("");
        setPostId("");
        if (err.response.status === 401) {
          window.location.reload();
          return navigate("/login");
        }
      },
      onSuccess: () => {
        setLikePost("");
        setPostId("");
        updateFlagComment(true);
      },
    }
  );

  const handleLikePost = (pid, likeVal) => {
    setPostId(pid);
    setLikePost(!likeVal);
    setTimeout(() => {
      postLikes.mutate();
    }, 2000);
  };

  const getLikesList = useMutation(
    async () =>
      axios.get(`${APIURL.url}/celebrations/likes?post_id=` + postId, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }),
    {
      onError: (err) => {
        setPostId("");
        if (err.response.status === 401) {
          window.location.reload();
          return navigate("/login");
        }
      },
      onSuccess: (response) => {
        setUsersList(response?.data);
        setPostId("");
        handleLikeOpen();
      },
    }
  );

  const handleLikeList = (pid) => {
    setPostId(pid);
    setTimeout(() => {
      getLikesList.mutate();
    }, 2000);
  };

  const [confirmOpen, setConfirmOpen] = useState();
  const handleConfirmOpen = () => setConfirmOpen(true);
  const handleConfirmClose = () => setConfirmOpen(false);

  const deletePost = useMutation(
    async () => {
      const formData = new FormData();
      formData.append("post_or_comment", postComment);
      formData.append("post_id", postId);
      formData.append("notification", notifyVal);
      formData.append("post_url", postURL);
      formData.append("status", false);

      try {
        const response = await axios({
          method: "put",
          url: `${APIURL.url}/company/celebration`,
          data: formData,
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: localStorage.getItem("token"),
          },
        });
  
        if (response) {          
          updateFlag(true);
          setPostId("");
          setPostURL("");
          setPostComment("");
          setNotifyVal("");
          setEMsg('Post deleted');
          setSendSuccess(true);
          handleConfirmClose();
        }
      } catch (error) {
        setPostId("");
        setPostURL("");
        setPostComment("");
        setNotifyVal("");
        setEMsg("something went wrong");
        setSendError(true);
        if (err.response.status === 401) {
          window.location.reload();
          return navigate("/login");
        }
      }
  });

  const handleConfirmDelete = () => {
    deletePost.mutate();
  }

  const handlePostDelete = (data) => {
    setPostId(data.id);
    setNotifyVal(data.notification);
    setPostURL(data.post_image_url);
    setPostComment(data.message);
    handleConfirmOpen();
  }

  const [postData, setPostData] = useState();
  const [editPost, setEditPost] = useState(false);
  const handleEditPostOpen = () => setEditPost(true);
  const handleEditPostClose = () => {
    setEditPost(false);
  };
  const handlePostEdit = (data) => {
    setPostId(data.parent_id);
    setPostData(data);
    handleEditPostOpen();
  }

  const postEditFlag = (editFlagVal) => {
    if (editFlagVal) {
      updateFlag(true);
      handleEditPostClose();
    }
  };

  return (
    <MDBox pt={2} pb={2} style={{ marginLeft: "25px" }}>
      <Grid container spacing={1}>
        <Grid item xs={12} md={1} lg={1}>
          <MDBox sx={{ margin: "0px auto", width: "36px" }}>
            {comment.profile_pic ? (
              <MDAvatar
                src={comment.profile_pic}
                alt={`${comment.first_name.slice(0, 1)}${comment.last_name.slice(0, 1)}`}
                size="sm"
                bgColor="light"
              />
            ) : (
              <Avatar
                size="sm"
                sx={{
                  bgcolor: "#ccc",
                  marginRight: "5px",
                  textTransform: "uppercase",
                }}
              >{`${comment.first_name.slice(0, 1)}${comment.last_name.slice(0, 1)}`}</Avatar>
            )}
          </MDBox>
        </Grid>
        <Grid item xs={12} md={11} lg={11}>
          <MDBox className="comments">
           {superFlag === "yes" ? (<>
              {permission.edit_permission && (
                <CreateIcon className="editIcon" onClick={() =>handlePostEdit(comment)} />
              )}
              {permission.delete_permission && (
              <DeleteIcon className="deleteIcon" onClick={() => handlePostDelete(comment)} />
              )}
            </>
            ) : (<>
              {token.userid === comment.created_by && (<>
                {permission.edit_permission && (
                <CreateIcon className="editIcon" onClick={() =>handlePostEdit(comment)} />
                )}
                {permission.delete_permission && (
                <DeleteIcon className="deleteIcon" onClick={() => handlePostDelete(comment)} />
                )}
              </>)}
            </>)}
                       
            <MDTypography variant="h6" fontWeight="medium" className="darkColor">
              {comment.first_name} {comment.last_name}
            </MDTypography>
            <MDTypography variant="button" color="text" fontWeight="regular" className="darkColor">
              {HighlightText(comment.message)}
            </MDTypography>
            {/* <MDBox className='createdOnBlock'>
                        <AccessTimeIcon className='createdIconComment' /> 
                        <span className='createdOnComment'> {comment.created_on}</span>
                    </MDBox> */}
            <Grid item>
              {comment.post_image_url && (
                <img
                  src={comment.post_image_url}
                  style={{ maxWidth: "250px", borderRadius: "10px" }}
                />
              )}
            </Grid>
          </MDBox>
          <MDBox className="likeBadge">
            {comment.liked ? (
              <img
                width={18}
                height={18}
                src={`${likeYes.Value}`}
                onClick={() => handleLikePost(comment.id, comment.liked)}
              />
            ) : (
              <img
                width={18}
                height={18}
                src={`${likeNo.Value}`}
                onClick={() => handleLikePost(comment.id, comment.liked)}
              />
            )}
            <span className="likeCount" onClick={() => handleLikeList(comment.id)}>
              {comment.likes}
            </span>
          </MDBox>
          <Badge color="primary" onClick={() => handleReply(comment.id)} className="likeBadge">
            <CommentIcon color="action" />
          </Badge>
          <Tooltip title={comment.created_on}>
            <Badge color="primary" onClick={() => handleReply(comment.id)} className="likeBadge">
              <AccessTimeIcon className={"createdIcon " + textColor} />
              <span className={"createdOn " + textColor}>
                {" "}
                {moment.utc(`${comment.created_on}`).local().startOf("seconds").fromNow()}
              </span>
            </Badge>
          </Tooltip>
        </Grid>
      </Grid>

      {comment.comments &&
        comment.comments.map((nestedComment) => (
          <>
            <Comment
              key={nestedComment.id}
              comment={nestedComment}
              style={{ marginLeft: "30px" }}
              updateFlagComment={updateFlag}
              parentId={parentId}
            />
            {/* commentsCount={updateCommentCount(comment.comments.length)} */}
          </>
        ))}
      {/* comment popup starts */}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        variant="outlined"
      >
        <Box className={"modalPopupBox replyBox " + backColor}>
          <Grid container>
            <Grid item xs={6}>
              <Typography
                id="keep-mounted-modal-title"
                variant="h5"
                component="h2"
                sx={txtColorChange}
              >
                Reply
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <MDBox variant="outlined" className="close-button" onClick={handleClose}>
                <CloseIcon />
              </MDBox>
            </Grid>
          </Grid>
          {permission.write_permission && (
          <CreateForm parent_id={postId} updateFlag={updateFlag} />
          )}
        </Box>
      </Modal>
      {/* comment popup ends */}
      {/* Likes list Popup starts */}
      <Modal
        open={likeOpen}
        onClose={handleLikeClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        variant="outlined"
      >
        <Box className="modalPopupBox">
          <Grid container>
            <Grid item xs={6}>
              <Typography
                id="keep-mounted-modal-title"
                variant="h5"
                component="h2"
                sx={txtColorChange}
                mb={2}
              >
                User Likes
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <MDBox variant="outlined" className="close-button" onClick={handleLikeClose}>
                <CloseIcon />
              </MDBox>
            </Grid>
          </Grid>
          {usersList &&
            usersList.map((user) => (
              <>
                <Grid container spacing={2} mb={2}>
                  <Grid item xs={2} md={1} lg={1}>
                    {user.profile_pic ? (
                      <MDAvatar
                        src={user.profile_pic}
                        alt={`${user.user_name.slice(0, 1)}`}
                        size="sm"
                        bgColor="light"
                      />
                    ) : (
                      <Avatar
                        size="sm"
                        sx={{
                          bgcolor: "#ccc",
                          marginRight: "5px",
                          textTransform: "uppercase",
                        }}
                      >{`${user.user_name.slice(0, 1)}`}</Avatar>
                    )}
                  </Grid>
                  <Grid item xs={10} md={11} lg={11}>
                    <MDBox mt={0.5} lineHeight={1} ml={2}>
                      <MDTypography variant="h5" fontWeight="medium">
                        {user.user_name}
                      </MDTypography>
                    </MDBox>
                  </Grid>
                </Grid>
              </>
            ))}
        </Box>
      </Modal>
      {/* Likes List Popup ends */}

      {/* Post Edit Starts */}
      <Modal
          open={editPost}
          onClose={handleEditPostClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          variant="outlined"
        >
          <Box className={"modalPopupBox replyBox " + backColor}>
            <Grid container>
              <Grid item xs={6}>
                <Typography
                  id="keep-mounted-modal-title"
                  variant="h5"
                  component="h2"
                  sx={txtColorChange}
                >
                  Edit Post
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <MDBox variant="outlined" className="close-button" onClick={handleEditPostClose}>
                  <CloseIcon />
                </MDBox>
              </Grid>
            </Grid>
            {permission.write_permission && (
            <CreateForm postData={postData} parent_id={postId} updateFlag={updateFlag} updateFlag={postEditFlag} />
            )}
          </Box>
        </Modal>  
        {/* Post Edit ends */}

      {/* Delete Confirmation Popup Starts */}
      <Modal
        open={confirmOpen}
        onClose={handleConfirmClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        variant="outlined"
      >
        <Box className={"modalPopupBox replyBox " + backColor}>
          <Grid container>
            <Grid item xs={12}>
              <Typography
                id="keep-mounted-modal-title"
                variant="h5"
                component="h2"
                sx={txtColorChange}
              >
                Are you sure you want to delete the post?
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <MDBox variant="outlined" className="close-button" onClick={handleConfirmClose}>
                <CloseIcon />
              </MDBox>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12}>
              <MDButton
                variant="gradient"
                color="primary"
                className="ml-5 mt-2"
                sx={bgColorChange}
                type="button"
                onClick={handleConfirmDelete}
              >
                Confirm
              </MDButton> 
            </Grid>
          </Grid>
        </Box>
      </Modal>  
      {/* Delete Confirmation Popup Ends */}
    </MDBox>
  );
};

const Post = ({ post, updateFlag }) => {
  const APIURL = URLSettings();
  const navigate = useNavigate();
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  const token = jose.decodeJwt(localStorage.getItem("token"));
  let superFlag = 'no';
  if (token.sp_admin === "yes" || token.admin === "yes") {
    superFlag = "yes";
  }

  //Permissions
  let module_name = "Celebrations";
  let permission = Permissions(module_name); 

  const settings = localStorage.getItem("settings");
  var obj = JSON.parse(settings);
  let likeYes = obj.find((o) => o.Name === "like_yes");
  let likeNo = obj.find((o) => o.Name === "like_no");

  const [handleNotifyColor, setHandleNotifyColor] = useState(false);
  const [handleLikeColor, setHandleLikeColor] = useState(false);
  const [flagCheck, setFlagChack] = useState(true);
  const [flagLikeCheck, setFlagLikeChack] = useState(true);
  const [likeCCount, setLikeCCount] = useState(0);
  const [likePost, setLikePost] = useState("");
  const [postId, setPostId] = useState("");
  const [postURL, setPostURL] = useState("");
  const [postComment, setPostComment] = useState("");
  const [notifyVal, setNotifyVal] = useState("");
  const [usersList, setUsersList] = useState([]);
  const [likeOpen, setLikeOpen] = useState(false);
  const handleLikeOpen = () => setLikeOpen(true);
  const handleLikeClose = () => {
    setUsersList([]);
    setLikeOpen(false);
  };

  const [eMsg, setEMsg] = useState("");
  const [sendError, setSendError] = useState("");
  const [sendSuccess, setSendSuccess] = useState("");
  const toggleSnackbar = () => {
    if (sendError) {
      setSendError(!sendError);
    }
    if (sendSuccess) {
      setSendSuccess(!sendSuccess);
    }
  };
  useEffect(() => {
    setTimeout(() => {
      setSendSuccess(false);
      setSendError(false);
    }, 2000);
  }, [sendError, sendSuccess]);

  const [showComments, setShowComments] = useState(false);
  const handleComments = () => {
    setShowComments(!showComments);
  };

  const updateNewFlagComment = (val) => {
    if (val) {
      updateFlag(true);
    }
  };



  let textColor = "darkColor";
  let backColor = "lightBackground";
  if (darkMode) {
    textColor = "lightColor";
    backColor = "darkBackground";
  }

  const postLikes = useMutation(
    async () =>
      axios.post(
        `${APIURL.url}/celebrations/likes`,
        {
          post_id: postId,
          like: likePost,
        },
        {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        }
      ),
    {
      onError: (err) => {
        console.log(err);
        setLikePost("");
        setPostId("");
        if (err.response.status === 401) {
          window.location.reload();
          return navigate("/login");
        }
      },
      onSuccess: () => {
        setLikePost("");
        setPostId("");
        updateFlag(true);
      },
    }
  );


  const updateNotification = useMutation(
    async () => {
      const formData = new FormData();
      formData.append("post_or_comment", postComment);
      formData.append("post_id", postId);
      formData.append("notification", notifyVal);
      formData.append("post_url", postURL);
      formData.append("status", false);

      try {
        const response = await axios({
          method: "put",
          url: `${APIURL.url}/company/celebration`,
          data: formData,
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: localStorage.getItem("token"),
          },
        });
  
        if (response) {          
          updateFlag(true);
          setPostId("");
          setPostURL("");
          setPostComment("");
          setNotifyVal("");
        }
      } catch (error) {
        setPostId("");
        setPostURL("");
        setPostComment("");
        setNotifyVal("");
        setEMsg("something went wrong");
        setSendError(true);
        if (err.response.status === 401) {
          window.location.reload();
          return navigate("/login");
        }
      }
  });

  const handleNotification = (data) => {
    setFlagLikeChack(false);
    setPostId(data.id);
    setPostURL(data.profile_pic);
    setPostComment(data.message);
    setNotifyVal(!data.notification);
    setHandleNotifyColor(!data.notification);
    setTimeout(() => {
      updateNotification.mutate();
    }, 2000);
  };

  const handleLikePost = (pid, likeVal, countLike) => {
    setFlagChack(false);
    setPostId(pid);
    setLikePost(!likeVal);
    setHandleLikeColor(!likeVal);
    if (likeVal !== true) {
      setLikeCCount(countLike + 1);
    } else {
      setLikeCCount(countLike - 1);
    }

    setTimeout(() => {
      postLikes.mutate();
    }, 2000);
  };

  const getLikesList = useMutation(
    async () =>
      axios.get(`${APIURL.url}/celebrations/likes?post_id=` + postId, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }),
    {
      onError: (err) => {
        setPostId("");
        if (err.response.status === 401) {
          window.location.reload();
          return navigate("/login");
        }
      },
      onSuccess: (response) => {
        setUsersList(response?.data);
        setPostId("");
      },
    }
  );

  const handleLikeList = (pid) => {
    setPostId(pid);
    setTimeout(() => {
      getLikesList.mutate();
    }, 2000);
    handleLikeOpen();
  };
  
  const [confirmOpen, setConfirmOpen] = useState();
  const handleConfirmOpen = () => setConfirmOpen(true);
  const handleConfirmClose = () => setConfirmOpen(false);
  const deletePost = useMutation(
    async () => {
      const formData = new FormData();
      formData.append("post_or_comment", postComment);
      formData.append("post_id", postId);
      formData.append("notification", notifyVal);
      formData.append("post_url", postURL);
      formData.append("status", false);

      try {
        const response = await axios({
          method: "put",
          url: `${APIURL.url}/company/celebration`,
          data: formData,
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: localStorage.getItem("token"),
          },
        });
  
        if (response) {          
          updateFlag(true);
          setPostId("");
          setPostURL("");
          setPostComment("");
          setNotifyVal("");
          setEMsg('Post deleted');
          setSendSuccess(true);
          handleConfirmClose();
        }
      } catch (error) {
        setPostId("");
        setPostURL("");
        setPostComment("");
        setNotifyVal("");
        setEMsg("something went wrong");
        setSendError(true);
        if (error.response.status === 401) {
          window.location.reload();
          return navigate("/login");
        }
      }
  });

  const handleConfirmDelete = () => {
    deletePost.mutate();
  }

  const handlePostDelete = (data) => {
    setPostId(data.id);
    setNotifyVal(data.notification);
    setPostURL(data.profile_pic);
    setPostComment(data.message);
    handleConfirmOpen();
  }

  const [postData, setPostData] = useState();
  const [editPost, setEditPost] = useState(false);
  const handleEditPostOpen = () => setEditPost(true);
  const handleEditPostClose = () => {
    setEditPost(false);
  };
  const handlePostEdit = (data) => {
    setPostId(data.parent_id);
    setPostData(data);
    handleEditPostOpen();
  }

  const postEditFlag = (editFlagVal) => {
    if (editFlagVal) {
      updateFlag(true);
      handleEditPostClose();
    }
  };

  let mesg = HighlightText(post.message);
  // console.log("msg::", mesg + ' ');

  return (
    <MDBox my={1}>
      <Card>
        <MDSnackbar
          color="error"
          icon="error"
          title="Error: Please try again"
          content={eMsg}
          open={sendError}
          close={toggleSnackbar}
        /> 
        <MDSnackbar
          color="success"
          icon="check_circle"
          title="Success"
          content={eMsg}
          open={sendSuccess}
          close={toggleSnackbar}
        /> 
        <MDBox pt={2} pb={2}>
          <Grid container spacing={3} alignItems="center" pl={2} pr={2} mb={1}>
            <Grid item xs={12} md={1} lg={1}>
              {post.profile_pic ? (
                <MDAvatar
                  src={post.profile_pic}
                  alt={`${post.first_name.slice(0, 1)}${post.last_name.slice(0, 1)}`}
                  size="sm"
                  bgColor="light"
                />
              ) : (
                <Avatar
                  size="sm"
                  sx={{
                    bgcolor: "#ccc",
                    marginRight: "5px",
                    textTransform: "uppercase",
                  }}
                >{`${post.first_name.slice(0, 1)}${post.last_name.slice(0, 1)}`}</Avatar>
              )}
            </Grid>
            <Grid item xs={12} md={11} lg={11}>
              <MDBox height="100%" mt={0.5} lineHeight={1}>
                <MDTypography variant="h5" fontWeight="medium">
                  {post.first_name} {post.last_name}
                </MDTypography>
                <Tooltip title={post.created_on}>
                  <MDTypography
                    variant="button"
                    fontWeight="regular"
                    sx={{ display: "inline-flex", marginTop: "10px" }}
                  >
                    <AccessTimeIcon className={"createdIcon " + textColor} />
                    <span className={"createdOn " + textColor}>
                      <DateConvertion dateObj={post.created_on} />
                      {/* {moment.utc(`${post.created_on}`).local().startOf('seconds').fromNow()} */}
                    </span>
                  </MDTypography>
                </Tooltip>
              </MDBox>
            </Grid>
            <Grid item xs={12} md={12} lg={12} sx={{ paddingTop: "0px !important" }}>
              <MDTypography variant="button" fontWeight="regular" sx={{ display: "inline-flex" }}>
                {/* {post.message}  */}
                {/* {mesg} {' '} */}
                {HighlightText(post.message)}
              </MDTypography>
            </Grid>
            {token.userid === post.created_by && (
              <>
                {flagLikeCheck ? (
                  <NotificationsIcon
                    className={"notificationIcon " + textColor}
                    onClick={() => handleNotification(post)}
                    sx={post.notification && txtColorChange}
                  />
                ) : (
                  <NotificationsIcon
                    className={"notificationIcon " + textColor}
                    onClick={() => handleNotification(post)}
                    sx={handleNotifyColor && txtColorChange}
                  />
                )}
              </>
            )}

            {superFlag === "yes" ? (<>
              {permission.edit_permission && (
              <CreateIcon className="editIcon" onClick={() =>handlePostEdit(post)} />
              )}
              {permission.delete_permission && (
              <DeleteIcon className="deleteIcon" onClick={() => handlePostDelete(post)} />
              )}
            </>
            ) : (<>
              {token.userid === post.created_by && (<>
                {permission.edit_permission && (
                <CreateIcon className="editIcon" onClick={() =>handlePostEdit(post)} />
                )}
                {permission.delete_permission && (
                <DeleteIcon className="deleteIcon" onClick={() => handlePostDelete(post)} />
                )}
              </>)}
            </>)}
          </Grid>
          <Grid container spacing={3} alignItems="center">
            <Grid item className="feedbanner">
              {post.post_image_url && (
                // <img src={post.post_image_url} /> , height: "400px !important"
                <Image
                  src={post.post_image_url}
                  maxWidth={false}
                  // sx={{ borderRadius: "0.75rem" }}
                />
              )}
              {/* {post.post_image_url ? (
                        <img src={post.post_image_url} />
                        ) : (
                        <img src={feedBanners} />  
                        )} */}
            </Grid>
          </Grid>
          <MDBox className="commentText">
            <MDBox className="likeBadge">
              {flagCheck ? (
                <img
                  width={18}
                  height={18}
                  src={post.liked ? likeYes.Value : likeNo.Value}
                  onClick={() => handleLikePost(post.id, post.liked, post.likes)}
                />
              ) : (
                <>
                  <img
                    width={18}
                    height={18}
                    src={handleLikeColor ? likeYes.Value : likeNo.Value}
                    onClick={() => handleLikePost(post.id, post.liked, post.likes)}
                  />
                </>
              )}
              {/* {handleLikeColor ? likeYes.Value : likeNo.Value } */}

              {/* {post.liked ? (
                            <img width={18} height={18} src={`${likeYes.Value}`} onClick={() => handleLikePost(post.id, post.liked)} />
                        ) : (
                            <img width={18} height={18} src={`${likeNo.Value}`} onClick={() => handleLikePost(post.id, post.liked)} />
                        )} */}
              {flagCheck ? (
                <span className="likeCount" onClick={() => handleLikeList(post.id)}>
                  {post.likes}
                </span>
              ) : (
                <span className="likeCount" onClick={() => handleLikeList(post.id)}>
                  {likeCCount}
                </span>
              )}
            </MDBox>
            <Badge
              badgeContent={post.comments.length}
              color="primary"
              onClick={handleComments}
              className="likeBadge"
              sx={{ marginTop: "-5px" }}
            >
              <CommentIcon color="action" sx={txtColorChange} />
            </Badge>
            {showComments && (
              <>
                {post.comments &&
                  post.comments.map((nestedComment) => (
                    <Comment
                      key={nestedComment.id}
                      comment={nestedComment}
                      updateFlagComment={updateNewFlagComment}
                      parentId={post.id}
                    />
                    // commentsCount={fullCountCommnets} postCount={post.comments.length}
                  ))}
              </>
            )}
          </MDBox>
          {permission.write_permission && ( 
            <CreateForm parent_id={post.id} updateFlag={updateFlag} key={post.id} /> 
          )}
          {/* Show Likes List starts */}
          <Modal
            open={likeOpen}
            onClose={handleLikeClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            variant="outlined"
          >
            <Box className={"modalPopupBox " + backColor}>
              <Grid container mb={2}>
                <Grid item xs={6}>
                  <Typography
                    id="keep-mounted-modal-title"
                    className="modalPopupTitle"
                    variant="h5"
                    component="h2"
                    sx={txtColorChange}
                    mb={2}
                  >
                    User Likes
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <MDBox variant="outlined" className="close-button" onClick={handleLikeClose}>
                    <CloseIcon />
                  </MDBox>
                </Grid>
              </Grid>
              <MDBox sx={{ minHeight: "150px" }}>
                {getLikesList.isLoading ? (
                  <Loader />
                ) : (
                  <>
                    {usersList &&
                      usersList.map((user) => (
                        <>
                          <Grid container spacing={2} mb={2}>
                            <Grid item xs={2} md={1} lg={1}>
                              {user.profile_pic ? (
                                <MDAvatar
                                  src={user.profile_pic}
                                  alt={`${user.user_name.slice(0, 1)}`}
                                  size="sm"
                                  bgColor="light"
                                />
                              ) : (
                                <Avatar
                                  size="sm"
                                  sx={{
                                    bgcolor: "#ccc",
                                    marginRight: "5px",
                                    textTransform: "uppercase",
                                  }}
                                >{`${user.user_name.slice(0, 1)}`}</Avatar>
                              )}
                            </Grid>
                            <Grid item xs={10} md={11} lg={11}>
                              <MDBox mt={0.5} lineHeight={1} ml={2}>
                                <MDTypography variant="h5" fontWeight="medium">
                                  {user.user_name}
                                </MDTypography>
                              </MDBox>
                            </Grid>
                          </Grid>
                        </>
                      ))}
                  </>
                )}
              </MDBox>
            </Box>
          </Modal>
          {/* Show Likes List ends */}
          {/* Post Edit Starts */}
          <Modal
            open={editPost}
            onClose={handleEditPostClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            variant="outlined"
          >
            <Box className={"modalPopupBox replyBox " + backColor}>
              <Grid container>
                <Grid item xs={6}>
                  <Typography
                    id="keep-mounted-modal-title"
                    variant="h5"
                    component="h2"
                    sx={txtColorChange}
                  >
                    Edit Post
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <MDBox variant="outlined" className="close-button" onClick={handleEditPostClose}>
                    <CloseIcon />
                  </MDBox>
                </Grid>
              </Grid>
              {permission.write_permission && (
              <CreateForm postData={postData} parent_id={postId} updateFlag={updateFlag} updateFlag={postEditFlag} />
              )}
            </Box>
          </Modal>  
          {/* Post Edit ends */}
          {/* Delete Confirmation Popup Starts */}
          <Modal
            open={confirmOpen}
            onClose={handleConfirmClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            variant="outlined"
          >
            <Box className={"modalPopupBox replyBox " + backColor}>
              <Grid container>
                <Grid item xs={12}>
                  <Typography
                    id="keep-mounted-modal-title"
                    variant="h5"
                    component="h2"
                    sx={txtColorChange}
                  >
                    Are you sure you want to delete the post?
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <MDBox variant="outlined" className="close-button" onClick={handleConfirmClose}>
                    <CloseIcon />
                  </MDBox>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={12}>
                  <MDButton
                    variant="gradient"
                    color="primary"
                    className="ml-5 mt-2"
                    sx={bgColorChange}
                    type="button"
                    onClick={handleConfirmDelete}
                  >
                    Confirm
                  </MDButton> 
                </Grid>
              </Grid>
            </Box>
          </Modal>  
          {/* Delete Confirmation Popup Ends */}
        </MDBox>
      </Card>
    </MDBox>
  );
};

const FacebookPosts = ({ posts, newhandlePostFlag }) => {
  const updateNewFlag = (val) => {
    if (val) {
      newhandlePostFlag(true);
    }
  };

  const [historySize, setHistorySize] = useState(15);
  const increaseHistorySize = () => {
    setHistorySize(historySize + 15);
  };

  return (
    <div className="facebook-posts">
      {posts.slice(0, historySize).map((post, index) => (
        <Post key={post.id} post={post} updateFlag={updateNewFlag} />
      ))}

      <MDButton
        variant="gradient"
        color="info"
        onClick={increaseHistorySize}
        disabled={historySize >= posts.length}
        className="mgTop"
        sx={bgColorChange}
      >
        Load More <Icon sx={{ ml: 0.5 }}>add</Icon>
      </MDButton>
    </div>
  );
};

const FBPosts = ({ data, postHandlePostFlag }) => {
  const navigate = useNavigate();
  useEffect(() => {
    if (!localStorage.getItem("token")) {
      window.location.reload();
      return navigate("/login");
    }
  }, []);

  const handlePostFlag = (val) => {
    if (val) {
      postHandlePostFlag(true);
    }
  };

  return (
    <div className="app">
      <FacebookPosts posts={data} newhandlePostFlag={handlePostFlag} />
    </div>
  );
};

export default FBPosts;
