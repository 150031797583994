/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import { useState } from "react";
import { Box, Modal, Typography } from "@mui/material";
import { txtColorChange } from "layouts/Rewards/User/components/Common/ColorChange";
import CloseIcon from '@mui/icons-material/Close';

// const style = {
//   position: "absolute",
//   top: "50%",
//   left: "50%",
//   transform: "translate(-50%, -50%)",
//   width: 500,
//   height: 500,
//   overflowY: "scroll",
//   bgcolor: "#a8a9af",
//   border: "2px solid #000",
//   boxShadow: 24,
//   p: 4,
// };

// Custom styles for OrderCartDisplay
function OrderCartDisplay({
  color,
  image,
  title,
  description,
  points,
  disclaimer,
  terms,
  shortDescription,
  handleCartDelete,
  keyNum,
  quantity,
}) {
  //Getting dollar conversion value from settings
  const settings = localStorage.getItem("settings");
  var obj = JSON.parse(settings);
  let pString = obj.find((o) => o.Name === "point_conversion");

  let shortDes;
  if (shortDescription != "" || shortDescription !== undefined) {
    shortDes = shortDescription.substring(0, 130);
  }

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  function fshortDes() {
    return { __html: shortDes };
  }
  function fTerms() {
    return { __html: terms };
  }
  function fDisclaimer() {
    return { __html: disclaimer };
  }
  function fDescription() {
    return { __html: description };
  }

  const handleDelete = (keyNum) => {
    handleCartDelete(keyNum);
  };

  return (
    <Card>
      <MDBox p={2}>
        <MDBox display="flex" alignItems="center">
          <MDAvatar
            src={image}
            alt={title}
            size="xl"
            variant="rounded"
            bgColor={color}
            sx={{ p: 1, mt: -6, borderRadius: ({ borders: { borderRadius } }) => borderRadius.xl }}
          />
          <MDBox ml={2} mt={-2} lineHeight={0}>
            <MDTypography variant="h6" textTransform="capitalize" fontWeight="medium" mb={1}>
              {title}
            </MDTypography>
            <MDTypography variant="p" sx={{ fontSize: "14px" }}>
              {quantity} (Quantity)
            </MDTypography>
          </MDBox>
          <MDTypography
            color="secondary"
            sx={{
              ml: "auto",
              mt: -1,
              alignSelf: "flex-start",
              py: 1.25,
            }}
          >
            <Icon
              color="error"
              fontSize="default"
              sx={{ cursor: "pointer" }}
              onClick={() => {
                handleDelete(keyNum);
              }}
            >
              delete
            </Icon>
          </MDTypography>
        </MDBox>
        <MDBox my={2} lineHeight={1}>
          <MDTypography variant="button" fontWeight="light" color="text">
            <div dangerouslySetInnerHTML={fshortDes()}></div>
          </MDTypography>
        </MDBox>
        <Divider />
        <MDBox display="flex" justifyContent="space-between" alignItems="center">
          <MDBox display="flex" flexDirection="column" lineHeight={0}>
            <MDTypography variant="button" fontWeight="medium">
              ${points} <MDTypography sx={{ fontSize: "12px"}}>($1 = {pString.Value}pts)</MDTypography>
            </MDTypography>
          </MDBox>
          <MDBox display="flex" flexDirection="column" lineHeight={0}>
            <MDTypography
              variant="button"
              fontWeight="regular"
              color="secondary"
              onClick={handleOpen}
              sx={{ cursor: "pointer" }}
            >
              Terms
            </MDTypography>
          </MDBox>
        </MDBox>
      </MDBox>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="modalPopupBox">
          <MDBox variant="outlined" className="close-button" onClick={handleClose}><CloseIcon /></MDBox>
          <Typography
            id="keep-mounted-modal-title"
            variant="h5"
            component="h2"
            sx={txtColorChange}
          >
            Description:
          </Typography>
          <MDTypography
            variant="button"
            fontWeight="light"
            color="secondary"
            dangerouslySetInnerHTML={fDescription()}
          ></MDTypography>
          <Typography
            id="keep-mounted-modal-title"
            variant="h5"
            component="h2"
            sx={txtColorChange}
          >
            Terms:
          </Typography>
          <MDTypography
            variant="button"
            fontWeight="light"
            color="secondary"
            dangerouslySetInnerHTML={fTerms()}
          ></MDTypography>
          <Typography
            id="keep-mounted-modal-title"
            variant="h5"
            component="h2"
            sx={txtColorChange}
          >
            Disclaimer:
          </Typography>
          <MDTypography
            variant="button"
            fontWeight="light"
            color="secondary"
            dangerouslySetInnerHTML={fDisclaimer()}
          ></MDTypography>
        </Box>
      </Modal>
    </Card>
  );
}

// Setting default values for the props of OrderCartDisplay
OrderCartDisplay.defaultProps = {
  color: "dark",
};

// Typechecking props for the ProfileInfoCard
OrderCartDisplay.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "dark",
    "light",
  ]),
  image: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  dateTime: PropTypes.string,
  description: PropTypes.node.isRequired,
};

export default OrderCartDisplay;
