// import PropTypes from "prop-types";
// Material Dashboard 2 PRO React context
// import { useMaterialUIController } from "context";
import { Box, Card, Grid, Icon, Modal, Switch, Tooltip, Typography } from "@mui/material";
import MDAvatar from "components/MDAvatar";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
// import MDDropzone from "components/MDDropzone";
import MDTypography from "components/MDTypography";
// import { useCallback, useState } from "react";
// import { DragDropContext, Droppable } from "react-beautiful-dnd";
// Images
import SiteFooter from "layouts/Rewards/User/components/Common/SiteFooter";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
// useState
import { useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import MDInput from "components/MDInput";
import URLSettings from "layouts/Rewards/User/components/Common/URLSettings";
import Loader from "layouts/Rewards/User/components/Common/Loader";
import MDSnackbar from "components/MDSnackbar";
import Logout from "layouts/Rewards/User/components/Common/Logout";
import { txtColorChange, bgColorChange } from "layouts/Rewards/User/components/Common/ColorChange";
import CloseIcon from '@mui/icons-material/Close';
import DashboardMenu from "examples/Navbars/DashboardMenu";

const axios = require("axios").default;
const jose = require("jose");

// const style = {
//   position: "absolute",
//   top: "50%",
//   left: "50%",
//   transform: "translate(-50%, -50%)",
//   width: 500,
//   bgcolor: "#a8a9af",
//   border: "2px solid #000",
//   boxShadow: 24,
//   p: 4,
// };

function Banners() {
  let APIURL = URLSettings();
  const navigate = useNavigate();
  // const [banner, setBanner] = useState();

  const logout = () => {
    localStorage.removeItem("token");
    navigate("/login");
  };

  useEffect(() => {
    if (!localStorage.getItem("token")) {
      window.location.reload();
      return navigate("/login");
    }
  }, []);

  const [eMsg, setEMsg] = useState("");
  const [sendError, setSendError] = useState("");
  const [sendSuccess, setSendSuccess] = useState("");
  const toggleSnackbar = () => {
    if (sendError) {
      setSendError(!sendError);
    }
    if (sendSuccess) {
      setSendSuccess(!sendSuccess);
    }
  };
  useEffect(() => {
    setTimeout(() => {
      setSendSuccess(false);
      setSendError(false);
    }, 2000);
  }, [sendError, sendSuccess]);

  const claims = jose.decodeJwt(localStorage.getItem("token"));
  const bannersList = useQuery(["bannersList"], async () =>
    axios.get(`${APIURL.url}/admin/bannerList`, {
      params: {
        company_id: claims.company_id,
      },
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    })
  );

  if (bannersList.isError) {
    if (bannersList.error.response.status === 401) {
      window.location.reload();
      return navigate("/login");
    }
  }

  // Banner Update
  const [modal, setModal] = useState(false);
  const [fLocation, setFLocation] = useState("");
  const [fcode, setFCode] = useState("");
  const [bId, setBId] = useState("");
  const [link, setLink] = useState(null);
  const [bType, setBType] = useState(null);
  const [imageId, setImageId] = useState(null);
  const [bStatus, setBStatus] = useState(false);

  const handleEdit = (row) => {
    if (row.banner_type === "upper" || row.banner_type === "middle") {
      setFLocation("banners");
    } else if (row.banner_type === "like_button") {
      setFLocation("icons");
    }
    setFCode(row.code);
    setLink(row.link);
    setBId(row.id);
    setBType(row.banner_type);
    setImageId(row.image_id);
    setBStatus(row.active);
    setModal(true);
  };
  const handleClose = () => setModal(false);

  const [selectedFile, setSelectedFile] = useState(null);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (selectedFile != null) { 
      const formData = new FormData();
      formData.append("file", selectedFile);
      formData.append("href_url", link);
      formData.append("file_location", fLocation);
      formData.append("code", fcode);
      formData.append("banner_id", bId);
      formData.append("image_id", imageId);
      formData.append("banner_status", bStatus);

      try {
        const response = await axios({
            method: "post",
            url: `${APIURL.url}/admin/bannerUpdate`,
            data: formData,
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: localStorage.getItem("token"),
            },        
          });
  
        if (response) {
          handleClose();
          setSendSuccess(true);
          setEMsg(response.data);
          bannersList.refetch();
        }
      } catch (error) {
        setEMsg(response.data);
        setSendError(true);
        if (error.response.status === 401) {
          window.location.reload();
          return navigate("/login");
        }
      }

    } else {

      try {
        const  response = await axios({
            method: "post",
            url: `${APIURL.url}/admin/bannerUpdateText`,
            data: {
              href_url : link,
              banner_status: bStatus,
              banner_id: bId
            },
            headers: {
              "Content-Type": "application/json",
              Authorization: localStorage.getItem("token"),
            },        
          });
  
        if (response) {
          handleClose();
          setSendSuccess(true);
          setEMsg(response.data);
          bannersList.refetch();
        }
      } catch (error) {
        setEMsg(response.data);
        setSendError(true);
        if (error.response.status === 401) {
          window.location.reload();
          return navigate("/login");
        }
      }

    }
  };

  const handleFileSelect = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleBStatus = () => {
    setBStatus(!bStatus);
  };

  if (!bannersList.isSuccess) {
    return <Loader />;
  }

  let nodata;
  if (
    !bannersList.data.data.upper &&
    !bannersList.data.data.middle &&
    !bannersList.data.data.like_button
  ) {
    if (
      bannersList.data.data.upper.length < 0 ||
      bannersList.data.data.middle.length < 0 ||
      bannersList.data.data.like_button.length < 0
    ) {
      nodata = "No Data Available";
    }
  }

  return (
    <DashboardLayout>
      {/* <DashboardNavbar /> */}
      <DashboardMenu />
      <MDBox pt={2} pb={3}>
        <Card>
          <MDSnackbar
            color="error"
            icon="error"
            title="Error: Please try again"
            content={eMsg}
            open={sendError}
            close={toggleSnackbar}
          /> 
          <MDSnackbar
            color="success"
            icon="check_circle"
            title="Success"
            content={eMsg}
            open={sendSuccess}
            close={toggleSnackbar}
          />
          <MDBox p={3} lineHeight={1}>
            {/* <MDTypography variant="h5" fontWeight="medium">
              Banner Images
            </MDTypography> */}
            <MDTypography
              component="p"
              variant="button"
              fontWeight="bold"
              sx={{ textAlign: "center" }}
              mt={3}
              mb={3}
            >
              {nodata}
            </MDTypography>
            {bannersList.data.data.upper && (
              <>
                <MDTypography variant="h5" fontWeight="medium" mt={3} sx={txtColorChange}>
                  Upper Banners
                </MDTypography>
                <Grid container>
                  {bannersList.data.data.upper.map((banner) => (
                    <Grid item xs={12} md={3} lg={3}>
                      <MDBox m={2}>
                        <MDBox position="relative" height="max-content" mx="auto">
                        <Typography>{banner.image_id} - {banner.image_type_name}</Typography>
                          <MDAvatar
                            src={banner.image_location}
                            alt="profile picture"
                            size="xxl"
                            variant="rounded"
                          />
                          <MDBox
                            alt="spotify logo"
                            position="absolute"
                            left={0}
                            bottom={0}
                            mr={-1}
                            mb={-1}
                          >
                            <Tooltip title="Edit" placement="top">
                              <MDButton
                                variant="gradient"
                                color="info"
                                size="small"
                                iconOnly
                                onClick={() => handleEdit(banner)}
                                sx={bgColorChange}
                              >
                                <Icon>edit</Icon>
                              </MDButton>
                            </Tooltip>
                            <Tooltip title="Download" placement="top">
                              <a href={banner.image_location} target="_blank" sx={bgColorChange}>
                                <MDButton
                                  variant="gradient"
                                  color="info"
                                  size="small"
                                  iconOnly
                                  sx={bgColorChange}
                                  className="marginLeft"
                                >
                                  <Icon>download</Icon>
                                </MDButton>
                              </a>
                            </Tooltip>
                            <MDTypography sx={{ fontSize: "14px" }}>
                              {(banner.image_type_name === "Mobile Light" || banner.image_type_name === "Mobile Dark") ? (<>
                                (340*150)
                              </>) : (<>
                                (1150*150)
                              </>)}                              
                            </MDTypography>
                          </MDBox>
                        </MDBox>
                      </MDBox>
                    </Grid>
                  ))}
                </Grid>
              </>
            )}
            {bannersList.data.data.middle && (
              <>
                <MDTypography variant="h5" fontWeight="medium" mt={8} sx={txtColorChange}>
                  Footnote Banners
                </MDTypography>
                <Grid container>
                  {bannersList.data.data.middle.map((banner) => (
                    <Grid item xs={12} md={3} lg={3}>
                      <MDBox m={2}>
                        <MDBox position="relative" height="max-content" mx="auto">
                          <Typography>{banner.image_id} - {banner.image_type_name}</Typography>
                          <MDAvatar
                            src={banner.image_location}
                            alt="profile picture"
                            size="xxl"
                            variant="rounded"
                          />
                          <MDBox
                            alt="spotify logo"
                            position="absolute"
                            left={0}
                            bottom={0}
                            mr={-1}
                            mb={-1}
                          >
                            <Tooltip title="Edit" placement="top">
                              <MDButton
                                variant="gradient"
                                color="info"
                                size="small"
                                iconOnly
                                onClick={() => handleEdit(banner)}
                                sx={bgColorChange}
                              >
                                <Icon>edit</Icon>
                              </MDButton>
                            </Tooltip>
                            <Tooltip title="Download" placement="top">
                              <a href={banner.image_location} target="_blank" sx={bgColorChange}>
                                <MDButton
                                  variant="gradient"
                                  color="info"
                                  size="small"
                                  iconOnly
                                  sx={bgColorChange}
                                  className="marginLeft"
                                >
                                  <Icon>download</Icon>
                                </MDButton>
                              </a>
                            </Tooltip>
                            <MDTypography sx={{ fontSize: "14px" }}>
                              (360*100)                        
                            </MDTypography>
                          </MDBox>
                        </MDBox>
                      </MDBox>
                    </Grid>
                  ))}
                </Grid>
              </>
            )}
            {bannersList.data.data.like_button && (
              <>
                <MDTypography variant="h5" fontWeight="medium" mt={8} sx={txtColorChange}>
                  Like Button
                </MDTypography>
                <Grid container>
                  {bannersList.data.data.like_button.map((banner) => (
                    <Grid item xs={12} md={2} lg={2}>
                      <MDBox m={2}>
                        <MDBox position="relative" height="max-content" mx="auto">
                          <MDAvatar
                            src={banner.image_location}
                            alt="profile picture"
                            size="xxl"
                            variant="rounded"
                          />
                          <MDBox
                            alt="spotify logo"
                            position="absolute"
                            left={0}
                            bottom={0}
                            mr={-1}
                            mb={-1}
                          >
                            <Tooltip title="Edit" placement="top">
                              <MDButton
                                variant="gradient"
                                color="info"
                                size="small"
                                iconOnly
                                onClick={() => handleEdit(banner)}
                                sx={bgColorChange}
                              >
                                <Icon>edit</Icon>
                              </MDButton>
                            </Tooltip>
                            <Tooltip title="Download" placement="top">
                              <a href={banner.image_location} target="_blank" sx={bgColorChange}>
                                <MDButton
                                  variant="gradient"
                                  color="info"
                                  size="small"
                                  iconOnly
                                  sx={bgColorChange}
                                  className="marginLeft"
                                >
                                  <Icon>download</Icon>
                                </MDButton>
                              </a>
                            </Tooltip>
                          </MDBox>
                        </MDBox>
                      </MDBox>
                    </Grid>
                  ))}
                </Grid>
              </>
            )}
          </MDBox>
        </Card>
      </MDBox>

      <Modal
        open={modal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="modalPopupBox">
          <Grid container>
            <Grid item xs={6}>
              <Typography id="keep-mounted-modal-title" variant="h5" component="h2" sx={txtColorChange}>
                Update Banner
              </Typography>
              <MDBox variant="outlined" className="close-button" onClick={handleClose}><CloseIcon /></MDBox>
            </Grid>
          </Grid>
          <MDBox component="form" role="form" onSubmit={handleSubmit}>
            <MDBox mt={2} mb={2}>
              Banner Type : {bType}
            </MDBox>
            <MDBox mt={2} mb={2}>
              <span fontSize="small">Upload Banner</span>
              <MDInput type="file" fullWidth onChange={handleFileSelect} />
            </MDBox>
            <MDBox mt={2} mb={2}>
              <MDInput
                type="text"
                fullWidth
                placeholder="Link"
                value={link}
                onChange={(e) => {
                  setLink(e.target.value);
                }}
              />
            </MDBox>
            <MDBox mt={2} mb={2}>
              <MDTypography
                variant="button"
                fontWeight="regular"
                color="text"
                sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
              >
                &nbsp;&nbsp;Banner Status :&nbsp;
              </MDTypography>
              <Switch checked={bStatus} onChange={handleBStatus} />
            </MDBox>
            <MDBox mt={2} mb={1}>
              <MDButton type="submit" variant="gradient" color="info" sx={bgColorChange}>
                Submit
              </MDButton>
            </MDBox>
          </MDBox>
        </Box>
      </Modal>

      <SiteFooter />
    </DashboardLayout>
  );
}

Banners.propTypes = {};

export default Banners;
