/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-routers components
import { Link } from "react-router-dom";

// prop-types is library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import MDButton from "components/MDButton";
import { Avatar, Icon } from "@mui/material";
import { txtColorChange } from "layouts/Rewards/User/components/Common/ColorChange";
// import { useState } from "react";

function ProfilesList({ title, profiles, shadow, event_type }) {

  // const [isVisible, setVisible] = useState(false);
  // const handlePopup = () => {
  //   setVisible(true);
  // }

  // {(isVisible) ? <p>Hello World</p> : ""}

  // <span  href="#" id="login" class="btn btn-default" >Click me</span>

  let renderProfiles;
  if (event_type === "birthday") {
    renderProfiles = profiles.map(({ id, emp_id, profile_pic, first_name, last_name, dob }) => (
      <Link to={"/giving?id="+ id + '&emp_id=' + emp_id + '&first_name=' + first_name + "&last_name=" + last_name}>
        <MDBox
          key={`${first_name} ${last_name}`}
          component="li"
          display="flex"
          alignItems="center"
          py={1}
        >
          <MDBox mr={2}>
            {profile_pic ? (
              <MDAvatar
                src={profile_pic}
                alt={`${first_name.slice(0, 1)}${last_name.slice(0, 1)}`}
                size="xs"
                shadow="md"
              />
            ) : (
              <Avatar
                sx={{
                  bgcolor: "#ccc",
                  marginRight: "5px",
                  textTransform: "uppercase",
                  width: "24px",
                  height: "24px",
                  fontSize: "14px",
                }}
              >
                {`${first_name.slice(0, 1)}${last_name.slice(0, 1)}`}
              </Avatar>
            )}
          </MDBox>
          <MDBox
            display="flex"
            flexDirection="column"
            alignItems="flex-start"
            justifyContent="center"
          >
            <MDTypography variant="button" fontWeight="medium" sx={{ fontSize: "12px" }}>
              {`${first_name} ${last_name}`}
            </MDTypography>
          </MDBox>
          <MDBox ml="auto" sx={txtColorChange} className="celebrationIcon">
            <Icon>cake</Icon> {dob}
          </MDBox>
        </MDBox>
      </Link>
    ));
  } else if (event_type === "anniversary") {
    renderProfiles = profiles.map(({ id, emp_id, profile_pic, first_name, last_name, hire_date }) => (
      <Link to={"/giving?id="+ id + '&emp_id=' + emp_id + '&first_name=' + first_name + "&last_name=" + last_name}>
      <MDBox
        key={`${first_name} ${last_name}`}
        component="li"
        display="flex"
        alignItems="center"
        py={1}
      >
        <MDBox mr={2}>
          {profile_pic ? (
            <MDAvatar
              src={profile_pic}
              alt={`${first_name.slice(0, 1)}${last_name.slice(0, 1)}`}
              size="xs"
              shadow="md"
            />
          ) : (
            <Avatar
              sx={{
                bgcolor: "#ccc",
                marginRight: "5px",
                textTransform: "uppercase",
                width: "24px",
                height: "24px",
                fontSize: "14px",
              }}
            >
              {`${first_name.slice(0, 1)}${last_name.slice(0, 1)}`}
            </Avatar>
          )}
        </MDBox>
        <MDBox
          display="flex"
          flexDirection="column"
          alignItems="flex-start"
          justifyContent="center"
        >
          <MDTypography variant="button" fontWeight="medium" sx={{ fontSize: "12px" }}>
            {`${first_name} ${last_name}`}
          </MDTypography>
        </MDBox>
        <MDBox ml="auto" sx={txtColorChange} className="celebrationIcon">
        {/* onClick={() => handlePopup()} */}
          <Icon>celebration</Icon> {hire_date}
        </MDBox>
      </MDBox>
      </Link>
    ));
  }

  return (
    <Card sx={{ height: "100%", boxShadow: !shadow && "none" }}>
      <MDBox pt={2} px={2}>
        <MDTypography variant="h6" fontWeight="medium" textTransform="uppercase" sx={txtColorChange} className="fontFamily">
          {title}
        </MDTypography>
      </MDBox>
      <MDBox p={2}>
        <MDBox component="ul" display="flex" flexDirection="column" p={0} m={0}>
          {renderProfiles}          
        </MDBox>
        {/* {isVisible && ( */}
        {/* <MDBox id="popover-content">
          sdsdf
            <div className="row">
            <div className="col-xs-4">
              <img width="88px" height="88px"src="https://pbs.twimg.com/profile_images/816404989392211968/Wv_8ZDrX_400x400.jpg"/>
            </div>
            <div className="col-xs-8">
              <h4>
              Justin Trudeau <br/>
              <small>
                Prime minister of Canada
              </small>
              </h4>
              <button className="btn btn-sm btn-primary">
              Follow
              </button>
              <button className="btn btn-sm btn-default">
              View profile
              </button>         
            </div>
        </MDBox> */}
        {/* )} */}
      </MDBox>
    </Card>
  );
}

// Setting default props for the ProfilesList
ProfilesList.defaultProps = {
  shadow: true,
};

// Typechecking props for the ProfilesList
ProfilesList.propTypes = {
  title: PropTypes.string.isRequired,
  profiles: PropTypes.arrayOf(PropTypes.object).isRequired,
  shadow: PropTypes.bool,
  event_type: PropTypes.string.isRequired,
};

export default ProfilesList;
