/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";
import { useMutation } from "react-query";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
// import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";

// Images
// import bgImage from "assets/images/bg-sign-in-basic.jpeg";
import MDSnackbar from "components/MDSnackbar";
// import APIURL from "../User/Constants";
import MDTypography from "components/MDTypography";
import Loader from "../User/components/Common/Loader";
import URLSettings from "../User/components/Common/URLSettings";
import NotFound from "../User/components/Common/NotFound";
import OutlinedInput from '@material-ui/core/OutlinedInput';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import { IconButton } from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
const jose = require("jose");

const useStyles = makeStyles(theme => ({
  bootstrapRoot: {
    padding: 0,
    'label + &': {
      marginTop: theme.spacing(1) * 3,
    },
  },
  bootstrapPasswordInput: {
    borderRadius: 7,
    backgroundColor: theme.palette.common.white,
    border: '1px solid #ced4da',
    fontSize: "14px !important",
    padding: '10px 12px !important',
    height: "20px !important",
  },
  bootstrapInput: {
    height: "25px !important"
  },
  bootstrapFormLabel: {
    visibility: "visible !important"
  },
  inputLabel: {
    fontSize: "12px",
    marginBottom: "5px"
  },
  viewIcon: {
    fontSize: "14px !important"
  }
}));

function Login() {
  const classes = useStyles(); 
  let APIURL = URLSettings();

  if (APIURL.url === null) {
    return <NotFound />;
  }

  const [logo, setLogo] = useState(0);
  const [eMsg, setEMsg] = useState("");
  const [employeeID, setEmployeeID] = useState(null);
  const [password, setPassword] = useState("");
  const [loginError, setLoginError] = useState(false);
  const navigate = useNavigate();

  // useEffect(() => {
  //   localStorage.clear();
  // }, []);

  const login = useMutation(
    async () =>
      axios.post(`${APIURL.url}/login`, {
        employee_id: employeeID,
        password: password,
      }),
    {
      onError: (err) => {
        setLoginError(true);
        setEMsg(err.response.data);
      },
      onSuccess: (data) => {
        // save token
        localStorage.setItem("token", data.data.token);
        localStorage.setItem("admin", data.data.admin);
        localStorage.setItem("personal_balance", data.data.personal_balance);
        localStorage.setItem("profilepic", data.data.profile_pic);
        var token = data.data.token;
        const claims = jose.decodeJwt(localStorage.getItem("token"));
        localStorage.setItem("userid", claims.userid);
        localStorage.setItem("jobtitle", claims.job_title);
        if (token) {
          getPermissions.mutate({ token });
          settings.mutate({ token });
        }
      },
    }
  );

  const getPermissions = useMutation(
    async () =>
      axios.get(`${APIURL.url}/getPermissions?u_id=`+localStorage.getItem("userid")+`&jobtitle=`+localStorage.getItem("jobtitle"), {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }),
    {
      onError: () => {        
      },
      onSuccess: (data) => {
        if (data.data.length > 0) {
          localStorage.setItem("permissions", JSON.stringify(data.data));
        } else {
          localStorage.setItem("permissions", JSON.stringify([]));
        }
        
      },
    }
  );  

  const settings = useMutation(
    async () =>
      axios.get(`${APIURL.url}/admin/settings`, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }),
    {
      onError: () => {
        setLogo("");
      },
      onSuccess: (data) => {
        // save token
        localStorage.setItem("settings", JSON.stringify(data.data.rows));
        // redirect to dashboard
        navigate("/home");
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      },
    }
  );

  async function fetchLogoData() {
    try {
      const result = await axios.get(`${APIURL.url}/settings`, {
        params: { setting_name: "login_screen_logo" },
      });
      setLogo("");
      if (result.data.logo) {
        setLogo(result.data.logo);
      }
    } catch (error) {
      // console.error(error);
      setLogo("");
      <NotFound />;
    }
  }

  const [singin, setSignin] = useState(false);
  async function fetchSigninType() {
    try {
      const result = await axios.get(`${APIURL.url}/settings`, {
        params: { setting_name: "sign_in_type" },
      });
      if (result.data.logo) {
        setSignin(result.data.logo);
      }
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    fetchLogoData();
    fetchSigninType();
  }, []);

  const toggleSnackbar = () => {
    if (loginError) {
      setLoginError(!loginError);
    }
  };
  useEffect(() => {
    setTimeout(() => {
      setLoginError(false);
    }, 2000);
  }, [loginError]);

  const handleLogin = (e) => {
    e.preventDefault();
    if (employeeID === "" && password === "") {
      e.preventDefault();
      setLoginError(true);
    } else {
      login.mutate();
    }
  };

  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  if (login.isLoading === true || settings.isLoading === true) {
    return <Loader />;
  }

  return (
    <>
      {(logo === 0 && <Loader />) ||
        (logo === "" && <NotFound />) ||
        (logo && (
          <BasicLayout>
            <MDSnackbar
              color="error"
              icon="error"
              title="Error: Unable to Login"
              content={eMsg}
              open={loginError}
              close={toggleSnackbar}
            />
            <Card>
              <MDBox
                variant="gradient"
                className="loginLogo"
                borderRadius="lg"
                coloredShadow="info"
                mx={2}
                mt={-3}
                p={2}
                mb={1}
                textAlign="center"
              >
                <img src={logo} alt="Wireless Vision" width={100} />
              </MDBox>
              <MDBox pt={4} pb={3} px={3}>
                <MDBox component="form" role="form" onSubmit={handleLogin}>
                  {/* {APIURL.code === "wirelessvision" ? ( */}
                  {(singin === "SSO") && (
                    <MDBox mb={2} className="loginPassword">
                      <InputLabel className={classes.inputLabel} htmlFor="standard-adornment-password">Employee Id</InputLabel> 
                      <OutlinedInput
                        id="standard-adornment-password"
                        type='text'             
                        placeholder='Enter Employee Id'
                        fullWidth
                        InputProps={{
                          disableUnderline: true,
                          classes: {
                            root: classes.bootstrapRoot,
                            input: classes.bootstrapPasswordInput
                          },
                        }}
                        InputLabelProps={{
                          shrink: true,
                          className: classes.bootstrapFormLabel,
                        }}
                        value={employeeID}
                        onChange={(e) => setEmployeeID(e.target.value)}
                      />
                    </MDBox>
                  )}
                  {(singin === "PHONE") && (
                    <MDBox mb={2} className="loginPassword">
                      <InputLabel className={classes.inputLabel} htmlFor="standard-adornment-password">Phone Number</InputLabel> 
                      <OutlinedInput
                        id="standard-adornment-password"
                        type='text'             
                        placeholder='Enter Phone Number'
                        fullWidth
                        InputProps={{
                          disableUnderline: true,
                          classes: {
                            root: classes.bootstrapRoot,
                            input: classes.bootstrapPasswordInput
                          },
                        }}
                        InputLabelProps={{
                          shrink: true,
                          className: classes.bootstrapFormLabel,
                        }}
                        value={employeeID}
                        onChange={(e) => setEmployeeID(e.target.value)}
                      />
                    </MDBox>
                  )}
                  {(singin === "EMAIL") && (
                    <MDBox mb={2} className="loginPassword">
                      <InputLabel className={classes.inputLabel} htmlFor="standard-adornment-password">Email Id</InputLabel> 
                      <OutlinedInput
                        id="standard-adornment-password"
                        type='text'             
                        placeholder='Enter Email Id'
                        fullWidth
                        InputProps={{
                          disableUnderline: true,
                          classes: {
                            root: classes.bootstrapRoot,
                            input: classes.bootstrapPasswordInput
                          },
                        }}
                        InputLabelProps={{
                          shrink: true,
                          className: classes.bootstrapFormLabel,
                        }}
                        value={employeeID}
                        onChange={(e) => setEmployeeID(e.target.value)}
                      />
                    </MDBox>
                  )}
                  <MDBox mb={2} className="loginPassword">
                    <InputLabel className={classes.inputLabel} htmlFor="standard-adornment-password">Password</InputLabel> 
                    <OutlinedInput
                      id="standard-adornment-password"
                      // label="Password"
                      type={showPassword ? 'text' : 'password'}               
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showPassword ? <VisibilityIcon className={classes.viewIcon} /> : <VisibilityOffIcon className={classes.viewIcon} />}
                          </IconButton> 
                        </InputAdornment>
                      }
                      placeholder='Enter your password'
                      fullWidth
                      InputProps={{
                        disableUnderline: true,
                        classes: {
                          root: classes.bootstrapRoot,
                          input: classes.bootstrapPasswordInput
                        },
                      }}
                      InputLabelProps={{
                        shrink: true,
                        className: classes.bootstrapFormLabel,
                      }}
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                    {/* <MDInput
                      type="password"
                      label="Password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      fullWidth
                    /> */}
                  </MDBox>
                  <MDBox mt={4} mb={1}>
                    <MDButton type="submit" variant="gradient" color="info">
                      sign in
                    </MDButton>
                    {(singin === "SSO") && (
                      <a href="https://mds.wirelessvision.com/reset" target="_blank">
                        <MDTypography
                          variant="button"
                          fontWeight="regular"
                          color="text"
                          sx={{ cursor: "pointer", userSelect: "none", mt: 1, float: "right", textAlign: "center" }}
                        >
                          Create New Password <br />Reset Password
                        </MDTypography>
                      </a>
                    )}
                    {(singin === "PHONE") && (
                      <Link to="/new-resetpassword">
                        <MDTypography
                          variant="button"
                          fontWeight="regular"
                          color="text"
                          sx={{ cursor: "pointer", userSelect: "none", mt: 1, float: "right", textAlign: "center" }}
                        >
                          Create New Password <br />Reset Password
                        </MDTypography>
                      </Link>
                    )}
                    {(singin === "EMAIL") && (
                      <Link to="/resetpassword">
                        <MDTypography
                          variant="button"
                          fontWeight="regular"
                          color="text"
                          sx={{ cursor: "pointer", userSelect: "none", mt: 1, float: "right", textAlign: "center" }}
                        >
                          Create New Password <br />Reset Password
                        </MDTypography>
                      </Link>
                    )}
                  </MDBox>
                </MDBox>
              </MDBox>
            </Card>
          </BasicLayout>
        ))}
    </>
  );
}

export default Login;
