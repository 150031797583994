/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";

// react-router components
import { Routes, Route, Navigate, useLocation, useNavigate } from "react-router-dom";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
// import Icon from "@mui/material/Icon";

// Material Dashboard 2 PRO React components
// import MDBox from "components/MDBox";

// Material Dashboard 2 PRO React examples
import Sidenav from "examples/Sidenav";

// Material Dashboard 2 PRO React themes
import theme from "assets/theme";
// import themeRTL from "assets/theme/theme-rtl";

// Material Dashboard 2 PRO React Dark Mode themes
import themeDark from "assets/theme-dark";
// import themeDarkRTL from "assets/theme-dark/theme-rtl";

// RTL plugins
// import rtlPlugin from "stylis-plugin-rtl";
// import { CacheProvider } from "@emotion/react";
// import createCache from "@emotion/cache";

// Material Dashboard 2 PRO React routes
import routes from "routes";
import adminroutes from "adminroutes";

// Material Dashboard 2 PRO React contexts
import { useMaterialUIController, setMiniSidenav } from "context";

// Images
// import brandWhite from "assets/images/WV/high-fived.png";

// react query
import { QueryClient, QueryClientProvider } from "react-query";
import SEO from "layouts/Rewards/User/components/Common/SEO";
// import APIURL from "layouts/Rewards/User/Constants";
import axios from "axios";
import ResetPassword from "layouts/Rewards/ResetPassword";
import ConfirmPassword from "layouts/Rewards/ConfirmPassword";
import URLSettings from "layouts/Rewards/User/components/Common/URLSettings";
import { Icon } from "@mui/material";
import OrderCart from "layouts/Rewards/User/components/OrderCart";
import Checkout from "layouts/Rewards/User/components/Checkout";
// import MagicParticles from "layouts/Rewards/User/components/Common/MagicParticles";
import Logout from "layouts/Rewards/User/components/Common/Logout";
import Chat from "layouts/Rewards/User/components/Common/Chat";
import NewCelebration from "layouts/Rewards/User/components/Common/Comments/NewCelebration";
import NewFeeds from "layouts/Rewards/User/components/Common/NewCelebrations/NewFeeds";
import MLogin from "layouts/Rewards/MLogin";
import NewResetPassword from "layouts/Rewards/NewResetPassword";
import OtpVerification from "layouts/Rewards/OtpVerification";
import MainLayout from "MainLayout";
import Login from "layouts/Rewards/Login";
import UserView from "layouts/Rewards/User";

export default function App() {

  let APIURL = URLSettings();
  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav, direction, layout, sidenavColor, darkMode } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  // const [rtlCache, setRtlCache] = useState(null);
  const { pathname } = useLocation();

  const [logo, setLogo] = useState();
  const [fav, setFav] = useState();

  async function fetchLogoData() {
    try {
      const result = await axios.get(`${APIURL.url}/settings`, {
        params: { setting_name: "side_bar_logo" },
      });
      setLogo(result.data.logo);
    } catch (error) {
      // console.error(error);
      // window.location.reload();

    }
  }
  async function fetchFaviconData() {
    try {
      const result = await axios.get(`${APIURL.url}/settings`, {
        params: { setting_name: "login_screen_fav_icon" },
      });
      setFav(result.data.logo);
      localStorage.setItem("loaderIcon", result.data.logo);
    } catch (error) {
      // console.error(error);
      // window.location.reload();
    }
  }

  const navigate = useNavigate();
  const location = useLocation();

  const locations = ["/resetpassword", "/confirmpassword", "/new-resetpassword", "/otp-verification"];
  useEffect(() => {
    if (!locations.includes(location.pathname)) {
      if (!localStorage.getItem("token")) {
        return navigate("/login");      
      }
    }
  }, []);

  useEffect(() => {
    fetchLogoData();
    fetchFaviconData();
  }, []);

  // Cache for the rtl
  // useMemo(() => {
  //   const cacheRtl = createCache({
  //     key: "rtl",
  //     stylisPlugins: [rtlPlugin],
  //   });
  //
  //   setRtlCache(cacheRtl);
  // }, []);

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  // Change the openConfigurator state
  // const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);

  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  let adminkey;
  // let adminFlag = false;
  // if (localStorage.getItem("token")) {
  //   const jose = require("jose");
  //   const claims = jose.decodeJwt(localStorage.getItem("token"));
  //   if (claims.sp_admin === "yes" || claims.admin === "yes") {
  //     adminFlag = true;
  //   }
  //   if (claims.user_roles.includes('design')) {
  //     adminFlag = true;
  //   } 
  // }

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        return <Route exact path={route.route} element={route.component} key={route.key} />;
      }

      return null;
    });

  let sidebar;
  if (
    pathname === "/" ||
    pathname === "/home" ||
    pathname === "/my-rewards" ||
    pathname === "/giving" ||
    pathname === "/redemption" ||
    pathname === "/profile-pic" ||
    pathname === "/ordercart" ||
    pathname === "/checkout" || 
    pathname === "/chat" || 
    pathname === "/celebrations" || 
    pathname === "/new-celebrations" ||
    pathname === "/group-gifting"
  ) {
    adminkey = true;
    sidebar = (
      <Sidenav
        color={sidenavColor}
        brand={logo}
        brandName="Wireless Vision"
        routes={routes}
        onMouseEnter={handleOnMouseEnter}
        onMouseLeave={handleOnMouseLeave}
      />
    );
  } else if (
    pathname === "/admin" ||
    pathname === "/dashboard" ||
    pathname === "/transactions" ||
    pathname === "/audit-log" ||
    pathname === "/user-roles" ||
    pathname === "/users" ||
    pathname === "/banners" ||
    pathname === "/admin-settings" || 
    pathname === "/settings" ||
    pathname === "/message-templates" ||
    pathname === "/email-templates" ||
    pathname === "/layout" || 
    pathname === "/custom-giftcards" || 
    pathname === "/giftcards" ||
    pathname === "/automation" || 
    pathname === "/meshibalance" ||
    pathname === "/roles" ||
    pathname === "/modules" || 
    pathname === "/role-modules" || 
    pathname === "/employee-role-mapping"
  ) {
    adminkey = false;

    sidebar = (
      <Sidenav
        color={sidenavColor}
        brand={logo}
        brandName="Wireless Vision"
        routes={adminroutes}
        onMouseEnter={handleOnMouseEnter}
        onMouseLeave={handleOnMouseLeave}
      />
    );
  }

  const queryClient = new QueryClient();

  return (
    <>
      <SEO title={APIURL.name} description={APIURL.name} favicon={fav} touchicon={fav} />
      <QueryClientProvider client={queryClient}>
        <ThemeProvider theme={darkMode ? themeDark : theme}>
          <CssBaseline />
          {localStorage.getItem("token") ? <>{sidebar}</> : ""}
          <Routes>           
            <Route path="/login" element={<Login />} />
            <Route path="/logout" element={<Logout />} />
            <Route path="/resetpassword" element={<ResetPassword />} />
            <Route path="/confirmpassword" element={<ConfirmPassword />} />
            <Route path="/new-resetpassword" element={<NewResetPassword />} />
            <Route path="/otp-verification" element={<OtpVerification />} /> 

            <Route path="/mlogin" element={<MLogin />} />
            <Route path="/chat" element={<Chat />} />
            <Route path="/celebrations" element={<NewCelebration />} />
            {/* <Route path="*" element={<Navigate to="/login" />} /> */}
            {/* <Route path="/magic" element={<MagicParticles />} /> */}
            {/* <Route path="/new-celebrations" element={<NewFeeds />} /> */}
            {/* <Route path="/emoji" element={<EmojiMart />} /> */}
            {/* {domain === "wv" && <Route path="*" element={<Navigate to="/login" />} />} */} 
            {/* <Route path="/logout" element={<Logout />} /> */}
            
            <Route element={<MainLayout />}>
              {adminkey ? <>{getRoutes(routes)}</> : <>{getRoutes(adminroutes)}</>}
              <Route path="/" element={<UserView />} />
              <Route path="/new-celebrations" element={<NewFeeds />} />
              <Route path="/ordercart" element={<OrderCart />} />
              <Route path="/checkout" element={<Checkout />} />
              <Route path="*" element={<Navigate to="home" />} />
            </Route>
          </Routes>
        </ThemeProvider>
      </QueryClientProvider>
    </>
  );
}
