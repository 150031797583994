/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Icon from "@mui/material/Icon";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Timeline context
import { useTimeline } from "examples/Timeline/context";

// Custom styles for the TimelineItem
import timelineItem from "examples/Timeline/TimelineItem/styles";
import BasicModal from "layouts/Rewards/User/components/Common/ModalPopup";
import MDButton from "components/MDButton";
import { useState } from "react";
import MagicParticles from "layouts/Rewards/User/components/Common/MagicParticles";
import { transColorChange1, transColorChange2 } from "layouts/Rewards/User/components/Common/ColorChange";

function TimelineItem({ color, icon, title, dateTime, description, lastItem }) {

  let changeColor = 0;
  if (color === "success" || color === "info" || color === "primary") {
    changeColor = 1;
  } else if (color === "secondary") {
    changeColor = 2;
  }
  const isDark = useTimeline();

  const [show, setShow] = useState(false);
  const handlePopup = () => {
    setShow(true);
  };
  const handleClose = () => setShow(false);

  return (
    
    <MDBox position="relative" mb={3} sx={(theme) => timelineItem(theme, { lastItem, isDark })}>
      <MDBox
        display="flex"
        justifyContent="center"
        alignItems="center"
        bgColor={color}
        color="white"
        width="2rem"
        height="2rem"
        borderRadius="50%"
        position="absolute"
        top="8%"
        left="2px"
        zIndex={2}
        // sx={{ fontSize: ({ typography: { size } }) => size.sm }}
        sx={changeColor === 1 ? transColorChange1 : changeColor === 2 ? transColorChange2 : "" }
      >
        <Icon fontSize="inherit" sx={{ fontSize: ({ typography: { size } }) => size.sm }}>{icon}</Icon>
      </MDBox>
      <MDBox ml={5.75} pt={description ? 0.7 : 0.5} lineHeight={0} maxWidth="30rem" sx={{border: "1px solid #bfbfbf", borderRadius: "10px", paddingLeft: "5px"}}>
        <MDTypography variant="button" fontWeight="medium" color={isDark ? "white" : "dark"}>
          {title}
        </MDTypography>
        <MDBox mt={0.5} mb={1}>
          <MDTypography variant="caption" color={isDark ? "secondary" : "text"}>
            {dateTime}
          </MDTypography>
        </MDBox>
        <MDBox mb={1.5}>
          {description ? (
            <MDButton className="btnMessage btnBackground" onClick={handlePopup}>
              {description}
            </MDButton>
          ) : null}
          <BasicModal title={title} description={description} show={show} close={handleClose} />
        </MDBox>
      </MDBox>
    </MDBox>
    
  );
}

// Setting default values for the props of TimelineItem
TimelineItem.defaultProps = {
  color: "info",
  lastItem: false,
  description: "",
};

// Typechecking props for the TimelineItem
TimelineItem.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "dark",
    "light",
  ]),
  icon: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  dateTime: PropTypes.string.isRequired,
  description: PropTypes.string,
  lastItem: PropTypes.bool,
};

export default TimelineItem;
