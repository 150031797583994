
import { Box, Card, Grid, Icon, Modal, Tooltip, Typography } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import React, { useEffect, useState } from "react";
import ReactPlayer from "react-player";
import { bgColorChange, txtColorChange } from "../Common/ColorChange";
import CloseIcon from '@mui/icons-material/Close';
import MDInput from "components/MDInput";
import { isMobile, isTablet } from "react-device-detect";
import URLSettings from "../Common/URLSettings";
import axios from "axios";
import MDSnackbar from "components/MDSnackbar";
import { useMutation } from "react-query";

const VideoBlock = ({pageStatus}) => {
    
  let APIURL = URLSettings();
  const settings = localStorage.getItem("settings");
  var obj = JSON.parse(settings);
  let videoObj = obj.find((o) => o.Name === "activity_video");

  const [blockStatus, setBlockStatus] = useState(pageStatus);
  useEffect(() => {
    setBlockStatus(pageStatus);
  },[pageStatus])

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false); 
  const [preview, setPreview] = useState(false);
  const handleEdit = () => {
    handleOpen();
  }
  const handlePreview = () => {
    setPreview(true);
    handleClose();
  }

  const handleCancel = () => {
    setPreview(false);
    setVideoURL(videoObj.Value);
  }

  const [videoURL, setVideoURL] = useState(videoObj.Value);
  const [eMsg, setEMsg] = useState("");
  const [sendError, setSendError] = useState("");
  const [sendSuccess, setSendSuccess] = useState("");
  const toggleSnackbar = () => {
    if (sendError) {
      setSendError(!sendError);
    }
    if (sendSuccess) {
      setSendSuccess(!sendSuccess);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      setSendSuccess(false);
      setSendError(false);
    }, 2000);
  }, [sendError, sendSuccess]); 

  const reloadSettings = useMutation(
    async () =>
      axios.get(`${APIURL.url}/admin/settings`, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }),
    {
      onError: (error) => {
      },
      onSuccess: (data) => {
        localStorage.setItem("settings", JSON.stringify(data.data.rows));
        window.location.reload();
      },
    }
  );

  const handleSubmit = async (event) => {
    event.preventDefault(); 
    let jsonData = {
        "data": [
            {
                "s_id": videoObj.id,
                "value": videoURL,
                "status": true
            },
        ]
    }
    try { 
      const response = await axios({
        method: "post",
        url: `${APIURL.url}/admin/settings`,
        data: jsonData,
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("token"),
        },
      });
      if (response) {
        reloadSettings.mutate();
        setEMsg(response.data);
        setSendSuccess(true);
        setPreview(false);
      }
    } catch (error) {
      setEMsg(error.response.data);
      setSendError(true);
      setPreview(false);
      if (error.response.status === 401) {
        window.location.reload();
        return navigate("/login");
      }
    }
  }

  const style = {
    backgroundImage: 'none', /* Remove the default background image */
    backgroundSize: 'contain',
  };

  let playerHeight = "400px";
  if (isMobile) {
    playerHeight = "100%";
  }
  if (isTablet) {
    playerHeight = "380px"
  }

  return (<>
    <Card sx={{
      marginBottom: "5px",
    }}>
      <MDSnackbar
        color="error"
        icon="error"
        title="Error: Please try again"
        content={eMsg}
        open={sendError}
        close={toggleSnackbar}
      />
      <MDSnackbar
        color="success"
        icon="check_circle"
        title="Success"
        content="Settings Updated"
        open={sendSuccess}
        close={toggleSnackbar}
      />
      {blockStatus && (
      <MDBox sx={{ position: "absolute", right: 0, top: 0, zIndex: 99}}>
        {preview ? (<>
          <Tooltip title="Cancel" placement="top"> 
            <MDButton
              variant="gradient"
              color="info"
              size="small"
              iconOnly
              onClick={handleCancel}
              sx={bgColorChange}
            >
              <Icon>close</Icon>
            </MDButton>  
          </Tooltip> 
          <Tooltip title="Save" placement="top">
            <MDButton
              variant="gradient"
              color="info"
              size="small"
              iconOnly
              onClick={handleSubmit}
              sx={bgColorChange}
            >
              <Icon>done</Icon>
            </MDButton>  
          </Tooltip>  
        </>) : (
        <Tooltip title="Edit" placement="top">
          <MDButton
            variant="gradient"
            color="info"
            size="small"
            iconOnly
            onClick={handleEdit}
            sx={bgColorChange} 
          >
            <Icon>edit</Icon>
          </MDButton>  
        </Tooltip>  
        )}
      </MDBox>
      )}
      <MDBox p={2}>          
        {/* <ReactPlayer url={videoURL} width="100%" height={isMobile ? "200px" : "400px"} controls="true" className="ytp-cued-thumbnail-overlay-image" style={{ backgroundPosition: "contain" }} /> */}
        <ReactPlayer url={videoURL} width="100%" height={playerHeight}>
          <div className="react-player-controls">
            <img className="react-player-thumbnail" alt="Thumbnail" style={style} />
          </div>
        </ReactPlayer>
      </MDBox>
    </Card>

    <Modal
    open={open}
    onClose={handleClose}
    aria-labelledby="modal-modal-title"
    aria-describedby="modal-modal-description"
    >
    <Box className="modalPopupBox">
      <Grid container>
        <Grid item xs={12}>
          <Typography id="keep-mounted-modal-title" variant="h5" component="h2" sx={txtColorChange}> 
            Edit Video URL
          </Typography>
          <MDBox variant="outlined" className="close-button" onClick={handleClose}><CloseIcon /></MDBox> 
        </Grid>
      </Grid>
      <MDBox component="form" role="form">
        <Grid container>
          <Grid item xs={12}>
            <MDBox mt={2} mb={2} mr={1}>
              <MDInput
                type="text"
                label="Video URL"
                fullWidth
                placeholder="Video URL"
                value={videoURL}
                onChange={(e) => {
                  setVideoURL(e.target.value);
                }}
              />
            </MDBox>
          </Grid>
        </Grid>
        <MDBox mt={2} mb={1}>
          <MDButton type="button" variant="gradient" color="info" sx={bgColorChange} onClick={handlePreview}>
            Preview
          </MDButton>
        </MDBox>
      </MDBox>
    </Box> 
    </Modal>
  </>);
};

export default VideoBlock;
