import MDBox from "components/MDBox";
import React from "react";
import "./Loader.css";

function Loader() {
  return (
    <MDBox
      className="loading-spinner"
      sx={{
        backgroundImage: `url(${localStorage.getItem("loaderIcon")})`,
        backgroundPosition: "center",
        backgroundSize: "contain",
        backgroundRepeat: "no-repeat",
      }}
    ></MDBox>
  );
}

export default Loader;
