import React from "react";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";

function NotFound() {
  return (
    <>
      <BasicLayout>
        <img src="notfound.png" alt="Wireless Vision" className="imgShadow" />
      </BasicLayout>
    </>
  );
}

NotFound.propTypes = {};

export default NotFound;
