/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-routers components
import { Link } from "react-router-dom";

// prop-types is library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import MDButton from "components/MDButton";
import { Avatar, Icon, Modal, Tooltip, Typography, Box, Grid, Switch } from "@mui/material";
import { txtColorChange, bgColorChange } from "layouts/Rewards/User/components/Common/ColorChange";
import { useEffect, useState } from "react";
import { useMutation } from "react-query";
import URLSettings from "layouts/Rewards/User/components/Common/URLSettings";
import MDSnackbar from "components/MDSnackbar";
import CloseIcon from '@mui/icons-material/Close';
import MDInput from "components/MDInput";
import axios from "axios";

function ListView({ title, profiles, shadow, pageStatus }) {

  let APIURL = URLSettings(); 
  const settings = localStorage.getItem("settings");
  let defaultValue = { id: "", Name: "", Value: "", Status: false };

  let lboard = defaultValue;
  if (settings && settings !== undefined) {
    var obj = JSON.parse(settings);
    lboard = obj.find((o) => o.Name === "leaderboard_amount");

    if (defaultValue === undefined) {
      lboard = defaultValue;
    }
  }

  const [leaderVal, setLeaderVal] = useState(lboard.Value);
  const [blockStatus, setBlockStatus] = useState(pageStatus);
  useEffect(() => {
    setBlockStatus(pageStatus);
  },[pageStatus])

  const [status, setStatus] = useState();
  useEffect(() => {
    if (leaderVal === "yes") {
      setStatus(true);
    } else {
      setStatus(false);
    }
  },[leaderVal])
  const handleStatus = () => {
    setStatus(!status);
    if (status) {
      setLeaderVal('no');
    } else {
      setLeaderVal('yes');
    }
  };

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setLeaderVal(lboard.Value);
    setOpen(false); 
  } 
  const handleEdit = (val) => {
    handleOpen();
  }
  const [eMsg, setEMsg] = useState("");
  const [sendError, setSendError] = useState("");
  const [sendSuccess, setSendSuccess] = useState("");
  const toggleSnackbar = () => {
    if (sendError) {
      setSendError(!sendError);
    }
    if (sendSuccess) {
      setSendSuccess(!sendSuccess);
    }
  };

  const reloadSettings = useMutation(
    async () =>
      axios.get(`${APIURL.url}/admin/settings`, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }),
    {
      onError: (error) => {
      },
      onSuccess: (data) => {
        localStorage.setItem("settings", JSON.stringify(data.data.rows));
        window.location.reload();
      },
    }
  );

  const handleSubmit = async (event) => {
    event.preventDefault(); 
    let jsonData = {
        "data": [
            {
              "s_id": lboard.id,
              "value": leaderVal,
              "status": true
            },
        ]
    }
    try { 
      const response = await axios({
        method: "post",
        url: `${APIURL.url}/admin/settings`,
        data: jsonData,
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("token"),
        },
      });
      if (response) {
        handleClose();
        setLeaderVal(leaderVal);
        reloadSettings.mutate();
        setEMsg(response.data);
        setSendSuccess(true);
      }
    } catch (error) {
      setLeaderVal(lboard.Value);
      setEMsg(error.response.data);
      setSendError(true);
      if (error.response.status === 401) {
        window.location.reload();
        return navigate("/login");
      }
    }
  }
  
  let renderProfiles = profiles.map(({ name, profile_pic, total }, key) => (
    <MDBox
      key={`${name}`}
      component="li"
      display="flex"
      alignItems="center"
      py={1}
    >
      <MDBox mr={2}>
        {profile_pic ? (
          <MDAvatar
            src={profile_pic}
            alt={`${name.slice(0, 1)}${name.slice(0, 1)}`}
            size="md"
            shadow="md"
          />
        ) : (
          <Avatar
            sx={{
              bgcolor: "#ccc",
              marginRight: "5px",
              textTransform: "uppercase",
              width: "50px",
              height: "50px",
              fontSize: "14px",
            }}
          >
            {`${name.slice(0, 1)}${name.slice(0, 1)}`}
          </Avatar>
        )}
      </MDBox>
      <MDBox
        display="flex"
        flexDirection="column"
        alignItems="flex-start"
        justifyContent="center"
      >
        <MDTypography variant="button" fontWeight="medium" sx={{ fontSize: "16px", color:"#344767 !important" }}>
          {`${name}`}
        </MDTypography>
      </MDBox>
      {lboard.Value === "yes" ? (
        <MDBox ml="auto" className="pointsColorChange" sx={txtColorChange}>
        {`${total}`}pts
        </MDBox>
      ) : (
        <MDBox ml="auto" className="pointsColorChange" sx={txtColorChange}>
        {key+1}
        </MDBox>
      )}
    </MDBox>
  ));
  

  return (
    <MDBox sx={{ height: "100%", boxShadow: !shadow && "none" }}>
      <MDSnackbar
        color="error"
        icon="error"
        title="Error: Please try again"
        content={eMsg}
        open={sendError}
        close={toggleSnackbar}
      />
      <MDSnackbar
        color="success"
        icon="check_circle"
        title="Success"
        content="Settings Updated"
        open={sendSuccess}
        close={toggleSnackbar}
      />
      {blockStatus && (
        <MDBox sx={{ position: "absolute", right: "15px", top: 0, zIndex: 99}}>
          <Tooltip title="Edit" placement="top">
            <MDButton
              variant="gradient"
              color="info"
              size="small"
              iconOnly
              onClick={handleEdit}
              sx={bgColorChange}  
            >
              <Icon>edit</Icon> 
            </MDButton>  
          </Tooltip> 
        </MDBox>
      )}
      <MDBox pt={2} px={2}>
        <MDTypography variant="h6" fontWeight="medium" textTransform="uppercase" sx={txtColorChange} className="fontFamily">
          {title}
        </MDTypography>
      </MDBox>
      <MDBox p={2}>
        <MDBox component="ul" display="flex" flexDirection="column" p={0} m={0}>
          {renderProfiles}
        </MDBox>
      </MDBox>
      {/* Modal Popup Starts */} 
      <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      >
      <Box className="modalPopupBox">
        <Grid container>
          <Grid item xs={12}> 
            <Typography id="keep-mounted-modal-title" variant="h5" component="h2" sx={txtColorChange}> 
              Display Points
            </Typography>
            <MDBox variant="outlined" className="close-button" onClick={handleClose}><CloseIcon /></MDBox> 
          </Grid>
        </Grid>
        <MDBox component="form" role="form">
          <Grid container>
            <Grid item xs={12}>
              <MDBox mt={2} mb={2} mr={1}> 
                <Switch checked={status} onChange={handleStatus} /> 
                {/* <MDInput
                  type="text"
                  label="Leader Board Amount"
                  fullWidth
                  placeholder="Leader Board Amount"
                  value={leaderVal}
                  onChange={(e) => {
                    setLeaderVal(e.target.value);
                  }}
                /> */}
              </MDBox>
            </Grid>
          </Grid>
          <MDBox mt={2} mb={1}>
            <MDButton type="button" variant="gradient" color="info" sx={bgColorChange} onClick={handleSubmit}>
              Submit
            </MDButton>
          </MDBox>
        </MDBox>
      </Box> 
      </Modal>
      {/* Modal Popup Ends */}
    </MDBox>
  );
}

// Setting default props for the ListView
ListView.defaultProps = {
  shadow: true,
};

// Typechecking props for the ListView
ListView.propTypes = {
  title: PropTypes.string.isRequired,
  profiles: PropTypes.arrayOf(PropTypes.object).isRequired,
  shadow: PropTypes.bool,
};

export default ListView;
