import React, { useEffect, useState } from "react";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardMenu from "examples/Navbars/DashboardMenu";
import SiteFooter from "../SiteFooter";

import MDBox from "components/MDBox";
import { Avatar, Card, Grid, Icon } from "@mui/material";
import MDTypography from "components/MDTypography";
import { txtColorChange, bgColorChange } from "../ColorChange";
import "./newfeeds.css";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import MDInput from "components/MDInput";
import MDAvatar from "components/MDAvatar";
import MDButton from "components/MDButton";
import burceMars from "assets/images/bruce-mars.jpg";
import feedBanners from "assets/images/image_not_available.png";
import { useMutation, useQuery } from "react-query";
const jose = require("jose");
import axios from "axios";
import URLSettings from "../URLSettings";
import Loader from "../Loader";
import EmojiPicker, {
  EmojiStyle,
  SkinTones,
  Theme,
  Categories,
  EmojiClickData,
  Emoji,
  SuggestionMode,
  SkinTonePickerLocation
} from "emoji-picker-react";
import InsertEmoticonIcon from '@mui/icons-material/InsertEmoticon';
import AttachmentIcon from '@mui/icons-material/Attachment';
import MDSnackbar from "components/MDSnackbar";
import TestChats from "./TestChats";
import FBPosts from "./FBPosts";
import { useNavigate } from "react-router-dom";
import { useMaterialUIController } from "context";
import CloseIcon from "@mui/icons-material/Close";
import ImageBlock1 from "./ImageBlock1";
import ImageBlock2 from "./ImageBlock2";
import CustomMentionsTextarea from "./CustomMentionsTextarea";
import MyComponent from "./MyComponent";
import Mention from "./Mentions/Mention";
import Permissions from "../Permissions";

const NewFeeds = () => {
  let APIURL = URLSettings(); 
  const claims = jose.decodeJwt(localStorage.getItem("token"));
  const navigate = useNavigate();

  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  let textColor = "darkColor";
  if (darkMode) {
      textColor = "lightColor";
  }

  let iconInfo;
  let profilPic = localStorage.getItem("profilepic");
  if (profilPic === "null") {
    iconInfo = (
      <Avatar
        size="sm"
        sx={{
          bgcolor: "#ccc",
          marginRight: "5px",
          textTransform: "uppercase",
        }}
      >{`${claims.first_name.slice(0, 1)}${claims.last_name.slice(0, 1)}`}</Avatar>
    );
  } else {
    iconInfo = (
      <MDAvatar
        src={profilPic}
        alt={`${claims.first_name.slice(0, 1)}${claims.last_name.slice(0, 1)}`}
        size="sm"
        bgColor="light"
      />
    );
  }

  const [eMsg, setEMsg] = useState("");
  const [sendError, setSendError] = useState("");
  const [sendSuccess, setSendSuccess] = useState("");
  const toggleSnackbar = () => {
    if (sendError) {
      setSendError(!sendError);
    }
    if (sendSuccess) {
      setSendSuccess(!sendSuccess);
    }
  };

  useEffect(() => {
    if (!localStorage.getItem("token")) {
      window.location.reload();
      return navigate("/login");
    }
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setSendSuccess(false);
      setSendError(false);
    }, 2000);
  }, [sendError, sendSuccess]);

  const [postText, setPostText] = useState("");
  const [fLocation, setFLocation] = useState("");
  const [startSelectedFile, setStartSelectedFile] = useState(null);
  const [parentId, setParentId] = useState(null); 

  const [showPicker, setShowPicker] = useState(false);
  const [chosenEmoji, setChosenEmoji] = useState(null);

  //Mention and HashTag elements starts here
  const [emailUsers, setEmailUsers] = useState([]);
  const [mentions, setMentions] = useState([]);
  const [mentionInput, setMentionInput] = useState('');
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [suggestedUsers, setSuggestedUsers] = useState([]);

  const userEmails = useMutation(
    async () =>
    axios.post(
      `${APIURL.url}/sendPostsUserEmail`,
      {
        userids: emailUsers,
      },
      {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }
    ),
    {
      onError: (err) => {
        if (err.response.status === 401) {
          window.location.reload();
          return navigate("/login");
        }
      },
      onSuccess: (response) => {   
        setEmailUsers([]);    
      }
    },
  );
  //Mention and HashTag elements ends here
  
  const handleStartFileSelect = (event) => {
    setStartSelectedFile(event.target.files[0]);
    setFLocation("celebration_posts");
  };

  const onEmojiClick = (emojiData: EmojiClickData, event: MouseEvent) => {
    setChosenEmoji(emojiData.unified);
    setPostText(postText + emojiData.emoji);
  }

  const showEmojiPicker = () => {
    setShowPicker(!showPicker);
  }

  const feeds = useQuery("feeds", async () =>
    axios.get(`${APIURL.url}/company/celebration`, {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    })
  );

  const handlePostFlag = (val) => {
    if (val) {
      feeds.refetch(); 
    }
  }

  const getUsersList = useQuery(["getUsersList"], async () =>
    axios.get(`${APIURL.url}/getUsers`, {
      params: {
        employee_id: '',
        email: '',
        u_id: ''
      },
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    })
  );



  const msgCheck = useMutation(
    async () =>
    axios.post(
      `${APIURL.url}/messageCheck`,
      {
        message: postText,
      },
      {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }
    ),
    {
      onError: (err) => {
        setEMsg(err.response.data);
        setSendError(true);
        if (err.response.status === 401) {
          logout();
        }
      },
      onSuccess: (response) => {
        if (response.data.clean === true) {
          // setPostText(response.data.message)
          handleSubmitPost.mutate();
        } else {
          setPostText(response.data.message)
          setEMsg('Your message contains  inappropriate language, which is replaced with *. Please rectify.');
          setSendError(true);
        }
      }
    },
  );

  const handleSubmit = (e) => {
    e.preventDefault();
    msgCheck.mutate();
  } 

  const handleSubmitPost = useMutation(async () => {
    // event.preventDefault(); 
    
    const formData = new FormData();
    formData.append("post_or_comment", postText);
    formData.append("post_file", startSelectedFile);
    formData.append("file_location", fLocation);
    formData.append("code", APIURL.code);
    if (parentId != null) {
      formData.append("parent_id", parentId);
    }

    try {
      const response = await axios({
          method: "post",
          url: `${APIURL.url}/company/celebration`,
          data: formData,
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: localStorage.getItem("token"),
          },        
        });

      if (response) {
        userEmails.mutate();
        setStartSelectedFile(null);
        setPostText("");
        setFLocation("");
        setParentId(null);
        setSendSuccess(true);
        setEMsg(response.data);
        feeds.refetch();
      }
    } catch (error) {
      setEMsg(error.message); 
      setSendError(true);
      if (error.response.status === 401) {
        window.location.reload();
        return navigate("/login");
      }
    }
  }
  );

  if (feeds.isError || getUsersList.isError) {
    if (feeds.error.response.status === 401) {
      window.location.reload();
      return navigate("/login");
    }
    if (getUsersList.error.response.status === 401) {
      window.location.reload();
      return navigate("/login");
    }
  }

  const [commentBlock, setCommentBlock] = useState(false);
  const handleComments = () => {
    setCommentBlock(!commentBlock);
  }

  //permissions
  let module_name = "Celebrations";
  let permission = Permissions(module_name); 

  //Mention and HashTag in textarea
  const handleContentChange = (e) => {
    const inputValue = e.target.value;
    setPostText(inputValue);

    const atIndex = inputValue.lastIndexOf('@');
    if (atIndex !== -1) {
      const query = inputValue.slice(atIndex + 1);
      setMentionInput(query);
      setSuggestedUsers(getUsersList.data.data.filter(user => user.first_name.toLowerCase().includes(query.toLowerCase())));
      setShowSuggestions(true);
    } else {
      setShowSuggestions(false);
    }
  };

  const handleAddMention = (user) => {
    setEmailUsers([...emailUsers, user]);
    const atIndex = postText.lastIndexOf('@');
    const newContent = postText.substring(0, atIndex) + `@${user.first_name} ` + postText.substring(atIndex + mentionInput.length + 1);
    setPostText(newContent);
    setMentions([...mentions, user.first_name]);
    setMentionInput('');
    setShowSuggestions(false);
  };

  const handleRemoveMention = (mentionToRemove) => {
    const updatedMentions = mentions.filter((mention) => mention !== mentionToRemove);
    setMentions(updatedMentions);
  };

  const renderMentions = () => {
    return mentions.map((mention, index) => (
      <Mention
        key={index}
        mention={mention}
        onClick={() => handleRemoveMention(mention)}
      />
    ));
  };

  const renderSuggestions = () => {
    if (!showSuggestions) {
      return null;
    }
      return (<>
        {suggestedUsers.length > 0 && (
        <ul className="suggestions">
          {suggestedUsers.map((user, index) => (
            <li key={index} onClick={() => handleAddMention(user)}>
              {user.profile_pic ? (
                <MDAvatar
                  src={user.profile_pic}
                  alt={`${user.first_name.slice(0, 1)}${user.last_name.slice(
                    0,
                    1
                  )}`}
                  size="sm"
                  bgColor="light"
                />
              ) : (
                <Avatar
                  size="sm"
                  sx={{
                    bgcolor: "#ccc",
                    marginRight: "5px",
                    textTransform: "uppercase",
                  }}
                >{`${user.first_name.slice(0, 1)}${user.last_name.slice(
                  0,
                  1
                )}`}</Avatar>
              )}
              {/* <img src={user.profile_pic} alt={user.name} className="profile-pic" /> */}
              {user.first_name} {user.last_name}
            </li>
          ))}
        </ul>
        )}
      </>);
  };

  if (!feeds.isSuccess || !getUsersList.isSuccess) {
    return <Loader />; 
  }

  return (
    <DashboardLayout>
      <DashboardMenu />
      <MDBox my={1}> 
        <MDSnackbar
          color="error"
          icon="error"
          title="Error: Please try again"
          content={eMsg}
          open={sendError}
          close={toggleSnackbar}
        /> 
        <MDSnackbar
          color="success"
          icon="check_circle"
          title="Success"
          content={eMsg}
          open={sendSuccess}
          close={toggleSnackbar}
        />
        <Grid container spacing={1}>
          <Grid item xs={12} md={6} lg={6} mb={2}>
            {permission.write_permission && (
            <MDBox my={1}>
              <Card>
                <MDBox pt={2} pl={2}>
                  <MDTypography variant="h6" mb={3} sx={txtColorChange} className="fontFamily">
                    <span className="iconDesign">
                      <BorderColorIcon />
                    </span>
                    Create Post
                  </MDTypography>
                </MDBox>
                <MDBox p={2} component="form" role="form">
                  
                {/* <div style={{ position: 'relative' }}>
                  <textarea
                    value={text}
                    onChange={handleInputChange}
                    placeholder="Type here..."
                    rows={5}
                    cols={50}
                  />
                  {showDropdown && (
                    <div
                      style={{
                        position: 'absolute',
                        top: 'calc(100% + 5px)',
                        left: 0,
                        backgroundColor: '#fff',
                        border: '1px solid #ccc',
                        borderRadius: '4px',
                        padding: '4px',
                        zIndex: 999
                      }}
                    >
                      {names.map((name, index) => (
                        <div
                          key={index}
                          style={{ cursor: 'pointer' }}
                          onClick={() => handleNameSelect(name)}
                        >
                          {name}
                        </div>
                      ))}
                    </div>
                  )}
                </div> */}

                  <div className="post">
                    {/* <div> */}
                      {/* {renderMentions()} */}
                    {/* </div> */}
                    <div>
                      {/* <textarea
                        placeholder=""
                        value={content}
                        onChange={handleContentChange}
                        rows={4}
                        sx={{ autoFocus: true, width: "100%", marginBottom: "15px", marginTop: "5px", '& .MuiInputLabel-root': {
                          marginTop: '-8px',
                        }, }}
                      /> */}
                      <div>
                        {/* <input
                          type="text"
                          placeholder="Mention user..."
                          value={mentionInput}
                          onChange={(e) => setMentionInput(e.target.value)}
                        /> */}
                        {renderSuggestions()}
                      </div>
                    </div>
                  </div>

                  <MDInput
                    label={iconInfo}
                    placeholder="What's on your mind?"
                    multiline
                    rows={4}
                    sx={{ autoFocus: true, width: "100%", marginBottom: "15px", marginTop: "5px", '& .MuiInputLabel-root': {
                      marginTop: '-8px',
                    }, }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={postText}
                    onChange={handleContentChange}
                    // onChange={(e) => {
                    //   setPostText(e.target.value);
                    // }}
                    id="userInput" 
                    // onChange={handleInputChange}
                    // onChange={handleOnChange}
                    // onKeyDown={handleKeyDown}
                  />
                  <MDBox sx={{ textAlign: "right"}}>
                    <MDBox className="sendIcon">
                      <MDButton
                        variant="gradient"
                        color="primary"
                        className="ml-5"
                        sx={bgColorChange}
                        type="button"
                        onClick={handleSubmit}
                        disabled = {permission.write_permission ? false : true}
                      >
                        <Icon sx={{ ml: 0.5 }} className="rotateIcon">send</Icon>
                      </MDButton>
                    </MDBox>
                    <MDBox className={"emojiBlock " + textColor}>
                      <InsertEmoticonIcon onClick={showEmojiPicker} />
                      {showPicker && (<>
                        <MDBox variant="outlined" className="emoji-close-button"  onClick={showEmojiPicker}>
                          <CloseIcon />
                        </MDBox>
                        <EmojiPicker
                          onEmojiClick={onEmojiClick}
                          autoFocusSearch={false}
                        />
                        </>
                      )}
                    </MDBox>
                    <MDBox className={"image-upload " + textColor}>
                      <label for="file-input1">
                        {startSelectedFile && (
                          <span className="attachmentText">{startSelectedFile.name}</span>
                        )}
                        <AttachmentIcon className="rotateIcon" />
                      </label>
                      <input id="file-input1" type="file" onChange={handleStartFileSelect} />
                    </MDBox>
                  </MDBox>

                  
                  {/* {userSuggestions.length > 0 && (
                    <div
                      style={{
                        position: 'absolute',
                        top: "190px",
                        left: 0,
                        width: '100%',
                        height: "200px",
                        overflow: "auto",
                        border: '1px solid #ccc',
                        borderRadius: '5px',
                        background: 'white',
                        zIndex: 1,
                      }}
                    >
                      {userSuggestions.map((user, index) => (
                        <div
                          key={index}
                          style={{
                            padding: '5px',
                            cursor: 'pointer',
                            backgroundColor: index === activeMentionIndex ? '#f0f0f0' : 'white',
                          }}
                          onClick={() => handleUserSelect(user)}
                        >
                          {user}
                        </div>
                      ))}
                    </div>
                  )} */}
                  {/* <p>Highlighted Text: {renderHighlightedText()}</p> */}
                  {/* Text Value :: {postText} */}

                  

                  {/* <CustomMentionsTextarea /> */}
                  {/* <MyComponent /> */}
                </MDBox>
              </Card>
            </MDBox>
            )}
            <FBPosts data={feeds?.data?.data} postHandlePostFlag={handlePostFlag} />
          </Grid>
          <Grid item xs={12} md={6} lg={6} mb={2}>
            <MDBox mt={1}>
              <ImageBlock1 />
              <ImageBlock2 />
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
      <SiteFooter />
    </DashboardLayout>
  );
};

export default NewFeeds;
