import { Navigate, useNavigate } from "react-router-dom";

function bgColorChange() {
  const settings = localStorage.getItem("settings");
  let btnBgColor = "";
  if (settings && settings !== "undefined") {
    var obj = JSON.parse(settings);
    let obj1 = obj.find((o) => o.Name === "btnBgColor");
    btnBgColor = obj1.Value;
  }

  return {
    background: `${btnBgColor} !important`,
    border: `1px solid ${btnBgColor} !important`,
    boxShadow: `0px 2px 1px -1px ${btnBgColor}, 0px 1px 1px 0px ${btnBgColor}, 0px 1px 3px 0px ${btnBgColor} !important`
  }

}

function txtColorChange () {
  const settings = localStorage.getItem("settings");
  let txtColor = "";
  if (settings && settings !== "undefined") {
    var obj = JSON.parse(settings);
    let obj1 = obj.find((o) => o.Name === "txtColor");
    txtColor = obj1.Value;
    // txtColor = `${obj1.Value} !important`;
  }

  return {
    color: `${txtColor} !important`,
  }
}

function txtColorChange1 () {
  const settings = localStorage.getItem("settings");
  let txtColor = "";
  if (settings && settings !== "undefined") {
    var obj = JSON.parse(settings);
    let obj1 = obj.find((o) => o.Name === "txtColor1");
    txtColor = obj1.Value;
    // txtColor = `${obj1.Value} !important`;
  }

  return {
    color: `${txtColor} !important`,
  }
}

function profileColorChange() {
  const settings = localStorage.getItem("settings");
  let profileBgColor = "";
  if (settings && settings !== "undefined") {
    var obj = JSON.parse(settings);
    let obj1 = obj.find((o) => o.Name === "profileBgColor");
    profileBgColor = obj1.Value;
  }

  return {
    background: `${profileBgColor} !important`,
  }

}

function transColorChange1() {
  const settings = localStorage.getItem("settings");
  let transColor1 = "";
  if (settings && settings !== "undefined") {
    var obj = JSON.parse(settings);
    let obj1 = obj.find((o) => o.Name === "transColor1");
    transColor1 = obj1.Value;
  }

  return {
    background: `${transColor1} !important`,
  }

}

function transColorChange2() {
  const settings = localStorage.getItem("settings");
  let transColor2 = "";
  if (settings && settings !== "undefined") {
    var obj = JSON.parse(settings);
    let obj1 = obj.find((o) => o.Name === "transColor2");
    transColor2 = obj1.Value;
  }

  return {
    background: `${transColor2} !important`,
  }

}

function testFun() {
  // window.location.reload();
  //window.location.assign("/login")
  //const navigate = useNavigate();
  //return navigate("/login");
  //navigate('/login')
}




export {bgColorChange, txtColorChange, profileColorChange, transColorChange1, transColorChange2, testFun, txtColorChange1}
