import { Box, Card, Grid, Icon, Modal, Tooltip, Typography } from "@mui/material";
import MDBox from "components/MDBox";
import React, { useEffect, useState } from "react";
import Image from "mui-image";
import URLSettings from "../Common/URLSettings";
import { bgColorChange, txtColorChange } from "../Common/ColorChange";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import MDSnackbar from "components/MDSnackbar";
import CloseIcon from '@mui/icons-material/Close';
import { isMobile } from "react-device-detect";
import { useMutation } from "react-query";
import axios from "axios";

const ActivityImage1 = ({pageStatus}) => {
    
  let APIURL = URLSettings();
  const settings = localStorage.getItem("settings");
  var obj = JSON.parse(settings);
  let activityImage = obj.find((o) => o.Name === "activity_image1");
  
  let blockHeight = "400px !important";
  if (isMobile) {
    blockHeight = "auto";
  }

  const [blockStatus, setBlockStatus] = useState(pageStatus);
  useEffect(() => {
    setBlockStatus(pageStatus);
  },[pageStatus])

  const [eMsg, setEMsg] = useState("");
  const [sendError, setSendError] = useState(""); 
  const [sendSuccess, setSendSuccess] = useState("");
  const toggleSnackbar = () => {
    if (sendError) {
      setSendError(!sendError);
    }
    if (sendSuccess) {
      setSendSuccess(!sendSuccess);
    }
  };
  const [selectedFile, setSelectedFile] = useState(null);
  const handleFileSelect = (event) => {
    setSelectedFile(event.target.files[0]);
  };
  
  const [sId, setSId] = useState("");
  const [sVal, setSValue] = useState("");
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false); 
  const handleEdit = (val) => {
    setSId(val.id);
    setSValue(val.Value);
    handleOpen();
  }
  useEffect(() => {
    setTimeout(() => {
      setSendSuccess(false);
      setSendError(false);
    }, 2000);
  }, [sendError, sendSuccess]); 

  const reloadSettings = useMutation(
    async () =>
      axios.get(`${APIURL.url}/admin/settings`, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }),
    {
      onError: (error) => {
      },
      onSuccess: (data) => {
        localStorage.setItem("settings", JSON.stringify(data.data.rows));
        window.location.reload();
      },
    }
  );

  const handleSubmit = async (event) => {
    event.preventDefault(); 
    if (selectedFile != null) {
      const formData = new FormData();
      formData.append("file", selectedFile);
      formData.append("file_location", "common");
      formData.append("code", APIURL.code);
      formData.append("s_id", sId);
      formData.append("value", sVal);
      formData.append("status", true);
      try {
        const response = await axios({ 
          method: "post",
          url: `${APIURL.url}/admin/updateSettings`,
          data: formData,
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: localStorage.getItem("token"),
          },
        });
        if (response) {
          reloadSettings.mutate();
          setEMsg(response.data);
          setSendSuccess(true);
          setSId("");
          setSValue("");
          handleClose();
        }
      } catch (error) {
        setEMsg(error.response.data);
        setSendError(true);
        handleClose();
        if (error.response.status === 401) {
          window.location.reload();
          return navigate("/login");
        }
      }
    } 
  }

  return (<>
    <Card sx={{
      marginBottom: "5px",
    }}>
      <MDSnackbar
        color="error"
        icon="error"
        title="Error: Please try again"
        content={eMsg}
        open={sendError}
        close={toggleSnackbar}
      />
      <MDSnackbar
        color="success"
        icon="check_circle"
        title="Success"
        content="Settings Updated"
        open={sendSuccess}
        close={toggleSnackbar}
      />
      {blockStatus && (
        <MDBox sx={{ position: "absolute", right: 0, top: 0, zIndex: 99}}>
          <Tooltip title="Edit" placement="top">
            <MDButton
              variant="gradient"
              color="info"
              size="small"
              iconOnly
              onClick={() => handleEdit(activityImage)}
              sx={bgColorChange} 
            >
              <Icon>edit</Icon> 
            </MDButton>  
          </Tooltip> 
        </MDBox>
      )}
      <MDBox p={2}>
        <Image
            src={activityImage.Value}
            maxWidth={false}
            sx={{ borderRadius: "0.75rem", height: blockHeight }}
        />
      </MDBox>
    </Card>

    <Modal
    open={open}
    onClose={handleClose}
    aria-labelledby="modal-modal-title"
    aria-describedby="modal-modal-description"
    >  
    <Box className="modalPopupBox"> 
      <Grid container>
        <Grid item xs={12}> 
          <Typography id="keep-mounted-modal-title" variant="h5" component="h2" sx={txtColorChange}>
            Edit Image (png / gif - 560*390)
          </Typography> 
          <MDBox variant="outlined" className="close-button" onClick={handleClose}><CloseIcon /></MDBox> 
        </Grid>
      </Grid>
      <MDBox component="form" role="form" onSubmit={handleSubmit}>
        <Grid container>
          <Grid item xs={12}>
            <MDBox mt={2} mb={2} mr={1}> 
              <MDInput type="file" fullWidth onChange={handleFileSelect} /> 
            </MDBox>
          </Grid>
        </Grid>
        <MDBox mt={2} mb={1}>  
          <MDButton type="submit" variant="gradient" color="info" sx={bgColorChange}>
            Save
          </MDButton>
        </MDBox>
      </MDBox>
    </Box> 
    </Modal>
  </>);
};

export default ActivityImage1;