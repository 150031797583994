// Material Dashboard 2 PRO React components
// import PropTypes from "prop-types";
import { useEffect, useState } from "react";

// Material Dashboard 2 PRO React context
// import { useMaterialUIController } from "context";
import { Box, Card, Grid, Icon, Modal, Skeleton, Tooltip, Typography } from "@mui/material";
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDSnackbar from "components/MDSnackbar";

import { useMutation, useQuery } from "react-query";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import MDAlert from "components/MDAlert";
import TransactionsSkeleton from "../TransactionsSkeleton";
import CodeDialog from "../CodeDialog";
import Transactions from "../Transactions";
import SiteFooter from "../Common/SiteFooter";
import URLSettings from "../Common/URLSettings";
import {txtColorChange, txtColorChange1, bgColorChange, testFun} from "../Common/ColorChange";
import Logout from "../Common/Logout";
import NotFound from "../Common/NotFound";
import DashboardMenu from "examples/Navbars/DashboardMenu";

const jose = require("jose");

// const style = {
//   position: "absolute",
//   top: "50%",
//   left: "50%",
//   transform: "translate(-50%, -50%)",
//   width: 500,
//   bgcolor: "#a8a9af",
//   border: "2px solid #000",
//   boxShadow: 24,
//   p: 4,
// };

function Redeem() {
  // const [controller] = useMaterialUIController();
  // const { darkMode } = controller;
  let APIURL = URLSettings();
  const [desiredPoints, setDesiredPoints] = useState(0);
  const [email, setEmail] = useState();
  const [cEmail, setCEmail] = useState();
  const [redeemError, setRedeemError] = useState(false);
  const [pointsError, setPointsError] = useState(false);
  const [sendSuccess, setSendSuccess] = useState(false);
  const [eMsg, setEMsg] = useState("");
  const [open, handleOpen] = useState(false);
  const [code, setCode] = useState("");
  const navigate = useNavigate();

  const toggleSnackbar = () => {
    if (redeemError) {
      setRedeemError(!redeemError);
    }
    if (pointsError) {
      setPointsError(!pointsError);
    }
    if (sendSuccess) {
      setSendSuccess(!sendSuccess);
    }
    if (eMsg) {
      setEMsg(!eMsg);
    }
  };
  useEffect(() => {
    setTimeout(() => {
      setRedeemError(false);
      setPointsError(false);
      setSendSuccess(false);
    }, 2000);
  }, [redeemError, pointsError, sendSuccess]);

  // let test = localStorage.getItem("bgColor");
  // let test = `{{ background: ""}}`;

  // const test = () => ({
  //   background: () => {
  //     return "#fa8d29 !important";
  //   },
  // });

  const logout = () => {
    localStorage.removeItem("token");
    navigate("/login");
  };

  useEffect(() => {
    if (!localStorage.getItem("token")) {
      window.location.reload();
      return navigate("/login");
    }
  }, []);

  const token = useQuery("token", async () => {
    // should be validating with public key
    const text = localStorage.getItem("token");
    if (text === null || text === "") {
      throw new Error("No token found");
    }
    const claims = jose.decodeJwt(text);
    claims.token = text;
    return claims;
  });

  const history = useQuery("receptionHistory", async () =>
    axios.get(`${APIURL.url}/getRecievedHistory`, {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    })
  );

  const points = useQuery("points", async () =>
    axios.get(`${APIURL.url}/getPointCount`, {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    })
  );

  const redeemPoints = useMutation(
    () =>
      axios.post(
        `${APIURL.url}/redeem/Amazon`,
        {
          points: Number(desiredPoints),
          c_email: cEmail,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.getItem("token"),
          },
        }
      ),
    {
      onError: (err) => {
        setEMsg(err.response.data);
        console.log(err);
        if (err.response.data === "Insufficient Points") {
          setPointsError(true);
        } else {
          setRedeemError(true);
        }
        if (err.response.status === 401) {
          // logout();
          // <Logout />
          window.location.reload();
          return navigate("/login");
        }
      },
      onSuccess: (data) => {
        // modal
        handleConfirmClose();
        setCEmail("");
        setSendSuccess(true);
        setCode(data);
        handleOpen(true);
        // update points
        points.refetch(); // todo figure out invalidate
        history.refetch(); // todo figure out invalidate
        setDesiredPoints(0);
      },
    }
  );

  const [confirm, setConfirm] = useState(false);
  const handleConfirmOpen = () => setConfirm(true);
  const handleConfirmClose = () => setConfirm(false);
  // token.data.email

  const redeemption = () => {
    // console.log(desiredPoints);
    handleConfirmOpen();
  };

  const confirmRedeemption = () => {
    redeemPoints.mutate();
  };

  if (token.isError) {
    window.location.reload();
    return navigate("/login");
  }

  if (history.isError || points.isError) {
    if (history.isError) {
      if (history.error.response.status === 401) {
        window.location.reload();
        return navigate("/login");
      }
    }
    if (points.isError) {
      console.log(points.error);
      if (points.error.response.status === 401) {
        window.location.reload();
        return navigate("/login");
      }
    }
    return (
      <Card id="redemptionCard" sx={{ overflow: "visible" }}>
        <MDBox p={3} m={1} mt={2}>
          <Grid container>
            <Grid item xs={6}>
              <MDAlert color="error" dismissible>
                Error: unable to load account data, please try again later.
              </MDAlert>
            </Grid>
            <Grid item xs={6}>
              <Grid container>
                <Grid item xs={12}>
                  <MDAlert color="error" dismissible>
                    {" "}
                    Error: unable to load account data{" "}
                  </MDAlert>
                </Grid>
                <Grid item xs={12}>
                  {/* <MDInput type="number" label="Points" disabled /> */}
                </Grid>
                <Grid item xs={12}>
                  <MDButton variant="gradient" color="success" disabled sx={bgColorChange}>
                    {/* Redeem on Amazon <Icon>shopping_bag</Icon> */}
                    Redemption
                  </MDButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </MDBox>
      </Card>
    );
  }

  if (history.isSuccess && points.isSuccess && token.isSuccess) {
    return (
      <DashboardLayout>
        {/* <DashboardNavbar /> */}
        <DashboardMenu />
        <MDBox my={1}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6} lg={6} mb={2} sx={{ paddingTop: "0px !important"}}>
              <Card id="redemptionCard" sx={{ overflow: "visible" }}>
                <CodeDialog open={open} handleOpen={handleOpen} code={code} type="Amazon" />
                <MDSnackbar
                  color="error"
                  icon="error"
                  title="Error: Unable to Redeem Points"
                  // content="Please try again later"
                  content={eMsg}
                  open={redeemError}
                  close={toggleSnackbar}
                />
                <MDSnackbar
                  color="error"
                  icon="error"
                  title="Error: Insufficient Points"
                  // content="Please redeem less points"
                  content={eMsg}
                  open={pointsError}
                  close={toggleSnackbar}
                />
                <MDSnackbar
                  color="success"
                  icon="check_circle"
                  title="Success"
                  content="Redeemed successful, please check your email"
                  open={sendSuccess}
                  close={toggleSnackbar}
                />
                <Transactions history={history.data.data} />
              </Card>
            </Grid>
            <Grid item xs={12} md={6} lg={6} mb={2} sx={{ paddingTop: "0px !important"}}>
              <Card>
                <MDBox p={3}>
                  <Grid container>
                    <Grid item xs={12}>
                      <MDTypography
                        variant="h6"
                        mb={3}
                        className="fontFamily"
                      >
                        <MDBox sx={txtColorChange} className="floatleft">You have </MDBox> 
                        <MDBox sx={txtColorChange1} className="floatleft">{points.data.data[0]?.personal_balance}</MDBox>
                        <MDBox sx={txtColorChange} className="floatleft"> points to redeem</MDBox>
                      </MDTypography>
                    </Grid>
                  </Grid>
                  <Grid container>
                    {/* <Grid item xs={6} md={4} lg={4}>
                      <MDInput
                        type="input"
                        label="Points"
                        value={desiredPoints}
                        onChange={(e) => {
                          setDesiredPoints(e.target.value);
                        }}
                        sx={{ mb: 2, width: "100%" }}
                      />
                    </Grid> */}
                    <Grid item xs={12} md={6} lg={6}>
                      <Link to="/redemption">
                        {/* <Tooltip
                        title="You will receive amazon gift card to your email"
                        placement="bottom"
                      > */}
                        <MDButton
                          variant="gradient"
                          color="success"
                          // sx={{ ml: 1 }}
                          // onClick={redeemption}
                          sx={bgColorChange}
                        >
                          {/* Redeem on Amazon <Icon sx={{ ml: 0.5 }}>shopping_bag</Icon> */}
                          Redemption
                        </MDButton>
                        {/* </Tooltip> */}
                      </Link>
                    </Grid>
                  </Grid>
                </MDBox>
              </Card>
            </Grid>
          </Grid>

          {/* <Modal
            open={confirm}
            onClose={handleConfirmClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <Grid container>
                <Grid item xs={12}>
                  <Typography id="keep-mounted-modal-title" variant="h5" component="h2">
                    Confirmation
                  </Typography>
                </Grid>
              </Grid>
              <MDBox component="form" role="form">
                <MDBox mt={2} mb={2}>
                  <MDInput
                    type="text"
                    fullWidth
                    label="Points"
                    placeholder="Points"
                    value={desiredPoints}
                    disabled={true}
                    // value={firstName}
                    // onChange={(e) => {
                    //   setFirstName(e.target.value);
                    // }}
                  />
                </MDBox>
                <MDBox mt={2} mb={2}>
                  <MDInput
                    type="text"
                    fullWidth
                    label="Email Id"
                    placeholder="Email Id"
                    value={token.data.email}
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                  />
                </MDBox>
                <MDBox mt={2} mb={2}>
                  <MDInput
                    type="text"
                    fullWidth
                    label="Confirm Email Id"
                    placeholder="Confirm Email Id"
                    value={cEmail}
                    onChange={(e) => {
                      setCEmail(e.target.value);
                    }}
                  />
                </MDBox>
                <MDBox mt={2} mb={1}>
                  <MDButton variant="gradient" color="info" onClick={confirmRedeemption}>
                    Confirm
                  </MDButton>
                </MDBox>
              </MDBox>
            </Box>
          </Modal> */}

          {/* <Card id="redemptionCard" sx={{ overflow: "visible" }}>
            <CodeDialog open={open} handleOpen={handleOpen} code={code} type="Amazon" />
            <MDSnackbar
              color="error"
              icon="error"
              title="Error: Unable to Redeem Points"
              content="Please try again later"
              open={redeemError}
              close={toggleSnackbar}
            />
            <MDSnackbar
              color="error"
              icon="error"
              title="Error: Insufficient Points"
              content="Please redeem less points"
              open={pointsError}
              close={toggleSnackbar}
            />
            <MDBox p={3} m={1} mt={2}>
              <Grid container>
                <Grid item xs={12} md={6} lg={6} mb={2}>
                  <Transactions history={history.data.data} />
                </Grid>
                <Grid item xs={12} md={6} lg={6} mb={2}>
                  <Grid container>
                    <Grid item xs={12}>
                      <MDTypography
                        sx={{ mb: 3 }}
                        variant="h3"
                      >{`You have ${points.data.data.currentbalance} points to redeem`}</MDTypography>
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item xs={6} md={4} lg={4}>
                      <MDInput
                        type="input"
                        label="Points"
                        value={desiredPoints}
                        onChange={(e) => {
                          setDesiredPoints(e.target.value);
                        }}
                        sx={{ mb: 2, width: "100%" }}
                      />
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                      <Tooltip
                        title="You will receive amazon gift card to your email"
                        placement="bottom"
                      >
                        <MDButton
                          variant="gradient"
                          color="success"
                          sx={{ ml: 1 }}
                          onClick={redeemption}
                        >
                          Redeem on Amazon <Icon sx={{ ml: 0.5 }}>shopping_bag</Icon>
                        </MDButton>
                      </Tooltip>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </MDBox>
          </Card> */}
        </MDBox>
        <SiteFooter />
      </DashboardLayout>
    );
  }

  return (
    <DashboardLayout>
      {/* <DashboardNavbar /> */}
      <MDBox my={1}>
        <Card id="redemptionCard" sx={{ overflow: "visible" }}>
          <MDBox p={3} m={1} mt={2}>
            <Grid container>
              <Grid item xs={12} md={6} lg={6}>
                <TransactionsSkeleton />
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <Grid container>
                  <Grid item xs={12}>
                    <Skeleton variant="text" width={200} height={30} animation="wave" />
                  </Grid>
                  {/* <Grid item xs={12}>
                    <MDInput type="number" label="Points" disabled />
                  </Grid> */}
                  <Grid item xs={12}>
                    <MDButton variant="gradient" color="success" disabled sx={bgColorChange}>
                      {/* Redeem on Amazon <Icon sx={{ ml: 0.5 }}>shopping_bag</Icon> */}
                      Redemption
                    </MDButton>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </MDBox>
        </Card>
      </MDBox>
      <SiteFooter />
    </DashboardLayout>
  );
}

export default Redeem;
