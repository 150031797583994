/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-router-dom components
import { Link } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import { Breadcrumbs as MuiBreadcrumbs } from "@mui/material";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

function Breadcrumbs({ icon, title, route, light, darkMode }) {
  const routes = route.slice(0, -1);

  const settings = localStorage.getItem("settings");
  let menu1 = { Name: "", Value: "" };
  let menu2 = { Name: "", Value: "" };
  let menu3 = { Name: "", Value: "" };
  if (settings && settings !== "undefined") {
    var obj = JSON.parse(settings);
    menu1 = obj.find((o) => o.Name === "side_bar_1");
    menu2 = obj.find((o) => o.Name === "side_bar_2");
    menu3 = obj.find((o) => o.Name === "side_bar_3");
  }

  if (route[0] === "home") {
    title = menu1.Value;
  } else if (route[0] === "my-rewards") {
    title = menu2.Value;
  } else if (route[0] === "giving") {
    title = menu3.Value;
  } else {
    title = title.replace("-", " ");
  }

  return (
    <MDBox mt={{ xs: 2, xl: 0 }}>
      <MuiBreadcrumbs
        sx={{
          width: "150px !important",
          marginLeft: "15px",
          "& .MuiBreadcrumbs-separator": {
            color: ({ palette: { white, grey } }) => (light ? white.main : grey[600]),
          },
        }}
      >
        {icon === "home" ? (
          ""
        ) : (
          <Link to="/home">
            <MDTypography
              component="span"
              variant="body2"
              // color={light ? "white" : "dark"}
              color={darkMode ? "secondary" : "text"}
              opacity={light ? 0.8 : 0.5}
              sx={{ lineHeight: 0, display: "none" }}
            >
              <Icon>{icon}</Icon>
            </MDTypography>
          </Link>
        )}
        {routes.map((el) => (
          <Link to={`/${el}`} key={el}>
            <MDTypography
              component="span"
              variant="button"
              fontWeight="regular"
              textTransform="capitalize"
              // color={light ? "white" : "dark"}
              color={darkMode ? "secondary" : "text"}
              opacity={light ? 0.8 : 0.5}
              sx={{ lineHeight: 0 }}
            >
              {el}
            </MDTypography>
          </Link>
        ))}
        <MDTypography
          variant="button"
          fontWeight="regular"
          textTransform="capitalize"
          // color={light ? "white" : "dark"}
          color={darkMode ? "secondary" : "text"}
          sx={{ lineHeight: 0, fontWeight:"bold" }}
        >
          {title}
        </MDTypography>
      </MuiBreadcrumbs>
      {/* <MDTypography
        fontWeight="bold"
        textTransform="capitalize"
        variant="h6"
        color={light ? "white" : "dark"}
        noWrap
      >
        {title.replace("-", " ")}
      </MDTypography> */}
    </MDBox>
  );
}

// Setting default values for the props of Breadcrumbs
Breadcrumbs.defaultProps = {
  light: false,
};

// Typechecking props for the Breadcrumbs
Breadcrumbs.propTypes = {
  icon: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  route: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
  light: PropTypes.bool,
  darkMode: PropTypes.bool,
};

export default Breadcrumbs;
