// import PropTypes from "prop-types";
// import { useState } from "react";

// Material Dashboard 2 PRO React context
// import { useMaterialUIController } from "context";
import { Icon } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import TimelineList from "examples/Timeline/TimelineList";
// import TimelineItem from "examples/Timeline/TimelineItem";
import TimelineItemSkeleton from "examples/Timeline/TimelineitemSkeleton/index";
import { bgColorChange } from "../Common/ColorChange";

function TransactionsSkeleton() {
  const items = [0, 1, 2, 3, 4];

  return (
    <MDBox>
      <TimelineList title="History" noCard>
        {items.map((index) => (
          <TimelineItemSkeleton lastItem={index === 4} />
        ))}
      </TimelineList>
      <MDButton variant="gradient" color="info" disabled sx={bgColorChange}>
        Load More <Icon>add</Icon>
      </MDButton>
    </MDBox>
  );
}

export default TransactionsSkeleton;
