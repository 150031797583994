// Material Dashboard 2 PRO React components
// import PropTypes from "prop-types";
import { useEffect, useState } from "react";

// Material Dashboard 2 PRO React context
// import { useMaterialUIController } from "context";
import { Box, Card, Grid, Icon, Modal, Typography } from "@mui/material";
import MDBox from "components/MDBox";

import { isMobile } from "react-device-detect";

import { useQuery } from "react-query";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import SiteFooter from "../Common/SiteFooter";
import { useMaterialUIController } from "context";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDAvatar from "components/MDAvatar";
import MDSnackbar from "components/MDSnackbar";
import URLSettings from "./URLSettings";
import { bgColorChange, txtColorChange } from "./ColorChange";
import MDTypography from "components/MDTypography";
import EditIcon from '@mui/icons-material/Edit';

const jose = require("jose");

function ProfilePic() {
  let APIURL = URLSettings();
  const profilPic = localStorage.getItem("profilepic");
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const logout = () => {
    localStorage.removeItem("token");
    navigate("/login");
  };

  useEffect(() => {
    if (!localStorage.getItem("token")) {
      window.location.reload();
      return navigate("/login");
    }
  }, []);

  const claims = jose.decodeJwt(localStorage.getItem("token"));
  const token = useQuery("token", async () => {
    // should be validating with public key
    const text = localStorage.getItem("token");
    if (text === null || text === "") {
      throw new Error("No token found");
    }
    const claims = jose.decodeJwt(text);
    claims.token = text;
    return claims;
  });

  if (token.isError) {
    window.location.reload();
    return navigate("/login");
  }

  const [error, setError] = useState(false);
  const [rsuccess, setRSuccess] = useState(false);
  const toggleSnackbar = () => {
    if (error) {
      setError(!error);
    }
    if (rsuccess) {
      setRSuccess(!rsuccess);
    }
  };
  useEffect(() => {
    setTimeout(() => {
      setRSuccess(false);
      setError(false);
    }, 2000);
  }, [rsuccess, error]);

  const [firstName, setFirstName] = useState(claims.first_name);
  const [lastName, setLastName] = useState(claims.last_name);
  const [emailId, setEmailId] = useState(claims.email);
  
  const [selectedFile, setSelectedFile] = useState(null);
  const handleSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData();
    formData.append("file_location", "profile_pic");
    formData.append("code", APIURL.code);
    formData.append("image_file", selectedFile);
    formData.append("first_name", firstName);
    formData.append("last_name", lastName);
    formData.append("email_id", emailId);
    try {
      const response = await axios({
        method: "post",
        url: `${APIURL.url}/profilePic`,
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: localStorage.getItem("token"),
        },
      });
      if (response) {
        setRSuccess(true);
        localStorage.setItem("profilepic", response.data.profile_url);
        setSelectedFile("");
        window.location.reload();
      }
    } catch (error) {
      console.log(error);
      if (error.response.status === 401) {
        window.location.reload();
        return navigate("/login");
      }
    }
  };

  const handleFileSelect = (event) => {
    setFirstName(claims.first_name);
    setLastName(claims.last_name);
    setEmailId(claims.email);
    setSelectedFile(event.target.files[0]);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDSnackbar
        color="error"
        icon="error"
        title="Error: Something went wrong"
        content="Something went wrong"
        open={error}
        close={toggleSnackbar}
      />
      <MDSnackbar
        color="success"
        icon="check_circle"
        title="Success"
        content="Profile pic updated"
        open={rsuccess}
        close={toggleSnackbar}
      />
      <MDBox my={6}>
        <Card sx={{ overflow: "visible" }}>
          <MDBox p={3} m={1} mt={2}>
            <Grid container mb={3} mt={3}>
              <Grid item xs={6}>
                <Typography id="keep-mounted-modal-title" variant="h5" component="h2" sx={txtColorChange}>
                  Profile Edit
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <MDBox sx={{width: "120px", position: "relative"}}>
                <MDAvatar src={profilPic} alt="profile picture" size="xxl" variant="rounded" />
                <MDBox sx={bgColorChange} className="profileEdit" onClick={handleOpen}>
                  <EditIcon />
                </MDBox>
              </MDBox>
            </Grid>
            {/* <Grid container mb={3} mt={3}>
              <Grid item xs={4}>
                <MDBox component="form" role="form" onSubmit={handleSubmit}>
                  <MDBox mt={2} mb={2}>
                    <span fontSize="small">Upload ProfilePic</span>
                    <MDInput type="file" fullWidth onChange={handleFileSelect} />
                  </MDBox>
                  <MDBox mt={2} mb={1}>
                    <MDButton type="submit" variant="gradient" color="info" sx={bgColorChange}>
                      Submit
                    </MDButton>
                  </MDBox>
                </MDBox>
              </Grid>
            </Grid> */}
          </MDBox>
        </Card>
      </MDBox>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="modalPopupBox">
          <Typography id="keep-mounted-modal-title" variant="h5" component="h2" sx={txtColorChange}>
            Profile Edit
          </Typography>
          <MDBox sx={{ minHeight: "150px" }}>
          <Grid container>
              <Grid item xs={6}>
                <MDBox mt={2} mb={2} mr={1}>
                  <MDInput
                    type="text"
                    label="First Name"
                    fullWidth
                    placeholder="First Name"
                    value={firstName}
                    onChange={(e) => {
                      setFirstName(e.target.value);
                    }}
                  />
                </MDBox>
              </Grid>
              <Grid item xs={6}>
                <MDBox mt={2} mb={2}>
                  <MDInput
                    type="text"
                    label="Last Name"
                    fullWidth
                    placeholder="Last Name"
                    value={lastName}
                    onChange={(e) => {
                      setLastName(e.target.value);
                    }}
                  />
                </MDBox>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={6}>
                <MDBox mt={2} mb={2}>
                  <MDInput
                    type="text"
                    label="Email Id"
                    fullWidth
                    placeholder="Email Id"
                    value={emailId}
                    onChange={(e) => {
                      setEmailId(e.target.value);
                    }}
                  />
                </MDBox>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12}>
                <MDBox>
                  <MDTypography
                    variant="button"
                    fontWeight="regular"
                    color="text"
                    sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
                  >
                    &nbsp;&nbsp;Profile Pic :&nbsp;
                  </MDTypography>
                  <MDInput type="file" fullWidth onChange={handleFileSelect} />
                </MDBox>
              </Grid>
              <MDBox mt={2} mb={1}>
                <MDButton variant="gradient" color="info" onClick={handleSubmit} sx={bgColorChange}>
                  Submit
                </MDButton>
              </MDBox>
            </Grid>
          </MDBox>
        </Box>  
      </Modal>
      <SiteFooter />
    </DashboardLayout>
  );
}

export default ProfilePic;
