import { Box, Card, Grid, Modal, Switch, Typography } from "@mui/material";
import axios from "axios";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDSnackbar from "components/MDSnackbar";
import MDTypography from "components/MDTypography";
import { useMaterialUIController } from "context";
import NotificationItem from "examples/Items/NotificationItem";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import React, { useEffect, useState } from "react";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import CartCount from "../Common/CartCount";
import { bgColorChange, txtColorChange } from "../Common/ColorChange";
import Loader from "../Common/Loader";
import URLSettings from "../Common/URLSettings";
import CloseIcon from '@mui/icons-material/Close';

// const style = {
//   position: "absolute",
//   top: "50%",
//   left: "50%",
//   transform: "translate(-50%, -50%)",
//   width: 500,
//   bgcolor: "#a8a9af",
//   border: "2px solid #000",
//   boxShadow: 24,
//   p: 4,
// };

const Checkout = () => {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  let APIURL = URLSettings();

  const jose = require("jose");
  const claims = jose.decodeJwt(localStorage.getItem("token"));

  const [email, setEmail] = useState("");
  const [confirmEmail, setConfirmEmail] = useState("");
  const [eMsg, setEMsg] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const navigate = useNavigate();
  //Modal popup
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [emailStatus, setEmailStatus] = useState(false);
  const handleStatus = () => {
    setEmailStatus(!emailStatus);
    setEmail("");
    if (!emailStatus) {
      setEmail(claims.email);
    }
  };


  const toggleSnackbar = () => {
    if (error) {
      setError(!error);
    }
    if (success) {
      setSuccess(!success);
    }
    if (eMsg) {
      setEMsg(!eMsg);
    }
  };
  useEffect(() => {
    setTimeout(() => {
      setSuccess(false);
      setError(false);
    }, 10000);
  }, [success, error]);

  const proceedCartOrder = useMutation(
    () =>
      axios.post(
        `${APIURL.url}/redeem/giftCard`,
        {
          cart: JSON.parse(localStorage.getItem("cartItems")),
          email: email,
          c_email: confirmEmail,
          note: "Gift card redemtion",
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.getItem("token"),
          },
        }
      ),
    {
      onError: (err) => {
        setOpen(false)
        setEMsg(err.response.data);
        // console.log(err);
        setError(true);
        if (err.response.status === 401) {
          window.location.reload();
          return navigate("/login");
        }
      },
      onSuccess: (data) => {
        localStorage.setItem("personal_balance", data.data.rem_bal);
        setOpen(false)
        setEMsg(data.data.success);
        setEmail("");
        setConfirmEmail("");
        setSuccess(true);
        localStorage.removeItem("cartItems");
      },
    }
  );

  useEffect(() => {
    if (!localStorage.getItem("token")) {
      window.location.reload();
      return navigate("/login");
    }
  }, []);

  useEffect(() => {
    setTimeout(() => {
      if (success) {
        navigate("/redemption");
      }
    }, 10000);
  }, [success]);

  const placeOrder = async (event) => {
    event.preventDefault();
    proceedCartOrder.mutate();
  };

  let cartItemsList = JSON.parse(localStorage.getItem("cartItems"));
  //Cart display
  let modifyCart = cartItemsList?.filter(
    (ele, ind) =>
      ind ===
      cartItemsList.findIndex((elem) => elem.code === ele.code && elem.points === ele.points)
  );

  if (proceedCartOrder.isLoading) {
    return <Loader />;
  }

  //Getting dollar conversion value from settings
  const settings = localStorage.getItem("settings");
  var obj = JSON.parse(settings);
  let pString = obj.find((o) => o.Name === "point_conversion");

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDSnackbar
        color="error"
        icon="error"
        title="Error: Unable to place order"
        content={eMsg}
        open={error}
        close={toggleSnackbar}
      />
      <MDSnackbar
        color="success"
        icon="check_circle"
        title="Success"
        content={eMsg}
        open={success}
        close={toggleSnackbar}
      />
      <MDBox mt={5}>
        {/* <Grid container spacing={3}>
          <Grid item xs={12} md={12} lg={12}>
            <MDTypography variant="h3" textTransform="capitalize" fontWeight="medium">
              Checkout
            </MDTypography>
          </Grid>
        </Grid> */}
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={6}>
            <Card className="cardBoard">
              <MDBox p={2}>
                <Typography id="keep-mounted-modal-title" variant="h5" component="h2" sx={txtColorChange}>
                  Order Summary
                  <MDTypography sx={{ fontSize: "12px", textAlign: "right", fontWeight: "bold" }}>
                    ($1 = {pString.Value}pts)
                  </MDTypography>
                </Typography>
                {modifyCart?.map((item, key) => (
                  <NotificationItem
                    icon={<img src={item.gift_card_image} width="75" />}
                    title={`$${item.points}`}
                    quantity={CartCount(item.code, item.points)}
                    keyNum={key}
                  />
                ))}
              </MDBox>
            </Card>
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <Card className="cardBoard">
              <MDBox p={2}>
                <Typography id="keep-mounted-modal-title" className="checkoutTitle" variant="h5" component="h2" sx={txtColorChange}>
                  Confirmation
                </Typography>
                <Switch checked={emailStatus} onChange={handleStatus} /> 
                <MDBox component="form" role="form">
                  <MDBox mt={2} mb={2}>
                    <MDInput
                      type="text"
                      fullWidth
                      label="Email Id"
                      placeholder="Email Id"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </MDBox>
                  <MDBox mt={2} mb={2}>
                    <MDInput
                      type="email"
                      name="confirmName"
                      fullWidth
                      label="Confirm Email Id"
                      placeholder="Confirm Email Id"
                      value={confirmEmail}
                      onChange={(e) => {
                        setConfirmEmail(e.target.value);
                      }}
                    />
                  </MDBox>
                  <Typography sx={{ fontSize: "12px" }} color={darkMode ? "secondary" : "text"}>
                    By clicking CONFIRM you're agreeing to the terms and conditions
                  </Typography>
                  <MDBox mt={2} mb={1}>
                    <MDButton variant="gradient" color="info" onClick={handleOpen} sx={bgColorChange}>
                      Confirm
                    </MDButton>
                  </MDBox>
                </MDBox>
              </MDBox>
            </Card>
            <Modal
              open={open}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box className="modalPopupBox">
                <Grid container>
                  <Typography sx={{ fontSize: "14px" }} color="secondary">
                    Rewards cannot be returned or exchanged once the order is placed, are you sure you
                    want to place the order?
                  </Typography>
                  <MDBox variant="outlined" className="close-button" onClick={handleClose}><CloseIcon /></MDBox>
                </Grid>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={12} lg={12}>
                    <MDBox mt={2} mb={1}>
                      <MDButton variant="gradient" color="info" onClick={handleClose} sx={bgColorChange} className="spacing">
                        Cancel
                      </MDButton>
                      <MDButton variant="gradient" color="success" onClick={placeOrder} sx={bgColorChange}>
                        Confirm
                      </MDButton>
                    </MDBox>
                  </Grid>
                </Grid>
              </Box>
            </Modal>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
};

export default Checkout;
