// import Card from "@mui/material/Card";
// import Divider from "@mui/material/Divider";

// Material Dashboard 2 PRO React components
import { Card, Grid } from "@mui/material";
import axios from "axios";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardMenu from "examples/Navbars/DashboardMenu";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";
import { txtColorChange } from "layouts/Rewards/User/components/Common/ColorChange";
import DataGridTables from "layouts/Rewards/User/components/Common/DataGridTables";
import Loader from "layouts/Rewards/User/components/Common/Loader";
import Logout from "layouts/Rewards/User/components/Common/Logout";
import SiteFooter from "layouts/Rewards/User/components/Common/SiteFooter";
import URLSettings from "layouts/Rewards/User/components/Common/URLSettings";
import { useEffect } from "react";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
// OrderDetails page components

function AuditLog() {
  let APIURL = URLSettings();
  const navigate = useNavigate();
  const logout = () => {
    localStorage.removeItem("token");
    navigate("/login");
  };

  useEffect(() => {
    if (!localStorage.getItem("token")) {
      window.location.reload();
      return navigate("/login");
    }
  }, []);

  const auditTables = useQuery(["auditTable"], async () =>
    axios.get(`${APIURL.url}/admin/auditTable`, {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    })
  );
  if (auditTables.isError) {
    if (auditTables.error.response.status === 401) {
      window.location.reload();
      return navigate("/login");
    }
  }

  if (!auditTables.isSuccess) {
    return <Loader />;
  }

  const transColumns = [
    { 
      field: "id", 
      headerName: "ID", 
      width: 90 
    },
    {
      field: "Created On",
      headerName: "Created On",
      minWidth: 300
    },
    {
      field: "Name",
      headerName: "Name",
      minWidth: 200
    },
    {
      field: "Table Name",
      headerName: "Table Name",
      minWidth: 150
    },
    {
      field: "Field Name",
      headerName: "Field Name",
      minWidth: 150
    },
    {
      field: "New Data",
      headerName: "New Data",
    },
    {
      field: "Company",
      headerName: "Company",
    }
  ];

  return (
    <DashboardLayout>
      {/* <DashboardNavbar /> */}
      <DashboardMenu />
      <MDBox mt={1} my={3}>
        <Card>
          <MDBox p={3} lineHeight={1}>
            <Grid container>
              <Grid item xs={6}>
                <MDTypography variant="h5" fontWeight="medium" sx={txtColorChange}>
                  Audit Log
                </MDTypography>
              </Grid>
            </Grid>
          </MDBox>
          {/* <DataTable table={auditTables.data.data} canSearch /> */}
          <DataGridTables tableRow={auditTables.data.data.rows} columns={transColumns}  />
          {/* <MDTypography
            component="p"
            variant="button"
            fontWeight="bold"
            sx={{ textAlign: "center" }}
            mt={3}
            mb={5}
          >
            No Data Available
          </MDTypography> */}
        </Card>
      </MDBox>
      <SiteFooter />
    </DashboardLayout>
  );
}

export default AuditLog;
