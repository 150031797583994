const { default: Footer } = require("examples/Footer");

function SiteFooter() {
  const settings = localStorage.getItem("settings");
  let copyright = { Name: "", Value: "" };
  if (settings) {
    var obj = JSON.parse(settings);
    copyright = obj.find((o) => o.Name === "copy_right");
    if (copyright != "undefined") {
      copyright = copyright;
    }
  }

  return <Footer company={{ href: "http://genesissoftsol.com", name: copyright.Value }} />;
}

export default SiteFooter;
