import { Grid } from "@mui/material";
import axios from "axios";
import MDBox from "components/MDBox";
import OrderCartDisplay from "examples/Cards/OrderCartDisplay";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import Loader from "../Common/Loader";
import URLSettings from "../Common/URLSettings";
import { isMobile } from "react-device-detect";
import { useMaterialUIController } from "context";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import { Link, useNavigate } from "react-router-dom";
import MDSnackbar from "components/MDSnackbar";
import CartCount from "../Common/CartCount";

const OrderCart = () => {
  let APIURL = URLSettings();
  const navigate = useNavigate();
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  //   const giftCards = useQuery(["giftCards"], async () =>
  //     axios.get(`${APIURL.url}/giftCardCatalog`, {
  //       params: {
  //         isMobile,
  //         isDark: darkMode,
  //       },
  //       headers: {
  //         Authorization: localStorage.getItem("token"),
  //       },
  //     })
  //   ); // TODO: error checking

  //   if (!giftCards.isSuccess) {
  //     return <Loader />;
  //   }
  const handleDelete = (cartObj) => {
    let cartList = JSON.parse(localStorage.getItem("cartItems"));

    if (cartObj > -1) {
      //Make sure item is present in the array, without if condition, -n indexes will be considered from the end of the array.
      cartList.splice(cartObj, 1);
    }
    localStorage.setItem("cartItems", JSON.stringify(cartList));
    let newCartList = JSON.parse(localStorage.getItem("cartItems"));
    setMCartCount(newCartList.length);
    //setUpdateCount(newCartList.length);
    //updateCountFromCart(newCartList.length);
    setCartDel(true);
  };

  var cartItemsList = JSON.parse(localStorage.getItem("cartItems"));
  let modifyCart = [];
  modifyCart = cartItemsList?.filter(
    (ele, ind) =>
      ind ===
      cartItemsList.findIndex((elem) => elem.code === ele.code && elem.points === ele.points)
  );

  let cartLength;
  if (modifyCart === undefined) {
    cartLength = 0;
  } else {
    cartLength = modifyCart?.length;
  }

  const [cartDel, setCartDel] = useState(false);
  const [mCartCount, setMCartCount] = useState(
    JSON.parse(localStorage.getItem("cartItems"))
      ? JSON.parse(localStorage.getItem("cartItems")).length
      : 0
  );

  const toggleSnackbar = () => {
    if (cartDel) {
      setCartDel(!cartDel);
    }
  };

  useEffect(() => {
    if (!localStorage.getItem("token")) {
      window.location.reload();
      return navigate("/login");
    }
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setCartDel(false);
    }, 2000);
  }, [cartDel]);

  return (
    <DashboardLayout>
      <DashboardNavbar cartCount={mCartCount} />
      <MDSnackbar
        autoHideDuration="1000"
        color="error"
        icon="error"
        title="Delete Cart"
        content="Gift Card deleted from cart"
        open={cartDel}
        close={toggleSnackbar}
      />
      <MDBox mt={5}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={10} lg={10}>
            {/* <MDTypography variant="h3" textTransform="capitalize" fontWeight="medium">
              Cart
            </MDTypography> */}
          </Grid>
          <Grid item xs={12} md={2} lg={2}>
            {cartLength > 0 && (
              <Link to="/checkout">
                <MDButton color="success" size="small" fullWidth>
                  Checkout
                </MDButton>
              </Link>
            )}
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} md={12} lg={12}>
            {cartLength === 0 && (
              <MDTypography
                component="p"
                variant="button"
                fontWeight="bold"
                sx={{ textAlign: "center" }}
                mt={3}
                mb={3}
              >
                No Data Available
              </MDTypography>
            )}
          </Grid>

          {modifyCart?.map((giftCard, key) => (
            <Grid item xs={12} md={6} lg={4}>
              <MDBox mb={1.5} mt={1.5}>
                <OrderCartDisplay
                  image={giftCard.gift_card_image}
                  title={giftCard.brandName}
                  description={giftCard.description}
                  quantity={CartCount(giftCard.code, giftCard.points)}
                  points={giftCard.points}
                  terms={giftCard.terms}
                  disclaimer={giftCard.disclaimer}
                  shortDescription={giftCard.shortDescription}
                  keyNum={key}
                  handleCartDelete={handleDelete}
                />
              </MDBox>
            </Grid>
          ))}
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
};

export default OrderCart;
