/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/** 
  All of the routes for the Material Dashboard 2 PRO React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that contains other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

// Material Dashboard 2 PRO React layouts
import UserView from "layouts/Rewards/User";
import LoginView from "layouts/Rewards/Login";

// Material Dashboard 2 PRO React components

// @mui icons
import Icon from "@mui/material/Icon";
import AdminView from "layouts/Rewards/Admin";
import MDAvatar from "components/MDAvatar";
// import Send from "layouts/Rewards/User/components/Send";
import TransactionList from "layouts/Rewards/Admin/components/TransactionList";
import AuditLog from "layouts/Rewards/Admin/components/AuditLog";
import UsersList from "layouts/Rewards/Admin/components/UsersList";
import Settings from "layouts/Rewards/Admin/components/Settings";
import Banners from "layouts/Rewards/Admin/components/Banners";
import UserRoles from "layouts/Rewards/Admin/components/UserRoles";
import AdminSettings from "layouts/Rewards/Admin/components/AdminSettings";
import MessageTemplates from "layouts/Rewards/Admin/components/MessageTemplates";
import EmailTemplates from "layouts/Rewards/Admin/components/EmailTemplates";
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import ViewComfyIcon from '@mui/icons-material/ViewComfy';
import JsLayout from "layouts/Rewards/Admin/JsLayout";
import CustomGiftCards from "layouts/Rewards/Admin/components/CustomGiftCards/CustomGiftCards";
import GiftCards from "layouts/Rewards/Admin/components/GiftCards";
import Automation from "layouts/Rewards/Admin/components/Automation";
import Dashboard from "layouts/Rewards/Admin/components/Dashboard";
import MeshiBalance from "layouts/Rewards/Admin/components/MeshiBalance";
import Roles from "layouts/Rewards/Admin/components/Roles";
import Modules from "layouts/Rewards/Admin/components/Modules";
import RoleModules from "layouts/Rewards/Admin/components/RoleModules";
import EmployeeRoleMapping from "layouts/Rewards/Admin/components/EmployeeRoleMapping";

const profilPic = localStorage.getItem("profilepic");
const settings = localStorage.getItem("settings");

let iconInfo = <Icon>account_circle</Icon>;
if (profilPic != "") {
  iconInfo = <MDAvatar src={profilPic} alt="Brooklyn Alice" size="sm" />;
}

let finalRoutes = {};
let finalRouteFlag = false;
let adminSetting = {};

let defaultValue = { Name: "", Value: "", Status: false };

let swagFlag = defaultValue;
let customSwag = {};
if (settings && settings !== undefined) {
  var obj = JSON.parse(settings);
  swagFlag = obj.find((o) => o.Name === "swag_flag");

  if (swagFlag === undefined ) {
    swagFlag = defaultValue;
  }
}

if (swagFlag.Value === "yes") {
  customSwag = {
    type: "collapse",
    name: "Custom Gift Cards",
    key: "custom-giftcards",
    icon: <Icon fontSize="medium">giftcard</Icon>,
    route: "/custom-giftcards",
    component: <CustomGiftCards />,
    noCollapse: true,
  }
}

if (localStorage.getItem("token")) {

  const jose = require("jose");
  const claims = jose.decodeJwt(localStorage.getItem("token"));

  if (claims.sp_admin === "yes") {

    adminSetting = {
      type: "collapse",
      name: "Configuration",
      key: "settings",
      icon: <AdminPanelSettingsIcon />,
      collapse: [
        // {
        //   type: "collapse",
        //   name: "Admin Settings",
        //   key: "admin-settings",
        //   icon: <Icon fontSize="medium">settings</Icon>,
        //   route: "/admin-settings",
        //   component: <AdminSettings />,
        //   noCollapse: true,
        // },
        {
          type: "collapse",
          name: "User Roles",
          key: "user-roles",
          icon: <Icon fontSize="medium">security</Icon>,
          route: "/user-roles",
          component: <UserRoles />,
          noCollapse: true,
        },
        {
          type: "collapse",
          name: "Security",
          key: "settings",
          icon: <Icon fontSize="medium">security</Icon>,
          collapse: [
            {
              name: "Roles",
              key: "roles",
              route: "/roles",
              component: <Roles />,
              noCollapse: true,
            },
            {
              name: "Modules",
              key: "modules",
              route: "/modules",
              component: <Modules />,
              noCollapse: true
            },
            {
              name: "Role Modules",
              key: "role-modules",
              route: "/role-modules",
              component: <RoleModules />,
              noCollapse: true
            },
            {
              name: "Employee Role Mapping",
              key: "employee-role-mapping",
              route: "/employee-role-mapping",
              component: <EmployeeRoleMapping />, 
              noCollapse: true
            }
          ],
        },
        {
          type: "collapse",
          name: "Templates",
          key: "templates",
          icon: <Icon fontSize="medium">message</Icon>,
          collapse: [
            {
              name: "Mesage Templates",
              key: "message-templates",
              route: "/message-templates",
              component: <MessageTemplates />,
              noCollapse: true,
            },
            {
              name: "Email Templates",
              key: "email-templates",
              route: "/email-templates",
              component: <EmailTemplates />,
              noCollapse: true
            }
          ],
        },
        {
          type: "collapse",
          name: "Layout",
          key: "layout",
          icon: <ViewComfyIcon />,
          route: "/layout",
          component: <JsLayout />,
          noCollapse: true,
        },
        {
          type: "collapse",
          name: "Gift Cards",
          key: "giftcards",
          icon: <ViewComfyIcon />,
          route: "/giftcards",
          component: <GiftCards />,
          noCollapse: true,
        },
        {
          type: "collapse",
          name: "Automation",
          key: "automation",
          icon: <Icon fontSize="medium">security</Icon>,
          route: "/automation",
          component: <Automation />,
          noCollapse: true,
        },
        {
          type: "collapse",
          name: "Meshi Balance",
          key: "meshibalance",
          icon: <Icon fontSize="medium">money</Icon>,
          route: "/meshibalance",
          component: <MeshiBalance />,
          noCollapse: true,
        },
      ],
    }

  }

  if (claims.sp_admin === "yes" || claims.admin === "yes") {
    finalRouteFlag = true;
  }

}

const designRoutes = [
  {
    type: "collapse",
    name: "Firstname Lastname",
    key: "user-name",
    icon: iconInfo,
    collapse: [
      {
        name: "User",
        key: "user",
        route: "/home",
        component: <UserView />,
      },
      {
        name: "Logout",
        key: "logout",
        route: "/login",
        component: <LoginView />,
      },
    ],
  },
  { type: "divider", key: "divider-01" },
  {
    type: "collapse",
    name: "Dashboard",
    key: "dashboard",
    icon: <Icon fontSize="medium">dashboard</Icon>,
    route: "/dashboard",
    component: <Dashboard />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Banners",
    key: "banners",
    icon: <Icon fontSize="medium">images</Icon>,
    route: "/banners",
    component: <Banners />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Settings",
    key: "admin-settings",
    icon: <Icon fontSize="medium">settings</Icon>,
    route: "/admin-settings",
    component: <AdminSettings />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Mesage Templates",
    key: "message-templates",
    icon: <Icon fontSize="medium">message</Icon>,
    route: "/message-templates",
    component: <MessageTemplates />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Email Templates",
    key: "email-templates",
    icon: <Icon fontSize="medium">message</Icon>,
    route: "/email-templates",
    component: <EmailTemplates />,
    noCollapse: true
  },
  customSwag,
  // {
  //   type: "collapse",
  //   name: "Configuration",
  //   key: "admin-settings",
  //   icon: <AdminPanelSettingsIcon />,
  //   collapse: [
  //     {
  //       type: "collapse",
  //       name: "Admin Settings",
  //       key: "admin-settings",
  //       icon: <Icon fontSize="medium">settings</Icon>,
  //       route: "/admin-settings",
  //       component: <AdminSettings />,
  //       noCollapse: true,
  //     },
  //     {
  //       type: "collapse",
  //       name: "Templates",
  //       key: "settings",
  //       icon: <Icon fontSize="medium">message</Icon>,
  //       collapse: [
  //         {
  //           name: "Mesage Templates",
  //           key: "message-templates",
  //           route: "/message-templates",
  //           component: <MessageTemplates />,
  //           noCollapse: true,
  //         },
  //         {
  //           name: "Email Templates",
  //           key: "email-templates",
  //           route: "/email-templates",
  //           component: <EmailTemplates />,
  //           noCollapse: true
  //         }
  //       ],
  //     }
  //   ],
  // },
]; 


const adminroutes = [
  {
    type: "collapse",
    name: "Firstname Lastname",
    key: "user-name",
    icon: iconInfo,
    collapse: [
      {
        name: "User",
        key: "user",
        route: "/home",
        component: <UserView />,
      },
      {
        name: "Logout",
        key: "logout",
        route: "/login",
        component: <LoginView />,
      },
    ],
  },
  { type: "divider", key: "divider-01" },
  {
    type: "collapse",
    name: "Dashboard",
    key: "admin",
    icon: <Icon fontSize="medium">dashboard</Icon>,
    route: "/admin",
    component: <AdminView />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Transactions List",
    key: "transactions",
    icon: <Icon fontSize="medium">list</Icon>,
    route: "/transactions",
    component: <TransactionList />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Users List",
    key: "users",
    icon: <Icon fontSize="medium">group</Icon>,
    route: "/users",
    component: <UsersList />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Audit Log",
    key: "audit-log",
    icon: <Icon fontSize="medium">key</Icon>,
    route: "/audit-log",
    component: <AuditLog />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Banners",
    key: "banners",
    icon: <Icon fontSize="medium">images</Icon>,
    route: "/banners",
    component: <Banners />,
    noCollapse: true,
  },
  adminSetting,
  // {
  //   type: "collapse",
  //   name: "Settings",
  //   key: "settings",
  //   icon: <Icon fontSize="medium">settings</Icon>,
  //   route: "/settings",
  //   component: <Settings />,
  //   noCollapse: true,
  // },
  {
    type: "collapse",
    name: "Settings",
    key: "admin-settings",
    icon: <Icon fontSize="medium">settings</Icon>,
    route: "/admin-settings",
    component: <AdminSettings />,
    noCollapse: true,
  },
  customSwag,  
];


if (finalRouteFlag) {
  finalRoutes = adminroutes;
} else {
  finalRoutes = designRoutes;
}


export default finalRoutes;
