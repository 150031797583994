// import React, { useState } from 'react';// potentially unnessary?

// @mui material components
import Grid from "@mui/material/Grid";
// import Card from "@mui/material/Card";
// import Divider from "@mui/material/Divider";
import { useEffect, useState } from "react";
// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
// import MuiLink from "@mui/material/Link";
// import BookingCard from "examples/Cards/BookingCard";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

// OrderDetails page components
import Banner from "layouts/Rewards/User/components/Banner";

import "./Dashboard.css";
import { useNavigate } from "react-router-dom";
import Feed from "./components/Feed";
import MiddleBanner from "./components/MiddleBanner";
import SiteFooter from "./components/Common/SiteFooter";
// import Activity from "./components/Activity";
import Celebration from "./components/Celebration";
import { Card, Tooltip, Icon, Switch } from "@mui/material";
import Login from "../Login";
import DashboardMenu from "examples/Navbars/DashboardMenu";
import MDButton from "components/MDButton";
import { bgColorChange } from "./components/Common/ColorChange";
import PagePermissions from "./components/Common/PagePermissions";

function UserView() {
  const navigate = useNavigate();

  useEffect(() => {
    if (!localStorage.getItem("token")) {
      window.location.reload();
      return navigate("/login");
    }
  }, []);

  let finalRouteFlag = 'no';
  if (localStorage.getItem("token")) {
    const jose = require("jose");
    const claims = jose.decodeJwt(localStorage.getItem("token"));
    if (claims.sp_admin === "yes" || claims.admin === "yes") {
      finalRouteFlag = "yes";
    }
  }

  PagePermissions();

  const [pageStatus, setPageStatus] = useState(false);
  const handleEdit = () => {
    setPageStatus(!pageStatus);
  } 

  return (
    <DashboardLayout>
      <DashboardMenu pageStatus={pageStatus} />
      <MDBox my={1}>
        {finalRouteFlag === 'yes' && (
        <MDBox sx={{ position: "absolute", right: 0, top: 0}}>
          <Tooltip title="Edit" placement="top">
            <Switch checked={pageStatus} onChange={handleEdit} />
            {/* <MDButton
              variant="gradient"
              color="info"
              size="small"
              iconOnly
              onClick={handleEdit}
              sx={bgColorChange}
            >
              <Icon>edit</Icon>
            </MDButton> */}
          </Tooltip> 
        </MDBox>
        )}
        <Grid container spacing={2} justifyContent="center" mt={1}>
          <Grid item xs={12} lg={12} sx={{ paddingTop: "0px !important"}}>
            <Banner location="upper" pageStatus={pageStatus} />
          </Grid>
          <Grid item xs={12} lg={12}>
            <Celebration pageStatus={pageStatus} />
          </Grid>
          <Grid item xs={12} lg={12}>
            <MiddleBanner location="middle" pageStatus={pageStatus} />
          </Grid>
        </Grid>
      </MDBox>
      <SiteFooter />
    </DashboardLayout>
  );
}

export default UserView;
