import { Card, Grid } from "@mui/material";
import ListView from "examples/Lists/ListView";
import React from "react";
import { useNavigate } from "react-router-dom";
import URLSettings from "../Common/URLSettings";
import { useQuery } from "react-query";
import MDBox from "components/MDBox";
import axios from "axios";

const LeaderBoard = ({pageStatus}) => {

  let APIURL = URLSettings();
  const navigate = useNavigate(); 

  const leaderBoard = useQuery(["leaderBoard"], async () =>
    axios.get(`${APIURL.url}/getLeaderBoard`, {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    })
  );

  if (leaderBoard.isError) {
    if (leaderBoard.error.response.status === 401) {
      window.location.reload();
      return navigate("/login");
    } 
  }

  if (!leaderBoard.isSuccess) { 
    return (
      <MDBox my={2}>
        <MDBox p={1} m={1} mt={2}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6} lg={6}>
              <Card />
            </Grid>
          </Grid>
        </MDBox>
      </MDBox> 
    );
  }

  return (
    <Card sx={{ backgroundColor: "#ffffff", borderRadius: "10px", padding: "5px", marginBottom: "20px" }}>      
      <MDBox 
        p={2}
        className="lbBoard"
      >
        <Grid container>
          <Grid item xs={12} md={12} lg={12}>
            <ListView title="Monthly LeaderBoard" profiles={leaderBoard.data.data?.leaderboard} pageStatus={pageStatus} />
          </Grid>
        </Grid>
      </MDBox>
    </Card>
  );
};

export default LeaderBoard;
