/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
// import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";

import MDSnackbar from "components/MDSnackbar";
import MDTypography from "components/MDTypography";
import Loader from "../User/components/Common/Loader";
import NotFound from "../User/components/Common/NotFound";
import { useMutation } from "react-query";
import URLSettings from "../User/components/Common/URLSettings";
import { MuiOtpInput } from 'mui-one-time-password-input'
import { Grid } from "@mui/material";
import CountdownTimer from "../User/components/Common/CountdownTimer";

function OtpVerification() {
  let APIURL = URLSettings();
  const [phone, setPhone] = useState(null);
  const [otp, setOtp] = useState('')
  const [error, setError] = useState(false);
  const [rsuccess, setRSuccess] = useState(false);
  const [eMsg, setEMsg] = useState("");
  const navigate = useNavigate();

  const toggleSnackbar = () => {
    if (error) {
      setError(!error);
    }
    if (rsuccess) {
      setRSuccess(!rsuccess);
    }
  };
  useEffect(() => {
    setTimeout(() => {
      setRSuccess(false);
      setError(false);
    }, 2000);
  }, [rsuccess, error]);

  const [logo, setLogo] = useState(0);
  async function fetchLogoData() {
    try {
      const result = await axios.get(`${APIURL.url}/settings`, {
        params: { setting_name: "login_screen_logo" },
      });
      if (result.data.logo) {
        setLogo(result.data.logo);
      }
    } catch (error) {
      console.error(error);
    }
  }
  useEffect(() => {
    fetchLogoData();
  }, []);

  const handleChange = (newValue) => {
    setOtp(newValue)
  }

  const sendResetLink = useMutation(
    async () =>
      axios.post(
        `${APIURL.url}/passwordReset`,
        {
          otp_verify: false,
          token: JSON.parse(localStorage.getItem("passwordToken")),
          otp: otp,
          // ui_url: window.location.origin,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      ),
    {
      onError: (err) => {
        console.log(err.response.data);
        setEMsg(err.response.data);
        setError(true);
      },
      onSuccess: (data) => {
        setRSuccess(true);
        setEMsg(data.data);
        setTimeout(() => {
          navigate("/confirmpassword");
        }, 3000);
      },
    }
  );

  const handleReset = () => {
    sendResetLink.mutate();
  };

  const resendOTP = useMutation(
    async () =>
      axios.post(
        `${APIURL.url}/passwordReset`,
        {
          reset_phone: localStorage.getItem("phone"),
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      ),
    {
      onError: (err) => {
        console.log(err.response.data);
        setEMsg(err.response.data);
        setError(true);
      },
      onSuccess: (data) => {
        setRSuccess(true);
        setEMsg('OTP sent to your phone, please check once');
        setTimeout(() => {
          navigate("/otp-verification");
        }, 3000);
      },
    }
  );

  const handleResendOTP = () => {
    resendOTP.mutate();
  }

  return (
    <>
      {(!logo && <Loader />) ||
        (logo === 0 && <NotFound />) ||
        (logo && logo !== "undefined" && (
          <BasicLayout>
            <MDSnackbar
              color="error"
              icon="error"
              title="Error: Something went wrong"
              content={eMsg}
              open={error}
              close={toggleSnackbar}
            />
            <MDSnackbar
              color="success"
              icon="check_circle"
              title="Success"
              content={eMsg}
              open={rsuccess}
              close={toggleSnackbar}
            />
            <Card>
              <MDBox
                variant="gradient"
                className="loginLogo"
                borderRadius="lg"
                coloredShadow="info"
                mx={2}
                mt={-3}
                p={2}
                mb={1}
                textAlign="center"
              >
                <img src={logo} alt="Wireless Vision" width={100} />
              </MDBox>
              <MDBox pt={4} pb={3} px={3}>
                <MDBox component="form" role="form">
                  <MDBox mb={2}>
                    <MuiOtpInput value={otp} onChange={handleChange} length={6} />
                    {/* <MDInput
                      type="input"
                      label="Phone Number"
                      value={phone}
                      onChange={(e) => setPhone(e.target.value)}
                      fullWidth
                    /> */}
                  </MDBox>
                  <MDBox mt={4} mb={1}>
                    <MDButton variant="gradient" color="info" fullWidth onClick={handleReset}>
                      Verify
                    </MDButton>
                    <Grid container>
                      <Grid item xs={12} md={6} lg={6}>
                        <MDTypography
                          variant="button"
                          fontWeight="regular"
                          color="text"
                          sx={{ cursor: "pointer", userSelect: "none", mt: 1, float: "left" }}
                        >
                          <CountdownTimer start={true} />
                        </MDTypography>
                      </Grid>
                      <Grid item xs={12} md={6} lg={6}>
                        <MDTypography
                          variant="button"
                          fontWeight="regular"
                          color="text"
                          sx={{ cursor: "pointer", userSelect: "none", mt: 1, float: "right" }}
                          onClick={handleResendOTP}
                        >
                          Resend otp
                        </MDTypography>
                      </Grid>
                    </Grid>
                  </MDBox>
                </MDBox>
              </MDBox>
            </Card>
          </BasicLayout>
        ))}
    </>
  );
}

export default OtpVerification;
