// import Card from "@mui/material/Card";
// import Divider from "@mui/material/Divider";

// Material Dashboard 2 PRO React components
import { Card, Grid } from "@mui/material";
import axios from "axios";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDSnackbar from "components/MDSnackbar";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardMenu from "examples/Navbars/DashboardMenu";
import { bgColorChange } from "layouts/Rewards/User/components/Common/ColorChange";
import { txtColorChange } from "layouts/Rewards/User/components/Common/ColorChange";
import SiteFooter from "layouts/Rewards/User/components/Common/SiteFooter";
import URLSettings from "layouts/Rewards/User/components/Common/URLSettings";
import { useEffect, useState } from "react";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
// OrderDetails page components

function MeshiBalance() {
  let APIURL = URLSettings();
  const navigate = useNavigate();

  useEffect(() => {
    if (!localStorage.getItem("token")) {
      window.location.reload();
      return navigate("/login");
    }
  }, []);

  const [balance, setBalance] = useState();
  const [eMsg, setEMsg] = useState("");
  const [createError, setCreateError] = useState(false);
  const [createSuccess, setCreateSuccess] = useState(false);
  const [rId, setRId] = useState();

  const toggleSnackbar = () => {
    if (createError) {
      setCreateError(!createError);
    }
    if (createSuccess) {
      setCreateSuccess(!createSuccess);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      setCreateError(false);
      setCreateSuccess(false);
    }, 2000);
  }, [createError, createSuccess]);

  const handleBalanceCreate = useMutation(
    async () =>
      axios.post(
        `${APIURL.url}/admin/meshibalance`,
        {
          balance: parseInt(balance),
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.getItem("token"),
          },
        }
      ),
    {
      onError: (err) => {
        setCreateError(true);
        if (err.response.status === 401) {
          window.location.reload();
          return navigate("/login");
        }
      },
      onSuccess: (data) => {
        setEMsg(data.data);
        setCreateSuccess(true);
        setBalance("");
      },
    }
  );

  const handleCreate = () => {
    handleBalanceCreate.mutate();
  };

  const handleRefcheck = () => {

  }

  return (
    <DashboardLayout>
      <DashboardMenu />
      <MDBox mt={1} my={3}>
        <MDSnackbar
          color="error"
          icon="error"
          title="Error: Something went wrong"
          content={eMsg}
          open={createError}
          close={toggleSnackbar}
        />
        <MDSnackbar
          color="success"
          icon="check_circle"
          title="Success"
          content={eMsg}
          open={createSuccess}
          close={toggleSnackbar}
        />
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={6}>
            <Card>
              <MDBox p={3} lineHeight={1}>
                <Grid container>
                  <Grid item xs={6}>
                    <MDTypography variant="h5" fontWeight="medium" sx={txtColorChange}>
                      Meshi Balance
                    </MDTypography>
                  </Grid>
                </Grid>
              </MDBox>
              <MDBox component="form" role="form" p={2}>
                <Grid container>
                  <Grid item xs={8}>
                    <MDBox mb={2} mr={1}>
                      <MDInput
                        type="text"
                        fullWidth
                        label="Meshi Balance"
                        placeholder="Meshi Balance"
                        value={balance}
                        onChange={(e) => {
                          setBalance(e.target.value);
                        }}
                      />
                    </MDBox>
                  </Grid>
                </Grid>
                <MDBox mb={1}>
                  <MDButton
                    variant="gradient"
                    color="info"
                    onClick={handleCreate}
                    sx={bgColorChange}
                  >
                    Submit
                  </MDButton>
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <Card>
              <MDBox p={3} lineHeight={1}>
                <Grid container>
                  <Grid item xs={6}>
                    <MDTypography variant="h5" fontWeight="medium" sx={txtColorChange}>
                      Check Transaction
                    </MDTypography>
                  </Grid>
                </Grid>
              </MDBox>
              <MDBox component="form" role="form" p={2}>
                <Grid container>
                  <Grid item xs={8}>
                    <MDBox mb={2} mr={1}>
                      <MDInput
                        type="text"
                        fullWidth
                        label="Reference Id"
                        placeholder="Reference Id"
                        value={rId}
                        onChange={(e) => {
                          setRId(e.target.value);
                        }}
                      />
                    </MDBox>
                  </Grid>
                </Grid>
                <MDBox mb={1}>
                  <MDButton
                    variant="gradient"
                    color="info"
                    onClick={handleRefcheck}
                    sx={bgColorChange}
                  >
                    Submit
                  </MDButton>
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <SiteFooter />
    </DashboardLayout>
  );
}

export default MeshiBalance;
