import { Box, Card, Grid, Icon, Modal, Switch, Typography } from "@mui/material";
import axios from "axios";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { bgColorChange } from "layouts/Rewards/User/components/Common/ColorChange";
import { txtColorChange } from "layouts/Rewards/User/components/Common/ColorChange";
import DataGridTables from "layouts/Rewards/User/components/Common/DataGridTables";
import Loader from "layouts/Rewards/User/components/Common/Loader";
import SiteFooter from "layouts/Rewards/User/components/Common/SiteFooter";
import URLSettings from "layouts/Rewards/User/components/Common/URLSettings";
import React, { useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import MDSnackbar from "components/MDSnackbar";
import DashboardMenu from "examples/Navbars/DashboardMenu";

const MessageTemplates = () => {
  let APIURL = URLSettings();
  const navigate = useNavigate();

  useEffect(() => {
    if (!localStorage.getItem("token")) {
      window.location.reload();
      return navigate("/login");
    }
  }, []);

  const msgColumns = [
    {
      field: "id",
      headerName: "ID",
      width: 90,
    },
    {
      field: "name",
      headerName: "Name",
      minWidth: 200,
    },
    {
      field: "message",
      headerName: "Message",
      minWidth: 500,
    },
    {
      field: "active",
      headerName: "Active",
      minWidth: 150,
    },
    {
      field: "actions",
      headerName: "Actions",
      sortable: false,
      renderCell: (params) => {
        return (
          <MDButton
            variant="gradient"
            color="error"
            size="small"
            iconOnly
            onClick={() => handleRowUpdate(params.row)}
            sx={bgColorChange}
          >
            <Icon>edit</Icon>
          </MDButton>
        );
      },
    },
  ];

  const [eMsg, setEMsg] = useState("");
  const [createError, setCreateError] = useState(false);
  const [createSuccess, setCreateSuccess] = useState(false);
  const [updateError, setUpdateError] = useState(false);
  const [updateSuccess, setUpdateSuccess] = useState(false);

  const toggleSnackbar = () => {
    if (createError) {
      setCreateError(!createError);
    }
    if (createSuccess) {
      setCreateSuccess(!createSuccess);
    }
    if (updateError) {
      setUpdateError(!updateError);
    }
    if (updateSuccess) {
      setUpdateSuccess(!updateSuccess);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      setCreateError(false);
      setCreateSuccess(false);
      setUpdateError(false);
      setUpdateSuccess(false);
    }, 2000);
  }, [
    createError,
    createSuccess,
    updateError,
    updateSuccess
  ]);

  const msgTemps = useQuery(["msgTemps"], async () =>
    axios.get(`${APIURL.url}/admin/messagetemplates`, {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    })
  );

  const [editUser, setEditUser] = useState(false);
  const [opText, setOpText] = useState(false);
  const [uId, setUId] = useState("");
  const [name, setName] = useState("");
  const [message, setMessage] = useState("");
  const [isdefault, setIsDefault] = useState(false);

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setUId("");
    setName("");
    setMessage("");
    setIsDefault(false);
    setEditUser(false);
    setOpText(false);
    setOpen(false);
  }
  

  const handleRowUpdate = async (cell) => {
    setOpText(true);
    setEditUser(true);
    let obj = cell;
    Object.keys(obj).forEach((key) => {
      var replacedKey = key.trim().toLowerCase().replace(" ", "_");
      if (key !== replacedKey) {
        obj[replacedKey] = obj[key];
        delete obj[key];
      }

      setUId(cell.id);
      setName(obj.name);
      setMessage(obj.message);
      setIsDefault(obj.active);
      handleOpen();
    });
  };

  const msgCreate = useMutation(
    async () =>
      axios.post(
        `${APIURL.url}/admin/messagetemplates`,
        {
          name: name,
          message: message,
          active: isdefault,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.getItem("token"),
          },
        }
      ),
    {
      onError: (err) => {
        setEMsg(err.response.data);
        setCreateError(true);
        if (err.response.status === 401) {
          window.location.reload();
          return navigate("/login");
        }
      },
      onSuccess: () => {
        setCreateSuccess(true);
        setName("");
        setMessage("");
        setIsDefault(false);
        handleClose();
        msgTemps.refetch();
      },
    }
  );

  const handleCreate = () => {
    msgCreate.mutate();
  };

  const msgUpdate = useMutation(
    async () =>
      axios.put(
        `${APIURL.url}/admin/messagetemplates?pk_id=` + uId,
        {
          name: name,
          message: message,
          active: isdefault,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.getItem("token"),
          },
        }
      ),
    {
      onError: (err) => {
        setEMsg(err.response.data);
        setUpdateError(true);
        if (err.response.status === 401) {
          window.location.reload();
          return navigate("/login");
        }
      },
      onSuccess: () => {
        setUpdateSuccess(true);
        setName("");
        setMessage("");
        setIsDefault(false);
        handleClose();
        msgTemps.refetch();
      },
    }
  );

  const handleUpdate = () => {
    msgUpdate.mutate();
  };

  const handleDefault = () => {
    setIsDefault(!isdefault);
  };

  if (msgTemps.isError) {
    if (msgTemps.error.response.status === 401) {
      window.location.reload();
      return navigate("/login");
    }
  }

  if (!msgTemps.isSuccess) {
    return <Loader />;
  }

  return (
    <DashboardLayout>
      {/* <DashboardNavbar /> */}
      <DashboardMenu />
      <MDBox mt={1} my={3}>
        <Card>
          <MDBox p={3} lineHeight={1}>
            <Grid container>
              <Grid item xs={6}>
                <MDTypography variant="h5" fontWeight="medium" sx={txtColorChange}>
                  Message Templates
                </MDTypography>
              </Grid>
              <Grid item xs={6}>
                <MDButton
                  variant="gradient"
                  color="info"
                  sx={bgColorChange}
                  className="adminBtn iconBtns"
                  onClick={handleOpen}
                >
                  <Icon>add</Icon>
                </MDButton>
              </Grid>
            </Grid>
          </MDBox>
          <MDSnackbar
            color="error"
            icon="error"
            title="Error: Something went wrong"
            content={eMsg}
            open={createError}
            close={toggleSnackbar}
          />
          <MDSnackbar
            color="success"
            icon="check_circle"
            title="Success"
            content="Created successfully"
            open={createSuccess}
            close={toggleSnackbar}
          />
          <MDSnackbar
            color="error"
            icon="error"
            title="Error: Something went wrong"
            content={eMsg}
            open={updateError}
            close={toggleSnackbar}
          />
          <MDSnackbar
            color="success"
            icon="check_circle"
            title="Success"
            content="Updated Successfully"
            open={updateSuccess}
            close={toggleSnackbar}
          /> 
          <DataGridTables tableRow={msgTemps.data.data} columns={msgColumns} />
        </Card>
      </MDBox>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="modalPopupBox">
          <Grid container>
            <Grid item xs={12}>
              <Typography
                id="keep-mounted-modal-title"
                variant="h5"
                component="h2"
                sx={txtColorChange}
              >
                {opText ? "Update Message Template" : "Create Message Template"}
              </Typography>
              <MDBox variant="outlined" className="close-button" onClick={handleClose}>
                <CloseIcon />
              </MDBox>
            </Grid>
          </Grid>
          <MDBox component="form" role="form" className="formInput">
            <Grid container>
              <Grid item xs={6}>
                <MDBox mt={2} mb={2} mr={1}>
                  <MDInput
                    type="text"
                    label="Name"
                    fullWidth
                    placeholder="Name"
                    value={name}
                    onChange={(e) => {
                      setName(e.target.value);
                    }}
                  />
                </MDBox>
              </Grid>
              <Grid item xs={6}>
                <MDBox mt={2} mb={2}>
                  <MDInput
                    type="text"
                    label="Message"
                    fullWidth
                    placeholder="Message"
                    value={message}
                    onChange={(e) => {
                      setMessage(e.target.value);
                    }}
                  />
                </MDBox>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={6}>
                <MDBox mt={2} mb={2}>
                  <MDTypography
                    variant="button"
                    fontWeight="regular"
                    color="text"
                    sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
                  >
                    &nbsp;&nbsp;Status :&nbsp;
                  </MDTypography>
                  <Switch checked={isdefault} onChange={handleDefault} />
                </MDBox>
              </Grid>
            </Grid>
            <MDBox mt={2} mb={1}>
              {opText ? (
                <>
                  <MDButton
                    variant="gradient"
                    color="info"
                    onClick={handleUpdate}
                    sx={bgColorChange}
                  >
                    Submit
                  </MDButton>
                </>
              ) : (
                <>
                  <MDButton
                    variant="gradient"
                    color="info"
                    onClick={handleCreate}
                    sx={bgColorChange}
                  >
                    Submit
                  </MDButton>
                </>
              )}
            </MDBox>
          </MDBox>
        </Box>
      </Modal>
      <SiteFooter />
    </DashboardLayout>
  );
};

export default MessageTemplates;
