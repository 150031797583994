/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
// import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";

import MDSnackbar from "components/MDSnackbar";
import Loader from "../User/components/Common/Loader";
import NotFound from "../User/components/Common/NotFound";
import { useMutation } from "react-query";
import URLSettings from "../User/components/Common/URLSettings";

function ConfirmPassword() {
  let APIURL = URLSettings();
  const [eMsg, setEMsg] = useState("");
  const [password, setPassword] = useState(null);
  const [cPassword, setCPassword] = useState(null);
  const [error, setError] = useState(false);
  const [rsuccess, setRSuccess] = useState(false);
  const navigate = useNavigate();

  const cToken = window.location.search.split("=")[1];

  const [logo, setLogo] = useState(0);
  async function fetchLogoData() {
    try {
      const result = await axios.get(`${APIURL.url}/settings`, {
        params: { setting_name: "login_screen_logo" },
      });
      if (result.data.logo) {
        setLogo(result.data.logo);
      }
    } catch (error) {
      // console.error(error);
    }
  }
  useEffect(() => {
    fetchLogoData();
  }, []);

  const toggleSnackbar = () => {
    if (error) {
      setError(!error);
    }
    if (rsuccess) {
      setRSuccess(!rsuccess);
    }
  };
  useEffect(() => {
    setTimeout(() => {
      setError(false);
      setRSuccess(false);
    }, 2000);
  }, [error, rsuccess]);

  const changePassword = useMutation(
    async () =>
      axios.post(
        `${APIURL.url}/passwordReset`,
        {
          // token: cToken,
          otp_verify: true,
          token: JSON.parse(localStorage.getItem("passwordToken")) ? JSON.parse(localStorage.getItem("passwordToken")) : cToken,
          password: password,
          confirm_password: cPassword,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      ),
    {
      onError: (err) => {
        setEMsg(err.response.data);
        setError(true);
      },
      onSuccess: (data) => {
        localStorage.removeItem("phone")
        localStorage.removeItem("passwordToken")
        setEMsg(data.data);
        setRSuccess(true);
        setTimeout(() => {
          navigate("/login");
        }, 3000);
      },
    }
  );

  const handleCPassword = () => {
    changePassword.mutate();
  };

  return (
    <>
      {(!logo && <Loader />) ||
        (logo === 0 && <NotFound />) ||
        (logo && logo !== "undefined" && (
          <BasicLayout>
            <MDSnackbar
              color="error"
              icon="error"
              title="Error: Something went wrong"
              content={eMsg}
              open={error}
              close={toggleSnackbar}
            />
            <MDSnackbar
              color="success"
              icon="check_circle"
              title="Success"
              content={eMsg}
              open={rsuccess}
              close={toggleSnackbar}
            />
            <Card>
              <MDBox
                variant="gradient"
                className="loginLogo"
                borderRadius="lg"
                coloredShadow="info"
                mx={2}
                mt={-3}
                p={2}
                mb={1}
                textAlign="center"
              >
                <img src={logo} alt="Wireless Vision" width={100} />
              </MDBox>
              <MDBox pt={4} pb={3} px={3}>
                <MDBox component="form" role="form">
                  <MDBox mb={2}>
                    <MDInput
                      type="password"
                      label="New Password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      fullWidth
                    />
                  </MDBox>
                  <MDBox mb={2}>
                    <MDInput
                      type="password"
                      label="Confirm Password"
                      value={cPassword}
                      onChange={(e) => setCPassword(e.target.value)}
                      fullWidth
                    />
                  </MDBox>
                  <MDBox mt={4} mb={2}>
                    <MDButton variant="gradient" color="info" fullWidth onClick={handleCPassword}>
                      Submit
                    </MDButton>
                  </MDBox>
                </MDBox>
              </MDBox>
            </Card>
          </BasicLayout>
        ))}
    </>
  );
}

export default ConfirmPassword;
