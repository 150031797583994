// import APIURL from "../../../../../../Constants";

function URLSettings() {
  const domain = window.location.host.split(".")[0];
  let url = null;
  let name = null;
  if (domain === "wirelessvision") {
    url = process.env.REACT_APP_WV_APIURL;
    name = "WirelessVision || Rewards";
  } else if (domain === "wildbills") {
    url = process.env.REACT_APP_WILDBILLS_APIURL;
    name = "WildBills || Rewards";
  } else if (domain === "demo") {
    url = process.env.REACT_APP_DEMO_APIURL;
    name = "Demo || Rewards";
  } else if (domain === "pizzahut") {
    url = process.env.REACT_APP_SAROKI_APIURL;
    name = "Pizzahut || Rewards"
  } else if (domain === "cannatron") {
    url = process.env.REACT_APP_CANNATRON_APIURL;
    name = "Cannatron || Rewards"
  } else if (domain === "test") {
    url = process.env.REACT_APP_TEST_APIURL;
    name = "Test || Rewards"
  } else if (domain === "test") {
    url = process.env.REACT_APP_TEST_APIURL;
    name = "Test || Rewards"
  } else if (domain === "2020companies") {
    url = process.env.REACT_APP_TW_APIURL;
    name = "Team Wireless || Rewards"
  } else if (domain === "tomeygroup") {
    url = process.env.REACT_APP_TOMEY_APIURL;
    name = "Tomey Group || Rewards"
  } else if (domain === "demo1") {
    url = process.env.REACT_APP_DEMO1_APIURL;
    name = "Demo1 || Rewards"
  } else if (domain === "demo2") {
    url = process.env.REACT_APP_DEMO2_APIURL;
    name = "Demo2 || Rewards"
  } else if (domain === "demo3") {
    url = process.env.REACT_APP_DEMO3_APIURL;
    name = "Demo3 || Rewards"
  } else if (domain === "demo4") {
    url = process.env.REACT_APP_DEMO4_APIURL;
    name = "Demo4 || Rewards"
  } else if (domain === "faygo") {
    url = process.env.REACT_APP_FAYGO_APIURL;
    name = "FAYGO || Rewards"
  } else if (domain === "laquinta") {
    url = process.env.REACT_APP_LAQUINTA_APIURL;
    name = "LaQuinta || Rewards"
  } else if (domain === "zax") {
    url = process.env.REACT_APP_ZAKS_APIURL;
    name = "Zax || Rewards"
  }

  const res = { url: url, code: domain, name: name };

  return res;
}

export default URLSettings;
