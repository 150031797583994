import { Box, Card, FormControl, Grid, Icon, InputLabel, MenuItem, Modal, Select, Switch, Typography } from "@mui/material";
import axios from "axios";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDSnackbar from "components/MDSnackbar";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardMenu from "examples/Navbars/DashboardMenu";
import { bgColorChange } from "layouts/Rewards/User/components/Common/ColorChange";
import { txtColorChange } from "layouts/Rewards/User/components/Common/ColorChange";
import DataGridTables from "layouts/Rewards/User/components/Common/DataGridTables";
import Loader from "layouts/Rewards/User/components/Common/Loader";
import SiteFooter from "layouts/Rewards/User/components/Common/SiteFooter";
import URLSettings from "layouts/Rewards/User/components/Common/URLSettings";
import React, { useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import MDInput from "components/MDInput";

const jose = require("jose");

const RoleModules = () => {
  let APIURL = URLSettings();
  const navigate = useNavigate(); 

  useEffect(() => {
    if (!localStorage.getItem("token")) {
      window.location.reload();
      return navigate("/login");
    }
  }, []);

  const [id, setId] = useState();
  const [role, setRole] = useState();
  const [module, setModule] = useState();
  const [readView, setReadView] = useState(false);
  const [writeView, setWriteView] = useState(false);
  const [editView, setEditView] = useState(false);
  const [approveView, setApproveView] = useState(false);
  const [deleteView, setDeleteView] = useState(false);
  const [opText, setOpText] = useState(false);
  const [eMsg, setEMsg] = useState("");
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  const handleRead = () => {
    setReadView(!readView);
  }
  const handleWrite = () => {
    setWriteView(!writeView);
  }
  const handleEditView = () => {
    setEditView(!editView)
  }
  const handleApprove = () => {
    setApproveView(!approveView);
  }
  const handleDelete = () => {
    setDeleteView(!deleteView);
  }

  const toggleSnackbar = () => {
    if (success) {
      setSuccess(!success);
    }
    if (error) {
      setError(!error);
    }
  };
  useEffect(() => {
    setTimeout(() => {
      setSuccess(false);
      setError(false);
    }, 2000);
  }, [success, error]);

  const roleModules = useQuery(["roleModules"], async () =>
    axios.get(`${APIURL.url}/admin/rolemodules`, {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    })
  );

  const roles = useQuery(["roles"], async () =>
    axios.get(`${APIURL.url}/admin/security`, {
      params: {
        table_name: "roles",
      },
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    })
  );

  const modules = useQuery(["module"], async () =>
    axios.get(`${APIURL.url}/admin/security`, {
      params: {
        table_name: "modules"
      },
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    })
  );

  if (roles.isError || modules.isError || roleModules.isError) {
    if (roles.error.response.status === 401) {
      window.location.reload();
      return navigate("/login");
    }
    if (modules.error.response.status === 401) {
      window.location.reload();
      return navigate("/login");
    }
    if (roleModules.error.response.status === 401) {
      window.location.reload();
      return navigate("/login");
    }
  }

  const handleRoleChange = (e) => {
    setRole(e.target.value);
  }

  const handleModuleChange = (e) => {
    setModule(e.target.value);
  }

  const [openCreate, setOpenCreate] = useState(false);
  const handleCreateOpen = () => setOpenCreate(true);
  const handleCreateClose = () => {
    setOpText(false);
    setOpenCreate(false);
    setId();
    setRole();
    setModule();
    setReadView(false);
    setWriteView(false);
    setEditView(false);
    setApproveView(false);
    setDeleteView(false);
  } 

  const roleCreate = useMutation(
    async () =>
      axios.post(
        `${APIURL.url}/admin/rolemodules`,
        {
          data: [{role_id: role, module_id: module, read_perm: readView, write_perm: writeView, edit_perm: editView, approve_perm: approveView, delete_perm: deleteView }]
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.getItem("token"),
          }
        }
      ),
    {
      onError: (err) => {
        setEMsg(err.response.data);
        setError(true);
        if (err.response.status === 401) {
          window.location.reload();
          return navigate("/login");
        }
      },
      onSuccess: (response) => {
        setEMsg(response.data);
        setSuccess(true);
        setId();
        setRole();
        setModule();
        setReadView(false);
        setWriteView(false);
        setEditView(false);
        setApproveView(false);
        setDeleteView(false);
        handleCreateClose();
        roleModules.refetch();
      },
    }
  );

  const handleCreate = () => {
    roleCreate.mutate();
  };

  const handleEdit = (row) => {
    setId(row.id);
    setRole(row.role_id);
    setModule(row.module_id);
    setReadView(row.read_perm);
    setWriteView(row.write_perm);
    setEditView(row.edit_perm);
    setApproveView(row.approve_perm);
    setDeleteView(row.delete_perm);
    setOpText(true);
    handleCreateOpen();
  };

  const roleUpdate = useMutation(
    async () =>
      axios.put(
        `${APIURL.url}/admin/rolemodules?pk_id=`+id,
        {
          data: {role_id: role, module_id: module, read_perm: readView, write_perm: writeView, edit_perm: editView, approve_perm: approveView, delete_perm: deleteView }
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.getItem("token"),
          }
        }
      ),
    {
      onError: (err) => {
        setEMsg(err.response.data);
        setError(true);
        if (err.response.status === 401) {
          window.location.reload();
          return navigate("/login");
        }
      },
      onSuccess: (response) => {
        setEMsg(response.data);
        setSuccess(true);
        setId("");
        setRole("");
        setModule("");
        setReadView(false);
        setWriteView(false);
        setEditView(false);
        setApproveView(false);
        setDeleteView(false);
        handleCreateClose();
        roleModules.refetch();
      },
    }
  );

  const handleUpdate = () => {
    setTimeout(() => {
      roleUpdate.mutate();
    }, 2000);
  }

  const columns = [
    {
      field: "id",
      headerName: "ID",
      width: 90,
    },
    {
      field: "role_name",
      headerName: "Role Name",
      minWidth: 200,
    },
    {
      field: "module_name",
      headerName: "Module Name",
      minWidth: 200,
    },
    {
      field: "read_perm",
      headerName: "Read Permission",
      minWidth: 200,
    },
    {
      field: "write_perm",
      headerName: "Write Permission",
      minWidth: 200,
    },
    {
      field: "edit_perm",
      headerName: "Edit Permission",
      minWidth: 200,
    },
    {
      field: "approve_perm",
      headerName: "Approve Permission",
      minWidth: 200,
    },
    {
      field: "delete_perm",
      headerName: "Delete Permission",
      minWidth: 200,
    },
    {
      field: "action",
      headerName: "Action",
      sortable: false,
      renderCell: (params) => {
        return (
          <>
            <MDButton
              variant="gradient"
              color="error"
              size="small"
              iconOnly
              onClick={() => handleEdit(params.row)}
              sx={bgColorChange}
            > 
              <Icon>edit</Icon>
            </MDButton>
          </>
        );
      },
    },
  ];

  if (!roles.isSuccess || !modules.isSuccess || !roleModules.isSuccess) {
    return <Loader />;
  }

  return (
    <DashboardLayout>
      <DashboardMenu />
      <MDSnackbar
        color="error"
        icon="error"
        title="Error: Unable to Assigned role"
        content={eMsg}
        open={error}
        close={toggleSnackbar}
      />
      <MDSnackbar
        color="success"
        icon="check_circle"
        title="Success"
        content={eMsg}
        open={success}
        close={toggleSnackbar}
      />
      <MDBox mt={1} my={3}>
        <Card>
          <MDBox p={3} lineHeight={1}>
            <Grid container>
              <Grid item xs={6}>
                <MDTypography variant="h5" fontWeight="medium" sx={txtColorChange}>
                  Role Module
                </MDTypography>
              </Grid>
              <Grid item xs={6}>
                <MDButton
                  variant="gradient"
                  color="info"
                  className="adminBtn"
                  sx={bgColorChange}
                  onClick={handleCreateOpen}
                >
                  Create New
                </MDButton>
              </Grid>
            </Grid>
          </MDBox>
          <DataGridTables tableRow={roleModules?.data?.data} columns={columns} />
        </Card>
      </MDBox>

      {/* Modal Create Starts Here */}
      <Modal
        open={openCreate}
        onClose={handleCreateClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="modalPopupBox"> 
          <Grid container>
            <Grid item xs={12}> 
              <Typography
                id="keep-mounted-modal-title"
                variant="h5"
                component="h2"
                sx={txtColorChange}
              >
                {opText ? "Update Role Modules" : "Create Role Modules"}
              </Typography>
              <MDBox variant="outlined" className="close-button" onClick={handleCreateClose}>
                <CloseIcon />
              </MDBox>
            </Grid>
          </Grid>
          <MDBox component="form" role="form">
            <Grid container>
              <Grid item xs={6}>
                <MDBox mb={2} mr={1}>
                  <FormControl variant="standard" sx={{ m: 1, width: "100%" }}> 
                    <InputLabel id="demo-simple-select-standard-label">Select Role</InputLabel>
                    <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" label="Select Role" sx={{minHeight: "40px"}} value={role} onChange={(e) => handleRoleChange(e)} >
                      {roles.data.data.map((role) => (
                        <MenuItem value={role.id}>{role.name}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </MDBox>
              </Grid>
              <Grid item xs={6}>
                <MDBox mb={2}>
                  <FormControl variant="standard" sx={{ m: 1, width: "100%" }}> 
                    <InputLabel id="demo-simple-select-standard-label">Select Module</InputLabel>
                    <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" label="Select Module" sx={{minHeight: "40px"}} value={module} onChange={(e) => handleModuleChange(e)}>
                      {modules.data.data.map((mod) => (
                        <MenuItem value={mod.id}>{mod.name}</MenuItem>
                      ))}  
                    </Select>
                  </FormControl>
                </MDBox>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={6}>
                <MDBox mt={2} mb={2}>
                  <MDTypography
                    variant="button"
                    fontWeight="regular"
                    color="text"
                    sx={{ cursor: "pointer", userSelect: "none", width: "140px", float: "left", marginTop: "10px" }}
                  >
                    Read Permission :
                  </MDTypography>
                  <Switch checked={readView} onChange={handleRead} />
                </MDBox>
              </Grid>
              <Grid item xs={6}>
                <MDBox mt={2} mb={2}>
                  <MDTypography
                    variant="button"
                    fontWeight="regular"
                    color="text"
                    sx={{ cursor: "pointer", userSelect: "none", width: "140px", float: "left", marginTop: "10px" }}
                  >
                    Write Permission :
                  </MDTypography>
                  <Switch checked={writeView} onChange={handleWrite} />
                </MDBox>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={6}>
                <MDBox mt={2} mb={2}>
                  <MDTypography
                    variant="button"
                    fontWeight="regular"
                    color="text"
                    sx={{ cursor: "pointer", userSelect: "none", width: "140px", float: "left", marginTop: "10px" }}
                  >
                    Edit Permission :
                  </MDTypography>
                  <Switch checked={editView} onChange={handleEditView} />
                </MDBox>
              </Grid>
              <Grid item xs={6}>
                <MDBox mt={2} mb={2}>
                  <MDTypography
                    variant="button"
                    fontWeight="regular"
                    color="text"
                    sx={{ cursor: "pointer", userSelect: "none",  width: "150px", float: "left", marginTop: "10px" }}
                  >
                    Approve Permission :
                  </MDTypography>
                  <Switch checked={approveView} onChange={handleApprove} />
                </MDBox>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={6}>
                <MDBox mt={2} mb={2}>
                  <MDTypography
                    variant="button"
                    fontWeight="regular"
                    color="text"
                    sx={{ cursor: "pointer", userSelect: "none", width: "140px", float: "left", marginTop: "10px" }}
                  >
                    Delete Permission :
                  </MDTypography>
                  <Switch checked={deleteView} onChange={handleDelete} />
                </MDBox>
              </Grid>
            </Grid>
            <MDBox mt={2} mb={1}>
              {opText ? (
                <>
                  <MDButton
                    variant="gradient"
                    color="info"
                    onClick={handleUpdate}
                    sx={bgColorChange}
                  >
                    Submit
                  </MDButton>
                </>
              ) : (
                <>
                  <MDButton
                    variant="gradient"
                    color="info"
                    onClick={handleCreate}
                    sx={bgColorChange}
                  >
                    Submit
                  </MDButton>
                </>
              )}
            </MDBox>
          </MDBox>
        </Box>
      </Modal> 
      {/* Modal Create Ends Here */}

      <SiteFooter />
    </DashboardLayout>
  );
};

export default RoleModules;
