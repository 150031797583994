// Post.js
import React, { useState } from 'react';
// import Mention from './Mention';


const Mention = ({ mention, onClick }) => {
  return (
    <span className="mention" onClick={onClick}>
      {mention}
    </span>
  );
};

const usersData = [
  { name: 'John Doe', profilePic: "https://en.wikipedia.org/wiki/Blue_flower#/media/File:Bachelor's_button,_Basket_flower,_Boutonniere_flower,_Cornflower_-_3.jpg" },
  { name: 'Jane Doe', profilePic: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR-jaY5cbo33Z9l59M4tXO_mXenPzptUACUXg&usqp=CAU" },
  // Add more user data
];

// const usersData = ["JohnDoe", "JaneDoe", "Alice", "Bob"]; // Replace with your user data

const MyComponent = () => {
  const [content, setContent] = useState('');
  const [mentions, setMentions] = useState([]);
  const [mentionInput, setMentionInput] = useState('');
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [suggestedUsers, setSuggestedUsers] = useState([]);

  const handleContentChange = (e) => {
    const inputValue = e.target.value;
    setContent(inputValue);

    const atIndex = inputValue.lastIndexOf('@');
    if (atIndex !== -1) {
      const query = inputValue.slice(atIndex + 1);
      setMentionInput(query);
      setSuggestedUsers(usersData.filter(user => user.name.toLowerCase().includes(query.toLowerCase())));
      setShowSuggestions(true);
    } else {
      setShowSuggestions(false);
    }
  };

  const handleAddMention = (user) => {
    const atIndex = content.lastIndexOf('@');
    const newContent = content.substring(0, atIndex) + `@${user.name} ` + content.substring(atIndex + mentionInput.length + 1);
    setContent(newContent);
    setMentions([...mentions, user.name]);
    setMentionInput('');
    setShowSuggestions(false);
  };

  const handleRemoveMention = (mentionToRemove) => {
    const updatedMentions = mentions.filter((mention) => mention !== mentionToRemove);
    setMentions(updatedMentions);
  };

  const renderMentions = () => {
    return mentions.map((mention, index) => (
      <Mention
        key={index}
        mention={mention}
        onClick={() => handleRemoveMention(mention)}
      />
    ));
  };

  const renderSuggestions = () => {
    if (!showSuggestions) {
      return null;
    }

    return (
      <ul className="suggestions">
        {suggestedUsers.map((user, index) => (
          <li key={index} onClick={() => handleAddMention(user)}>
            <img src={user.profilePic} alt={user.name} className="profile-pic" />
            {user.name}
          </li>
        ))}
      </ul>
    );
  };

  return (
    <div className="post">
      <div>
        {renderMentions()}
      </div>
      <div>
        <textarea
          placeholder=""
          value={content}
          onChange={handleContentChange}
        />
        <div>
          <input
            type="text"
            placeholder="Mention user..."
            value={mentionInput}
            onChange={(e) => setMentionInput(e.target.value)}
          />
          {renderSuggestions()}
        </div>
      </div>
    </div>
  );
};

export default MyComponent;
