import PropTypes from "prop-types";
import { useState } from "react";

// Material Dashboard 2 PRO React context
// import { useMaterialUIController } from "context";
import { Icon } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import TimelineList from "examples/Timeline/TimelineList";
import TimelineItem from "examples/Timeline/TimelineItem";
import { bgColorChange } from "../Common/ColorChange";

function Transactions(props) {
  // const [controller] = useMaterialUIController();
  // const { darkMode } = controller;
  const [historySize, setHistorySize] = useState(5);
  const { history, isGiftingAccount } = props;

  // helper functions
  // recieve = from person, gift = from company
  const getTransactionIcon = (type) => {
    switch (type) {
      case "Points":
        return isGiftingAccount ? "send" : "move_to_inbox";
      case "Gift":
        return "redeem";
      case "Message":
        return "mail";
      case "Redemption":
        return "shopping_bag";
      case "CustomRedemption":
        return "shopping_bag";
      case "Balance":
        return "redeem";
      case "Expiry":
        return "cancel";
      default:
        return "";
    }
  };
  const getTransactionColor = (type) => {
    switch (type) {
      case "Points":
        return isGiftingAccount ? "secondary" : "secondary"; //-> green / orange
      case "Gift":
        return "secondary"; 
      case "Message":
        return "info"; //-> orange
      case "Redemption":
        return "secondary"; //->orange
      case "CustomRedemption":
        return "secondary"; //->orange
      case "Balance":
        return "secondary";
      case "Expiry":
        return "error";
      default:
        return "";
    }
  };

    const getTransactionDetails = (type) => {
      // console.log(type)
      // let tran_name = type.transaction_name
      // console.log(tran_name)
    switch (type.transaction_name) {
      case "Redemption":
        return type.giftcard;
      default:
        return type.message;
    }
  };

  const getTransactionTitle = (details) => {
    switch (details.transaction_name) {
      case "Points":
        return `${isGiftingAccount ? "Sent" : "Recieved"} ${details.balance} points ${
          isGiftingAccount ? "to" : "from"
        } ${details.first_name} ${details.last_name}`;
      case "Gift":
        return `Received ${details.amount} points from ${details.first_name}`;
      case "Message":
        return `${isGiftingAccount ? "To" : "From"} ${details.first_name} ${details.last_name}`;
      case "Redemption":
        return `Redeemed ${details.amount} points on ${details.message}`;
      case "CustomRedemption":
        return `Redeemed ${details.amount} points on ${details.message}`;
      case "Balance":
        return `Received ${details.amount} points from ${details.first_name}`;
      case "Expiry":
        return `${details.balance} points expired`;
      default:
        return "";
    }
  };

  const increaseHistorySize = () => {
    setHistorySize(historySize + 5);
  };
  // todo add details interaction modal
  return (
    <MDBox pb={3} pl={3}>
      <TimelineList title="History" noCard>
        {history.slice(0, historySize).map((transaction, index) => (
          <TimelineItem
            color={getTransactionColor(transaction.transaction_name)}
            icon={getTransactionIcon(transaction.transaction_name)}
            dateTime={transaction.transaction_date}
            // description={transaction.message}
            description={getTransactionDetails(transaction)}
            title={getTransactionTitle(transaction)}
            lastItem={index === history.length - 1}
          />
        ))}
      </TimelineList>
      <MDButton
        variant="gradient"
        color="info"
        onClick={increaseHistorySize}
        disabled={historySize >= history.length}
        sx={bgColorChange}
      >
        Load More <Icon>add</Icon>
      </MDButton>
    </MDBox>
  );
}

Transactions.defaultProps = {
  isGiftingAccount: false,
};

Transactions.propTypes = {
  history: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      otherParty: PropTypes.string,
      value: PropTypes.number,
      date: PropTypes.string, // todo exact format
      type: PropTypes.string,
      message: PropTypes.string,
    })
  ).isRequired,
  isGiftingAccount: PropTypes.bool,
};

export default Transactions;
