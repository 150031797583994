import { Card, Divider, FormControl, FormLabel, Icon, LinearProgress, MenuItem, NativeSelect, Table, Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
// import Card from "@mui/material/Card";
// import Divider from "@mui/material/Divider";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDDatePicker from "components/MDDatePicker";
import MDTypography from "components/MDTypography";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";
import ListComplexStatisticsCard from "examples/Cards/StatisticsCards/ListComplexStatisticsCard";
import Footer from "examples/Footer";
// import MiniStatisticsCard from "examples/Cards/StatisticsCards/MiniStatisticsCard";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Invoice from "layouts/pages/account/billing/components/Invoice";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { bgColorChange, txtColorChange } from "../User/components/Common/ColorChange";
import Loader from "../User/components/Common/Loader";
import Logout from "../User/components/Common/Logout";
import SiteFooter from "../User/components/Common/SiteFooter";
import URLSettings from "../User/components/Common/URLSettings";
import DashboardMenu from "examples/Navbars/DashboardMenu";
import { InputLabel, Select } from "@material-ui/core";
import DataGridTables from "../User/components/Common/DataGridTables";
import DashboardCard from "examples/Cards/StatisticsCards/DashboardCard";
import MDButton from "components/MDButton";
// import Statistics from "./components/Statistics";
// import TopCharts from "./components/TopCharts";
// OrderDetails page components

const axios = require("axios").default;
const jose = require("jose");

function AdminView() {
  let APIURL = URLSettings();
  const navigate = useNavigate();
  const logout = () => {
    localStorage.removeItem("token");
    navigate("/login");
  };

  const [quaterly, setQuaterly] = useState("Q1");
  const [hireDate, setHireDate] = useState("");

  useEffect(() => {
    if (!localStorage.getItem("token")) {
      window.location.reload();
      return navigate("/login");
    }
  }, []);

  const balanceCheck = useQuery(["balanceCheck"], async () =>
    axios.get(`${APIURL.url}/admin/balanceCheck`, {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    })
  );

  const [range, setRange] = useState('q1');
  
  const [spinner, setSpinner] = useState(false);  
  const tableStructure = useQuery(["tableStructure"], async () =>
    axios.get(`${APIURL.url}/admin/dashboard?range=` + range +`&previous=`+parseInt(0), {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    }),
  );

  const userReport = useQuery(["userReport"], async () =>
    axios.get(`${APIURL.url}/admin/user_report`, {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    })
  );

  const currentDate = new Date();
  currentDate.setDate(currentDate.getDate() - 30);
  const [fromDate, setFromDate] = useState(currentDate);
  const [toDate, setToDate] = useState(new Date());

  // useEffect(() => {
  //   if (fromDate && toDate) {
  //     handleDateMessages();
  //   }
  // }, [fromDate, toDate]);

  const transactionsActivity = useQuery(["transactionsActivity"], async () =>
    axios.get(`${APIURL.url}/admin/transactionsActivity`, {
      params: {
        start_date: fromDate,
        end_date: toDate,
      },
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    })
  );

  const userEngagement = useQuery(["userEngagement"], async () =>
    axios.get(`${APIURL.url}/admin/user_engagement`, {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    })
  );

  const handleTransaction = () => {
    transactionsActivity.refetch();
  }

  const messageActivity = useQuery(["messageActivity"], async () =>
    axios.get(`${APIURL.url}/admin/messageActivity`, {
      params: {
        start_date: fromDate,
        end_date: toDate,
      },
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    })
  );

  if (balanceCheck.isError || transactionsActivity.isError || userEngagement.isError || messageActivity.isError || tableStructure.isError || userReport.isError) {
    if (balanceCheck.error.response.status === 401) {
      window.location.reload();
      return navigate("/login");
    }
    if (transactionsActivity.error.response.status === 401) {
      window.location.reload();
      return navigate("/login");
    }
    if (userEngagement.error.response.status === 401) {
      window.location.reload();
      return navigate("/login");
    }
    if (messageActivity.error.response.status === 401) {
      window.location.reload();
      return navigate("/login");
    }
    if (tableStructure.error.response.status === 401) {
      window.location.reload();
      return navigate("/login");
    }
    if (userReport.error.response.status === 401) {
      window.location.reload();
      return navigate("/login");
    }
  }

  const [age, setAge] = useState('');

  const handleChange = (event) => {
    setAge(event.target.value);
  };

  // "Available balance to allocate"
  //       "Allocated to managers"
  //       "Gifted to users"
  //       "Redeemed by users"
  //       "Total Balance"

  const abal = balanceCheck.data?.data["Available balance to allocate"]
    ? "$" + balanceCheck.data?.data["Available balance to allocate"]
    : "$" + 0;
  const bgive = balanceCheck.data?.data["Allocated to managers"]
    ? "$" + balanceCheck.data?.data["Allocated to managers"]
    : "$" + 0;
  const giftusers = balanceCheck.data?.data["Gifted to users"]
    ? "$" + balanceCheck.data?.data["Gifted to users"]
    : "$" + 0;
  const bredeem = balanceCheck.data?.data["Redeemed by users"]
    ? "$" + balanceCheck.data?.data["Redeemed by users"]
    : "$" + 0;
  const tbal = balanceCheck.data?.data["Total Balance"]
    ? "$" + balanceCheck.data?.data["Total Balance"]
    : "$" + 0;

  const msg = transactionsActivity.data?.data.Message ? transactionsActivity.data?.data.Message : 0;
  const points = transactionsActivity.data?.data.Points
    ? transactionsActivity.data?.data.Points
    : 0;
  const redemption = transactionsActivity.data?.data.Redemption
    ? transactionsActivity.data?.data.Redemption
    : 0;

  const handleDateMessages = () => {
    messageActivity.refetch();
    // transactionsActivity.refetch();
  };

  const columns = [
    { 
      field: "id", 
      headerName: "ID", 
      width: 60 
    },
    {
      field: "First Name",
      headerName: "First Name",
      minWidth: 150
    },
    {
      field: "Last Name",
      headerName: "Last Name",
      minWidth: 150
    },
    {
      field: "Employee Id",
      headerName: "Employee Id",
      minWidth: 150
    },
    {
      field: "Job Title",
      headerName: "Job Title",
    },
    {
      field: "Phone Number",
      headerName: "Phone Number",
      minWidth: 180
    },
    {
      field: "Email",
      headerName: "Email",
    },
    {
      field: "Giving Balance",
      headerName: "Giving Balance",
      minWidth: 180
    },
    {
      field: "Personal Balance",
      headerName: "Personal Balance",
      minWidth: 180
    },
    {
      field: "Personal Account",
      headerName: "Personal Account",
      minWidth: 180,
      renderCell: (params) => {
        return params.value ? <Icon color="success">done</Icon> : <Icon color="error">close</Icon> 
      } 
    },
    {
      field: "Giving Account",
      headerName: "Giving Account",
      minWidth: 180,
      renderCell: (params) => {
        return params.value ? <Icon color="success">done</Icon> : <Icon color="error">close</Icon> 
      } 
    },
    {
      field: "Org Level1",
      headerName: "Org Level1",
    },
    {
      field: "Org Level2",
      headerName: "Org Level2",
    },
    {
      field: "Org Level3",
      headerName: "Org Level3",
    },
    {
      field: "Org Level4",
      headerName: "Org Level4",
    },
    {
      field: "Hire Date",
      headerName: "Hire Date",
    },
    {
      field: "DOB",
      headerName: "DOB",
    },
    {
      field: "Supervisor",
      headerName: "Supervisor",
    },
    {
      field: "Company",
      headerName: "Company",
    },
    {
      field: "last_login",
      headerName: "Last Login",
    },
    {
      field: "Status",
      headerName: "Status",
      renderCell: (params) => {
        return params.value ? <Icon color="success">done</Icon> : <Icon color="error">close</Icon> 
      } 
    },
    {
      field: "Total Likes",
      headerName: "Total Likes"
    },
    {
      field: "Total Likes Recieved",
      headerName: "Total Likes Recieved"
    },
    {
      field: "Total Messages Recieved",
      headerName: "Total Messages Recieved"
    },
    {
      field: "Total Messages Sent",
      headerName: "Total Messages Sent"
    },
    {
      field: "Total Points Recieved",
      headerName: "Total Points Recieved"
    },
    {
      field: "Total Points Sent",
      headerName: "Total Points Sent"
    },
  ];

  const claims = jose.decodeJwt(localStorage.getItem("token"));
  const usersList = useQuery(["usersList"], async () =>
    axios.get(`${APIURL.url}/admin/user_report`, {
      params: {
        company_id: claims.company_id,
        admin: localStorage.getItem("admin"),
      },
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    })
  );

  const handleQuaterChange = (e) => {
    setQuaterly(e.target.value);
    setRange(e.target.value);
    setTimeout(() => {
      setSpinner(true);
      tableStructure.refetch();
    }, 1000);
    setTimeout(() => {
      setSpinner(false);
    }, 3000);
  }

  if (!messageActivity.isSuccess || !balanceCheck.isSuccess || !transactionsActivity.isSuccess || !userEngagement.isSuccess || !tableStructure.isSuccess || !usersList.isSuccess || !userReport.isSuccess) {
    return (
      <Loader />
    );
  }

  if (spinner) {
    return (<Loader />);
  }

  return (
    <DashboardLayout>
      <DashboardMenu /> 
      <MDBox mt={1.5} my={4}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={4}>
            <Card sx={{ height: "360px" }}>
              <MDBox px={2} display="flex" justifyContent="space-between" alignItems="center" sx={txtColorChange}>
                <MDTypography variant="h6" fontWeight="medium" sx={txtColorChange}>
                  Category(Mutually Exclusive)
                </MDTypography>
                Dollars
              </MDBox>
              <Divider />
              <MDBox p={2} sx={{ paddingTop: "0px" }}>
                <MDBox component="ul" display="flex" flexDirection="column" p={0} m={0}>
                  <Invoice date="Available Balance to allocated" id="" price="" balance={abal} />
                  <Invoice date="Allocated to Managers (excl gifted $)" id="" price="" balance={bgive} />
                  <Invoice date="Gifted to Users" id="" price="" balance={giftusers} />
                  <Invoice date="Redeemed by Users" id="" price="" balance={bredeem} />
                  <Divider />
                  <Invoice date="Total" id="" price="" balance={tbal} />
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
          <Grid item xs={12} md={8} lg={8}>
            <Grid item xs={12} md={12} lg={12}>
              <Card sx={{padding:"10px", paddingBottom: "20px"}}>
                <Grid container>
                  <Grid item xs={12} md={6} lg={6}>
                    <MDTypography variant="h6" fontWeight="medium" sx={txtColorChange}>
                        Activities
                    </MDTypography>
                  </Grid>
                  <Grid item xs={12} md={6} lg={6}>
                    <MDBox sx={{ float: "right", maxWidth: "150px", position: "relative", padding: "0px" }} className="quater">
                      <FormControl fullWidth>
                        <NativeSelect
                          value={range}
                          inputProps={{
                            name: 'Quater',
                            id: 'uncontrolled-native',
                          }}
                          onChange={(e) => handleQuaterChange(e)}
                          sx={{ color: "#1f69c4", fontWeight: "bold" }}
                        >
                          <option value="q1">Q1</option>
                          <option value="q2">Q2</option>
                          <option value="q3">Q3</option>
                          <option value="q4">Q4</option>
                        </NativeSelect>                        
                      </FormControl>
                    </MDBox>
                  </Grid>
                </Grid>
                <MDBox pt={2} display="flex" justifyContent="space-between" alignItems="center">
                  <table className="tg">
                    <thead>
                      <tr>
                        <th className="tg-baqh" colspan="2">
                          <MDTypography variant="h6" fontWeight="medium" sx={txtColorChange} p={1}>Total Funded</MDTypography>
                        </th>
                        <th className="tg-baqh" colspan="2">
                          <MDTypography variant="h6" fontWeight="medium" sx={txtColorChange} p={1}>Manager Activity</MDTypography>
                        </th>
                        <th className="tg-0lax" colspan="2">
                          <MDTypography variant="h6" fontWeight="medium" sx={txtColorChange} p={1}>Receipient Activity</MDTypography>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className="tg-0lax" rowspan="3">
                          <MDTypography display="block" variant="button" fontWeight="medium">
                            Allocated to Managers
                          </MDTypography>
                        </td>
                        <td className="tg-lqy6" rowspan="3">
                          <MDTypography display="block" variant="button" fontWeight="medium">
                            $ {tableStructure?.data?.data?.table1?.allocated_to_managers}
                          </MDTypography>
                        </td>
                        <td className="tg-0lax" rowspan="2">
                          <MDTypography display="block" variant="button" fontWeight="medium">
                          Gifted to Users
                          </MDTypography>
                        </td>
                        <td className="tg-lqy6" rowspan="2">
                          <MDTypography display="block" variant="button" fontWeight="medium">
                            $ {tableStructure?.data?.data?.table2?.gifted_to_users}
                          </MDTypography>
                        </td>
                        <td className="tg-0lax">
                          <MDTypography display="block" variant="button" fontWeight="medium">
                            Redeemed
                          </MDTypography>
                        </td>
                        <td className="tg-lqy6">
                          <MDTypography display="block" variant="button" fontWeight="medium">
                            $ {tableStructure?.data?.data?.table3?.redeemed}
                          </MDTypography>
                        </td>
                      </tr>
                      <tr>
                        <td className="tg-0lax">
                          <MDTypography display="block" variant="button" fontWeight="medium">
                          Yet to Redeem
                          </MDTypography>
                        </td>
                        <td className="tg-lqy6">
                          <MDTypography display="block" variant="button" fontWeight="medium">
                          $ {tableStructure?.data?.data?.table3?.yet_to_redeem}
                          </MDTypography>
                        </td>
                      </tr>
                      <tr>
                        <td className="tg-0lax">
                          <MDTypography display="block" variant="button" fontWeight="medium">
                          Yet to Gift
                          </MDTypography>
                        </td>
                        <td className="tg-lqy6">
                          <MDTypography display="block" variant="button" fontWeight="medium">
                          $ {tableStructure?.data?.data?.table2?.yet_to_gift}
                          </MDTypography>
                        </td>
                        <td className="tg-0lax"></td>
                        <td className="tg-lqy6"></td>
                      </tr>
                      <tr>
                        <td className="tg-0lax">
                          <MDTypography display="block" variant="button" fontWeight="medium">
                          Yet to Allocate
                          </MDTypography>
                        </td>
                        <td className="tg-lqy6">
                          <MDTypography display="block" variant="button" fontWeight="medium">
                          $ {tableStructure?.data?.data?.table1?.yet_to_allocate}
                          </MDTypography>
                        </td>
                        <td className="tg-0lax"></td>
                        <td className="tg-lqy6"></td>
                        <td className="tg-0lax"></td>
                        <td className="tg-lqy6"></td>
                      </tr>
                      <tr>
                        <td className="tg-0lax">
                          <MDTypography display="block" variant="button" fontWeight="medium">
                          Total
                          </MDTypography>
                        </td>
                        <td className="tg-lqy6">
                          <MDTypography display="block" variant="button" fontWeight="medium">
                          $ {tableStructure?.data?.data?.table1?.total}
                          </MDTypography>
                        </td>
                        <td className="tg-0lax"></td>
                        <td className="tg-lqy6">
                          <MDTypography display="block" variant="button" fontWeight="medium">
                          $ {tableStructure?.data?.data?.table2?.total}
                          </MDTypography>
                        </td>
                        <td className="tg-0lax"></td>
                        <td className="tg-lqy6">
                          <MDTypography display="block" variant="button" fontWeight="medium">
                          $ {tableStructure?.data?.data?.table3?.total}
                          </MDTypography>
                        </td>
                      </tr>
                    </tbody>
                  </table>                
                </MDBox>
              </Card>
            </Grid>
            {/* <Grid item xs={12} md={12} lg={12} mt={3}>
              <Grid container spacing={1}> */}
                {/* <Grid item xs={12} md={6} lg={4}>
                  <MDBox mb={1.5}>
                    <ListComplexStatisticsCard
                      color="dark"
                      icon="leaderboard"
                      title={{
                        tile1: "Messages",
                        tile2: "Points",
                        tile3: "Redemption",
                      }}
                      count={{
                        tile1: msg,
                        tile2: points,
                        tile3: redemption,
                      }}
                      percentage={{
                        // color: "success",
                        // amount: "$4000",
                        label: "Transactions based on Type",
                      }}
                    />
                  </MDBox>
                </Grid> */}
                {/* <Grid item xs={12} md={6} lg={9}>
                  <Card p={2} className="msgsCount">
                    <MDBox display="flex" justifyContent="space-between" pt={1} px={2}>
                      <MDBox
                        variant="gradient"
                        bgColor="success"
                        color="white"
                        coloredShadow="success"
                        borderRadius="xl"
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        width="4rem"
                        height="4rem"
                        mt={-3}
                      >
                        <Icon fontSize="medium" color="inherit">
                          message
                        </Icon>
                      </MDBox>
                      <MDBox display="flex" alignItems="flex-start">
                        <MDBox mr={1}>
                          <MDDatePicker
                            input={{ placeholder: "From Date" }}
                            selected={fromDate && new Date(fromDate)}
                            onChange={(fromDate) => setFromDate(new Date(fromDate))}
                          />
                        </MDBox>
                        <MDBox>
                          <MDDatePicker
                            input={{ placeholder: "To Date" }}
                            selected={toDate && new Date(toDate)}
                            onChange={(toDate) => {
                              setToDate(new Date(toDate));
                            }}
                          />
                        </MDBox>
                        
                      </MDBox>
                    </MDBox>
                    <MDBox mb={1.5}>
                      {messageActivity.data.data.length === 0 ? (
                        <MDTypography
                          component="p"
                          variant="button"
                          fontWeight="bold"
                          sx={{ textAlign: "center" }}
                          mt={3}
                        >
                          No Data Available
                        </MDTypography>
                      ) : (
                        <>
                          {messageActivity.data?.data.map((act) => (
                            <MDBox className="boxEffect">
                              <p>
                                {act.msg} <span>{act.likes}</span>
                              </p>
                            </MDBox>
                          ))}
                        </>
                      )}
                    </MDBox>
                  </Card>
                </Grid> */}
              {/* </Grid>
            </Grid> */}
          </Grid>
        </Grid>
      </MDBox>
      <MDBox my={2}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={4} lg={4}>
            <Card sx={{ padding: "10px", height: "320px" }}>
              <MDTypography variant="h6" fontWeight="medium" sx={txtColorChange}>
                Participation
              </MDTypography>
              <Divider />
              <MDBox p={2} sx={{ paddingTop: "0px" }}>
                <MDBox component="ul" display="flex" flexDirection="column" p={0} m={0}>
                  <Invoice date="User logged in atleast once" id="" price="" balance={userEngagement?.data?.data[0].logged_in_once} />
                  <Invoice date="% of the above count" id="" price="" balance={userEngagement?.data?.data[0].logged_in_once_percentage + "%"} />
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
          <Grid item xs={12} md={4} lg={4}>
            <Card sx={{ padding: "10px", height: "320px" }}>
              <MDTypography variant="h6" fontWeight="medium" sx={txtColorChange}>
                Active Engagement
              </MDTypography>
              <Divider />
              <MDBox p={2} sx={{ paddingTop: "0px" }}>
                <MDBox component="ul" display="flex" flexDirection="column" p={0} m={0}>
                  <Invoice date="User logged in last 30days" id="" price="" balance={userEngagement?.data?.data[0].logged_in_30_days} />
                  <Invoice date="% of the above count" id="" price="" balance={userEngagement?.data?.data[0].logged_in_30_percentage + "%"} />
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
          <Grid item xs={12} md={4} lg={4}>
            <Card sx={{ padding: "10px", height: "320px" }}>
              <MDTypography variant="h6" fontWeight="medium" sx={txtColorChange}>
                Activity
              </MDTypography>
              <Divider />
              <MDBox p={2} sx={{ paddingTop: "0px" }}>
                <MDBox component="ul" display="flex" flexDirection="column" p={0} m={0}>
                  <Invoice date="Interaction activity in last 30days (ie.view, like, post, redeem, comment, send messages and points)" id="" price="" balance={userEngagement?.data?.data[0].interaction_activity_count} />
                  <Invoice date="Recognization activity in last 30days (ie.Sending messages and points)" id="" price="" balance={userEngagement?.data?.data[0].recognization_activity_count} />
                  <Invoice date="Recognizations by Manager" id="" price="" balance={userEngagement?.data?.data[0].recognization_by_managers} />
                  <Invoice date="Recognizations Peer to Peer" id="" price="" balance={userEngagement?.data?.data[0].recognization_by_peer_to_peer} />
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Grid container spacing={3}>
        <Grid item xs={12} md={12} lg={12}>
          <Card sx={{padding: "0px 10px", marginBottom: "15px"}}>
            <Grid container>
              <Grid item xs={12} md={4} lg={4}></Grid>
              <Grid item xs={12} md={4} lg={4}>
                <MDBox>
                  <Grid container>
                    <Grid item xs={12} md={4} lg={4}>
                      <MDBox mt={1} mb={1} mr={1} className="dashboardPicker">
                        <FormControl>
                          {/* <FormLabel id="demo-radio-buttons-group-label" sx={{ fontSize: "12px" }}>From Date</FormLabel> */}
                          <MDDatePicker
                              input={{ placeholder: "From Date" }}
                              selected={fromDate && new Date(fromDate)}
                              onChange={(fromDate) => {
                                setFromDate(new Date(fromDate));
                              }}
                              dateFormat="m-d-yyyy"
                              value={fromDate}
                              fullWidth
                            /> 
                          </FormControl>
                      </MDBox> 
                    </Grid>
                    <Grid item xs={12} md={4} lg={4}>
                      <MDBox mt={1} mb={1} mr={1} className="dashboardPicker">
                        <FormControl>
                          {/* <FormLabel id="demo-radio-buttons-group-label" sx={{ fontSize: "12px" }}>To Date</FormLabel> */}
                          <MDDatePicker
                              input={{ placeholder: "To Date" }}
                              selected={toDate && new Date(toDate)}
                              onChange={(toDate) => {
                                setToDate(new Date(toDate));
                              }}
                              dateFormat="m-d-yyyy"
                              value={toDate}
                              fullWidth
                            /> 
                        </FormControl>
                      </MDBox>  
                    </Grid>
                    <Grid item xs={12} md={4} lg={4}>
                      <MDBox mt={1} mb={1} mr={1}>
                        <MDButton type="submit" variant="gradient" color="info" sx={bgColorChange} fullWidth onClick={handleTransaction}>
                          Retrieve
                        </MDButton>  
                      </MDBox>
                    </Grid>
                  </Grid>  
                </MDBox>      
              </Grid>
              <Grid item xs={12} md={4} lg={4}></Grid>
            </Grid>       
          </Card>
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6} lg={3}>
          <MDBox mb={1.5}>
            <DashboardCard
              color="dark"
              icon="group"
              title={{
                tile1: "Total Users Count ",
                tile2: "This Month Added Users",
                tile3: "Last Month Added Users",
              }}
              count={{
                tile1: transactionsActivity?.data?.data.total_user_count
              }}
              percentage={{
                label: "End of period user count",
              }}
            />
          </MDBox>
          {/* <Card sx={{padding: "0px 10px"}}>
            <Grid container>
              <Grid item xs={12} md={2} lg={2}></Grid>
              <Grid item xs={12} md={8} lg={8}>
                <MDBox mt={1} mb={1} mr={1} className="dashboardPicker">
                  <FormControl>
                    <FormLabel id="demo-radio-buttons-group-label" sx={{ fontSize: "12px" }}>From Date</FormLabel>
                    <MDDatePicker
                        input={{ placeholder: "From Date" }}
                        selected={fromDate && new Date(fromDate)}
                        onChange={(fromDate) => {
                          setFromDate(new Date(fromDate));
                        }}
                        dateFormat="m-d-yyyy"
                        value={fromDate}
                      /> 
                    </FormControl>
                </MDBox>
                <MDBox mb={1} mr={1} className="dashboardPicker">
                  <FormControl>
                    <FormLabel id="demo-radio-buttons-group-label" sx={{ fontSize: "12px" }}>To Date</FormLabel>
                    <MDDatePicker
                        input={{ placeholder: "To Date" }}
                        selected={toDate && new Date(toDate)}
                        onChange={(toDate) => {
                          setToDate(new Date(toDate));
                        }}
                        dateFormat="m-d-yyyy"
                        value={toDate}
                      /> 
                  </FormControl>
                </MDBox>
              </Grid>
              <Grid item xs={12} md={2} lg={2}></Grid>
            </Grid>              
          </Card> */}
        </Grid>
        <Grid item xs={12} md={6} lg={3}>
          <MDBox mb={1.5}>
            <DashboardCard
              color="dark"
              icon="group"
              title={{
                tile1: "Total Users Count ",
                tile2: "This Month Added Users",
                tile3: "Last Month Added Users",
              }}
              count={{
                tile1: transactionsActivity?.data?.data.users_added_in_range
              }}
              percentage={{
                label: "New users", //Total Users Count
              }}
            />
          </MDBox>
        </Grid>
        <Grid item xs={12} md={6} lg={3}>
          <MDBox mb={1.5}>
            <DashboardCard
              color="dark"
              icon="groupremove"
              count={{
                tile1: transactionsActivity?.data?.data.terminated_users
              }}
              percentage={{
                label: "Terminated user", //Current Month Users Count
              }}
            />
          </MDBox>
        </Grid>
        <Grid item xs={12} md={6} lg={3}>
          <MDBox mb={1.5}>
            <DashboardCard
              color="dark"
              icon="groupadd"
              count={{
                tile1: ((transactionsActivity?.data?.data.total_user_count+transactionsActivity?.data?.data.total_user_count_based_on_start_date)/2)
              }}
              percentage={{
                label: "Average users for the given period", //Terminated Users Count
              }}
            />
          </MDBox>
        </Grid>
      </Grid>
      {/* <Grid container spacing={3}> */}
        {/* <Grid item xs={12} md={6} lg={6}>
          <MDBox mb={1.5}> */}
            {/* <Card sx={{ height: "260px" }}>
              <MDBox px={2} display="flex" justifyContent="space-between" alignItems="center" sx={txtColorChange}>
                <MDTypography variant="h6" fontWeight="medium" sx={txtColorChange}>
                  Details
                </MDTypography>
                Dollars
              </MDBox>
              <Divider />
              <MDBox p={2} sx={{ paddingTop: "0px" }}>
                <MDBox component="ul" display="flex" flexDirection="column" p={0} m={0}>
                  <Invoice date="End of period user count" id="" price="" balance={transactionsActivity?.data?.data.total_user_count} />
                  <Invoice date="New users" id="" price="" balance={transactionsActivity?.data?.data.users_added_in_range} />
                  <Invoice date="Terminated user" id="" price="" balance={transactionsActivity?.data?.data.terminated_users} />
                  <Invoice date="Average users for the given period" id="" price="" balance={((transactionsActivity?.data?.data.total_user_count+transactionsActivity?.data?.data.total_user_count_based_on_start_date)/2)} />
                   <Divider /> 
                   <Invoice date="Total" id="" price="" balance={tbal} /> 
                </MDBox>
              </MDBox>
            </Card> */}
            {/* <DashboardCard
              color="dark"
              icon="group"
              title={{
                tile1: "Total Users Count ",
                tile2: "This Month Added Users",
                tile3: "Last Month Added Users",
              }}
              count={{
                tile1: transactionsActivity?.data?.data.total_user_count
              }}
              percentage={{
                label: "End of period user count",
              }}
            /> */}
          {/* </MDBox> */}
          {/* <Card sx={{padding: "0px 10px"}}>
            <Grid container>
              <Grid item xs={12} md={2} lg={2}></Grid>
              <Grid item xs={12} md={8} lg={8}>
                <MDBox mt={1} mb={1} mr={1} className="dashboardPicker">
                  <FormControl>
                    <FormLabel id="demo-radio-buttons-group-label" sx={{ fontSize: "12px" }}>From Date</FormLabel>
                    <MDDatePicker
                        input={{ placeholder: "From Date" }}
                        selected={fromDate && new Date(fromDate)}
                        onChange={(fromDate) => {
                          setFromDate(new Date(fromDate));
                        }}
                        dateFormat="m-d-yyyy"
                        value={fromDate}
                      /> 
                    </FormControl>
                </MDBox>
                <MDBox mb={1} mr={1} className="dashboardPicker">
                  <FormControl>
                    <FormLabel id="demo-radio-buttons-group-label" sx={{ fontSize: "12px" }}>To Date</FormLabel>
                    <MDDatePicker
                        input={{ placeholder: "To Date" }}
                        selected={toDate && new Date(toDate)}
                        onChange={(toDate) => {
                          setToDate(new Date(toDate));
                        }}
                        dateFormat="m-d-yyyy"
                        value={toDate}
                      /> 
                  </FormControl>
                </MDBox>
              </Grid>
              <Grid item xs={12} md={2} lg={2}></Grid>
            </Grid>              
          </Card> */}
        {/* </Grid>
        <Grid item xs={12} md={6} lg={6}>
          <MDBox mb={1.5}>
            <Card sx={{ height: "260px" }}>
              <MDBox px={2} display="flex" justifyContent="space-between" alignItems="center" sx={txtColorChange}>
                <MDTypography variant="h6" fontWeight="medium" sx={txtColorChange}>
                  Details
                </MDTypography>
                Dollars
              </MDBox>
              <Divider />
              <MDBox p={2} sx={{ paddingTop: "0px" }}>
                <MDBox component="ul" display="flex" flexDirection="column" p={0} m={0}>
                  <Invoice date="Total dollars redeem towards giftcard" id="" price="" balance={transactionsActivity?.data?.data.total_redeem_data} /> 
                  <Invoice date="Total dollars gifted to users for the given period" id="" price="" balance={transactionsActivity?.data?.data.total_gifted_data} />
                  {/* <Invoice date="Terminated user" id="" price="" balance={giftusers} />
                  <Invoice date="Average users for the given period" id="" price="" balance={bredeem} /> */}
                  {/* <Divider /> */}
                  {/* <Invoice date="Total" id="" price="" balance={tbal} /> */}
                {/* </MDBox>
              </MDBox>
            </Card> */}
            {/* <DashboardCard
              color="dark"
              icon="group"
              title={{
                tile1: "Total Users Count ",
                tile2: "This Month Added Users",
                tile3: "Last Month Added Users",
              }}
              count={{
                tile1: transactionsActivity?.data?.data.users_added_in_range
              }}
              percentage={{
                label: "New users", //Total Users Count
              }}
            /> */}
          {/* </MDBox>
        </Grid>  */}
        {/* <Grid item xs={12} md={6} lg={3}>
          <MDBox mb={1.5}>
            <DashboardCard
              color="dark"
              icon="groupremove"
              count={{
                tile1: transactionsActivity?.data?.data.terminated_users
              }}
              percentage={{
                label: "Terminated user", //Current Month Users Count
              }}
            />
          </MDBox>
        </Grid> */}
        {/* <Grid item xs={12} md={6} lg={3}>
          <MDBox mb={1.5}>
            <DashboardCard
              color="dark"
              icon="groupadd"
              count={{
                tile1: ((transactionsActivity?.data?.data.total_user_count+transactionsActivity?.data?.data.total_user_count_based_on_start_date)/2)
              }}
              percentage={{
                label: "Average users for the given period", //Terminated Users Count
              }}
            />
          </MDBox>
        </Grid> */}
      {/* </Grid> */}
      <Grid container spacing={3}>
        <Grid item xs={12} md={12} lg={12}>
          <Card>
            {/* <MDTypography variant="h6" fontWeight="medium" sx={txtColorChange}>
                List
            </MDTypography> */}
            <DataGridTables tableRow={usersList?.data?.data} columns={columns} /> 
          </Card>
        </Grid>
      </Grid>
      <SiteFooter />
    </DashboardLayout>
  );
}

export default AdminView;
