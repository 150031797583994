import { Avatar, Box, Card, Divider, Grid, Icon, Modal, Switch, Tooltip, Typography } from "@mui/material";
import MDBox from "components/MDBox";
import React, { useEffect, useState } from "react";
import { bgColorChange, profileColorChange, txtColorChange } from "../Common/ColorChange";
import MDAvatar from "components/MDAvatar";
import { useMutation, useQuery } from "react-query";
import MDTypography from "components/MDTypography";
import URLSettings from "../Common/URLSettings";
import { Link } from "react-router-dom";
import MDButton from "components/MDButton";
import { useMaterialUIController } from "context";
import axios from "axios";
import CloseIcon from '@mui/icons-material/Close';
import MDInput from "components/MDInput";
import MDSnackbar from "components/MDSnackbar";
// import DoneIcon from '@mui/icons-material/Done';

const jose = require("jose");

const ProfileBlock = ({pageStatus}) => {
  // console.log("profile page Status::", pageStatus);
  let APIURL = URLSettings(); 
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  const settings = localStorage.getItem("settings");
  var obj = JSON.parse(settings);
  let userText1 = obj.find((o) => o.Name === "userText1");
  let userText2 = obj.find((o) => o.Name === "userText2");
  let profileBg = obj.find((o) => o.Name === "profileBgColor");
  let btnColor = obj.find((o) => o.Name === "btnBgColor");
  
  let profilPic = localStorage.getItem("profilepic");
  const token = useQuery("token", async () => {
    // should be validating with public key
    const text = localStorage.getItem("token");
    if (text === null || text === "") {
      throw new Error("No token found");
    }
    const claims = jose.decodeJwt(text);
    claims.token = text;
    return claims;
  });

  const profileDetails = useQuery(["profileDetails"], async () =>
    axios.get(`${APIURL.url}/getProfileDetails`, {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    })
  ); 

  const [blockStatus, setBlockStatus] = useState(pageStatus);
  useEffect(() => {
    setBlockStatus(pageStatus);
  },[pageStatus])

  if (token.isError) {
    window.location.reload();
    return navigate("/login");
  }

  useEffect(() => {
    if (!localStorage.getItem("token")) {
      window.location.reload();
      return navigate("/login");
    }
  }, []);

  let cardBoard = "cardBody";
  if (darkMode) {
    cardBoard = "cardBodyDark";
  }

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false); 

  const [uText1, setUText1] = useState(userText1.Value);
  const [uText2, setUText2] = useState(userText2.Value);
  const [pBgColor, setPBgColor] = useState(profileBg.Value);
  const [bColor, setBColor] = useState(btnColor.Value);

  const [preview, setPreview] = useState(false);
  const handleEdit = () => {
    handleOpen();
  }

  const handlePreview = () => {
    setPreview(true);
    handleClose();
  }

  const handleCancel = () => {
    setUText1(userText1.Value);
    setUText2(userText2.Value);
    setPBgColor(profileBg.Value);
    setBColor(btnColor.Value);
    setPreview(false);
  }

  const [eMsg, setEMsg] = useState("");
  const [sendError, setSendError] = useState("");
  const [sendSuccess, setSendSuccess] = useState("");
  const toggleSnackbar = () => {
    if (sendError) {
      setSendError(!sendError);
    }
    if (sendSuccess) {
      setSendSuccess(!sendSuccess);
    }
  };
  useEffect(() => {
    setTimeout(() => {
      setSendSuccess(false);
      setSendError(false);
    }, 2000);
  }, [sendError, sendSuccess]);

  const callSettings = useMutation(
    async () =>
      axios.get(`${APIURL.url}/admin/settings`, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }),
    {
      onError: () => {
        if (err.response.status === 401) {
          window.location.reload();
          return navigate("/login");
        }
      },
      onSuccess: (data) => {
        localStorage.setItem("settings", JSON.stringify(data.data.rows));
      },
    }
  );

  //Block option Update
  const handleSubmit = async (event) => {
    event.preventDefault(); 
    let jsonData = {
        "data": [
            {
                "s_id": profileBg.id,
                "value": pBgColor,
                "status": true
            },
            {
              "s_id": userText1.id,
              "value": uText1,
              "status": true
            },
            {
              "s_id": userText2.id,
              "value": uText2,
              "status": true
            },
            {
              "s_id": btnColor.id,
              "value": bColor,
              "status": true
            },
        ]
    }
    try {
      const response = await axios({
        method: "post",
        url: `${APIURL.url}/admin/settings`,
        data: jsonData,
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("token"),
        },
      });
      if (response) {
        setEMsg('Success');
        setSendSuccess(true);
        setPreview(false);
        callSettings.mutate();
      }
    } catch (error) {
      setEMsg(response.data);
      setSendError(true);
      setPreview(false);
      if (err.response.status === 401) {
        window.location.reload();
        return navigate("/login");
      }
    }
  }

  if (profileDetails.isError) {
    if (profileDetails.error.response.status === 401) {
      window.location.reload();
      return navigate("/login");
    } 
  }

  if (!profileDetails.isSuccess || token.isLoading) {
    return (
      <MDBox my={2}>
        <MDBox p={1} m={1} mt={2}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6} lg={6}>
              <Card />
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
    );
  }

  return (<>
    <Card sx={{marginBottom: "5px"}}> 
      <MDSnackbar
        color="error"
        icon="error"
        title="Error: Please try again"
        content={eMsg}
        open={sendError}
        close={toggleSnackbar}
      />
      <MDSnackbar
        color="success"
        icon="check_circle"
        title="Success"
        content="Settings Updated"
        open={sendSuccess}
        close={toggleSnackbar}
      />
      {blockStatus && (
      <MDBox sx={{ position: "absolute", right: 0, top: 0, zIndex: 99}}>
        {preview ? (<>
          <Tooltip title="Cancel" placement="top">
            <MDButton
              variant="gradient"
              color="info"
              size="small"
              iconOnly
              onClick={handleCancel}
              sx={{ background: `${pBgColor} !important`, border: `1px solid ${pBgColor} !important`, boxShadow: `0px 2px 1px -1px ${pBgColor}, 0px 1px 1px 0px ${pBgColor}, 0px 1px 3px 0px ${pBgColor} !important`  }}
            >
              <Icon>close</Icon>
            </MDButton>  
          </Tooltip> 
          <Tooltip title="Save" placement="top">
            <MDButton
              variant="gradient"
              color="info"
              size="small"
              iconOnly
              onClick={handleSubmit}
              sx={{ background: `${pBgColor} !important`, border: `1px solid ${pBgColor} !important`, boxShadow: `0px 2px 1px -1px ${pBgColor}, 0px 1px 1px 0px ${pBgColor}, 0px 1px 3px 0px ${pBgColor} !important`  }}
            >
              <Icon>done</Icon>
            </MDButton>  
          </Tooltip>  
        </>) : (
        <Tooltip title="Edit" placement="top">
          <MDButton
            variant="gradient"
            color="info"
            size="small"
            iconOnly
            onClick={handleEdit}
            sx={bgColorChange} 
          >
            <Icon>edit</Icon>
          </MDButton>  
        </Tooltip>  
        )}
      </MDBox>
      )}
      <div className="cardDesign">
        {/* sx={profileColorChange} */}
        <MDBox className="cardHeader" sx={{ background: `${pBgColor} !important` }}>
          <div className="cardImg">
            {profilPic === "null" ? (
              <Avatar
                size="xxl"
                sx={{
                  bgcolor: "#ccc",
                  marginRight: "5px",
                  textTransform: "uppercase",
                  fontSize: "2.5rem",
                  width: "110px",
                  height: "110px",
                }}
              >{`${token.data.first_name.slice(0, 1)}${token.data.last_name.slice(
                0,
                1
              )}`}</Avatar>
            ) : (
              <MDAvatar
                src={profilPic}
                alt={`${token.data.first_name.slice(0, 1)}${token.data.last_name.slice(0, 1)}`}
                size="xxl"
                bgColor="light"
              />
            )}
          </div>
        </MDBox>
        <div className={cardBoard}> 
          <MDTypography
            variant="button"
            fontWeight="medium"
            mt={2}
            mb={3}
            color={darkMode ? "white" : "text"}
          >
            Welcome Back
          </MDTypography>
          <MDTypography
            variant="h3"
            color={darkMode ? "white" : "text"}
            fontWeight="medium"
            sx={{ fontSize: "16px" }}
          >
            {profileDetails.data.data?.[0].name}
          </MDTypography>
          <MDTypography
            variant="caption"
            color={darkMode ? "white" : "text"}
            fontWeight="medium"
            sx={{ fontSize: "16px" }}
          >
            {profileDetails.data.data?.[0].job_title}
          </MDTypography>
          <Divider /> 
          <MDBox className="pointsBlock">
            <Grid container spacing={1}>
              {token.data.has_giving_account && (
                <>
                  <Grid item xs={12} md={4} xl={4}>
                    <MDTypography
                      className="pointsInnerBlock"
                      color={darkMode ? "white" : "text"}
                    >
                      <span color={darkMode ? "white" : "text"}>{profileDetails.data.data?.[0].giving_balance}</span>
                      <br />
                      Points to Give
                    </MDTypography>
                  </Grid>
                  <Grid item xs={12} md={4} xl={4}>
                    <MDTypography
                      className="pointsInnerBlock dividerBlock"
                      color={darkMode ? "white" : "text"}
                    >
                      <span>{profileDetails.data.data?.[0].personal_balance}</span>
                      <br />
                      Points to Redeem
                    </MDTypography>
                  </Grid>
                  <Grid item xs={12} md={4} xl={4}>
                    <MDTypography
                      className="pointsInnerBlock"
                      color={darkMode ? "white" : "text"}
                    >
                      <span>{profileDetails.data.data?.[0].recognition}</span>
                      <br />
                      People Recognized You
                    </MDTypography>
                  </Grid>
                </>
              )}

              {!token.data.has_giving_account && (
                <>
                  <Grid item xs={12} md={6} xl={6}>
                    <MDTypography
                      className="pointsInnerBlock dividerBlock"
                      color={darkMode ? "white" : "text"}
                    >
                      <span>{profileDetails.data.data?.[0].personal_balance}</span>
                      <br />
                      Points to Redeem
                    </MDTypography>
                  </Grid>
                  <Grid item xs={12} md={6} xl={6}>
                    <MDTypography
                      className="pointsInnerBlock"
                      color={darkMode ? "white" : "text"}
                    >
                      <span>{profileDetails.data.data?.[0].recognition}</span>
                      <br />
                      People Recognized You
                    </MDTypography>
                  </Grid>
                </>
              )}
            </Grid>
          </MDBox>
          <Divider />
          <MDBox>
            <Link to="/redemption">
              <MDButton variant="gradient" color="info" className="profileBtn" sx={{ background: `${bColor} !important`, border: `1px solid ${bColor} !important`, boxShadow: `0px 2px 1px -1px ${bColor}, 0px 1px 1px 0px ${bColor}, 0px 1px 3px 0px ${bColor} !important`  }}>
                {/* sx={bgColorChange} */}
                {/* {userText1.Value} */}
                {uText1}
              </MDButton> 
            </Link>
            <Link to="/giving">
              <MDButton variant="gradient" color="info" className="profileBtn" sx={{ background: `${bColor} !important`, border: `1px solid ${bColor} !important`, boxShadow: `0px 2px 1px -1px ${bColor}, 0px 1px 1px 0px ${bColor}, 0px 1px 3px 0px ${bColor} !important`  }}>
                {/* sx={bgColorChange} */}
                {/* {userText2.Value} */}
                {uText2}
              </MDButton>
            </Link>
          </MDBox>
        </div>
      </div>
    </Card>

    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box className="modalPopupBox">
        <Grid container>
          <Grid item xs={12}>
            <Typography id="keep-mounted-modal-title" variant="h5" component="h2" sx={txtColorChange}> 
              Edit Profile
            </Typography>
            <MDBox variant="outlined" className="close-button" onClick={handleClose}><CloseIcon /></MDBox> 
          </Grid>
        </Grid>
        <MDBox component="form" role="form">
          <Grid container>
            <Grid item xs={6}>
              <MDBox mt={2} mb={2} mr={1}>
                <MDInput
                  type="text"
                  label="Profile Background Color"
                  fullWidth
                  placeholder="Profile Background Color"
                  // profileBgColor
                  value={pBgColor}
                  onChange={(e) => {
                    setPBgColor(e.target.value);
                  }}
                />
                <span className="colorNote">(Use google to find Hexcode for color)</span>
              </MDBox>
            </Grid>
            <Grid item xs={6}>
              <MDBox mt={2} mb={2}>
                <MDInput
                  type="text"
                  label="userText1"
                  fullWidth
                  placeholder="userText1"
                  value={uText1}
                  onChange={(e) => {
                    setUText1(e.target.value);
                  }}
                />
              </MDBox>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={6}>
              <MDBox mt={2} mb={2} mr={1}>
                <MDInput
                  type="text"
                  label="userText2"
                  fullWidth
                  placeholder="userText2"
                  value={uText2}
                  onChange={(e) => {
                    setUText2(e.target.value);
                  }}
                />
              </MDBox>
            </Grid>
            <Grid item xs={6}>
              <MDBox mt={2} mb={2} mr={1}>
                <MDInput
                  type="text"
                  label="Buttons color"
                  fullWidth
                  placeholder="Buttons color"
                  value={bColor}
                  onChange={(e) => {
                    setBColor(e.target.value);
                  }}
                />
                <span className="colorNote">(Use google to find Hexcode for color)</span>
              </MDBox>
            </Grid>
          </Grid>
          <MDBox mt={2} mb={1}>
            <MDButton type="button" variant="gradient" color="info" sx={bgColorChange} onClick={handlePreview}>
              Preview
            </MDButton>
          </MDBox>
        </MDBox>
      </Box>
    </Modal>
    </>
  );
};

export default ProfileBlock;
