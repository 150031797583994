const CartCount = (code, points) => {
  var testCart = JSON.parse(localStorage.getItem("cartItems"));

  const filtered = testCart.filter((obj) => {
    return obj.code === code && obj.points === points;
  });

  return filtered.length;
};

export default CartCount;
