import React, { useEffect, useState } from "react";
const jose = require("jose");

const Permissions = (module_name) => {

  const claims = jose.decodeJwt(localStorage.getItem("token"));
  let perm = JSON.parse(localStorage.getItem("permissions")) !== null ? JSON.parse(localStorage.getItem("permissions")): [];
  
  let new_permissions = {
    read_permission: true,
    write_permission: false,
    edit_permission: false,
    approve_permission: false,
    delete_permission: false,
  };

  if (claims.company_id === 2 || claims.company_id === 72) {
    new_permissions = {
      read_permission: true,
      write_permission: true,
      edit_permission: true,
      approve_permission: true,
      delete_permission: true,
    };
  }
  const [item, setItem] = useState(new_permissions);

  useEffect(() => {
      if (perm.length === 0) {
        setItem({ ...new_permissions });
      } else {
        let roles = [...perm].filter((permission) => {
          return permission.module_name === module_name;
        })
        let modulePermissions = {
          module_name: module_name,
          read_permission: true,
          write_permission: false,
          edit_permission: false,
          approve_permission: false,
          delete_permission: false,
        }
        roles.forEach((role) => {
          modulePermissions.read_permission = modulePermissions.read_permission || role.read_permission;
          modulePermissions.write_permission = modulePermissions.write_permission || role.write_permission;
          modulePermissions.edit_permission = modulePermissions.edit_permission || role.edit_permission;
          modulePermissions.delete_permission = modulePermissions.delete_permission || role.delete_permission;
          modulePermissions.approve_permission = modulePermissions.approve_permission || role.approve_permission;
        })

        setItem(
          modulePermissions
        );
      }
  }, [module_name]); 

  return item;
};

export default Permissions;

