import { Card } from '@mui/material'
import MDBox from 'components/MDBox'
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout'
import DashboardNavbar from 'examples/Navbars/DashboardNavbar'
import React from 'react'
import './layout.css'
import Layout from './Layout'
import URLSettings from 'layouts/Rewards/User/components/Common/URLSettings'
import { useNavigate } from 'react-router-dom'
import Loader from 'layouts/Rewards/User/components/Common/Loader'
import DashboardMenu from 'examples/Navbars/DashboardMenu'

const JsLayout = () => {

  let APIURL = URLSettings();
  const navigate = useNavigate();

  const settings = localStorage.getItem("settings");
  let oldOrder = [];
  let layoutId;

  if (settings && settings !== undefined) {
    var obj = JSON.parse(settings);
    const layoutInfo = obj.find((o) => o.Name === "layoutOrder");
    oldOrder = JSON.parse(layoutInfo.Value);
    layoutId = layoutInfo.id;
  }

  if (oldOrder.length < 0) {
    return <Loader />
  }

  // console.log("order::", oldOrder);

  return (
    <DashboardLayout>
        {/* <DashboardNavbar /> */}
        <DashboardMenu />
        <MDBox pt={2} pb={3}>
            <Card sx={{ padding: "10px" }}>
                <Layout data={oldOrder} layoutId={layoutId} />
            </Card>
        </MDBox>
    </DashboardLayout>
  )
}

export default JsLayout