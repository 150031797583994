import React from 'react'
import Permissions from './Permissions';

const PagePermissions = () => {

    let celName = "Celebrations";
    let celPermissions = Permissions(celName); 

    let groupName = "Group Gifting";
    let gPermissions = Permissions(groupName);

    localStorage.setItem("celPage", JSON.stringify(celPermissions));
    localStorage.setItem("groupPage", JSON.stringify(gPermissions));

}

export default PagePermissions