// import Card from "@mui/material/Card";
// import Divider from "@mui/material/Divider";

// Material Dashboard 2 PRO React components
import { Box, Card, Grid, Icon, Modal, Typography } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
// import MiniStatisticsCard from "examples/Cards/StatisticsCards/MiniStatisticsCard";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
// import Statistics from "./components/Statistics";
// import TopCharts from "./components/TopCharts";
// OrderDetails page components
// import dataTableData from "layouts/applications/data-tables/data/dataTableData";
import { useMutation, useQuery } from "react-query";
// import Loader from "layouts/Rewards/User/components/Common/Loader";
import SiteFooter from "layouts/Rewards/User/components/Common/SiteFooter";
import MDDatePicker from "components/MDDatePicker";
import MDSnackbar from "components/MDSnackbar";
import URLSettings from "layouts/Rewards/User/components/Common/URLSettings";
import Loader from "layouts/Rewards/User/components/Common/Loader";
import Logout from "layouts/Rewards/User/components/Common/Logout";
import { txtColorChange } from "layouts/Rewards/User/components/Common/ColorChange";
import DataGridTables from "layouts/Rewards/User/components/Common/DataGridTables";
import Button from '@mui/material/Button';
import PrivacyTipIcon from "@mui/icons-material/PrivacyTip";
import CloseIcon from '@mui/icons-material/Close';
import { GridColDef } from '@mui/x-data-grid';
import DashboardMenu from "examples/Navbars/DashboardMenu";

const axios = require("axios").default;
const jose = require("jose");

function TransactionList() {
  let APIURL = URLSettings();
  const navigate = useNavigate();

  const [eMsg, setEMsg] = useState("");
  const [flagError, setFlagError] = useState(false);
  const [flagSuccess, setFlagSuccess] = useState(false);

  const [msgFlag, setMsgFlag] = useState(false);
  const [giftFlag, setGiftFlag] = useState(false);
  const [popupVal, setPopupVal] = useState("");

  const handleMsgClose = () => setMsgFlag(false);

  const toggleSnackbar = () => {
    if (flagError) {
      setFlagError(!flagError);
    }
    if (flagSuccess) {
      setFlagSuccess(!flagSuccess);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      setFlagSuccess(false);
      setFlagError(false);
    }, 2000);
  }, [flagSuccess, flagError]);

  const logout = () => {
    localStorage.removeItem("token");
    navigate("/login");
  };

  useEffect(() => {
    if (!localStorage.getItem("token")) {
      window.location.reload();
      return navigate("/login");
    }
  }, []);

  const claims = jose.decodeJwt(localStorage.getItem("token"));

  const currentDate = new Date();
  currentDate.setDate(currentDate.getDate() - 365);
  const [fromDate, setFromDate] = useState(currentDate);
  const [toDate, setToDate] = useState(new Date());

  useEffect(() => {
    if (fromDate || toDate) {
      handleTrans();
    }
  }, [fromDate, toDate]);

  const transList = useQuery(["transactionsList"], async () =>
    axios.get(`${APIURL.url}/admin/transactionsList`, {
      params: {
        company_id: claims.company_id,
        admin: localStorage.getItem("admin"),
        start_date: fromDate,
        end_date: toDate,
      },
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    })
  );

  if (transList.isError) {
    if (transList.error.response.status === 401) {
      window.location.reload();
      return navigate("/login");
    }
  }

  const handleTrans = () => {
    transList.refetch();
  };

  const transactionUpdateFlag = useMutation(
    async (data) =>
      axios.post(
        `${APIURL.url}/admin/transactionsListUpdate`,
        {
          flag: data.tFlag,
          transaction_id: data.tId,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.getItem("token"),
          },
        }
      ),
    {
      onError: (err) => {
        setEMsg(err.response.data);
        setFlagError(true);
        // console.log(err);
        if (err.response.status === 401) {
          // logout();
          // <Logout />
          window.location.reload();
          return navigate("/login");
        }
      },
      onSuccess: () => {
        setFlagSuccess(true);
        transList.refetch();
      },
    }
  );

  const updateFlag = async (cell) => {
    let tFlag = true;
    let tId = cell.id;
    if (cell.Flag === true) {
      tFlag = false;
      tId = cell.id;
    }
    await transactionUpdateFlag.mutate({ tFlag, tId });
  };

  if (!transList.isSuccess) {
    return (
      <Loader />
    );
  }


  const handlePopup = (msg) => {
    setMsgFlag(true);
    setPopupVal(msg);
  }

  const transColumns: GridColDef[] = [
    { 
      field: "id", 
      headerName: "Id", 
      width: 90 
    },
    {
      field: "transaction_date",
      headerName: "Transaction Date",
      minWidth: 150,
      type: 'date',
      valueGetter: ({ value }) => value && new Date(value),
    },
    {
      field: "From",
      headerName: "From",
      minWidth: 150,
    },
    {
      field: "From user id",
      headerName: "From user id",
      minWidth: 150,
    },
    {
      field: "To",
      headerName: "To", 
      // minWidth: 150
    },
    {
      field: "To user id",
      headerName: "To user id", 
      // minWidth: 150
    },
    {
      field: "Transaction Type",
      headerName: "Transaction Type",
      minWidth: 150,
    },
    {
      field: "Amount (in points)",
      headerName: "Amount(In Points)",
      minWidth: 180,
    },
    {
      field: "Points",
      headerName: "Points",
    },
    {
      field: "Gift Card",
      headerName: "Gift Card",
      renderCell: (params) => {
        if (params.value != null) {
          return <PrivacyTipIcon
                onClick={() => handlePopup(params.value)}
                sx={{ cursor: "pointer" }}
              />
        }
        return ""
      }
    },
    {
      field: "Likes",
      headerName: "Likes",
    },
    {
      field: "Message",
      headerName: "Message",
      renderCell: (params) => {
        return <Icon sx={{ cursor: "pointer" }} onClick={() => handlePopup(params.value)}>
                message
              </Icon>
      }
    },
    {
      field: "Flag",
      headerName: "Flag",
      type: "boolean",
      renderCell: (params) => {
        return params.value ? (<Icon
          color="success"
          onClick={() => updateFlag(params.row)}
          sx={{ cursor: "pointer" }}
        >
          flag
        </Icon>) : (
          <Icon
          color="error"
          onClick={() => updateFlag(params.row)}
          sx={{ cursor: "pointer" }}
        >
          flag
        </Icon>
        )
      }
    },
  ];

  

  return (
    <DashboardLayout>
      {/* <DashboardNavbar /> */}
      <DashboardMenu /> 
      <MDBox pt={2} pb={3}> 
        <Card>
          <MDBox p={3} lineHeight={1}>
            <Grid container>
              <Grid item xs={12} md={6} lg={6}>
                <MDTypography variant="h5" fontWeight="medium" sx={txtColorChange}>
                  Transaction List
                </MDTypography>
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <Grid container sx={{ float: "right" }}>
                  <Grid item xs={12} md={4} lg={4} />
                  <Grid item xs={12} md={4} lg={4}>
                    <MDDatePicker
                      input={{ placeholder: "From Date" }}
                      // value={fromDate}
                      selected={fromDate && new Date(fromDate)}
                      onChange={(fromDate) => setFromDate(new Date(fromDate))}
                    />
                  </Grid>
                  <Grid item xs={12} md={4} lg={4}>
                    <MDDatePicker
                      input={{ placeholder: "To Date" }}
                      selected={toDate && new Date(toDate)}
                      onChange={(toDate) => {
                        setToDate(new Date(toDate));
                        // handleTrans();
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </MDBox>
          <MDSnackbar
            color="error"
            icon="error"
            title="Error: Something went wrong"
            // content="Something went wrong"
            content={eMsg}
            open={flagError}
            close={toggleSnackbar}
          />
          <MDSnackbar
            color="success"
            icon="check_circle"
            title="Success"
            content="Transaction Flag"
            open={flagSuccess}
            close={toggleSnackbar}
          />
          {transList.data.data.rows.length === 0 ? (
            <MDTypography
              component="p"
              variant="button"
              fontWeight="bold"
              sx={{ textAlign: "center" }}
              mt={3}
              mb={3}
            >
              No Data Available
            </MDTypography>
          ) : (<>
              {/* <DataTable
                table={transList.data.data}
                canSearch
                // canRefresh={canRefresh}
                updateFlag={updateFlag}
              /> */}

              <br />
              <DataGridTables tableRow={transList.data.data.rows} columns={transColumns} />
              
              <Modal
                open={msgFlag}
                onClose={handleMsgClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box className="modalPopupBox">
                  <Typography
                    id="keep-mounted-modal-title"
                    variant="h5"
                    component="h2"
                    color="secondary"
                  >
                    <MDBox variant="outlined" className="close-button" onClick={handleMsgClose}><CloseIcon /></MDBox>
                    {popupVal}
                  </Typography>
                </Box>
              </Modal>
            </>
          )}          
        </Card>
      </MDBox>
      <SiteFooter />
    </DashboardLayout>
  );
}

export default TransactionList;
