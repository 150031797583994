// import Card from "@mui/material/Card";
// import Divider from "@mui/material/Divider";

// Material Dashboard 2 PRO React components
import { Box, Card, Grid, Icon, Modal, Switch, Typography } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
// import MiniStatisticsCard from "examples/Cards/StatisticsCards/MiniStatisticsCard";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
// import Statistics from "./components/Statistics";
// import TopCharts from "./components/TopCharts";
// OrderDetails page components
// import dataTableData from "layouts/applications/data-tables/data/dataTableData";
import { useMutation, useQuery } from "react-query";
import MDButton from "components/MDButton";
// import Loader from "layouts/Rewards/User/components/Common/Loader";
import SiteFooter from "layouts/Rewards/User/components/Common/SiteFooter";
import MDInput from "components/MDInput";
// import sampleFile from "assets/user_data.csv";
import AddIcon from "@mui/icons-material/Add";
import MDSnackbar from "components/MDSnackbar";
import URLSettings from "layouts/Rewards/User/components/Common/URLSettings";
import Loader from "layouts/Rewards/User/components/Common/Loader";
import MDDatePicker from "components/MDDatePicker";
import Logout from "layouts/Rewards/User/components/Common/Logout";
import { txtColorChange, bgColorChange } from "layouts/Rewards/User/components/Common/ColorChange";
import importPoints from "../../../../../assets/images/import-points.png"
import importUsers from "../../../../../assets/images/import-user.png"
import addUsers from "../../../../../assets/images/add-user.png"
import CloseIcon from '@mui/icons-material/Close';
import DataGridTables from "layouts/Rewards/User/components/Common/DataGridTables";
import DashboardMenu from "examples/Navbars/DashboardMenu";

const axios = require("axios").default;
const jose = require("jose");

// const style = {
//   position: "absolute",
//   top: "50%",
//   left: "50%",
//   transform: "translate(-50%, -50%)",
//   width: 500,
//   bgcolor: "#1a2035",
//   // border: "2px solid #000",
//   boxShadow: 24,
//   borderRadius: "20px",
//   p: 4,
// };

function UsersList() {
  let APIURL = URLSettings();
  const navigate = useNavigate();
  const logout = () => {
    localStorage.removeItem("token");
    navigate("/login");
  };

  let defaultValue = { Name: "", Value: "", Status: false };
  let importPointsURL;
  let importUsersURL;
  const settings = localStorage.getItem("settings");
  if (settings && settings !== undefined) {
    var obj = JSON.parse(settings);
    importPointsURL = obj.find((o) => o.Name === "sample_file_points_import");
    importUsersURL = obj.find((o) => o.Name === "sample_file_users_import");
    if (importPointsURL === undefined || importUsersURL === undefined) {
      importPointsURL = defaultValue;
      importUsersURL = defaultValue;
    }
  }

  useEffect(() => {
    if (!localStorage.getItem("token")) {
      window.location.reload();
      return navigate("/login");
    }
  }, []);

  const [eMsg, setEMsg] = useState("");
  const [createError, setCreateError] = useState(false);
  const [createSuccess, setCreateSuccess] = useState(false);
  const [updateError, setUpdateError] = useState(false);
  const [updateSuccess, setUpdateSuccess] = useState(false);
  const [iSuccess, setISuccess] = useState(false);
  const [iError, setIError] = useState(false);
  const [iPSuccess, setIPSuccess] = useState(false);
  const [iPError, setIPError] = useState(false);

  const toggleSnackbar = () => {
    if (createError) {
      setCreateError(!createError);
    }
    if (createSuccess) {
      setCreateSuccess(!createSuccess);
    }
    if (updateError) {
      setUpdateError(!updateError);
    }
    if (updateSuccess) {
      setUpdateSuccess(!updateSuccess);
    }
    if (iError) {
      setIError(!iError);
    }
    if (iSuccess) {
      setISuccess(!iSuccess);
    }
    if (iPError) {
      setIPError(!iPError);
    }
    if (iPSuccess) {
      setIPSuccess(!iPSuccess);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      setCreateError(false);
      setCreateSuccess(false);
      setUpdateError(false);
      setUpdateSuccess(false);
      setIError(false);
      setISuccess(false);
      setIPError(false);
      setIPSuccess(false);
    }, 2000);
  }, [createError, createSuccess, updateError, updateSuccess, iError, iSuccess, iPError, iPSuccess]);

  const claims = jose.decodeJwt(localStorage.getItem("token"));
  
  const usersList = useQuery(["usersList"], async () =>
    axios.get(`${APIURL.url}/admin/userList`, {
      params: {
        company_id: claims.company_id,
        admin: localStorage.getItem("admin"),
      },
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    })
  );
  if (usersList.isError) {
    if (usersList.error.response.status === 401) {
      window.location.reload();
      return navigate("/login");
    }
  }

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [opText, setOpText] = useState(false);

  const [popen, setPOpen] = useState(false);
  const handlePOpen = () => setPOpen(true);
  const handlePClose = () => setPOpen(false);

  // Popup Modal
  const [create, setCreate] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [employeeId, setEmployeeId] = useState("");
  const [emailId, setEmailId] = useState("");
  const [givingAcct, setGivingAcct] = useState(false);
  const [givingBal, setGivingBal] = useState("");
  const [newGivingBal, setNewGivingBal] = useState(0);
  const [uId, setUId] = useState("");
  const [jobTitle, setJobTitle] = useState("");
  const [phoneNum, setPhoneNum] = useState("");
  const [location1, setLocation1] = useState("");
  const [location2, setLocation2] = useState("");
  const [location3, setLocation3] = useState("");
  const [location4, setLocation4] = useState("");
  const [dob, setDob] = useState("");
  const [hireDate, setHireDate] = useState("");
  const [supervisor, setSupervisor] = useState("");
  const [active, setActive] = useState(false);
  const handleOpenCreate = () => {
    setCreate(true);
  };
  const handleCloseCreate = () => {
    setEditUser(false);
    setCreate(false) 
  };

  const [givingAccount, setGivingAccount] = useState(false);
  const handleAccount = () => {
    setGivingAccount(!givingAccount);
    setGivingAcct(!givingAccount);
  };

  const [statusAccount, setStatusAccount] = useState(false);
  const handleStatusAccount = () => {
    setStatusAccount(!statusAccount);
    setActive(!active);
  };


  const userCreate = useMutation(
    async () =>
      axios.post(
        `${APIURL.url}/admin/creatUser`,
        {
          first_name: firstName,
          last_name: lastName,
          employee_id: employeeId,
          email_id: emailId,
          giving_acct: givingAcct,
          giving_bal: Number(givingBal),
          phone_number: phoneNum,
          job_title: jobTitle,
          org_level_1: location1,
          org_level_2: location2,
          org_level_3: location3,
          org_level_4: location4,
          date_of_birth: dob,
          hire_date: hireDate,
          supervisor: supervisor,
          active: true
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.getItem("token"),
          },
        }
      ),
    {
      onError: (err) => {
        setEMsg(err.response.data);
        // console.log(err);
        setCreateError(true);
        if (err.response.status === 401) {
          // logout();
          // <Logout />
          window.location.reload();
          return navigate("/login");
        }
      },
      onSuccess: () => {
        setCreateSuccess(true);
        setFirstName("");
        setLastName("");
        setEmployeeId("");
        setEmailId("");
        setGivingAcct(false);
        setGivingBal("");
        setPhoneNum("");
        setJobTitle("");
        setLocation1("");
        setLocation2("");
        setLocation3("");
        setLocation4("");
        setDob("");
        setHireDate("");
        setSupervisor("");
        setActive("");
        handleCloseCreate();
        usersList.refetch();
      },
    }
  );

  const handleCreateModal = () => {
    setOpText(false);
    if (opText) {
      setFirstName("");
      setLastName("");
      setEmployeeId("");
      setEmailId("");
      setGivingAcct(false);
      setGivingBal("");
      setPhoneNum("");
      setJobTitle("");
      setLocation1("");
      setLocation2("");
      setLocation3("");
      setLocation4("");
      setDob("");
      setHireDate("");
      setSupervisor("");
      setActive("");
    }
    handleOpenCreate();
  };

  const handleCreate = () => {
    userCreate.mutate();
  };

  //Update user details
  const userUpdate = useMutation(
    async () =>
      axios.post(
        `${APIURL.url}/admin/updateUser`,
        {
          id: uId,
          first_name: firstName,
          last_name: lastName,
          employee_id: employeeId,
          email_id: emailId,
          giving_acct: givingAcct,
          giving_bal: Number(givingBal),
          new_giving_bal: Number(newGivingBal),
          phone_number: phoneNum,
          job_title: jobTitle,
          org_level_1: location1,
          org_level_2: location2,
          org_level_3: location3,
          org_level_4: location4,
          date_of_birth: dob,
          hire_date: hireDate,
          supervisor: supervisor,
          active: active,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.getItem("token"),
          },
        }
      ),
    {
      onError: (err) => {
        setEMsg(err.response.data);
        // console.log(err);
        setUpdateError(true);
        if (err.response.status === 401) {
          window.location.reload();
          return navigate("/login");
        }
      },
      onSuccess: () => {
        setUpdateSuccess(true);
        setFirstName("");
        setLastName("");
        setEmployeeId("");
        setEmailId("");
        setGivingAcct(false);
        setGivingBal("");
        setNewGivingBal(0);
        setJobTitle("");
        setPhoneNum("");
        setLocation1("");
        setLocation2("");
        setLocation3("");
        setLocation4("");
        setDob("");
        setHireDate("");
        setSupervisor("");
        setActive("");
        handleCloseCreate();
        usersList.refetch();
      },
    }
  );

  const handleUpdate = () => {
    userUpdate.mutate();
  };

  const [editUser, setEditUser] = useState(false);
  const handleUserUpdate = async (cell) => {
    setEditUser(true);
    let obj = cell;
    Object.keys(obj).forEach((key) => {
      var replacedKey = key.trim().toLowerCase().replace(" ", "_");
      if (key !== replacedKey) {
        obj[replacedKey] = obj[key];
        delete obj[key];
      }
    });

    setOpText(true);
    setUId(cell.id);
    setFirstName(obj.first_name);
    setLastName(obj.last_name);
    setEmployeeId(obj.employee_id);
    setEmailId(obj.email);
    setGivingAccount(obj.giving_account);
    setGivingAcct(obj.giving_account);
    setGivingBal(obj.giving_balance);
    setJobTitle(obj.job_title);
    setPhoneNum(obj.phone_number);
    setLocation1(obj.org_level1);
    setLocation2(obj.org_level2);
    setLocation3(obj.org_level3);
    setLocation4(obj.org_level4);
    setDob(obj.dob);
    setHireDate(obj.hire_date);
    setSupervisor(obj.supervisor);
    setActive(obj.status);
    handleOpenCreate();
  };

  //Import Userslist
  const [selectedFile, setSelectedFile] = useState(null);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData();
    formData.append("csv_user_data", selectedFile);
    formData.append("file_location", "user_import_files");
    formData.append("code", APIURL.code);
    try {
      const response = await axios({
        method: "post",
        url: `${APIURL.url}/admin/userImport`,
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: localStorage.getItem("token"),
        },
      });
      // console.log("res::", response);
      if (response) {
        // console.log(response);
        setISuccess(true);
        handleClose();
        usersList.refetch();
      }
    } catch (error) {
      setEMsg(error.response.data);
      setIError(true);
      if (error.response.status === 401) {
        window.location.reload();
        return navigate("/login");
      }
    }
  };

  const handleFileSelect = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  //Import User points
  const [selectedPFile, setSelectedPFile] = useState(null);
  const handlePSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData();
    formData.append("csv_user_points_data", selectedPFile);
    formData.append("file_location", "user_import_files");
    formData.append("code", APIURL.code);
    try {
      const response = await axios({
        method: "post",
        url: `${APIURL.url}/admin/userImportPoints`,
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: localStorage.getItem("token"),
        },
      });
      if (response) {
        // console.log(response);
        setIPSuccess(true);
        handlePClose();
        usersList.refetch();
      }
    } catch (error) {
      setEMsg(error.response.data);
      // console.log(error);
      setIPError(true);
      if (error.response.status === 401) {
        window.location.reload();
        return navigate("/login");
      }
    }
  };

  const handlePFileSelect = (event) => {
    setSelectedPFile(event.target.files[0]);
  };

  useEffect(() => {
    if (editUser) {
      usersList.refetch();
    }
  }, [editUser])

  const revertPoints = useMutation(
    async () =>
      axios.put(
        `${APIURL.url}/admin/points/revert`,
        {
          pk_id: uId,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.getItem("token"),
          },
        }
      ),
    {
      onError: (err) => {
        setEMsg(err.response.data);
        setUpdateError(true);
        if (err.response.status === 401) {
          window.location.reload();
          return navigate("/login");
        }
      },
      onSuccess: () => {
        setUId("");
        setUpdateSuccess(true);
        usersList.refetch();
      },
    }
  );

  const handleRevertPoints = (row) => {
    setUId(row.id);
    setTimeout(() => {
      revertPoints.mutate();
    }, 2000);
  }

  const userColumns = [
    { 
      field: "id", 
      headerName: "ID", 
      width: 60 
    },
    {
      field: "First Name",
      headerName: "First Name",
      minWidth: 150
    },
    {
      field: "Last Name",
      headerName: "Last Name",
      minWidth: 150
    },
    {
      field: "Employee Id",
      headerName: "Employee Id",
      minWidth: 150
    },
    {
      field: "Job Title",
      headerName: "Job Title",
    },
    {
      field: "Phone Number",
      headerName: "Phone Number",
      minWidth: 180
    },
    {
      field: "Email",
      headerName: "Email",
    },
    {
      field: "Giving Balance",
      headerName: "Giving Balance",
      minWidth: 180
    },
    {
      field: "Personal Balance",
      headerName: "Personal Balance",
      minWidth: 180
    },
    {
      field: "Personal Account",
      headerName: "Personal Account",
      minWidth: 180,
      renderCell: (params) => {
        return params.value ? <Icon color="success">done</Icon> : <Icon color="error">close</Icon> 
      } 
    },
    {
      field: "Giving Account",
      headerName: "Giving Account",
      minWidth: 180,
      renderCell: (params) => {
        return params.value ? <Icon color="success">done</Icon> : <Icon color="error">close</Icon> 
      } 
    },
    {
      field: "Org Level1",
      headerName: "Org Level1",
    },
    {
      field: "Org Level2",
      headerName: "Org Level2",
    },
    {
      field: "Org Level3",
      headerName: "Org Level3",
    },
    {
      field: "Org Level4",
      headerName: "Org Level4",
    },
    {
      field: "Hire Date",
      headerName: "Hire Date",
    },
    {
      field: "DOB",
      headerName: "DOB",
    },
    {
      field: "Supervisor",
      headerName: "Supervisor",
    },
    {
      field: "Company",
      headerName: "Company",
    },
    {
      field: "Last Login",
      headerName: "Last Login",
    },
    {
      field: "Status",
      headerName: "Status",
      renderCell: (params) => {
        return params.value ? <Icon color="success">done</Icon> : <Icon color="error">close</Icon> 
      } 
    },
    {
      field: "actions",
      headerName: "Actions",
      sortable: false,
      renderCell: (params) => {
        return (<><MDButton
                    variant="gradient"
                    color="error"
                    size="small"
                    iconOnly
                    onClick={() => handleUserUpdate(params.row)}
                    sx={bgColorChange}
                  >
                    <Icon>edit</Icon>
                  </MDButton>
                  <MDButton
                  variant="gradient"
                  color="error"
                  size="small"
                  iconOnly
                  onClick={() => handleRevertPoints(params.row)}
                  sx={bgColorChange}
                  className="refreshBtn"
                >
                  <Icon>refresh</Icon>
                </MDButton></>);
      }
    },
  ];

  if (!usersList.isSuccess) {
    return (
      <Loader />
    );
  }

  return (
    <DashboardLayout>
      {/* <DashboardNavbar /> */}
      <DashboardMenu />
      <MDBox pt={2} pb={3}>
        <Card>
          <MDBox p={3} lineHeight={1}>
            <Grid container>
              <Grid item xs={6}>
                <MDTypography variant="h5" fontWeight="medium" sx={txtColorChange}>
                  Users List
                </MDTypography>
              </Grid>
              <Grid item xs={6}>
                <MDButton
                  variant="gradient"
                  color="info"
                  sx={bgColorChange}
                  className="adminBtn iconBtns"
                  onClick={handleCreateModal}
                >
                  <img src={addUsers} width="25" height="25" />
                </MDButton>
                <MDButton
                  variant="gradient"
                  color="info"
                  sx={bgColorChange}
                  onClick={handleOpen}
                  className="adminBtn iconBtns"
                >
                  <img src={importUsers} width="25" height="25" />
                </MDButton>
                <MDButton
                  variant="gradient"
                  color="info"
                  sx={bgColorChange}
                  onClick={handlePOpen}
                  className="adminBtn iconBtns"
                >
                  <img src={importPoints} width="25" height="25" />
                </MDButton>
              </Grid>
            </Grid>
          </MDBox>
          <MDSnackbar
            color="error"
            icon="error"
            title="Error: Something went wrong"
            // content="User not created, Something went wrong"
            content={eMsg}
            open={createError}
            close={toggleSnackbar}
          />
          <MDSnackbar
            color="success"
            icon="check_circle"
            title="Success"
            content="User Created"
            open={createSuccess}
            close={toggleSnackbar}
          />
          <MDSnackbar
            color="error"
            icon="error"
            title="Error: Something went wrong"
            content={eMsg}
            // content="User Update not done, Something went wrong"
            open={updateError}
            close={toggleSnackbar}
          />
          <MDSnackbar
            color="success"
            icon="check_circle"
            title="Success"
            content="User Updated"
            open={updateSuccess}
            close={toggleSnackbar}
          />
          <MDSnackbar
            color="success"
            icon="check_circle"
            title="Success"
            content="Users Imported"
            open={iSuccess}
            close={toggleSnackbar}
          />
          <MDSnackbar
            color="error"
            icon="error"
            title="Error: Something went wrong"
            content={eMsg}
            // content="Users Not Imported, Something went wrong"
            open={iError}
            close={toggleSnackbar}
          />
          <MDSnackbar
            color="error"
            icon="error"
            title="Error: Something went wrong"
            content={eMsg}
            open={iPError}
            close={toggleSnackbar}
          />
          <MDSnackbar
            color="success"
            icon="check_circle"
            title="Success"
            content="User Points Imported"
            open={iPSuccess}
            close={toggleSnackbar}
          />
          {/* <DataTable
            table={usersList.data.data}
            canSearch
            noActions
            handleUserUpdate={handleUserUpdate}
          /> */}
          <DataGridTables tableRow={usersList.data.data.rows} columns={userColumns} /> 
        </Card>
      </MDBox>
      <SiteFooter />
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="modalPopupBox">
          <Grid container>
            <Grid item xs={6}>
              <Typography id="keep-mounted-modal-title" variant="h5" component="h2" sx={txtColorChange}>
                Import Users
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <MDBox variant="outlined" className="close-button" onClick={handleClose}><CloseIcon /></MDBox>
              <a
                href={importUsersURL.Value}
                download
              >
                <MDButton variant="gradient" color="info" sx={bgColorChange} className="adminBtn">
                  Sample File
                </MDButton>
              </a>
            </Grid>
          </Grid>
          <MDBox component="form" role="form" onSubmit={handleSubmit}>
            <MDBox mt={2} mb={2}>
              <span fontSize="small">Upload CSV File</span>
              <MDInput type="file" fullWidth onChange={handleFileSelect} />
            </MDBox>
            <MDBox mt={2} mb={1}>
              <MDButton type="submit" variant="gradient" color="info" sx={bgColorChange}>
                Upload
              </MDButton>
            </MDBox>
          </MDBox>
        </Box>
      </Modal>

      <Modal
        open={popen}
        onClose={handlePClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        variant="outlined"
      >
        <Box className="modalPopupBox">
          <Grid container>
            <Grid item xs={6}>
              <Typography id="keep-mounted-modal-title" variant="h5" component="h2" sx={txtColorChange}>
                Import Points
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <MDBox variant="outlined" className="close-button" onClick={handlePClose}><CloseIcon /></MDBox>
              <a
                href={importPointsURL.Value}
                download
              >
                <MDButton variant="gradient" color="info" sx={bgColorChange} className="adminBtn">
                  Sample File
                </MDButton>
              </a>
            </Grid>
          </Grid>
          <MDBox component="form" role="form" onSubmit={handlePSubmit}>
            <MDBox mt={2} mb={2}>
              <span fontSize="small">Upload CSV File</span>
              <MDInput type="file" fullWidth onChange={handlePFileSelect} />
            </MDBox>
            <MDBox mt={2} mb={1}>
              <MDButton type="submit" variant="gradient" color="info"sx={bgColorChange}>
                Upload points
              </MDButton>
            </MDBox>
          </MDBox>
        </Box>
      </Modal>

      <Modal
        open={create}
        onClose={handleCloseCreate}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="modalPopupBox">
          <Grid container>
            <Grid item xs={12}>
              <Typography id="keep-mounted-modal-title" variant="h5" component="h2" sx={txtColorChange}>
                {opText ? "Update User" : "Create Users"}
              </Typography>
              <MDBox variant="outlined" className="close-button" onClick={handleCloseCreate}><CloseIcon /></MDBox>
            </Grid>
          </Grid>
          <MDBox component="form" role="form" className="formInput">
            <Grid container>
              <Grid item xs={6}>
                <MDBox mt={2} mb={2} mr={1}>
                  <MDInput
                    type="text"
                    label="First Name"
                    fullWidth
                    placeholder="First Name"
                    value={firstName}
                    onChange={(e) => {
                      setFirstName(e.target.value);
                    }}
                  />
                </MDBox>
              </Grid>
              <Grid item xs={6}>
                <MDBox mt={2} mb={2}>
                  <MDInput
                    type="text"
                    label="Last Name"
                    fullWidth
                    placeholder="Last Name"
                    value={lastName}
                    onChange={(e) => {
                      setLastName(e.target.value);
                    }}
                  />
                </MDBox>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={6}>
                <MDBox mt={2} mb={2} mr={1}>
                  <MDInput
                    type="text"
                    label="Employee Id"
                    fullWidth
                    placeholder="Employee Id"
                    value={employeeId}
                    onChange={(e) => {
                      setEmployeeId(e.target.value);
                    }}
                  />
                </MDBox>
              </Grid>
              <Grid item xs={6}>
                <MDBox mt={2} mb={2}>
                  <MDInput
                    type="text"
                    label="Email Id"
                    fullWidth
                    placeholder="Email Id"
                    value={emailId}
                    onChange={(e) => {
                      setEmailId(e.target.value);
                    }}
                  />
                </MDBox>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={6}>
                <MDBox mt={2} mb={2}>
                  <MDTypography
                    variant="button"
                    fontWeight="regular"
                    color="text"
                    sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
                  >
                    &nbsp;&nbsp;Giving Account :&nbsp;
                  </MDTypography>
                  <Switch checked={givingAccount} onChange={handleAccount} />
                </MDBox>
              </Grid>
              <Grid item xs={6} className="disableInputColor">
                {givingAccount && (
                  <MDBox mt={2} mb={2}>
                    <MDInput
                      type="text"
                      label={"Giving Balance (" + givingBal +")"}
                      fullWidth
                      placeholder="New Giving Balance"
                      value={newGivingBal}
                      onChange={(e) => {
                        setNewGivingBal(e.target.value);
                      }}
                    />
                    <MDBox sx={{ display: "none !important" }}>
                      <MDInput
                        type="text"
                        label={"Giving Balance (" + givingBal +")"}
                        fullWidth
                        placeholder="Giving Balance"
                        value={givingBal}
                        // onChange={(e) => {
                        //   setGivingBal(e.target.value);
                        // }}
                      />
                    </MDBox>
                  </MDBox>
                )}
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={6}>
                <MDBox mt={2} mb={2} mr={1}>
                  <MDInput
                    type="text"
                    fullWidth
                    label="Job Title"
                    placeholder="Job Title"
                    value={jobTitle}
                    onChange={(e) => {
                      setJobTitle(e.target.value);
                    }}
                  />
                </MDBox>
              </Grid>
              <Grid item xs={6}>
                <MDBox mt={2} mb={2}>
                  <MDInput
                    type="text"
                    label="Phone Number"
                    fullWidth
                    placeholder="Phone Number"
                    value={phoneNum}
                    onChange={(e) => {
                      setPhoneNum(e.target.value);
                    }}
                  />
                </MDBox>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={6}>
                <MDBox mt={2} mb={2} mr={1}>
                  <MDInput
                    type="text"
                    label="Location 1"
                    fullWidth
                    placeholder="Location 1"
                    value={location1}
                    onChange={(e) => {
                      setLocation1(e.target.value);
                    }}
                  />
                </MDBox>
              </Grid>
              <Grid item xs={6}>
                <MDBox mt={2} mb={2}>
                  <MDInput
                    type="text"
                    label="Location 2"
                    fullWidth
                    placeholder="Location 2"
                    value={location2}
                    onChange={(e) => {
                      setLocation2(e.target.value);
                    }}
                  />
                </MDBox>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={6}>
                <MDBox mt={2} mb={2} mr={1}>
                  <MDInput
                    type="text"
                    label="Location 3"
                    fullWidth
                    placeholder="Location 3"
                    value={location3}
                    onChange={(e) => {
                      setLocation3(e.target.value);
                    }}
                  />
                </MDBox>
              </Grid>
              <Grid item xs={6}>
                <MDBox mt={2} mb={2}>
                  <MDInput
                    type="text"
                    label="Location 4"
                    fullWidth
                    placeholder="Location 4"
                    value={location4}
                    onChange={(e) => {
                      setLocation4(e.target.value);
                    }}
                  />
                </MDBox>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={6}>
                <MDBox mt={2} mb={2} mr={1}>
                  <MDDatePicker
                    input={{ placeholder: "DOB" }}
                    selected={dob && new Date(dob)}
                    onChange={(dob) => {
                      setDob(new Date(dob));
                    }}
                    value={dob}
                  />
                </MDBox>
              </Grid>
              <Grid item xs={6}>
                <MDBox mt={2} mb={2}>
                  <MDDatePicker
                      input={{ placeholder: "Hire Date" }}
                      selected={hireDate && new Date(hireDate)}
                      onChange={(hireDate) => {
                        setHireDate(new Date(hireDate));
                      }}
                      dateFormat="m-d-yyyy"
                      value={hireDate}
                    />
                </MDBox>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={6}>
                <MDBox mt={2} mb={2} mr={1}>
                  <MDInput
                    type="text"
                    label="Supervisor"
                    fullWidth
                    placeholder="Supervisor"
                    value={supervisor}
                    onChange={(e) => {
                      setSupervisor(e.target.value);
                    }}
                  />
                </MDBox>
              </Grid>
              {opText ? (<>
              <Grid item xs={6}>
                <MDBox mt={2} mb={2}>
                  <MDTypography
                    variant="button"
                    fontWeight="regular"
                    color="text"
                    sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
                  >
                    &nbsp;&nbsp;Status :&nbsp;
                  </MDTypography>
                  <Switch checked={active} onChange={handleStatusAccount} />
                </MDBox>
              </Grid>
              </>) : ""}
            </Grid>
            <MDBox mt={2} mb={1}>
              {opText ? (
                <>
                  <MDButton variant="gradient" color="info" onClick={handleUpdate} sx={bgColorChange}>
                    Submit
                  </MDButton>
                </>
              ) : (
                <>
                  <MDButton variant="gradient" color="info" onClick={handleCreate} sx={bgColorChange}>
                    Submit
                  </MDButton>
                </>
              )}
            </MDBox>
          </MDBox>
        </Box>
      </Modal>
    </DashboardLayout>
  );
}

export default UsersList;
