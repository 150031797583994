import React from 'react'
import moment from "moment-timezone";

const DateConvertion = ({dateObj}) => {
    // console.log("date::", dateObj);
    // const createdOn = new Date(dateObj);
    // const localCreatedOn = createdOn.toLocaleTimeString();
    
    // const formattedCreatedOn = moment(localCreatedOn).fromNow();

    const createdOn = moment.utc(dateObj);
    const localCreatedOn = createdOn.local();
    // console.log("local::", localCreatedOn);
    const formattedCreatedOn = localCreatedOn.fromNow();

    // console.log("format::", formattedCreatedOn);

    return formattedCreatedOn;
}

export default DateConvertion



