import { Navigate } from "react-router-dom"

function Logout() {
  localStorage.removeItem("token");
  localStorage.removeItem("admin");
  localStorage.removeItem("profilepic");
  localStorage.removeItem("profilepic");
  localStorage.removeItem("personal_balance");
  localStorage.removeItem("settings");
  localStorage.removeItem("celPage");
  localStorage.removeItem("groupPage");
  localStorage.clear();
  return <Navigate to='/login'/>
}

export default Logout;
