// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
// import MDTypography from "components/MDTypography";
import PropTypes from "prop-types";

import { Box, Grid, Icon, Modal, Skeleton, Switch, Tooltip, Typography } from "@mui/material";
// import Carousel from "react-material-ui-carousel";
import MuiLink from "@mui/material/Link";
import BookingCard from "examples/Cards/BookingCard";
// Material Dashboard 2 PRO React context
import { useMaterialUIController } from "context";
// import Image from "mui-image";
// import { Link, Skeleton } from "@mui/material";

import { useQuery } from "react-query";
import { isMobile } from "react-device-detect";
import MDAlert from "components/MDAlert";
import { useNavigate } from "react-router-dom";
import URLSettings from "../Common/URLSettings";
import Logout from "../Common/Logout";
import { useEffect, useState } from "react";
import MDButton from "components/MDButton";
import { bgColorChange, txtColorChange } from "../Common/ColorChange";
import CloseIcon from '@mui/icons-material/Close';
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";

const axios = require("axios").default;
const jose = require("jose");

function MiddleBanner(props) {
  // console.log("props::", props);
  let APIURL = URLSettings();
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const { location, pageStatus } = props;
  const navigate = useNavigate();

  const [blockStatus, setBlockStatus] = useState(pageStatus);
  useEffect(() => {
    setBlockStatus(pageStatus);
  },[pageStatus])

  const token = useQuery("token", async () => {
    // should be validating with public key
    const text = localStorage.getItem("token");
    if (text === null || text === "") {
      throw new Error("No token found");
    }
    const claims = jose.decodeJwt(text);
    claims.token = text;
    return claims;
  });

  const bannerImages = useQuery(["bannerImages"], async () =>
    axios.get(`${APIURL.url}/getBanners`, {
      params: {
        isMobile,
        isDark: darkMode,
      },
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    })
  ); // TODO: error checking

  if (token.isError) {
    window.location.reload();
    return navigate("/login");
  }

  useEffect(() => {
    if (!localStorage.getItem("token")) {
      window.location.reload();
      return navigate("/login");
    }
  }, []);

  const [imgURL, setImgURL] = useState(null);
  const [bannerData, setBannerData] = useState();
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false); 
  const [eMsg, setEMsg] = useState("");
  const [sendError, setSendError] = useState("");
  const [sendSuccess, setSendSuccess] = useState("");
  const toggleSnackbar = () => {
    if (sendError) {
      setSendError(!sendError);
    }
    if (sendSuccess) {
      setSendSuccess(!sendSuccess);
    }
  };
  useEffect(() => {
    setTimeout(() => {
      setSendSuccess(false);
      setSendError(false);
    }, 2000);
  }, [sendError, sendSuccess]); 

  
  const handleEdit = (val) => {
    setImgURL(val.image_location);
    setLink(val?.link);
    setBStatus(val?.active);
    setBannerData(val);
    handleOpen();
  }
  const [link, setLink] = useState(null);
  const [bStatus, setBStatus] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const handleFileSelect = (event) => {
    setSelectedFile(event.target.files[0]);
  };
  const handleBStatus = () => {
    setBStatus(!bStatus);
  };
  const handleSubmit = async (event) => {
    event.preventDefault(); 
    if (selectedFile != null) { 
      const formData = new FormData();
      formData.append("file", selectedFile);
      formData.append("href_url", link);
      formData.append("file_location", "banners");
      formData.append("code", bannerData?.code);
      formData.append("banner_id", bannerData?.id);
      formData.append("image_id", bannerData?.image_id);
      formData.append("banner_status", bStatus);

      try {
        const response = await axios({
            method: "post",
            url: `${APIURL.url}/admin/bannerUpdate`,
            data: formData,
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: localStorage.getItem("token"),
            },        
          });

        if (response) {
          handleClose();
          setSendSuccess(true);
          setEMsg(response.data);
          bannerImages.refetch();
        }
      } catch (error) {
        setEMsg(response.data);
        setSendError(true);
        if (error.response.status === 401) {
          window.location.reload();
          return navigate("/login");
        }
      }
    } else {
      try {
        const  response = await axios({
            method: "post",
            url: `${APIURL.url}/admin/bannerUpdateText`,
            data: {
              href_url : link,
              banner_status: bStatus,
              banner_id: bannerData?.id
            },
            headers: {
              "Content-Type": "application/json",
              Authorization: localStorage.getItem("token"),
            },        
          });
  
        if (response) {
          handleClose();
          setSendSuccess(true);
          setEMsg(response.data);
          bannerImages.refetch();
        }
      } catch (error) {
        setEMsg(response.data);
        setSendError(true);
        if (error.response.status === 401) {
          window.location.reload();
          return navigate("/login");
        }
      }
    } 
  }

  if (bannerImages.isError) {
    if (bannerImages.error.response.status === 401) {
      window.location.reload();
      return navigate("/login");
    }
    return (
      <MDAlert color="error" dismissible>
        An error has occoured, please try again later
      </MDAlert>
    );
  }

  if (!bannerImages.isSuccess) {
    return (
      <MDBox
        component="li"
        justifyContent="space-between"
        bgColor={darkMode ? "transparent" : "grey-100"}
        borderRadius="lg"
        p={3}
        mt={1}
        sx={{ listStyle: "none" }}
      >
        <Skeleton variant="rect" height="100px" animation="wave" />
      </MDBox>
    );
  }

  return (<>
    <MDBox mt={0}>
      <Grid container spacing={2}>
        {bannerImages.data.data[location].map((img) => (
          <Grid item xs={12} md={6} lg={4}>
            <MDBox sx={{ position: "relative" }}>
              {blockStatus && (
              <MDBox sx={{ position: "absolute", right: 0, top: 0, zIndex: 99}}>
                <Tooltip title="Edit" placement="top">
                  <MDButton
                    variant="gradient"
                    color="info"
                    size="small"
                    iconOnly
                    onClick={() => handleEdit(img)}
                    sx={bgColorChange} 
                  >
                    <Icon>edit</Icon>
                  </MDButton>  
                </Tooltip> 
              </MDBox>
              )}
              <MuiLink href={img.link} target="_blank" rel="noreferrer">
                <BookingCard image={img.image_location} />
              </MuiLink>
            </MDBox>
          </Grid>
        ))}
      </Grid>
    </MDBox>
    
    <Modal
    open={open}
    onClose={handleClose}
    aria-labelledby="modal-modal-title"
    aria-describedby="modal-modal-description"
    >
    <Box className="modalPopupBox">
      <Grid container>
        <Grid item xs={12}>
          <Typography id="keep-mounted-modal-title" variant="h5" component="h2" sx={txtColorChange}> 
            Edit Banner
          </Typography>
          <MDBox variant="outlined" className="close-button" onClick={handleClose}><CloseIcon /></MDBox> 
        </Grid> 
      </Grid>
      <MDBox component="form" role="form" onSubmit={handleSubmit}>
        <img src={imgURL !== null && imgURL} width="300" />
        <MDTypography sx={{ fontSize: "14px", color: "#ffffff" }}>
          (360*100)
        </MDTypography>
        <Grid container>
          <Grid item xs={6}>
            <MDBox mt={2} mb={2} mr={1}>
              {/* <span fontSize="small">Upload Banner</span> */}
              <MDInput type="file" fullWidth onChange={handleFileSelect} />
            </MDBox>
          </Grid>
          <Grid item xs={6}>
            <MDBox mt={2} mb={2} mr={1}>
              <MDInput
                type="text"
                fullWidth
                placeholder="Link"
                value={link}
                onChange={(e) => {
                  setLink(e.target.value);
                }}
              /> 
            </MDBox>
          </Grid>
        </Grid>
        <MDBox mt={2} mb={2}>
          <MDTypography
            variant="button"
            fontWeight="regular"
            color="text"
            sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
          >
            &nbsp;&nbsp;Banner Status :&nbsp;
          </MDTypography> 
          <Switch checked={bStatus} onChange={handleBStatus} /> 
        </MDBox>
        <MDBox mt={2} mb={2}>
          {/* <MDInput
            type="text"
            fullWidth
            placeholder="Link"
            value={link}
            onChange={(e) => {
              setLink(e.target.value);
            }}
          />  */}
        </MDBox>
        
        <MDBox mt={2} mb={1}>
          <MDButton type="submit" variant="gradient" color="info" sx={bgColorChange}>
            Submit
          </MDButton>
        </MDBox>
        
        
      </MDBox>
    </Box> 
    </Modal>
  </>);
}

MiddleBanner.propTypes = {
  location: PropTypes.string.isRequired,
};

export default MiddleBanner;
