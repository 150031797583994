import React from "react";
import { Helmet } from "react-helmet";
import PropTypes from "prop-types";
// import DocumentMeta from "react-document-meta";

function SEO({ title, description, favicon, touchicon }) {
  return (
    <Helmet>
      {/* {favicon} */}
      <link rel="shortcut icon" href={favicon} />
      <link rel="apple-touch-icon" sizes="76x76" href={touchicon} />
      {/* Standard metadata tags */}
      <title>{title}</title>
      <meta name="description" content={description} />
      {/* End standard metadata tags */}
    </Helmet>
  );
}

// function SEO({ title, description, favicon, touchicon }) {
//   return (
//     <DocumentMeta>
//       {favicon}
//       {/* <link rel="shortcut icon" href={favicon} /> */}
//       <link rel="apple-touch-icon" sizes="76x76" href={touchicon} />
//       {/* Standard metadata tags */}
//       <title>{title}</title>
//       <meta name="description" content={description} />
//       {/* End standard metadata tags */}
//     </DocumentMeta>
//   );
// }

// Setting default values for the props of modalpopup
SEO.defaultProps = {};

// Typechecking props for the modalpopup
SEO.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  // favicon: PropTypes.func.isRequired,
  // touchicon: PropTypes.func.isRequired,
};

export default SEO;
