import React, { useState } from 'react';

 

const Comment = ({ content, replies }) => {

  const [newReply, setNewReply] = useState('');

  const [commentReplies, setCommentReplies] = useState(replies);

 

  const handleReplyChange = (event) => {

    setNewReply(event.target.value);

  };

 

  const handleReplySubmit = (event) => {

    event.preventDefault();

    if (newReply.trim() !== '') {

      const updatedReplies = [...commentReplies, newReply];

      setCommentReplies(updatedReplies);

      setNewReply('');

    }

  };

 

  return (

    <div className="comment">

      <p>{content}</p>

      <div className="replies">

        <h4>Replies</h4>

        {commentReplies.map((reply, index) => (

          <p key={index}>{reply}</p>

        ))}

      </div>

      <form onSubmit={handleReplySubmit}>

        <input

          type="text"

          value={newReply}

          onChange={handleReplyChange}

          placeholder="Add a reply..."

        />

        <button type="submit">Submit</button>

      </form>

    </div>

  );

};

 

const Post = ({ content, comments }) => {

  const [newComment, setNewComment] = useState('');

  const [postComments, setPostComments] = useState(comments);

 

  const handleCommentChange = (event) => {

    setNewComment(event.target.value);

  };

 

  const handleCommentSubmit = (event) => {

    event.preventDefault();

    if (newComment.trim() !== '') {

      const updatedComments = [...postComments, { content: newComment, replies: [] }];

      setPostComments(updatedComments);

      setNewComment('');

    }

  };

 

  return (

    <div className="post">

      <p>{content}</p>

      <div className="comments">

        <h3>Comments</h3>

        {postComments.map((comment, index) => (

          <Comment key={index} content={comment.content} replies={comment.replies} />

        ))}

      </div>

      <form onSubmit={handleCommentSubmit}>

        <input

          type="text"

          value={newComment}

          onChange={handleCommentChange}

          placeholder="Add a comment..."

        />

        <button type="submit">Submit</button>

      </form>

    </div>

  );

};

 

const TestChats = () => {

  const posts = [

    {

      id: 1,

      content: 'This is my first post!',

      comments: [

        { content: 'Great post!', replies: [] },

        { content: 'Keep it up!', replies: [] },

        {

          content: 'Thanks!',

          replies: ['You\'re welcome!', 'I enjoyed reading it!'],

        },

      ],

    },

    {

      id: 2,

      content: 'Just had an amazing vacation!',

      comments: [

        { content: 'Looks like you had a great time!', replies: [] },

        { content: 'Where did you go?', replies: [] },

        {

          content: 'We went to Hawaii!',

          replies: ['Oh, that sounds amazing!', 'I love Hawaii!'],

        },

      ],

    },

  ];

 

  return (

    <div className="app">

      {posts.map((post) => (

        <Post key={post.id} content={post.content} comments={post.comments} />

      ))}

    </div>

  );

};

 

export default TestChats;
