// Material Dashboard 2 PRO React components
// import PropTypes from "prop-types";

import { useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";

// Material Dashboard 2 PRO React context
import { useMaterialUIController } from "context";
import { Box, Card, Divider, Grid, Icon, Modal, Skeleton, Tab, Tabs, Tooltip, Typography } from "@mui/material";
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDAlert from "components/MDAlert";
import MDSnackbar from "components/MDSnackbar";
import { Link, useNavigate } from "react-router-dom";
// import MDBadge from "components/MDBadge";
// import PanToolRoundedIcon from "@mui/icons-material/PanToolRounded";
import FeedTimelineItem from "examples/Timeline/FeedTimelineItem";
// import image from "assets/images/WV/high-fived.png";
import URLSettings from "../Common/URLSettings";
// import CustomizedAccordions from "../Common/Accordion";
// import BasicModal from "../Common/ModalPopup";
import { txtColorChange, bgColorChange } from "../Common/ColorChange";
import Logout from "../Common/Logout";
import CloseIcon from '@mui/icons-material/Close';
import MDInput from "components/MDInput";
import TabPanel from "../Common/TabPanel";

const axios = require("axios").default;
const jose = require("jose");

function Feed({pageStatus}) {
  let APIURL = URLSettings();
  //Hight Fived Image
  const settings = localStorage.getItem("settings");
  var obj = JSON.parse(settings);
  let aString = obj.find((o) => o.Name === "activity_string");
  let aName = obj.find((o) => o.Name === "activity_name");
  let likeYes = obj.find((o) => o.Name === "like_yes");
  let likeNo = obj.find((o) => o.Name === "like_no");

  // const [info, setInfo] = useState("");
  // const [show, setShow] = useState(false);
  const [eMsg, setEMsg] = useState("");
  const [historySize, setHistorySize] = useState(8);
  const [likeError, setLikeError] = useState(false);
  const [sendError, setSendError] = useState("");
  const [sendSuccess, setSendSuccess] = useState("");
  // const closeError = () => setLikeError(false);
  const toggleSnackbar = () => {
    if (likeError) {
      setLikeError(!likeError);
    }
    if (sendError) {
      setSendError(!sendError);
    }
    if (sendSuccess) {
      setSendSuccess(!sendSuccess);
    }
  };
  useEffect(() => {
    setTimeout(() => {
      setLikeError(false);
      setSendSuccess(false);
      setSendError(false);
    }, 2000);
  }, [likeError, sendError, sendSuccess]);
  // const queryClient = useQueryClient();
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const navigate = useNavigate();

  const logout = () => {
    localStorage.removeItem("token");
    navigate("/login");
  };

  const token = useQuery("token", async () => {
    // should be validating with public key
    const text = localStorage.getItem("token");
    if (text === null || text === "") {
      throw new Error("No token found");
    }
    const claims = jose.decodeJwt(text);
    claims.token = text;
    return claims;
  });

  const transactions = useQuery(["recentTransactions"], async () =>
    axios.get(`${APIURL.url}/getActivity`, {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    })
  );

  const likeTransaction = useMutation(
    (data) =>
      axios.post(
        `${APIURL.url}/likeTransaction`,
        {
          transactionID: data.transactionID,
          like: data.liked,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.getItem("token"),
          },
        }
      ),
    {
      onError: (err) => {
        setLikeError(true);
        setEMsg(err.response.data);
        if (err.response.status === 401) {
          // logout();
          // <Logout />
          window.location.reload();
          return navigate("/login");
        }
      },
      onSuccess: (data) => {
        if (data.change !== 0) {
          // console.log("Liked!");
          // queryClient.invalidateQueries("recentTransactions", { refetchInactive: true });
          transactions.refetch(); // works but should be unnecessary
        }
      },
    }
  );
  // const [currentMessages, setCurrentMessages] = useState(transactions);

  const updateLikes = async (transactionID, liked) => {
    console.log(`Attempting to like transaction ${transactionID}`);
    await likeTransaction.mutate({ transactionID, liked });
    // set
  };

  /* const updateLikes = (messageId) => {
    const newMessages = currentMessages.map((message) => {
      if (message.id === messageId) {
        const newMsg = {
          ...message,
          likes: message.isLiked ? message.likes - 1 : message.likes + 1,
          isLiked: !message.isLiked,
        };
        return newMsg;
      }
      return message;
    });
  }; */

  // Loadmore Functionality
  const increaseHistorySize = () => {
    setHistorySize(historySize + 5);
  };

  if (token.isError) {
    window.location.reload();
    return navigate("/login");
  }

  useEffect(() => {
    if (!localStorage.getItem("token")) {
      window.location.reload();
      return navigate("/login");
    }
  }, []);

  // Settings Content edit starts
  const [value, setValue] = useState(1);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [blockStatus, setBlockStatus] = useState(pageStatus);
  useEffect(() => {
    setBlockStatus(pageStatus);
  },[pageStatus])

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false); 

  const [aTitle, setATitle] = useState(aName.Value);
  const [aTxt, setATxt] = useState(aString.Value);
  const [lYes, setLYes] = useState(likeYes.Value);
  const [lNo, setLNo] = useState(likeNo.Value);

  const [preview, setPreview] = useState(false);
  const handleEdit = () => {
    handleOpen();
  }
  const handlePreview = () => {
    setPreview(true);
    handleClose();
  }
  const handleCancel = () => {
    setATitle(aName.Value);
    setATxt(aString.Value);
    setLYes(likeYes.Value);
    setLNo(likeNo.Value);
    setPreview(false);
  }

  const callSettings = useMutation(
    async () =>
      axios.get(`${APIURL.url}/admin/settings`, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }),
    {
      onError: () => {
        if (err.response.status === 401) {
          window.location.reload();
          return navigate("/login");
        }
      },
      onSuccess: (data) => {
        localStorage.setItem("settings", JSON.stringify(data.data.rows));
      },
    }
  );

  const handleSubmit = async (event) => {
    event.preventDefault(); 
    let jsonData = {
        "data": [
            {
                "s_id": aName.id,
                "value": aTitle,
                "status": true
            },
            {
              "s_id": aString.id,
              "value": aTxt,
              "status": true
            }
        ]
    }
    try {
      const response = await axios({
        method: "post",
        url: `${APIURL.url}/admin/settings`,
        data: jsonData,
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("token"),
        },
      });
      if (response) {
        setEMsg('Success');
        setSendSuccess(true);
        setPreview(false);
        callSettings.mutate();
      }
    } catch (error) {
      setEMsg(response.data);
      setSendError(true);
      setPreview(false);
      if (err.response.status === 401) {
        window.location.reload();
        return navigate("/login");
      }
    }
  }

  const [selectedFile, setSelectedFile] = useState(null);
  const handleFileSelect = (event) => {
    setSelectedFile(event.target.files[0]);
  };
  const handleYesSubmit = async (event) => {
    event.preventDefault(); 
    if (selectedFile != null) {
      const formData = new FormData();
      formData.append("file", selectedFile);
      formData.append("file_location", "common");
      formData.append("code", APIURL.code);
      formData.append("s_id", likeYes.id);
      formData.append("value", likeYes.Value);
      formData.append("status", true);
      try {
        const response = await axios({ 
          method: "post",
          url: `${APIURL.url}/admin/updateSettings`,
          data: formData,
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: localStorage.getItem("token"),
          },
        });
        if (response) {
          callSettings.mutate();
          setEMsg(response.data);
          setSendSuccess(true);
          handleClose();
        }
      } catch (error) {
        setEMsg(error.response.data);
        setSendError(true);
        handleClose();
        if (error.response.status === 401) {
          window.location.reload();
          return navigate("/login");
        }
      }
    } 
  }

  const [noSelectedFile, setNoSelectedFile] = useState(null);
  const handleNoFileSelect = (event) => {
    setNoSelectedFile(event.target.files[0]);
  };
  const handleNoSubmit = async (event) => {
    event.preventDefault(); 
    if (noSelectedFile != null) {
      const formData = new FormData();
      formData.append("file", noSelectedFile);
      formData.append("file_location", "common");
      formData.append("code", APIURL.code);
      formData.append("s_id", likeNo.id);
      formData.append("value", likeNo.Value);
      formData.append("status", true);
      try {
        const response = await axios({ 
          method: "post",
          url: `${APIURL.url}/admin/updateSettings`,
          data: formData,
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: localStorage.getItem("token"),
          },
        });
        if (response) {
          callSettings.mutate();
          setEMsg(response.data);
          setSendSuccess(true);
          handleClose();
        }
      } catch (error) {
        setEMsg(error.response.data);
        setSendError(true);
        handleClose();
        if (error.response.status === 401) {
          window.location.reload();
          return navigate("/login");
        }
      }
    } 
  }
  // Settings Content edit ends

  if (transactions.isError || updateLikes.isError) {
    if (transactions.error.response.status === 401) {
      window.location.reload();
      return navigate("/login");
    }
    if (updateLikes.error.response.status === 401) {
      window.location.reload();
      return navigate("/login");
    }
    return (
      <Card id="socialFeed" sx={{ overflow: "visible" }}>
        <MDAlert color="error" dismissible>
          An error has occoured, please try again later
        </MDAlert>
      </Card>
    );
  }

  if (!transactions.isSuccess) {
    return (
      <Card id="socialFeed" sx={{ overflow: "visible" }}>
        <MDBox p={3} m={1} mt={2}>
          <Grid container>
            <Grid item xs={12}>
              <MDTypography variant="h3" fontWeight="medium" sx={{ mb: 3 }}>
                {`${aName.Value}`}
              </MDTypography>
            </Grid>
            {[...Array(historySize).keys()].map((item) => (
              <Grid item xs={12}>
                <Grid container spacing={3}>
                  <Grid item xs={9}>
                    <Skeleton variant="text" height="20px" animation="wave" />
                  </Grid>
                  <Grid item xs={2}>
                    <Skeleton variant="text" height="20px" animation="wave" />
                  </Grid>
                  <Grid item xs={1}>
                    <Skeleton variant="circular" height="20px" width="20px" animation="wave" />
                  </Grid>
                </Grid>
                {item !== historySize - 1 && <Divider />}
              </Grid>
            ))}
          </Grid>
        </MDBox>
      </Card>
    );
  }

  // const openMessage = async (row) => {
  //   setInfo(row.Message);
  //   setShow(true);
  // };
  // const handleClose = () => setShow(false);

  // sx={{ overflow: "visible", height: "960px",  overflowY: "scroll", overflowX: { xs: "overflow" }, }}
  return (<>
    <MDBox sx={{ backgroundColor: "#ffffff", borderRadius: "10px", padding: "5px" }}>
    {blockStatus && (
      <MDBox sx={{ position: "absolute", right: 15, top: 0, zIndex: 99}}>
        {preview ? (<>
          <Tooltip title="Cancel" placement="top">
            <MDButton
              variant="gradient"
              color="info"
              size="small"
              iconOnly
              onClick={handleCancel}
              sx={bgColorChange}
            >
              <Icon>close</Icon>
            </MDButton>  
          </Tooltip> 
          <Tooltip title="Save" placement="top">
            <MDButton
              variant="gradient"
              color="info"
              size="small"
              iconOnly
              onClick={handleSubmit}
              sx={bgColorChange}
            >
              <Icon>done</Icon>
            </MDButton>  
          </Tooltip>  
        </>) : (
        <Tooltip title="Edit" placement="top">
          <MDButton
            variant="gradient"
            color="info"
            size="small"
            iconOnly
            onClick={handleEdit}
            sx={bgColorChange} 
          >
            <Icon>edit</Icon>
          </MDButton>  
        </Tooltip>  
        )}
      </MDBox>
      )}
      <Card id="socialFeed" class="custom-scroll">
        <MDSnackbar
          color="error"
          icon="error"
          title="Error: Unable to Like Message"
          content={eMsg}
          open={likeError}
          close={toggleSnackbar}
        />
        <MDBox p={3} m={0} mt={0}>
          <Grid container>
            <Grid item xs={12}>
              <MDTypography variant="h6" fontWeight="medium" sx={txtColorChange} className="fontFamily">
                {/* {`${aName.Value}`} */}
                {aTitle}
              </MDTypography>
            </Grid>
            <Grid>
              {/* <div>
                <BasicModal
                  title="Latest Activity"
                  description={info}
                  show={show}
                  close={handleClose}
                />
              </div> */}
              {/* <CustomizedAccordions feedItems={transactions.data.data} /> */}
            </Grid>
            <Grid item xs={12}>
              <MDBox p={2}>
                {transactions.data.data.slice(0, historySize).map((msg, index) => (
                  <Grid container spacing={3}>
                    <Grid item xs={8} md={9} lg={9}>
                      <FeedTimelineItem
                        color="#f4f4f4"
                        // icon="https://wv-rewards-pro.s3.amazonaws.com/wv/icons/like_icon_yes.png"
                        // aString.Value
                        icon={`${likeYes.Value}`}
                        title={`${msg.sender_first_name} ${msg.sender_last_name} ${aTxt} ${msg.reciever_first_name} ${msg.reciever_last_name}!`}
                        sender_details={`${msg.sender_first_name} ${msg.sender_last_name}`}
                        sender_pb={msg.sender_pb}
                        sender_gb={msg.sender_gb}
                        atxt={aTxt}
                        reciever_details={`${msg.reciever_first_name} ${msg.reciever_last_name}`}
                        reciever_pb={msg.reciever_pb}
                        reciever_gb={msg.reciever_gb}
                        description={msg.message}
                      />
                    </Grid>
                    <Grid item xs={4} md={3} lg={3} sx={{ textAlign: "right"}}>
                      <MDBox mr={1}>
                        <MDButton
                          className="btnBackground"
                          variant="contained"
                          sx={{ mr: "2px", mb: "2px" }}
                        >
                          {msg.likes}
                        </MDButton>
                        <MDButton
                          className="btnBackground"
                          color={darkMode ? "dark" : ""}
                          onClick={() => updateLikes(msg.id, !msg.user_liked)}
                          p={3}
                        >
                          {msg.user_liked ? (
                            <>
                              {/* <PanToolRoundedIcon color={msg.user_liked ? "warning" : "secondary"} /> */}
                              {/*<img src="https://wv-rewards-pro.s3.amazonaws.com/wv/icons/like_icon_yes.png" />*/}
                              <img width={20} height={20} src={`${likeYes.Value}`} />
                            </>
                          ) : (
                            <>
                              {/*<img src="https://wv-rewards-pro.s3.amazonaws.com/wv/icons/like_icon_no.png" />*/}
                              <img width={20} height={20} src={`${likeNo.Value}`} />
                            </>
                          )}
                        </MDButton>
                      </MDBox>
                    </Grid>
                    {index !== transactions.data.data.length - 1 && (
                      <Divider sx={{ m: "0.5rem 0" }} />
                    )}
                  </Grid>
                ))}
              </MDBox>
            </Grid>
            {/* {transactions.data.data.slice(0, historySize).map((msg, index) => (
              <Grid item xs={12}>
                <Grid container spacing={3}>
                  <Grid item xs={8} md={9} lg={9}>
                    <MDTypography>{`${msg.senderFirstName} ${msg.senderLastName} high-fived ${msg.recieverFirstName} ${msg.recieverLastName}!`}</MDTypography>
                    {msg.Message ? (
                      <MDButton
                        className="btnMessage"
                        color={darkMode ? "dark" : ""}
                        onClick={() => openMessage(msg)}
                      >
                        <Icon sx={{ m: 1, color: "#4CA750" }}>comments</Icon>
                        {msg.Message}
                      </MDButton>
                    ) : (
                      ""
                    )}
                  </Grid>
                  <Grid item xs={4} md={3} lg={3}>
                    <MDBox mr={1}>
                      <MDButton className="btnBackground" variant="contained" sx={{ mr: "2px" }}>
                        {msg.Likes}
                      </MDButton>
                      <MDButton
                        className="btnBackground"
                        color={darkMode ? "dark" : ""}
                        onClick={() => updateLikes(msg.TransactionID, !msg.userLiked)}
                      >
                        <PanToolRoundedIcon color={msg.userLiked ? "warning" : "secondary"} />
                      </MDButton>
                    </MDBox>
                  </Grid>
                </Grid>
                {index !== transactions.data.data.length - 1 && <Divider sx={{ m: "0.5rem 0" }} />}
              </Grid>
            ))} */}
            
            <MDButton
              variant="gradient"
              color="info"
              onClick={increaseHistorySize}
              disabled={historySize >= transactions.data.data.length}
              // sx={{ mt: "20px" }}
              // className="teal-bg-color"
              className="mgTop"
              sx={bgColorChange}
            >
              Load More <Icon sx={{ ml: 0.5 }}>add</Icon>
            </MDButton>
            <Link to="/giving">
              <MDButton variant="gradient" color="info" className="mgLeft" sx={bgColorChange}>
              {/* sx={{ mt: "20px", ml: "5px" }} */}
                Send Message <Icon sx={{ ml: 0.5 }}>mail</Icon>
              </MDButton>
            </Link>
          </Grid>
        </MDBox>
      </Card>
    </MDBox>
    {/* Modal popup starts */}
    <Modal
    open={open}
    onClose={handleClose}
    aria-labelledby="modal-modal-title"
    aria-describedby="modal-modal-description"
    >
      <Box className="modalPopupBox">
        <Grid container>
          <Grid item xs={12}>
            <Typography id="keep-mounted-modal-title" variant="h5" component="h2" sx={txtColorChange}> 
              Edit Feed Content
            </Typography>
            <MDBox variant="outlined" className="close-button" onClick={handleClose}><CloseIcon /></MDBox> 
          </Grid>
        </Grid>
        <Tabs value={value} onChange={handleChange} aria-label="Tabs">
          <Tab label="Text Change" value={1} className="fontFamily" />
          <Tab label="Like Yes" value={2} className="fontFamily" />
          <Tab label="Like No" value={3} className="fontFamily" />
        </Tabs>
        <TabPanel value={value} index={1}>
          <MDBox component="form" role="form">
            <Grid container>
              <Grid item xs={6}>
                <MDBox mt={2} mb={2} mr={1}>
                  <MDInput
                    type="text"
                    label="Activity Name"
                    fullWidth
                    placeholder="Activity Name"
                    value={aTitle}
                    onChange={(e) => {
                      setATitle(e.target.value);
                    }}
                  />
                </MDBox>
              </Grid>
              <Grid item xs={6}>
                <MDBox mt={2} mb={2}>
                  <MDInput
                    type="text"
                    label="Activity String"
                    fullWidth
                    placeholder="Activity String"
                    value={aTxt}
                    onChange={(e) => {
                      setATxt(e.target.value);
                    }}
                  />
                </MDBox>
              </Grid>
            </Grid>
            <MDBox mt={2} mb={1}>
              <MDButton type="button" variant="gradient" color="info" sx={bgColorChange} onClick={handlePreview}>
                Preview
              </MDButton>
            </MDBox>
          </MDBox>
        </TabPanel>
        <TabPanel value={value} index={2}>
          <MDBox component="form" role="form" onSubmit={handleYesSubmit}>
            <Grid container>
              <Grid item xs={12}>
                <MDBox mt={2} mb={2} mr={1}>
                  <MDInput type="file" fullWidth onChange={handleFileSelect} /> 
                  <span className="colorNote" sx={{ fontSize: "16px !important" }}>(png - 24*24)</span>
                </MDBox>
              </Grid>
            </Grid>
            <MDBox mt={2} mb={1}>  
              <MDButton type="submit" variant="gradient" color="info" sx={bgColorChange}>
                Save
              </MDButton>
            </MDBox>
          </MDBox>
        </TabPanel>
        <TabPanel value={value} index={3}>
          <MDBox component="form" role="form" onSubmit={handleNoSubmit}>
            <Grid container>
              <Grid item xs={12}>
                <MDBox mt={2} mb={2} mr={1}> 
                  <MDInput type="file" fullWidth onChange={handleNoFileSelect} /> 
                  <span className="colorNote" sx={{ fontSize: "16px !important" }}>(png - 24*24)</span>
                </MDBox>
              </Grid>
            </Grid>
            <MDBox mt={2} mb={1}>  
              <MDButton type="submit" variant="gradient" color="info" sx={bgColorChange}>
                Save
              </MDButton>
            </MDBox>
          </MDBox>          
        </TabPanel> 
      </Box>
    </Modal>
    {/* Modal popup ends */}
  </>);
}

Feed.propTypes = {};

export default Feed;
