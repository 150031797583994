// import Card from "@mui/material/Card";
// import Divider from "@mui/material/Divider";

// Material Dashboard 2 PRO React components
import { Card, Grid } from "@mui/material";
import axios from "axios";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDSnackbar from "components/MDSnackbar";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardMenu from "examples/Navbars/DashboardMenu";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { bgColorChange } from "layouts/Rewards/User/components/Common/ColorChange";
import { txtColorChange } from "layouts/Rewards/User/components/Common/ColorChange";
import Loader from "layouts/Rewards/User/components/Common/Loader";
import SiteFooter from "layouts/Rewards/User/components/Common/SiteFooter";
import URLSettings from "layouts/Rewards/User/components/Common/URLSettings";
import { useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useNavigate } from "react-router-dom";

function Automation() {
  let APIURL = URLSettings();
  const navigate = useNavigate();

  useEffect(() => {
    if (!localStorage.getItem("token")) {
      window.location.reload();
      return navigate("/login");
    }
  }, []);

  const [eMsg, setEMsg] = useState("");
  const [createError, setCreateError] = useState(false);
  const [createSuccess, setCreateSuccess] = useState(false);

  const toggleSnackbar = () => {
    if (createError) {
      setCreateError(!createError);
    }
    if (createSuccess) {
      setCreateSuccess(!createSuccess);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      setCreateError(false);
      setCreateSuccess(false);
    }, 2000);
  }, [createError, createSuccess]); 

  const pointsRevert = useMutation(
    async () =>
      axios.post(
        `${APIURL.url}/admin/points/revert`,
        {
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.getItem("token"),
          },
        }
      ),
    {
      onError: (err) => {
        setEMsg(err.response.data);
        setCreateError(true);
        if (err.response.status === 401) {
          window.location.reload();
          return navigate("/login");
        }
      },
      onSuccess: () => {
        setEMsg(data.data);
        setCreateSuccess(true);
      },
    }
  );

  const handleRevert = () => {
    pointsRevert.mutate();
  }

  const [selectedFile, setSelectedFile] = useState(null);
  const handleSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData();
    formData.append("automation_file", selectedFile);
    formData.append("file_location", "automation_import");
    formData.append("code", APIURL.code);
    try {
      const response = await axios({
        method: "post",
        url: `${APIURL.url}/admin/send/points`,
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: localStorage.getItem("token"),
        },
      });
      if (response) {
        setEMsg(response.data);
        setCreateSuccess(true);
      }
    } catch (error) {
      setEMsg(error.response.data);
      setCreateError(true);
      if (error.response.status === 401) {
        window.location.reload();
        return navigate("/login");
      }
    }
  };
  const handleFileSelect = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  // if (automation.isError) {
  //   if (automation.error.response.status === 401) {
  //     window.location.reload();
  //     return navigate("/login");
  //   }
  // }

  // if (!automation.isSuccess) {
  //   return <Loader />;
  // }


  return (
    <DashboardLayout>
      {/* <DashboardNavbar /> */}
      <DashboardMenu />
      <MDBox mt={1} my={3}>
        <MDSnackbar
          color="error"
          icon="error"
          title="Error: Something went wrong"
          content={eMsg}
          open={createError}
          close={toggleSnackbar}
        />
        <MDSnackbar
          color="success"
          icon="check_circle"
          title="Success"
          content={eMsg}
          open={createSuccess}
          close={toggleSnackbar}
        />
        <Grid container spacing={1}>
            <Grid item xs={6}>
                <Card>
                    <MDBox p={3} lineHeight={1}>
                        <MDTypography variant="h5" fontWeight="medium" sx={txtColorChange}>
                            Automation
                        </MDTypography>
                        <MDBox component="form" role="form">
                            <MDBox mt={2} mb={2}>
                                <MDInput type="file" fullWidth onChange={handleFileSelect} />
                            </MDBox>
                            <MDBox mt={2} mb={1}>
                                <MDButton type="button" variant="gradient" color="info" sx={bgColorChange} onClick={handleSubmit}>
                                    Upload
                                </MDButton>
                            </MDBox>
                        </MDBox>
                    </MDBox>
                </Card>
            </Grid>
            <Grid item xs={6}>
                <Card>
                    <MDBox p={3} lineHeight={1}>
                        <MDTypography variant="h5" fontWeight="medium" sx={txtColorChange}>
                            Points to Revert
                        </MDTypography>
                        <MDBox mt={5} mb={5}>
                            <MDButton type="button" variant="gradient" color="info" sx={bgColorChange} onClick={handleRevert}>
                                Trigger
                            </MDButton>
                        </MDBox>
                    </MDBox>
                </Card>
            </Grid>
        </Grid>
      </MDBox>
      <SiteFooter />
    </DashboardLayout>
  );
}

export default Automation;
