import {
  Autocomplete,
  Avatar,
  Box,
  Card,
  FormControl,
  Grid,
  Icon,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import axios from "axios";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardMenu from "examples/Navbars/DashboardMenu";
import { bgColorChange } from "layouts/Rewards/User/components/Common/ColorChange";
import DataGridTables from "layouts/Rewards/User/components/Common/DataGridTables";
import Loader from "layouts/Rewards/User/components/Common/Loader";
import SiteFooter from "layouts/Rewards/User/components/Common/SiteFooter";
import TabPanel from "layouts/Rewards/User/components/Common/TabPanel";
import URLSettings from "layouts/Rewards/User/components/Common/URLSettings";
import React, { useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
const jose = require("jose");
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { txtColorChange } from "layouts/Rewards/User/components/Common/ColorChange";
import MDSnackbar from "components/MDSnackbar";
import MDAvatar from "components/MDAvatar";

const EmployeeRoleMapping = () => {
  let APIURL = URLSettings();
  const navigate = useNavigate();

  useEffect(() => {
    if (!localStorage.getItem("token")) {
      window.location.reload();
      return navigate("/login");
    }
  }, []);

  const empRoleMapping = useQuery(["empRoleMapping"], async () =>
    axios.get(`${APIURL.url}/admin/rolemapping`, {
      params: {
        table_name: "employee",
      },
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    })
  );

  const jobRoleMapping = useQuery(["jobRoleMapping"], async () =>
    axios.get(`${APIURL.url}/admin/rolemapping`, {
      params: {
        table_name: "jobtitle",
      },
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    })
  );

  const roles = useQuery(["roles"], async () =>
    axios.get(`${APIURL.url}/admin/security`, {
      params: {
        table_name: "roles",
      },
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    })
  );

  const users = useQuery(["users"], async () =>
    axios.get(`${APIURL.url}/getUsers`, {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    })
  );

  const [role, setRole] = useState([]);
  const [user, setUser] = useState([]);

  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => {
    setShow(false);
    setRole([]);
    setUser([]);
  }

  const [eShow, setEShow] = useState(false);
  const handleEShow = () => setEShow(true);
  const handleEClose = () => {
    setEShow(false);
    setRole([]);
    setUser([]);
  };

  const [jshow, setJShow] = useState(false);
  const handleJShow = () => setJShow(true);
  const handleJClose = () => setJShow(false);

  const [jeShow, setJEShow] = useState(false);
  const handleJEShow = () => setJEShow(true);
  const handleJEClose = () => setJEShow(false);

  const [Recipient, setRecipient] = useState("");
  const [userSuggestions, setUserSuggestions] = useState([]);
  const getSuggestions = useMutation(
    async (search) =>
      axios.get(`${APIURL.url}/getUserSuggestions`, {
        params: {
          search,
        },
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }),
    {
      onError: (err) => {
        setEMsg(err.response.data);
        setSearchError(true);
        if (err.response.status === 401) {
          window.location.reload();
          return navigate("/login");
        }
      },
      onSuccess: (data) => {
        setUserSuggestions(data.data);
      },
    }
  );
  const updateSuggestions = (e) => {
    getSuggestions.mutate(e.target.value);
  };
  const updateRecipient = (e, option, i) => {
    if (option) {
      const { name, value } = e.target;
      const onchangeVal = [...addData];
      onchangeVal[i]['user_id'] = option.id;
      setAddData(onchangeVal);
      setRecipient(option.id);
    }
  };


  const [id, setId] = useState();
  const [editVal, setEditVal] = useState([]);
  const [gCardVal, setGCardVal] = useState([]);
  const [jobtitle, setJobTitle] = useState();
  const [finalVal, setFinalVal] = useState();

  const [eMsg, setEMsg] = useState("");
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  const toggleSnackbar = () => {
    if (success) {
      setSuccess(!success);
    }
    if (error) {
      setError(!error);
    }
  };
  useEffect(() => {
    setTimeout(() => {
      setSuccess(false);
      setError(false);
    }, 2000);
  }, [success, error]);

  const claims = jose.decodeJwt(localStorage.getItem("token"));
  const [value, setValue] = useState(1);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };


  //Create
  const [finalData, setFinalData] = useState([]);
  const [roleType, setRoleType] = useState("");
  const [addData, setAddData] = useState([
    { role_id: "", user_id: ""},
  ]);
  const handleAddCard = () => {
    setAddData([...addData, { role_id: "", user_id: "" }]);
  };

  const handleDeleteCard = (i) => {
    const deleteVal = [...addData];
    if (addData.length > 1) {
      deleteVal.splice(i, 1);
      setAddData(deleteVal);
    }
  };

  const [jaddData, setJAddData] = useState([
    { role_id: "", job_title: ""},
  ]);
  const handleJAddCard = () => {
    setJAddData([...jaddData, { role_id: "", job_title: "" }]);
  };
  const handleJDeleteCard = (i) => {
    const deleteVal = [...jaddData];
    if (jaddData.length > 1) {
      deleteVal.splice(i, 1);
      setJAddData(deleteVal);
    }
  };

  const handleRoleChange = (e, i) => {
    const { name, value } = e.target;
    const onchangeVal = [...addData];
    onchangeVal[i]['role_id'] = value;
    setAddData(onchangeVal);
    setRole(e.target.value);
  };

  const handleJobRoleChange = (e, j) => {
    const { name, value } = e.target;
    const jobonchangeVal = [...jaddData];
    jobonchangeVal[j]['role_id'] = value;
    setJAddData(jobonchangeVal);
    setRole(e.target.value);
  };

  const handleUserChange = (e) => {
    setUser(e.target.value);
  };

  

  const handleJobChange = (e, i) => {
    const { name, value } = e.target;
    const jonchangeVal = [...jaddData];
    if (name === "job_title") {
      jonchangeVal[i][name] = value;
    } 
    setJAddData(jonchangeVal);
  };

  

  //Update
  const updateCards = (e, option) => {
    if (option) {
      setGCardVal(option);
    }
  };

  // const handleUpdate = async (row) => {
  //   setId(row.id);
  //   setEditVal({ id: row.gift_card_id, name: row.gift_card_name, company_id: claims.company_id });
  //   setCommVal(row.comm_value);
  //   handleEShow();
  // };

  const roleCreate = useMutation(
    async () =>
      axios.post(
        `${APIURL.url}/admin/rolemapping?table_name=`+roleType,
        {
          data: finalData
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.getItem("token"),
          }
        }
      ),
    {
      onError: (err) => {
        setEMsg(err.response.data);
        setError(true);
        if (err.response.status === 401) {
          window.location.reload();
          return navigate("/login");
        }
      },
      onSuccess: (response) => {
        setEMsg(response.data);
        setSuccess(true);
        setRole();
        setAddData([{ role_id: "", user_id: ""}]);
        handleClose();
        if (roleType === "employee") {
          empRoleMapping.refetch();
        } else if (roleType === "jobtitle") {
          jobRoleMapping.refetch();
        }
      },
    }
  );

  const handleCreate = () => {
    setRoleType("employee");
    setFinalData(addData);
    setTimeout(() => {
      roleCreate.mutate();
    }, 2000);
  };

  const handleEdit = (row) => {
    setId(row.id);
    setRole(row.role_id);
    setUser(row.user_id);
    handleEShow();
  };

  const handleJEdit= (row) => {
    setId(row.id);
    setRole(row.role_id);
    setJobTitle(row.job_title);
    handleJEShow();
  };


  //Job Role Mapping
  const handleJobCreate = () => {
    setRoleType("jobtitle");
    setFinalData(jaddData);
    setTimeout(() => {
      roleCreate.mutate();
    }, 2000);
  }

  const handleRChange = (e) => {
    setRole(e.target.value);
  }
  const handleRJChange = (e) => {
    setRole(e.target.value);
  }
  const handleUChange = (e) => {
    setUser(e.target.value);
  }

  const roleUpdate = useMutation(
    async () =>
      axios.put(
        `${APIURL.url}/admin/rolemapping?table_name=employee&pk_id=`+id,
        {
          data: {role_id: role, user_id: user}
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.getItem("token"),
          }
        }
      ),
    {
      onError: (err) => {
        setEMsg(err.response.data);
        setError(true);
        if (err.response.status === 401) {
          window.location.reload();
          return navigate("/login");
        }
      },
      onSuccess: (response) => {
        setEMsg(response.data);
        setSuccess(true);
        setRole([]);
        setUser([]);
        handleEClose();
        empRoleMapping.refetch();
      },
    }
  );

  const handleUpdate = () => {
    roleUpdate.mutate();
  }

  const roleJUpdate = useMutation(
    async () =>
      axios.put(
        `${APIURL.url}/admin/rolemapping?table_name=jobtitle&pk_id=`+id,
        {
          data: {role_id: role, job_title: jobtitle}
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.getItem("token"),
          }
        }
      ),
    {
      onError: (err) => {
        setEMsg(err.response.data);
        setError(true);
        if (err.response.status === 401) {
          window.location.reload();
          return navigate("/login");
        }
      },
      onSuccess: (response) => {
        setEMsg(response.data);
        setSuccess(true);
        setId();
        setRole([]);
        setJobTitle();
        handleJEClose();
        jobRoleMapping.refetch();
      },
    }
  );

  const handleJUpdate = () => {
    roleJUpdate.mutate();
  }

  if (empRoleMapping.isError || jobRoleMapping.isError || roles.isError || users.isError) {
    if (empRoleMapping.error.response.status === 401) {
      window.location.reload();
      return navigate("/login");
    }
    if (jobRoleMapping.error.response.status === 401) {
      window.location.reload();
      return navigate("/login");
    }
    if (roles.error.response.status === 401) {
      window.location.reload();
      return navigate("/login");
    }
    if (users.error.response.status === 401) {
      window.location.reload();
      return navigate("/login");
    }
  }

  const columns = [
    {
      field: "id",
      headerName: "ID",
      width: 90,
    },
    {
      field: "role_name",
      headerName: "Role Name",
      minWidth: 200,
    },
    {
      field: "first_name",
      headerName: "First Name",
      minWidth: 200,
    },
    {
      field: "last_name",
      headerName: "Last Name",
      minWidth: 200,
    },
    {
      field: "action",
      headerName: "Action",
      sortable: false,
      renderCell: (params) => {
        return (
          <>
            <MDButton
              variant="gradient"
              color="error"
              size="small"
              iconOnly
              onClick={() => handleEdit(params.row)}
              sx={bgColorChange}
            >
              <Icon>edit</Icon>
            </MDButton>
          </>
        );
      },
    },
  ];

  const jcolumns = [
    {
      field: "id",
      headerName: "ID",
      width: 90,
    },
    {
      field: "role_name",
      headerName: "Role Name",
      minWidth: 200,
    },
    {
      field: "job_title",
      headerName: "Job Title",
      minWidth: 200,
    },
    {
      field: "action",
      headerName: "Action",
      sortable: false,
      renderCell: (params) => {
        return (
          <>
            <MDButton
              variant="gradient"
              color="error"
              size="small"
              iconOnly
              onClick={() => handleJEdit(params.row)}
              sx={bgColorChange}
            >
              <Icon>edit</Icon>
            </MDButton>
          </>
        );
      },
    },
  ];

  if (!roles.isSuccess || !users.isSuccess || !jobRoleMapping.isSuccess || !empRoleMapping.isSuccess) {
    return <Loader />;
  }

  return (
    <DashboardLayout>
      <DashboardMenu />
      <MDSnackbar
        color="error"
        icon="error"
        title="Error: Unable to Assigned role"
        content={eMsg}
        open={error}
        close={toggleSnackbar}
      />
      <MDSnackbar
        color="success"
        icon="check_circle"
        title="Success"
        content={eMsg}
        open={success}
        close={toggleSnackbar}
      /> 
      <MDBox my={3}>
        <Tabs value={value} onChange={handleChange} aria-label="Role Mapping">
          <Tab label="Employee Role Mapping" value={1} className="fontFamily" />
          <Tab label="Job Role Mapping" value={2} className="fontFamily" />
        </Tabs>
        <TabPanel value={value} index={1}>
          <Card p={2}>
            <Grid container>
              <Grid item xs={12} md={6} lg={6}></Grid>
              <Grid item xs={12} md={6} lg={6}>
                <MDButton
                  variant="gradient"
                  color="info"
                  className="adminBtn"
                  sx={bgColorChange}
                  onClick={handleShow}
                >
                  Create New
                </MDButton>
              </Grid>
            </Grid>
            <DataGridTables tableRow={empRoleMapping?.data?.data} columns={columns} />
          </Card>
        </TabPanel>
        <TabPanel value={value} index={2}>
          <Card p={2}>
            <Grid container>
              <Grid item xs={12} md={6} lg={6}></Grid>
              <Grid item xs={12} md={6} lg={6}>
                <MDButton
                  variant="gradient"
                  color="info"
                  className="adminBtn"
                  sx={bgColorChange}
                  onClick={handleJShow}
                >
                  Create New
                </MDButton>
              </Grid>
            </Grid>
            <DataGridTables tableRow={jobRoleMapping?.data?.data} columns={jcolumns} />
          </Card>
        </TabPanel>
      </MDBox>

      {/* Employee Role Mapping Starts */}
      {/* Add Employee Role Mapping */}
      <Modal
        open={show}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="modalPopupBox">
          <Typography id="keep-mounted-modal-title" variant="h5" component="h2" sx={txtColorChange}>
            Add Employee Role Mapping
          </Typography>
          <MDBox sx={{ minHeight: "150px", marginTop: "5px" }}>
            {addData.map((val, i) => (
              <Grid container>
                <Grid item xs={5}>
                  <MDBox mb={2} mr={1}>
                    <FormControl variant="standard" sx={{ width: "100%" }}>
                      <InputLabel id="demo-simple-select-standard-label">Select Role</InputLabel>
                      <Select
                        labelId="demo-simple-select-standard-label"
                        id="demo-simple-select-standard"
                        label="Select Role"
                        sx={{ minHeight: "40px" }}
                        value={val.role_id}
                        onChange={(e) => handleRoleChange(e, i)}
                      >
                        {roles.data.data.map((role) => (
                          <MenuItem value={role.id}>{role.name}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </MDBox>
                </Grid>
                <Grid item xs={5}>
                  <MDBox mb={2}>
                    <Autocomplete
                      options={userSuggestions}
                      renderOption={(props, option) => (
                        <li {...props} key={option.userid}>
                          {option.profile_pic ? (
                            <MDAvatar
                              src={option.profile_pic}
                              alt={`${option.first_name.slice(0, 1)}${option.last_name.slice(0, 1)}`}
                              size="sm"
                              bgColor="light"
                            />
                          ) : (
                            <Avatar
                              size="sm"
                              sx={{
                                bgcolor: "#ccc",
                                marginRight: "5px",
                                textTransform: "uppercase",
                              }}
                            >{`${option.first_name.slice(0, 1)}${option.last_name.slice(
                              0,
                              1
                            )}`}</Avatar>
                          )}
                          {`${option.first_name} ${option.last_name} - ${option.employee_id}`}
                        </li>
                      )}
                      getOptionLabel={(option) =>
                        `${option.first_name} ${option.last_name} - ${option.employee_id}`
                      }
                      isOptionEqualToValue={(option, value) => option.id === value.id}
                      onChange={(event, selectedOption) => {
                        updateRecipient(event, selectedOption, i)
                      }}
                      renderInput={(params) => (
                        <MDInput
                          label="Select User"
                          {...params}
                          variant="standard"
                          onChange={updateSuggestions}
                          sx={{ mb: 3 }}
                        />
                      )}
                    />
                  </MDBox>
                </Grid>
                <Grid item xs={2}>
                  <MDBox mt={2} mb={2}>
                    <AddIcon className="giftCardAdd" onClick={handleAddCard} />
                    <RemoveIcon className="giftCardAdd" onClick={() => handleDeleteCard(i)} />
                  </MDBox>
                </Grid>
              </Grid>
            ))}
            <MDBox mt={2} mb={1}>
              <MDButton variant="gradient" color="info" sx={bgColorChange} onClick={handleCreate}>
                Submit
              </MDButton>
            </MDBox>
          </MDBox>
        </Box>
      </Modal>

      {/* Edit Employee Role Mapping */}
      <Modal
        open={eShow}
        onClose={handleEClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="modalPopupBox">
          <Typography id="keep-mounted-modal-title" variant="h5" component="h2" sx={txtColorChange}>
            Edit Employee Role Mapping
          </Typography>
          <MDBox sx={{ minHeight: "150px" }}>
            <Grid container>
              <Grid item xs={6}>
                <MDBox mt={2} mb={2} mr={1}>
                  <FormControl variant="standard" sx={{ width: "100%" }}>
                    <InputLabel id="demo-simple-select-standard-label">Select Role</InputLabel>
                    <Select
                      labelId="demo-simple-select-standard-label"
                      id="demo-simple-select-standard"
                      label="Select Role"
                      sx={{ minHeight: "40px" }}
                      value={role}
                      onChange={(e) => handleRChange(e)}
                    >
                      {roles.data.data.map((role) => (
                        <MenuItem value={role.id}>{role.name}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </MDBox>
              </Grid>
              <Grid item xs={6}>
                <MDBox mt={2} mb={2}>
                  <FormControl variant="standard" sx={{ width: "100%" }}>
                    <InputLabel id="demo-simple-select-standard-label">Select User</InputLabel>
                    <Select
                      labelId="demo-simple-select-standard-label"
                      id="demo-simple-select-standard"
                      label="Select User"
                      sx={{ minHeight: "40px" }}
                      value={user}
                      onChange={(e) => handleUChange(e)}
                    >
                      {users.data.data.map((user) => (
                        <MenuItem value={user.id}>{user.first_name} {user.last_name}-{user.employee_id}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </MDBox>
              </Grid>
            </Grid>
            <MDBox mt={2} mb={1}>
              <MDButton
                variant="gradient"
                color="info"
                sx={bgColorChange}
                onClick={handleUpdate}
              >
                Submit
              </MDButton>
            </MDBox>
          </MDBox>
        </Box>
      </Modal>
      {/* Employee Role Mapping Ends */}

      {/* JobTitle Role Mapping Starts */}
      <Modal
        open={jshow}
        onClose={handleJClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="modalPopupBox">
          <Typography id="keep-mounted-modal-title" variant="h5" component="h2" sx={txtColorChange}>
            Add Jobtitle Role Mapping
          </Typography>
          <MDBox sx={{ minHeight: "150px" }}>
            {jaddData.map((val, j) => (
              <Grid container>
                <Grid item xs={5}>
                  <MDBox mt={2} mb={2} mr={1}>
                    <FormControl variant="standard" sx={{ width: "100%" }}>
                      <InputLabel id="demo-simple-select-standard-label">Select Role</InputLabel>
                      <Select
                        labelId="demo-simple-select-standard-label"
                        id="demo-simple-select-standard"
                        label="Select Role"
                        sx={{ minHeight: "40px" }}
                        value={val.role_id}
                        onChange={(e) => handleJobRoleChange(e, j)}
                      >
                        {roles.data.data.map((role) => (
                          <MenuItem value={role.id}>{role.name}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </MDBox>
                </Grid>
                <Grid item xs={5}>
                  <MDBox mt={2} mb={2}>
                    <MDInput
                      type="text"
                      name="job_title"
                      label="Job Title"
                      fullWidth
                      placeholder="Job Title"
                      value={val.job_title}
                      onChange={(e) => {
                        handleJobChange(e, j);
                      }}
                    />
                  </MDBox>
                </Grid>
                <Grid item xs={2}>
                  <MDBox mt={2} mb={2}>
                    <AddIcon className="giftCardAdd" onClick={handleJAddCard} />
                    <RemoveIcon className="giftCardAdd" onClick={() => handleJDeleteCard(j)} />
                  </MDBox>
                </Grid>
              </Grid>
            ))}
            <MDBox mt={2} mb={1}>
              <MDButton variant="gradient" color="info" sx={bgColorChange} onClick={handleJobCreate}> 
                Submit
              </MDButton>
            </MDBox>
          </MDBox>
        </Box>
      </Modal>

      {/* Edit Jobtitle Role Mapping */}
      <Modal
        open={jeShow}
        onClose={handleJEClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="modalPopupBox">
          <Typography id="keep-mounted-modal-title" variant="h5" component="h2" sx={txtColorChange}>
            Edit Jobtitle Role Mapping
          </Typography>
          <MDBox sx={{ minHeight: "150px" }}>
            <Grid container>
              <Grid item xs={6}>
                <MDBox mt={2} mb={2} mr={1}>
                  <FormControl variant="standard" sx={{ width: "100%" }}>
                    <InputLabel id="demo-simple-select-standard-label">Select Role</InputLabel>
                    <Select
                      labelId="demo-simple-select-standard-label"
                      id="demo-simple-select-standard"
                      label="Select Role"
                      sx={{ minHeight: "40px" }}
                      value={role}
                      onChange={(e) => handleRJChange(e)}
                    >
                      {roles.data.data.map((role) => (
                        <MenuItem value={role.id}>{role.name}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>                    
                </MDBox>
              </Grid>
              <Grid item xs={6}>
                <MDBox mt={2} mb={2}>
                  <MDInput
                    type="text"
                    label="JobTitle"
                    fullWidth
                    placeholder="Job Title"
                    value={jobtitle}
                    onChange={(e) => {
                      setJobTitle(e.target.value);
                    }}
                  />
                </MDBox>
              </Grid>
            </Grid>
            <MDBox mt={2} mb={1}>
              <MDButton
                variant="gradient"
                color="info"
                sx={bgColorChange}
                onClick={handleJUpdate}
              >
                Submit
              </MDButton>
            </MDBox>
          </MDBox>
        </Box>
      </Modal>
      {/* JobTitle Role Mapping Ends */}

      <SiteFooter />
    </DashboardLayout>
  );
};

export default EmployeeRoleMapping;
