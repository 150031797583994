// import Card from "@mui/material/Card";
// import Divider from "@mui/material/Divider";

// Material Dashboard 2 PRO React components
import {
  Autocomplete,
  Avatar,
  Box,
  Card,
  FormControl,
  Grid,
  Icon,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Switch,
  Typography,
} from "@mui/material";
import axios from "axios";
import MDAvatar from "components/MDAvatar";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDSnackbar from "components/MDSnackbar";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { txtColorChange, bgColorChange } from "layouts/Rewards/User/components/Common/ColorChange";
import Loader from "layouts/Rewards/User/components/Common/Loader";
import Logout from "layouts/Rewards/User/components/Common/Logout";
import SiteFooter from "layouts/Rewards/User/components/Common/SiteFooter";
import URLSettings from "layouts/Rewards/User/components/Common/URLSettings";
import { useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import CloseIcon from '@mui/icons-material/Close';
import DataGridTables from "layouts/Rewards/User/components/Common/DataGridTables";
import DashboardMenu from "examples/Navbars/DashboardMenu";
// OrderDetails page components

const jose = require("jose");

function Modules() {
  let APIURL = URLSettings();
  const navigate = useNavigate();

  useEffect(() => {
    if (!localStorage.getItem("token")) {
      window.location.reload();
      return navigate("/login");
    }
  }, []);

  const [id, setId] = useState();
  const [name, setName] = useState();
  const [status, setStatus] = useState(true);
  const [opText, setOpText] = useState(false);
  const [eMsg, setEMsg] = useState("");
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  const handleStatus = () => {
    setStatus(!status);
  };

  const toggleSnackbar = () => {
    if (success) {
      setSuccess(!success);
    }
    if (error) {
      setError(!error);
    }
  };
  useEffect(() => {
    setTimeout(() => {
      setSuccess(false);
      setError(false);
    }, 2000);
  }, [success, error]);

  const modules = useQuery(["module"], async () =>
    axios.get(`${APIURL.url}/admin/security`, {
      params: {
        table_name: "modules"
      },
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    })
  );

  if (modules.isError) {
    if (modules.error.response.status === 401) {
      window.location.reload();
      return navigate("/login");
    }
  }

  const [openCreate, setOpenCreate] = useState(false);
  const handleCreateOpen = () => setOpenCreate(true);
  const handleCreateClose = () => {
    setOpText(false);
    setOpenCreate(false);
    setName("");
    setStatus(true);
  } 

  const moduleCreate = useMutation(
    async () =>
      axios.post(
        `${APIURL.url}/admin/security?table_name=modules`,
        {
          name: name,
          status: status,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.getItem("token"),
          },
        }
      ),
    {
      onError: (err) => {
        setEMsg(err.response.data);
        setError(true);
        if (err.response.status === 401) {
          window.location.reload();
          return navigate("/login");
        }
      },
      onSuccess: (response) => {
        setEMsg(response.data);
        setSuccess(true);
        setName("");
        setStatus(true);
        handleCreateClose();
        modules.refetch();
      },
    }
  );

  const handleCreate = () => {
    moduleCreate.mutate();
  };

  const handleEdit = (row) => {
    setId(row.id);
    setName(row.name);
    setOpText(true);
    handleCreateOpen();
  };

  const moduleUpdate = useMutation(
    async () =>
      axios.put(
        `${APIURL.url}/admin/security?table_name=modules&pk_id=`+id,
        {
          name: name,
          status: status,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.getItem("token"),
          },
        }
      ),
    {
      onError: (err) => {
        setEMsg(err.response.data);
        setError(true);
        if (err.response.status === 401) {
          window.location.reload();
          return navigate("/login");
        }
      },
      onSuccess: (response) => {
        setEMsg(response.data);
        setSuccess(true);
        setId("");
        setName("");
        setStatus(true);
        handleCreateClose();
        modules.refetch();
      },
    }
  );

  const handleUpdate = () => {
    moduleUpdate.mutate();
  }

  const columns = [
    { 
      field: "id", 
      headerName: "ID", 
      width: 90 
    },
    {
      field: "name",
      headerName: "Name",
      minWidth: 200
    },
    {
      field: "action",
      headerName: "Action",
      sortable: false,
      renderCell: (params) => {
        return (
          <>
            <MDButton
              variant="gradient"
              color="error"
              size="small"
              iconOnly
              onClick={() => handleEdit(params.row)}
              sx={bgColorChange}
            >
              <Icon>edit</Icon>
            </MDButton>
          </>
        );
      }
    }
  ];

  if (!modules.isSuccess) {
    return <Loader />;
  }

  return (
    <DashboardLayout>
      <DashboardMenu />
      <MDSnackbar
        color="error"
        icon="error"
        title="Error: Unable to Assigned role"
        content={eMsg}
        open={error}
        close={toggleSnackbar}
      />
      <MDSnackbar
        color="success"
        icon="check_circle"
        title="Success"
        content={eMsg}
        open={success}
        close={toggleSnackbar}
      />
      <MDBox mt={1} my={3}>
        <Card>
          <MDBox p={3} lineHeight={1}>
            <Grid container>
              <Grid item xs={6}>
                <MDTypography variant="h5" fontWeight="medium" sx={txtColorChange}>
                  Modules
                </MDTypography>
              </Grid>
              <Grid item xs={6}>
                <MDButton
                  variant="gradient"
                  color="info"
                  className="adminBtn"
                  sx={bgColorChange}
                  onClick={handleCreateOpen}
                >
                  Create New
                </MDButton>
              </Grid>
            </Grid>
          </MDBox>
          <DataGridTables tableRow={modules?.data?.data} columns={columns}  />
        </Card>
      </MDBox>
      <SiteFooter />

      <Modal
        open={openCreate}
        onClose={handleCreateClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="modalPopupBox">
          <Grid container>
            <Grid item xs={12}>
              <Typography
                id="keep-mounted-modal-title"
                variant="h5"
                component="h2"
                sx={txtColorChange}
              >
                {opText ? "Update Modules" : "Create Modules"}
              </Typography>
              <MDBox variant="outlined" className="close-button" onClick={handleCreateClose}>
                <CloseIcon />
              </MDBox>
            </Grid>
          </Grid>
          <MDBox component="form" role="form">
            <Grid container>
              <Grid item sx={12}>
                <MDBox mt={2} mb={2} mr={1}>
                  <MDInput
                    type="text"
                    label="Name"
                    fullWidth
                    placeholder="Name"
                    value={name}
                    onChange={(e) => {
                      setName(e.target.value);
                    }}
                  />
                </MDBox>
              </Grid>
              <Grid item xs={12}>
                <MDBox mt={2} mb={2} mr={1}>
                  <MDTypography
                    variant="button"
                    fontWeight="regular"
                    color="text"
                    sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
                  >
                    &nbsp;&nbsp;Status :&nbsp;
                  </MDTypography>
                  <Switch checked={status} onChange={handleStatus} /> 
                </MDBox>
              </Grid>
            </Grid>
            <MDBox mt={2} mb={1}>
              {opText ? (
                <>
                  <MDButton
                    variant="gradient"
                    color="info"
                    onClick={handleUpdate}
                    sx={bgColorChange}
                  >
                    Submit
                  </MDButton>
                </>
              ) : (
                <>
                  <MDButton
                    variant="gradient"
                    color="info"
                    onClick={handleCreate}
                    sx={bgColorChange}
                  >
                    Submit
                  </MDButton>
                </>
              )}
            </MDBox>
          </MDBox>
        </Box>
      </Modal>
    </DashboardLayout>
  );
}

export default Modules;
