import React, { useState, useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";


export const MainLayout = () => {

  const navigate = useNavigate();
  const location = useLocation();

  const [userLoggedIn, setUserLoggedIn] = useState(localStorage.getItem("token"))
  
  const jose = require("jose");
  const checkForExpiration = () => {
    try {
      let loginToken = localStorage.getItem('token');
      if (loginToken) {
        setUserLoggedIn(true);

        let token_object = jose.decodeJwt(localStorage.getItem('token'));
        let token_exp = token_object.exp

        //Check if token expired
        if (new Date() > new Date(token_exp * 1000)) {
          setUserLoggedIn(false)
        }
      } else {
        setUserLoggedIn(false)
      }
    }catch(e){
      setUserLoggedIn(false)
    }
  }
  useEffect(() => {
    checkForExpiration();
  }, [location.pathname])

  useEffect(() => {
    if (!userLoggedIn) {
      localStorage.removeItem("token");
      localStorage.removeItem("admin");
      localStorage.removeItem("profilepic");
      localStorage.removeItem("profilepic");
      localStorage.removeItem("personal_balance");
      localStorage.removeItem("settings");
      navigate("/login", { replace: true, state: { from: location.pathname } });
    }
  }, [userLoggedIn]);

  return (<>
    {userLoggedIn && <Outlet />} 
    </>
  );
};

export default MainLayout;
