// Material Dashboard 2 PRO React components
// import PropTypes from "prop-types";
import { useEffect, useState } from "react";

// Material Dashboard 2 PRO React context
// import { useMaterialUIController } from "context";
import { Box, Card, Grid, Icon, Tab, Tabs, Tooltip, Typography } from "@mui/material";
import MDBox from "components/MDBox";

import { isMobile } from "react-device-detect";

import { useQuery } from "react-query";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import SiteFooter from "../Common/SiteFooter";
import { useMaterialUIController } from "context";
import MuiLink from "@mui/material/Link";
import BookingCard from "examples/Cards/BookingCard";
import URLSettings from "../Common/URLSettings";
import MDTypography from "components/MDTypography";
import BookingCard1 from "examples/Cards/BookingCard1";
import TabPanel from "../Common/TabPanel";
import Loader from "../Common/Loader";
import Logout from "../Common/Logout";
import ComingSoon from "../../../../../assets/images/swag-coming-soon.gif";

const jose = require("jose");

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function Redeemption() {
  let APIURL = URLSettings();
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const navigate = useNavigate();

  const settings = localStorage.getItem("settings");
  let disclaimer = { Name: "", Value: "" };
  let tabsDefault = { Name: "", Value: 1 };
  let giftCardName = { Name: "", Value: "" };
  let donationName = { Name: "", Value: "" };
  let swagName = { Name: "", Value: "" };

  let giftCardFlag = { Name: "", Value: "" };
  let donationFlag = { Name: "", Value: "" };
  let swagFlag = { Name: "", Value: "" };
  let defaultVal = 1; 
  if (settings && settings !== "undefined") {
    var obj = JSON.parse(settings);
    disclaimer = obj.find((o) => o.Name === "disclaimer");
    tabsDefault = obj.find((o) => o.Name === "tabs_defaltVal"); 
    defaultVal = tabsDefault.Value;
    giftCardFlag = obj.find((o) => o.Name === "giftcard_flag"); 
    donationFlag = obj.find((o) => o.Name === "donation_flag"); 
    swagFlag = obj.find((o) => o.Name === "swag_flag");
    giftCardName = obj.find((o) => o.Name === "giftcard_name"); 
    donationName = obj.find((o) => o.Name === "donation_name"); 
    swagName = obj.find((o) => o.Name === "swag_name");
  }

  function fDisclaimer() {
    return { __html: disclaimer.Value };
  }


  const logout = () => {
    localStorage.removeItem("token");
    navigate("/login");
  };

  useEffect(() => {
    if (!localStorage.getItem("token")) {
      window.location.reload();
      return navigate("/login");
    }
  }, []);

  const token = useQuery("token", async () => {
    // should be validating with public key
    const text = localStorage.getItem("token");
    if (text === null || text === "") {
      throw new Error("No token found");
    }
    const claims = jose.decodeJwt(text);
    claims.token = text;
    return claims;
  });

  if (token.isError) {
    window.location.reload();
    return navigate("/login");
  }

  const giftCards = useQuery(["giftCards"], async () =>
    axios.get(`${APIURL.url}/giftCardCatalog`, {
      params: {
        isMobile,
        isDark: darkMode,
      },
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    })
  ); // TODO: error checking

  //Swag store api integration
  const swagStores = useQuery(["swagStores"], async () =>
    axios.get(`${APIURL.url}/getSwagCatalog`, {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    })
  );

  const [value, setValue] = useState(Number(defaultVal));
  const handleChange = (event, newValue) => {
    setValue(Number(newValue));
  };

  const [tCount, setTCount] = useState(0);
  const handleCartCountVal = (valCount) => {
    setTCount(valCount);
    // console.log("count::", valCount);
    // console.log("session count::", JSON.parse(localStorage.getItem("cartItems")).length);
  };

  if (giftCards.isError || swagStores.isError) {
    if (giftCards.error.response.status === 401) {
      window.location.reload();
      return navigate("/login");
    }
    if (swagStores.error.response.status === 401) {
      window.location.reload();
      return navigate("/login");
    }
  }

  if (!giftCards.isSuccess || !swagStores.isSuccess) {
    return <Loader />;
  }

  return (
    <DashboardLayout>
      <DashboardNavbar cartCount={tCount} />
      <MDBox my={3}>
        <Grid container spacing={2}>
          <Box sx={{ width: "100%" }}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs value={value} onChange={handleChange} aria-label="GiftCard">
                {(giftCardFlag.Value === "yes") && (
                <Tab label={giftCardName.Value} value={1} className="fontFamily" />
                )}
                {(donationFlag.Value === "yes") && (
                <Tab label={donationName.Value} value={2} className="fontFamily" />
                )}
                {(swagFlag.Value === "yes") && (
                <Tab label={swagName.Value} value={3} className="fontFamily" />
                )}
              </Tabs>
            </Box>
            {(giftCardFlag.Value === "yes") && (
            <TabPanel value={value} index={1}>
              <Grid container spacing={2}>
                {giftCards.data.data.map((giftCard) => (
                  <>
                    {(giftCard.gift_card_type === "gift card" || giftCard.gift_card_type === "cash equivalent") && (
                      <Grid item xs={12} md={6} lg={3}>
                        <MDBox>
                          <BookingCard1
                            title={giftCard.brandName}
                            image={giftCard.imageUrl}
                            cardDetails={giftCard.card_details}
                            cardType={giftCard.valueType}
                            handleCartCount={handleCartCountVal}
                            redemptionInstructions={giftCard.redemptionInstructions}
                            terms={giftCard.terms}
                            description={giftCard.description}
                            disclaimer={giftCard.disclaimer}
                            shortDescription={giftCard.shortDescription}
                          />
                        </MDBox>
                      </Grid>
                    )}
                  </>
                ))}
              </Grid>
            </TabPanel>
            )}
            {(donationFlag.Value === "yes") && (
            <TabPanel value={value} index={2}>
              <Grid container spacing={2}>
                {giftCards.data.data.map((giftCard) => (
                  <>
                    {giftCard.gift_card_type === "donation" && (
                      <Grid item xs={12} md={6} lg={3}>
                        <MDBox>
                          <BookingCard1
                            title={giftCard.brandName}
                            image={giftCard.imageUrl}
                            cardDetails={giftCard.card_details}
                            cardType={giftCard.valueType}
                            handleCartCount={handleCartCountVal}
                            redemptionInstructions={giftCard.redemptionInstructions}
                            terms={giftCard.terms}
                            description={giftCard.description}
                            disclaimer={giftCard.disclaimer}
                            shortDescription={giftCard.shortDescription}
                          />
                        </MDBox>
                      </Grid>
                    )}
                  </>
                ))}
              </Grid>
            </TabPanel>
            )}
            {(swagFlag.Value === "yes") && (
            <TabPanel value={value} index={3}>
              {swagStores.data.data.length > 0 ? (
                <>
                  <Grid container spacing={2}>
                      {swagStores.data.data.map((swagStore) => (
                        <Grid item xs={12} md={6} lg={3}>
                          <MDBox>
                            <BookingCard1
                              title={swagStore.card_name}
                              image={swagStore.image_url}
                              cardDetails={swagStore.id + " - " + swagStore.card_code + " - " + swagStore.card_value}
                              cardType="swagstore"
                              handleCartCount={handleCartCountVal}
                              redemptionInstructions={swagStore.redemption_instructions}
                              terms={swagStore.terms}
                              description={swagStore.description}
                              disclaimer={swagStore.disclaimer}
                              shortDescription={swagStore.description}
                            />
                          </MDBox>
                        </Grid>
                      ))}
                  </Grid>
                </>
              ) : (
                <>
                  <div className="image-container">
                    <img src={ComingSoon} width="500" height="240" style={{ alignSelf: 'center' }} />
                  </div>
                </>
              )}
            </TabPanel>
            )}
          </Box>
        </Grid>
      </MDBox>
      <Grid container spacing={2}>
        <Grid item xs={12} md={12} lg={12} mb={2}>
          <Card>
            <MDBox p={2}>
              <Typography sx={{fontSize: "10px"}} color={darkMode ? "secondary" : "text"}>
                <div dangerouslySetInnerHTML={fDisclaimer()}></div>
              </Typography>
            </MDBox>
          </Card>
        </Grid>
      </Grid>
      <SiteFooter />
    </DashboardLayout>
  );
}

export default Redeemption;
