/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import App from "App";
import './index.css';
// import Test from "../public/test.html";

// Material Dashboard 2 PRO React Context Provider
import { MaterialUIControllerProvider } from "context";
// import SEO from "layouts/Rewards/User/components/Common/SEO";

ReactDOM.render(
  <BrowserRouter>
    <MaterialUIControllerProvider>
      <App />
    </MaterialUIControllerProvider>
  </BrowserRouter>,
  document.getElementById("root")

  // react helmet
  // https://www.fullstacklabs.co/blog/improving-seo-in-react-apps-with-react-helmet
  // domain === "wv" ? document.getElementById("root") : document.getElementById("root1")
);
