/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";

// react-router-dom components
import { useLocation, NavLink } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import MuiLink from "@mui/material/Link";
import Icon from "@mui/material/Icon";
import { useMutation, useQuery } from "react-query";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React examples
import SidenavCollapse from "examples/Sidenav/SidenavCollapse";
import SidenavList from "examples/Sidenav/SidenavList";
import SidenavItem from "examples/Sidenav/SidenavItem";

// Custom styles for the Sidenav
import SidenavRoot from "examples/Sidenav/SidenavRoot";
// import sidenavLogoLabel from "examples/Sidenav/styles/sidenav";

// Material Dashboard 2 PRO React context
import {
  useMaterialUIController,
  setMiniSidenav,
  setTransparentSidenav,
  setWhiteSidenav,
} from "context";
import MDAvatar from "components/MDAvatar";
import { Avatar, Box, Grid, Modal, Switch, Tab, Tabs, Tooltip, Typography } from "@mui/material";
import TwitterIcon from '@mui/icons-material/Twitter';
import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import PinterestIcon from '@mui/icons-material/Pinterest';
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import SideDashboardNavbar from "examples/Navbars/SideDashboardNavbar";
import InstagramIcon from "@mui/icons-material/Instagram";
import { txtColorChange, bgColorChange } from "layouts/Rewards/User/components/Common/ColorChange";
import MDButton from "components/MDButton";
import CloseIcon from '@mui/icons-material/Close';
import MDInput from "components/MDInput";
import axios from "axios";
import URLSettings from "layouts/Rewards/User/components/Common/URLSettings";
import TabPanel from "layouts/Rewards/User/components/Common/TabPanel";

const jose = require("jose");

function Sidenav({ color, brand, brandName, routes, ...rest }) {

  let APIURL = URLSettings(); 
  const [openCollapse, setOpenCollapse] = useState(false);
  const [openNestedCollapse, setOpenNestedCollapse] = useState(false);
  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav, transparentSidenav, whiteSidenav, darkMode } = controller;
  const location = useLocation();
  const { pathname } = location;
  const collapseName = pathname.split("/").slice(1)[0];
  const items = pathname.split("/").slice(1);
  const itemParentName = items[1];
  const itemName = items[items.length - 1];

  const settings = localStorage.getItem("settings");
  var obj = JSON.parse(settings);
  let facebook = obj.find((o) => o.Name === "facebook");
  let linkedin = obj.find((o) => o.Name === "linkedin");
  let twitter = obj.find((o) => o.Name === "twitter");
  let instagram = obj.find((o) => o.Name === "instagram");
  let tiktok = obj.find((o) => o.Name === "tiktok");
  let support = obj.find((o) => o.Name === "support");
  let side_bar1 = obj.find((o) => o.Name === "side_bar_1");
  let side_bar2 = obj.find((o) => o.Name === "side_bar_2");
  let side_bar3 = obj.find((o) => o.Name === "side_bar_3");
  let side_bar4 = obj.find((o) => o.Name === "side_bar_4");
  let side_bar5 = obj.find((o) => o.Name === "side_bar_5");
  let side_bar6 = obj.find((o) => o.Name === "side_bar_6");
  let sBarColor = obj.find((o) => o.Name === "sidebarBgColor"); 
  let sBarTxColor = obj.find((o) => o.Name === "sidebarTxtColor");
  let sbgImg = obj.find((o) => o.Name === "background_image");
  let lsImg = obj.find((o) => o.Name === "login_screen_logo");
  let lfImg = obj.find((o) => o.Name === "login_screen_fav_icon");
  function supportText() {
    return { __html: support.Value };
  }

  const userName = useQuery(
    "token",
    async () => {
      // should be validating with public key
      const text = localStorage.getItem("token");
      if (text === null || text === "") {
        throw new Error("No token found");
      }
      const claims = jose.decodeJwt(text);
      claims.token = text;
      return claims;
    },
    {
      placeholderData: {
        first_name: "loading",
        last_name: "...",
      },
    }
  );

  let pBal;
  if (userName) {
    if (userName !== "") {
      pBal = localStorage.getItem("personal_balance");
    }
  }

  let defaultValue = { id: "", Name: "", Value: "", Status: false };

  if (settings && settings !== undefined) {
    side_bar1 = side_bar1;
    side_bar2 = side_bar2;
    side_bar3 = side_bar3;
    side_bar4 = side_bar4;
    side_bar5 = side_bar5;
    side_bar6 = side_bar6;
    sBarColor = sBarColor;
    sBarTxColor = sBarTxColor;
    sbgImg = sbgImg;
    lsImg = lsImg;
    lfImg = lfImg;
    if (side_bar1 === undefined || side_bar2 === undefined || side_bar3 === undefined || side_bar4 === undefined || side_bar5 === undefined || side_bar6 === undefined || sBarColor === undefined || sBarTxColor === undefined || sbgImg === undefined || lsImg === undefined || lfImg === undefined) {
      side_bar1 = defaultValue;
      side_bar2 = defaultValue;
      side_bar3 = defaultValue;
      side_bar4 = defaultValue;
      side_bar5 = defaultValue;
      side_bar6 = defaultValue;
      sBarColor = defaultValue;
      sBarTxColor = defaultValue;
      sbgImg = defaultValue;
      lsImg = defaultValue;
      lfImg = defaultValue;
    }
  }

  let sidebarTxtColor = defaultValue;
  let sidebarLogo = defaultValue;
  if (settings && settings !== undefined) {
    var obj = JSON.parse(settings);
    sidebarTxtColor = obj.find((o) => o.Name === "sidebarTxtColor");
    sidebarLogo = obj.find((o) => o.Name === "side_bar_logo");
    if (sidebarTxtColor === undefined || sidebarLogo === undefined) {
      sidebarTxtColor = defaultValue;
      sidebarLogo = defaultValue;
    }
  }

  // let textColor = "white";
  // if (transparentSidenav || (whiteSidenav && !darkMode)) {
  //   textColor = "dark";
  // } else if (whiteSidenav && darkMode) {
  //   textColor = "inherit";
  // }
  let textColor = sidebarTxtColor.Value;

  const closeSidenav = () => setMiniSidenav(dispatch, true);

  useEffect(() => {
    setOpenCollapse(collapseName);
    setOpenNestedCollapse(itemParentName);
  }, []);

  const [value, setValue] = useState(1);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    // A function that sets the mini state of the sidenav.
    function handleMiniSidenav() {
      setMiniSidenav(dispatch, window.innerWidth < 1200);
      setTransparentSidenav(dispatch, window.innerWidth < 1200 ? false : transparentSidenav);
      setWhiteSidenav(dispatch, window.innerWidth < 1200 ? false : whiteSidenav);
    }

    /** 
     The event listener that's calling the handleMiniSidenav function when resizing the window.
    */
    window.addEventListener("resize", handleMiniSidenav);

    // Call the handleMiniSidenav function to set the state with the initial value.
    handleMiniSidenav();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleMiniSidenav);
  }, [dispatch, location]);

  // Render all the nested collapse items from the routes.js
  const renderNestedCollapse = (collapse) => {
    const template = collapse.map(({ name, route, key, href }) =>
      href ? (
        <Link
          key={key}
          href={href}
          target="_blank"
          rel="noreferrer"
          sx={{ textDecoration: "none" }}
        >
          <SidenavItem name={name} nested />
        </Link>
      ) : (
        <NavLink to={route} key={key} sx={{ textDecoration: "none" }}>
          <SidenavItem name={name} active={route === pathname} nested />
        </NavLink>
      )
    );

    return template;
  };
  // Render the all the collpases from the routes.js
  const renderCollapse = (collapses) =>
    collapses.map(({ name, collapse, route, href, key }) => {
      let returnValue;

      if (collapse) {
        returnValue = (
          <SidenavItem
            key={key}
            color={color}
            name={name}
            active={key === itemParentName ? "isParent" : false}
            open={openNestedCollapse === key}
            onClick={({ currentTarget }) =>
              openNestedCollapse === key && currentTarget.classList.contains("MuiListItem-root")
                ? setOpenNestedCollapse(false)
                : setOpenNestedCollapse(key)
            }
          >
            {renderNestedCollapse(collapse)}
          </SidenavItem>
        );
      } else {
        returnValue = href ? (
          <Link
            href={href}
            key={key}
            target="_blank"
            rel="noreferrer"
            sx={{ textDecoration: "none" }}
          >
            <SidenavItem color={color} name={name} active={key === itemName} />
          </Link>
        ) : (
          <NavLink to={route} key={key} sx={{ textDecoration: "none" }}>
            <SidenavItem color={color} name={name} active={key === itemName} />
          </NavLink>
        );
      }
      return <SidenavList key={key}>{returnValue}</SidenavList>;
    });

  // Render all the routes from the routes.js (All the visible items on the Sidenav)
  const renderRoutes = routes.map(
    ({ type, name, icon, title, collapse, noCollapse, key, href, route }) => {
      let returnValue;
      let iconInfo;
      let profilPic = localStorage.getItem("profilepic");
      if (profilPic === "null") {
        iconInfo = (
          <Avatar
            size="sm"
            sx={{
              bgcolor: "#ccc",
              marginRight: "5px",
              textTransform: "uppercase",
            }}
          >{`${userName.data.first_name.slice(0, 1)}${userName.data.last_name.slice(
            0,
            1
          )}`}</Avatar>
        );
      } else {
        iconInfo = (
          <MDAvatar
            src={profilPic}
            alt={`${userName.data.first_name.slice(0, 1)}${userName.data.last_name.slice(0, 1)}`}
            size="sm"
            bgColor="light"
          />
        );
      }

      if (type === "collapse") {
        if (key === "user-name") {
          returnValue = (
            <SidenavCollapse
              key={key}
              name={`${userName.data.first_name} ${userName.data.last_name}`}
              icon={iconInfo}
              active={key === collapseName}
              open={openCollapse === key}
              onClick={() => (openCollapse === key ? setOpenCollapse(false) : setOpenCollapse(key))}
            >
              {collapse ? renderCollapse(collapse) : null}
            </SidenavCollapse>
          );
        } else if (href) {
          returnValue = (
            <Link
              href={href}
              key={key}
              target="_blank"
              rel="noreferrer"
              sx={{ textDecoration: "none" }}
            >
              <SidenavCollapse
                name={name}
                icon={icon}
                active={key === collapseName}
                noCollapse={noCollapse}
              />
            </Link>
          );
        } else if (noCollapse && route) {
          returnValue = (
            <NavLink to={route} key={key}>
              <SidenavCollapse
                name={name}
                icon={icon}
                noCollapse={noCollapse}
                active={key === collapseName}
              >
                {collapse ? renderCollapse(collapse) : null}
              </SidenavCollapse>
            </NavLink>
          );
        } else {
          returnValue = (
            <SidenavCollapse
              key={key}
              name={name}
              icon={icon}
              active={key === collapseName}
              open={openCollapse === key}
              onClick={() => (openCollapse === key ? setOpenCollapse(false) : setOpenCollapse(key))}
            >
              {collapse ? renderCollapse(collapse) : null}
            </SidenavCollapse>
          );
        }
      } else if (type === "title") {
        returnValue = (
          <MDTypography
            key={key}
            color={textColor}
            display="block"
            variant="caption"
            fontWeight="bold"
            textTransform="uppercase"
            pl={3}
            mt={2}
            mb={1}
            ml={1}
          >
            {title}
          </MDTypography>
        );
      } else if (type === "divider") {
        returnValue = (
          <Divider
            key={key}
            light={
              (!darkMode && !whiteSidenav && !transparentSidenav) ||
              (darkMode && !transparentSidenav && whiteSidenav)
            }
          />
        );
      }
      return returnValue;
    }
  );

  // Toggle related Code starts here
  let finalRouteFlag = 'no';
  if (localStorage.getItem("token")) {
    const jose = require("jose");
    const claims = jose.decodeJwt(localStorage.getItem("token"));
    if (claims.sp_admin === "yes" || claims.admin === "yes") {
      finalRouteFlag = "yes";
    }
  }

  const [pageStatus, setPageStatus] = useState(false);
  const handleEdit = () => {
    setPageStatus(!pageStatus);
  } 
  const [selectedFile, setSelectedFile] = useState(null);
  const handleFileSelect = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  //background Image file
  const [bFile, setBFile] = useState(null);
  const handleBFileSelect = (event) => {
    setBFile(event.target.files[0]);
  };
  
  //login screen logo
  const [lSFile, setLSFile] = useState(null);
  const handleLSFileSelect = (event) => {
    setLSFile(event.target.files[0]);
  };

  //login screen fav icon
  const [lFFile, setLFFile] = useState(null);
  const handleLFFileSelect = (event) => {
    setLFFile(event.target.files[0]);
  };

  const [sId, setSId] = useState(""); 
  const [sVal, setSValue] = useState("");
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false); 
  const handleEditImg = (val) => {
    setSId(val.id);
    setSValue(val.Value);
    handleOpen();
  }

  const handleSocialIcons = () => {
    console.log("social icons");
    handleSocialOpen();
  }

  const handleMenuItems = () => {
    handleMenuOpen();
  }

  const [sbarBgColor, setSBarBgColor] = useState(sBarColor.Value);
  const [sbarTxtColor, setSBarTxtColor] = useState(sBarTxColor.Value);

  const [sidebar1, setSidebar1] = useState(side_bar1.Value);
  const [s1Status, setS1Status] = useState(side_bar1.Status);
  const handleS1Status = () => {
    setS1Status(!s1Status);
  };

  const [sidebar2, setSidebar2] = useState(side_bar2.Value);
  const [s2Status, setS2Status] = useState(side_bar2.Status);
  const handleS2Status = () => {
    setS2Status(!s2Status);
  };

  const [sidebar3, setSidebar3] = useState(side_bar3.Value);
  const [s3Status, setS3Status] = useState(side_bar3.Status);
  const handleS3Status = () => {
    setS3Status(!s3Status);
  };

  const [sidebar4, setSidebar4] = useState(side_bar4.Value);
  const [s4Status, setS4Status] = useState(side_bar4.Status);
  const handleS4Status = () => {
    setS4Status(!s4Status);
  };

  const [sidebar5, setSidebar5] = useState(side_bar5.Value);
  const [s5Status, setS5Status] = useState(side_bar5.Status);
  const handleS5Status = () => {
    setS5Status(!s5Status);
  };

  const [sidebar6, setSidebar6] = useState(side_bar6.Value);
  const [s6Status, setS6Status] = useState(side_bar6.Status);
  const handleS6Status = () => {
    setS6Status(!s6Status);
  };

  const [facebookLink, setFacebookLink] = useState(facebook.Value);
  const [fStatus, setFStatus] = useState(facebook.Status);
  const handleFStatus = () => {
    setFStatus(!fStatus);
  };

  const [instagramLink, setInstagramLink] = useState(instagram.Value);
  const [iStatus, setIStatus] = useState(instagram.Status);
  const handleIStatus = () => {
    setIStatus(!iStatus);
  };

  const [linkedinLink, setLinkedinLink] = useState(linkedin.Value);
  const [lStatus, setLStatus] = useState(linkedin.Status);
  const handleLStatus = () => {
    setLStatus(!lStatus);
  };

  const [twitterLink, setTwitterLink] = useState(twitter.Value);
  const [tStatus, setTStatus] = useState(twitter.Status);
  const handleTStatus = () => {
    setTStatus(!tStatus);
  };

  const [tiktokLink, setTiktokLink] = useState(tiktok.Value);
  const [tiStatus, setTiStatus] = useState(tiktok.Status);
  const handleTiStatus = () => {
    setTiStatus(!tiStatus);
  };
  

  const [openMenu, setOpenMenu] = useState(false);
  const handleMenuOpen = () => setOpenMenu(true);
  const handleMenuClose = () => setOpenMenu(false); 

  const [openSocial, setOpenSocial] = useState(false);
  const handleSocialOpen = () => setOpenSocial(true);
  const handleSocialClose = () => setOpenSocial(false); 

  const reloadSettings = useMutation(
    async () =>
      axios.get(`${APIURL.url}/admin/settings`, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }),
    {
      onError: (error) => {
      },
      onSuccess: (data) => {
        localStorage.setItem("settings", JSON.stringify(data.data.rows));
        window.location.reload();
      },
    }
  );

  // Background Image
  const handleBackImgSubmit = async () => {
    // event.preventDefault(); 
    if (bFile != null) {
      const formData = new FormData();
      formData.append("file", bFile);
      formData.append("file_location", "common");
      formData.append("code", APIURL.code);
      formData.append("s_id", sbgImg.id);
      formData.append("value", sbgImg.Value);
      formData.append("status", true);
      try { 
        const response = await axios({ 
          method: "post",
          url: `${APIURL.url}/admin/updateSettings`,
          data: formData,
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: localStorage.getItem("token"),
          }, 
        });
        if (response) {
          handleClose();
          reloadSettings.mutate();
        }
      } catch (error) {
        handleClose();
        if (error.response.status === 401) {
          window.location.reload();
          return navigate("/login");
        }
      }
    } 
  }

  // Login Screen Logo
  const handleLoginSSubmit = async () => {
    // event.preventDefault(); 
    if (lSFile != null) {
      const formData = new FormData();
      formData.append("file", lSFile);
      formData.append("file_location", "common");
      formData.append("code", APIURL.code);
      formData.append("s_id", lsImg.id);
      formData.append("value", lsImg.Value);
      formData.append("status", true);
      try { 
        const response = await axios({ 
          method: "post",
          url: `${APIURL.url}/admin/updateSettings`,
          data: formData,
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: localStorage.getItem("token"),
          }, 
        });
        if (response) {
          handleClose();
          reloadSettings.mutate();
        }
      } catch (error) {
        handleClose();
        if (error.response.status === 401) {
          window.location.reload();
          return navigate("/login");
        }
      }
    } 
  }

  // Login Screen Fav Icon
  const handleLoginFSubmit = async () => {
    // event.preventDefault(); 
    if (lFFile != null) {
      const formData = new FormData();
      formData.append("file", lFFile);
      formData.append("file_location", "common");
      formData.append("code", APIURL.code);
      formData.append("s_id", lfImg.id);
      formData.append("value", lfImg.Value);
      formData.append("status", true);
      try { 
        const response = await axios({ 
          method: "post",
          url: `${APIURL.url}/admin/updateSettings`,
          data: formData,
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: localStorage.getItem("token"),
          }, 
        });
        if (response) {
          handleClose();
          reloadSettings.mutate();
        }
      } catch (error) {
        handleClose();
        if (error.response.status === 401) {
          window.location.reload();
          return navigate("/login");
        }
      }
    } 
  }

  const handleLogins = () => {
    if (bFile != null) {
      handleBackImgSubmit();
    } 

    if (lSFile != null) {
      handleLoginSSubmit();
    }

    if (lFFile != null) {
      handleLoginFSubmit();
    }
  }

  const handleSubmit = async (event) => {
    event.preventDefault(); 
    if (selectedFile != null) {
      const formData = new FormData();
      formData.append("file", selectedFile);
      formData.append("file_location", "common");
      formData.append("code", APIURL.code);
      formData.append("s_id", sId);
      formData.append("value", sVal);
      formData.append("status", true);
      try { 
        const response = await axios({ 
          method: "post",
          url: `${APIURL.url}/admin/updateSettings`,
          data: formData,
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: localStorage.getItem("token"),
          }, 
        });
        if (response) {
          setSId("");
          setSValue("");
          handleClose();
          reloadSettings.mutate();
        }
      } catch (error) {
        handleClose();
        if (error.response.status === 401) {
          window.location.reload();
          return navigate("/login");
        }
      }
    } 
  }
  //Color Change
  const handleSidebarColorSubmit = async (event) => {
    event.preventDefault(); 
    let jsonData = {
        "data": [
            {
                "s_id": sBarColor.id,
                "value": sbarBgColor,
                "status": true
            },
            {
              "s_id": sBarTxColor.id,
              "value": sbarTxtColor,
              "status": true
            },
        ]
    }
    try { 
      const response = await axios({
        method: "post",
        url: `${APIURL.url}/admin/settings`,
        data: jsonData,
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("token"),
        },
      });
      if (response) {
        reloadSettings.mutate();
        handleClose();
      }
    } catch (error) {
      handleClose();
      if (error.response.status === 401) {
        window.location.reload();
        return navigate("/login");
      }
    }
  }
  //Menu Items 
  const handleMenuSubmit = async (event) => {
    event.preventDefault(); 
    let jsonData = {
        "data": [
            {
                "s_id": side_bar1.id,
                "value": sidebar1,
                "status": s1Status
            },
            {
              "s_id": side_bar2.id,
              "value": sidebar2,
              "status": s2Status
            },
            {
              "s_id": side_bar3.id,
              "value": sidebar3,
              "status": s3Status
            },
            {
              "s_id": side_bar4.id,
              "value": sidebar4,
              "status": s4Status
            },
            {
              "s_id": side_bar5.id,
              "value": sidebar5,
              "status": s5Status
            },
            {
              "s_id": side_bar6.id,
              "value": sidebar6,
              "status": s6Status
            },
        ]
    }
    try { 
      const response = await axios({
        method: "post",
        url: `${APIURL.url}/admin/settings`,
        data: jsonData,
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("token"),
        },
      });
      if (response) {
        reloadSettings.mutate();
        handleMenuClose();
      }
    } catch (error) {
      handleMenuClose();
      if (error.response.status === 401) {
        window.location.reload();
        return navigate("/login");
      }
    }
  }
  //Social Links
  const handleSocialSubmit = async (event) => {
    event.preventDefault(); 
    let jsonData = {
        "data": [
            {
                "s_id": facebook.id,
                "value": facebookLink,
                "status": fStatus
            },
            {
              "s_id": linkedin.id,
              "value": linkedinLink,
              "status": lStatus
            },
            {
              "s_id": twitter.id,
              "value": twitterLink,
              "status": tStatus
            },
            {
              "s_id": instagram.id,
              "value": instagramLink,
              "status": iStatus
            },
            {
              "s_id": tiktok.id,
              "value": tiktokLink,
              "status": tiStatus
            },
        ]
    }
    try { 
      const response = await axios({
        method: "post",
        url: `${APIURL.url}/admin/settings`,
        data: jsonData,
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("token"),
        },
      });
      if (response) {
        reloadSettings.mutate();
        handleSocialClose();
      }
    } catch (error) {
      handleSocialClose();
      if (error.response.status === 401) {
        window.location.reload();
        return navigate("/login");
      }
    }
  }
  // Toggle related code ends here

  return (<>
    {finalRouteFlag === 'yes' && (
      <MDBox sx={{ position: "fixed", left: 0, top: 0, zIndex: 9999}}>
        <Tooltip title="Edit" placement="top">
          <Switch checked={pageStatus} onChange={handleEdit} />
        </Tooltip> 
      </MDBox>
      )}
    <SidenavRoot
      {...rest}
      variant="permanent"
      ownerState={{ transparentSidenav, whiteSidenav, miniSidenav, darkMode }}
    >
      <MDBox pt={3} pb={1} px={4} textAlign="center">
        <MDBox
          display={{ xs: "block", xl: "none" }}
          position="absolute"
          top={0}
          right={0}
          p={1.625}
          onClick={closeSidenav}
          sx={{ cursor: "pointer" }}
        >
          <MDTypography variant="h6" color="secondary">
            <Icon sx={{ fontWeight: "bold" }}>close</Icon>
          </MDTypography>
        </MDBox>
        <MDBox component={NavLink} to="/home" alignItems="center">
          {pageStatus && (
          <MDBox sx={{ position: "absolute", right: 0, top: 0, zIndex: 9999}}>
            <Tooltip title="Edit" placement="top"> 
              <MDButton
                variant="gradient"
                color="info"
                size="small"
                iconOnly
                onClick={() => handleEditImg(sidebarLogo)}
                sx={bgColorChange} 
              >
                <Icon>edit</Icon> 
              </MDButton>  
            </Tooltip> 
          </MDBox>
          )}
          {brand && <MDBox component="img" src={brand} alt="Brand" sx={{ width: "180px" }} />}
          {/* width="3rem"  */}
          {/* <MDBox
            width={!brandName && "100%"}
            sx={(theme) => sidenavLogoLabel(theme, { miniSidenav })}
          >
            <MDTypography component="h6" variant="button" fontWeight="medium" color={textColor}>
              {brandName}
            </MDTypography>
          </MDBox> */}
        </MDBox>
        {/* <MDBox component="img" src={brand} alt="Brand" width={100} /> */}
      </MDBox>
      <Divider
        light={
          (!darkMode && !whiteSidenav && !transparentSidenav) ||
          (darkMode && !transparentSidenav && whiteSidenav)
        }
      />
      <MDBox sx={{ position: "relative" }}>
        {pageStatus && (
        <MDBox sx={{ position: "absolute", right: 0, top: "65px", zIndex: 9999}}>
          <Tooltip title="Edit" placement="top"> 
            <MDBox sx={{ fontSize: "14px", cursor: "pointer" }} onClick={handleMenuItems}>
              <Icon sx={{ color: sidebarTxtColor.Value }}>edit</Icon>
            </MDBox>
          </Tooltip> 
        </MDBox>
        )}
      </MDBox>
      <List>
        {renderRoutes}
      </List>
      <Divider
        light={
          (!darkMode && !whiteSidenav && !transparentSidenav) ||
          (darkMode && !transparentSidenav && whiteSidenav)
        }
      />
      {/* <MDBox color={textColor}> */}
        <MDTypography
            // color={textColor}
            display="block"
            variant="caption"
            pl={3}
            mt={2}
            mb={1}
            ml={2}
            sx={{ fontSize: "18px", color: sidebarTxtColor.Value}}
          >
            <Icon mr={2} sx={{ float: "left", marginTop: "5px", marginRight:"12px", fontSize: "18px"}}>
          diamond
        </Icon>
        {pBal}pts
          </MDTypography>
        
      {/* </MDBox> */}
      {/* <SideDashboardNavbar /> */}
      <Divider
        light={
          (!darkMode && !whiteSidenav && !transparentSidenav) ||
          (darkMode && !transparentSidenav && whiteSidenav)
        }
      />
      <MDBox sx={{ position: "relative" }}>
        {pageStatus && (
        <MDBox sx={{ position: "absolute", right: 0, top: 0, zIndex: 9999}}>
          <Tooltip title="Edit" placement="top"> 
            <MDBox sx={{ fontSize: "14px", cursor: "pointer" }} onClick={() => handleSocialIcons()}>
              <Icon sx={{ color: sidebarTxtColor.Value }}>edit</Icon>
            </MDBox>
          </Tooltip> 
        </MDBox>
        )}
      </MDBox>
      <MDBox pt={3} pb={1} px={4} textAlign="center" sx={txtColorChange} className="sideNavSocialIcons">
        {twitter.Status && (
        <MuiLink href={twitter.Value} target="_blank" rel="noreferrer" mr={2} sx={{ color: sidebarTxtColor.Value }}>
          <TwitterIcon />
        </MuiLink>
        )}
        {facebook.Status && (
        <MuiLink href={facebook.Value} target="_blank" rel="noreferrer" mr={2} sx={{ color: sidebarTxtColor.Value }}>
          <FacebookIcon />
        </MuiLink>
        )}
        {instagram.Status && (
        <MuiLink href={instagram.Value} target="_blank" rel="noreferrer" mr={2} sx={{ color: sidebarTxtColor.Value }}>
          <InstagramIcon />
        </MuiLink>
        )}
        {linkedin.Status && (
        <MuiLink href={linkedin.Value} target="_blank" rel="noreferrer" mr={2} sx={{ color: sidebarTxtColor.Value }}>
          <LinkedInIcon />
        </MuiLink>
        )}
        {tiktok.Status && (
        <MuiLink href={tiktok.Value} target="_blank" rel="noreferrer" sx={{ color: sidebarTxtColor.Value }}>
          <span class="material-icons">tiktok </span>
        </MuiLink>
        )}
      </MDBox>
      <MDBox className="supportTxt" dangerouslySetInnerHTML={supportText()} sx={{ color: sidebarTxtColor.Value }}>
          {/* <p>For Support, please <a href="https://wirelessvision.sysaidit.com/" target="_blank">click here</a> to submit a vision direct ticket</p> */}
      </MDBox>
    </SidenavRoot>

    {/* Admin Edit Page Code Starts here */}
    <Modal
    open={open}
    onClose={handleClose}
    aria-labelledby="modal-modal-title"
    aria-describedby="modal-modal-description"
    >  
    <Box className="modalPopupBox">
      <Grid container>
        <Grid item xs={12}>
          <Typography id="keep-mounted-modal-title" variant="h5" component="h2" sx={txtColorChange}>
            Edit Logo
          </Typography> 
          <MDBox variant="outlined" className="close-button" onClick={handleClose}><CloseIcon /></MDBox> 
        </Grid>
      </Grid>
      <MDBox component="form" role="form" onSubmit={handleSubmit}>
        <Tabs value={value} onChange={handleChange} aria-label="Tabs">
          <Tab label="Logo" value={1} className="fontFamily" />
          <Tab label="Login Logos" value={2} className="fontFamily" />
          <Tab label="Sidebar Colors" value={3} className="fontFamily" />
        </Tabs>
        <TabPanel value={value} index={1}>  
          <Grid container>
            <Grid item xs={12}>
              <MDBox mt={2} mb={2} mr={1}>
                <span className="colorNote">side_bar_logo((180*100))</span>
                <MDInput type="file" fullWidth onChange={handleFileSelect} /> 
              </MDBox>
            </Grid>
          </Grid>
          <MDBox mt={2} mb={1}>  
            <MDButton type="submit" variant="gradient" color="info" sx={bgColorChange}>
              Save
            </MDButton>
          </MDBox>
        </TabPanel>
        <TabPanel value={value} index={2}>  
          <Grid container>
            <Grid item xs={12}>
              <img src={sbgImg.Value !== "" && sbgImg.Value} width="200" height="80" />
              <MDBox>  
                <span className="colorNote">background_image(1920*1080)</span>
                <MDInput type="file" fullWidth onChange={handleBFileSelect} /> 
              </MDBox>
            </Grid>
            <Grid item xs={12}>
              <img src={lsImg.Value !== "" && lsImg.Value} width="200" height="80" />
              <MDBox>
                <span className="colorNote">login_screen_logo(100*50)</span>
                <MDInput type="file" fullWidth onChange={handleLSFileSelect} /> 
              </MDBox>
            </Grid>
            <Grid item xs={12}>
              <img src={lfImg.Value !== "" && lfImg.Value} width="200" height="80" />
              <MDBox>
                <span className="colorNote">login_screen_fav_icon(25*25)</span>
                <MDInput type="file" fullWidth onChange={handleLFFileSelect} /> 
              </MDBox>
            </Grid>
          </Grid>
          <MDBox mt={2} mb={1}>  
            <MDButton type="button" variant="gradient" color="info" sx={bgColorChange} onClick={handleLogins}>
              Save
            </MDButton>
          </MDBox>
        </TabPanel>
        <TabPanel value={value} index={3}>
          <MDBox component="form" role="form">
            <Grid container>
              <Grid item xs={12}>
                <MDBox mt={2} mb={2}>
                  <MDInput
                    type="text"
                    label="sidebarBgColor [Menu Background Color]"
                    fullWidth
                    placeholder="sidebarBgColor [Menu Background Color]"
                    value={sbarBgColor}
                    onChange={(e) => {
                      setSBarBgColor(e.target.value);
                    }}
                  />
                  <span className="colorNote">(Use google to find Hexcode for color)</span>
                </MDBox>              
              </Grid>
              <Grid item xs={12}>
                <MDBox mb={2}>
                  <MDInput
                    type="text"
                    label="sidebarTxtColor [Menu Text Color]:"
                    fullWidth
                    placeholder="sidebarTxtColor [Menu Text Color]:"
                    value={sbarTxtColor}
                    onChange={(e) => {
                      setSBarTxtColor(e.target.value);
                    }}
                  />
                  <span className="colorNote">(Use google to find Hexcode for color)</span>
                </MDBox>              
              </Grid>
            </Grid>
            <MDBox mt={1} mb={1}>  
              <MDButton type="button" variant="gradient" color="info" sx={bgColorChange} onClick={handleSidebarColorSubmit}>
                Save
              </MDButton>
            </MDBox>
          </MDBox>
        </TabPanel> 
      </MDBox>
    </Box> 
    </Modal>

    <Modal
    open={openMenu}
    onClose={handleMenuClose}
    aria-labelledby="modal-modal-title"
    aria-describedby="modal-modal-description"
    >  
    <Box className="modalPopupBox">
      <Grid container>
        <Grid item xs={12}>
          <Typography id="keep-mounted-modal-title" variant="h5" component="h2" sx={txtColorChange}>
            Edit Menu Items
          </Typography> 
          <MDBox variant="outlined" className="close-button" onClick={handleMenuClose}><CloseIcon /></MDBox> 
        </Grid>
      </Grid>
      <MDBox component="form" role="form" onSubmit={handleMenuSubmit}>
        <Grid container>
          <Grid item xs={6}>
            <MDBox mt={2} mb={2} mr={1}>
              <MDInput
                  type="text"
                  label="Sidebar 1"
                  fullWidth
                  placeholder="Sidebar 1"
                  value={sidebar1}
                  onChange={(e) => {
                    setSidebar1(e.target.value);
                  }}
                />
            </MDBox>
          </Grid>
          <Grid item xs={6}>
            <MDBox mt={2} mb={2}>
              <MDTypography
                variant="button"
                fontWeight="regular"
                color="text"
                sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
              >
                &nbsp;&nbsp;Status :&nbsp;
              </MDTypography>
              <Switch checked={s1Status} onChange={handleS1Status} /> 
            </MDBox>
          </Grid>
          <Grid item xs={6}>
            <MDBox mt={2} mb={2} mr={1}>
              <MDInput
                  type="text"
                  label="Sidebar 2"
                  fullWidth
                  placeholder="Sidebar 2"
                  value={sidebar2}
                  onChange={(e) => {
                    setSidebar2(e.target.value);
                  }}
                />
            </MDBox>
          </Grid>
          <Grid item xs={6}>
            <MDBox mt={2} mb={2}>
              <MDTypography
                variant="button"
                fontWeight="regular"
                color="text"
                sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
              >
                &nbsp;&nbsp;Status :&nbsp;
              </MDTypography>
              <Switch checked={s2Status} onChange={handleS2Status} /> 
            </MDBox>
          </Grid>
          <Grid item xs={6}>
            <MDBox mt={2} mb={2} mr={1}>
              <MDInput
                  type="text"
                  label="Sidebar 3"
                  fullWidth
                  placeholder="Sidebar 3"
                  value={sidebar3}
                  onChange={(e) => {
                    setSidebar3(e.target.value);
                  }}
                />
            </MDBox>
          </Grid>
          <Grid item xs={6}>
            <MDBox mt={2} mb={2}>
              <MDTypography
                variant="button"
                fontWeight="regular"
                color="text"
                sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
              >
                &nbsp;&nbsp;Status :&nbsp;
              </MDTypography>
              <Switch checked={s3Status} onChange={handleS3Status} /> 
            </MDBox>
          </Grid>
          <Grid item xs={6}>
            <MDBox mt={2} mb={2} mr={1}>
              <MDInput
                  type="text"
                  label="Sidebar 4"
                  fullWidth
                  placeholder="Sidebar 4"
                  value={sidebar4}
                  onChange={(e) => {
                    setSidebar4(e.target.value);
                  }}
                />
            </MDBox>
          </Grid>
          <Grid item xs={6}>
            <MDBox mt={2} mb={2}>
              <MDTypography
                variant="button"
                fontWeight="regular"
                color="text"
                sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
              >
                &nbsp;&nbsp;Status :&nbsp;
              </MDTypography>
              <Switch checked={s4Status} onChange={handleS4Status} /> 
            </MDBox>
          </Grid>
          <Grid item xs={6}>
            <MDBox mt={2} mb={2} mr={1}>
              <MDInput
                  type="text"
                  label="Sidebar 5"
                  fullWidth
                  placeholder="Sidebar 5"
                  value={sidebar5}
                  onChange={(e) => {
                    setSidebar5(e.target.value);
                  }}
                />
            </MDBox>
          </Grid>
          <Grid item xs={6}>
            <MDBox mt={2} mb={2}>
              <MDTypography
                variant="button"
                fontWeight="regular"
                color="text"
                sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
              >
                &nbsp;&nbsp;Status :&nbsp;
              </MDTypography>
              <Switch checked={s5Status} onChange={handleS5Status} /> 
            </MDBox>
          </Grid>
          <Grid item xs={6}>
            <MDBox mt={2} mb={2} mr={1}>
              <MDInput
                  type="text"
                  label="Sidebar 6"
                  fullWidth
                  placeholder="Sidebar 6"
                  value={sidebar6}
                  onChange={(e) => {
                    setSidebar6(e.target.value);
                  }}
                />
            </MDBox>
          </Grid>
          <Grid item xs={6}>
            <MDBox mt={2} mb={2}>
              <MDTypography
                variant="button"
                fontWeight="regular"
                color="text"
                sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
              >
                &nbsp;&nbsp;Status :&nbsp;
              </MDTypography>
              <Switch checked={s6Status} onChange={handleS6Status} /> 
            </MDBox>
          </Grid>
        </Grid>
        <MDBox mt={2} mb={1}>  
          <MDButton type="submit" variant="gradient" color="info" sx={bgColorChange}>
            Save
          </MDButton>
        </MDBox>
      </MDBox>
    </Box> 
    </Modal>

    <Modal
    open={openSocial}
    onClose={handleSocialClose}
    aria-labelledby="modal-modal-title"
    aria-describedby="modal-modal-description"
    >  
    <Box className="modalPopupBox">
      <Grid container>
        <Grid item xs={12}>
          <Typography id="keep-mounted-modal-title" variant="h5" component="h2" sx={txtColorChange}>
            Edit Social Items
          </Typography> 
          <MDBox variant="outlined" className="close-button" onClick={handleSocialClose}><CloseIcon /></MDBox> 
        </Grid>
      </Grid>
      <MDBox component="form" role="form" onSubmit={handleSocialSubmit}>
        <Grid container>
          <Grid item xs={6}>
            <MDBox mt={2} mb={2} mr={1}>
              <MDInput
                type="text"
                label="Facebook Link"
                fullWidth
                placeholder="Facebook Link"
                value={facebookLink}
                onChange={(e) => {
                  setFacebookLink(e.target.value);
                }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={6}>
            <MDBox mt={2} mb={2}>
              <MDTypography
                variant="button"
                fontWeight="regular"
                color="text"
                sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
              >
                &nbsp;&nbsp;Status :&nbsp;
              </MDTypography>
              <Switch checked={fStatus} onChange={handleFStatus} /> 
            </MDBox>
          </Grid>          
          <Grid item xs={6}>
            <MDBox mt={2} mb={2} mr={1}>
              <MDInput
                type="text"
                label="Instagram Link"
                fullWidth
                placeholder="Instagram Link"
                value={instagramLink}
                onChange={(e) => {
                  setInstagramLink(e.target.value);
                }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={6}>
            <MDBox mt={2} mb={2}>
              <MDTypography
                variant="button"
                fontWeight="regular"
                color="text"
                sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
              >
                &nbsp;&nbsp;Status :&nbsp;
              </MDTypography>
              <Switch checked={iStatus} onChange={handleIStatus} /> 
            </MDBox>
          </Grid>
          <Grid item xs={6}>
            <MDBox mt={2} mb={2} mr={1}>
              <MDInput
                type="text"
                label="Linkedin Link"
                fullWidth
                placeholder="Linkedin Link"
                value={linkedinLink}
                onChange={(e) => {
                  setLinkedinLink(e.target.value);
                }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={6}>
            <MDBox mt={2} mb={2}>
              <MDTypography
                variant="button"
                fontWeight="regular"
                color="text"
                sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
              >
                &nbsp;&nbsp;Status :&nbsp;
              </MDTypography>
              <Switch checked={lStatus} onChange={handleLStatus} /> 
            </MDBox>
          </Grid>
          <Grid item xs={6}>
            <MDBox mt={2} mb={2} mr={1}>
              <MDInput
                type="text"
                label="Tiktok Link"
                fullWidth
                placeholder="Tiktok Link"
                value={tiktokLink}
                onChange={(e) => {
                  setTiktokLink(e.target.value);
                }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={6}>
            <MDBox mt={2} mb={2}>
              <MDTypography
                variant="button"
                fontWeight="regular"
                color="text"
                sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
              >
                &nbsp;&nbsp;Status :&nbsp;
              </MDTypography>
              <Switch checked={tiStatus} onChange={handleTiStatus} /> 
            </MDBox>
          </Grid>
        {/* </Grid>
        <Grid container> */}
          <Grid item xs={6}>
            <MDBox mt={2} mb={2} mr={1}>
              <MDInput
                type="text"
                label="Twitter Link"
                fullWidth
                placeholder="Twitter Link"
                value={twitterLink}
                onChange={(e) => {
                  setTwitterLink(e.target.value);
                }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={6}>
            <MDBox mt={2} mb={2}>
              <MDTypography
                variant="button"
                fontWeight="regular"
                color="text"
                sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
              >
                &nbsp;&nbsp;Status :&nbsp;
              </MDTypography>
              <Switch checked={tStatus} onChange={handleTStatus} /> 
            </MDBox>
          </Grid>
        </Grid> 
        <MDBox mt={2} mb={1}>  
          <MDButton type="submit" variant="gradient" color="info" sx={bgColorChange}>
            Save
          </MDButton>
        </MDBox>
      </MDBox>
    </Box> 
    </Modal>
    {/* Admin Edit Page Code Ends here */}
  </>);
}

// Setting default values for the props of Sidenav
Sidenav.defaultProps = {
  color: "info",
  brand: "",
};

// Typechecking props for the Sidenav
Sidenav.propTypes = {
  color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "dark"]),
  brand: PropTypes.string,
  brandName: PropTypes.string.isRequired,
  routes: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default Sidenav;
