/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/** 
  All of the routes for the Material Dashboard 2 PRO React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that contains other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

// Material Dashboard 2 PRO React layouts
import UserView from "layouts/Rewards/User";
import LoginView from "layouts/Rewards/Login";

// Material Dashboard 2 PRO React components

// @mui icons
import Icon from "@mui/material/Icon";
import Redeem from "layouts/Rewards/User/components/Redeem";
import Send from "layouts/Rewards/User/components/Send";
import AdminView from "layouts/Rewards/Admin";
import Redeemption from "layouts/Rewards/User/components/Redeemption";
import MDAvatar from "components/MDAvatar";
import ProfilePic from "layouts/Rewards/User/components/Common/ProfilePic";
import NewFeeds from "layouts/Rewards/User/components/Common/NewCelebrations/NewFeeds";
import Logout from "layouts/Rewards/User/components/Common/Logout";
import Dashboard from "layouts/Rewards/Admin/components/Dashboard";
import GroupGift from "layouts/Rewards/User/components/GroupGift";

const adminVal = localStorage.getItem("admin");
const profilPic = localStorage.getItem("profilepic");
const settings = localStorage.getItem("settings");

const calOption = JSON.parse(localStorage.getItem("celPage"));
const gOption = JSON.parse(localStorage.getItem("groupPage"));

let aFlag = false;
let finalRouteFlag = "no";
let givingAccount = false;
if (localStorage.getItem("token")) {
  const jose = require("jose");
  const claims = jose.decodeJwt(localStorage.getItem("token"));

  if (claims && claims.user_roles !== undefined) {
    if (claims.user_roles.includes('design')) {
      aFlag = true;
    } 
  }
  if (claims.sp_admin === "yes" || claims.admin === "yes") {
    finalRouteFlag = "yes";
  }
  givingAccount = claims.has_giving_account;
}

let defaultValue = { Name: "", Value: "", Status: false };

let menu1 = defaultValue;
let menu2 = defaultValue;
let menu3 = defaultValue;
let menu4 = defaultValue;
let menu5 = defaultValue;
let menu6 = defaultValue;

if (settings && settings !== undefined) {
  var obj = JSON.parse(settings);
  menu1 = obj.find((o) => o.Name === "side_bar_1");
  menu2 = obj.find((o) => o.Name === "side_bar_2");
  menu3 = obj.find((o) => o.Name === "side_bar_3");
  menu4 = obj.find((o) => o.Name === "side_bar_4");
  menu5 = obj.find((o) => o.Name === "side_bar_5");
  menu6 = obj.find((o) => o.Name === "side_bar_6");

  if (menu1 === undefined || menu2 === undefined || menu3 === undefined || menu4 === undefined || menu5 === undefined || menu6 === undefined) {
    menu1 = defaultValue;
    menu2 = defaultValue;
    menu3 = defaultValue;
    menu4 = defaultValue;
    menu5 = defaultValue;
    menu6 = defaultValue;
  }
}

let celebrate = {}
let groupGifting = {}

let celPerm = false;
let gPerm = false;
if (calOption) {
  if (calOption.read_permission) {
    celPerm = true;
  }
}
if (gOption) {
  if (gOption.read_permission === true) {
    gPerm = true;
  }
}

if (celPerm) {
  if (menu5.Status === true) {
    celebrate = {
      type: "collapse",
      name: menu5.Value,
      key: "celebration",
      icon: <Icon fontSize="medium">celebration</Icon>,
      route: "/new-celebrations",
      component: <NewFeeds />,
      noCollapse: true,
    }
  }
}

if (gPerm) {
  if (menu6.Status === true && givingAccount) {
    groupGifting = {
      type: "collapse",
      name: menu6.Value,
      key: "group-gifting",
      icon: <Icon fontSize="medium">money</Icon>,
      route: "/group-gifting",
      component: <GroupGift />, 
      noCollapse: true,
    }
  }
}

let iconInfo = <Icon>account_circle</Icon>;
if (profilPic != "") {
  iconInfo = <MDAvatar src={profilPic} alt={profilPic} size="sm" />;
}

let info;
if (finalRouteFlag === "yes") {
  info = [
    {
      name: "Admin",
      key: "admin",
      route: "/admin",
      component: <AdminView />,
    },
    {
      type: "collapse",
      name: "Profile Edit",
      key: "profile-pic",
      route: "/profile-pic",
      component: <ProfilePic />,
      noCollapse: true,
    },
    {
      name: "Logout",
      key: "logout",
      route: "/logout",
      component: <Logout />,
    },
  ];
} else if (finalRouteFlag === "no") {

  if (aFlag) {
    info = [
      {
        name: "Dashboard",
        key: "dashboard",
        route: "/dashboard",
        component: <Dashboard />,
      },
      {
        type: "collapse",
        name: "Profile Edit",
        key: "profile-pic",
        route: "/profile-pic",
        component: <ProfilePic />,
        noCollapse: true,
      },
      {
        name: "Logout",
        key: "logout",
        route: "/logout",
        component: <Logout />,
      },
    ];
  } else {
    info = [
      {
        type: "collapse",
        name: "Profile Edit",
        key: "profile-pic",
        route: "/profile-pic",
        component: <ProfilePic />,
        noCollapse: true,
      },
      {
        name: "Logout",
        key: "logout",
        route: "/logout",
        component: <Logout />,
      },
    ];
  }

}

const menu = [
  {
    type: "collapse",
    name: "Firstname Lastname",
    key: "user-name",
    icon: <Icon>account_circle</Icon>,
    // icon: iconInfo,
    collapse: info,
  },
  { type: "divider", key: "divider-01" },
  {
    type: "collapse",
    name: menu1.Value,
    key: "home",
    icon: <Icon fontSize="medium">home</Icon>,
    route: "/home",
    component: <UserView />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: menu2.Value,
    key: "my-rewards",
    icon: <Icon fontSize="medium">star</Icon>,
    route: "/my-rewards",
    component: <Redeem />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: menu3.Value,
    key: "giving",
    icon: <Icon fontSize="medium">favorite</Icon>,
    route: "/giving",
    component: <Send />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: menu4.Value,
    key: "redemption",
    icon: <Icon fontSize="medium">money</Icon>,
    route: "/redemption",
    component: <Redeemption />,
    noCollapse: true,
  },
  celebrate,
  groupGifting
];

const routes = menu;

export default routes;
