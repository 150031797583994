import React from 'react';

const Mention = ({ mention, onClick }) => {
  return (
    <span className="mention" onClick={onClick}>
      {mention}
    </span>
  );
};


export default Mention;